import React, { Fragment, useState } from "react";
import { Button, Card } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Calendar } from "react-feather";
import { addRemoveRTLLTRForDateRangePicker } from "../../helper/helper";
import { amountFormatter, customRange } from "../../constants/utils";
import { FormattedMessage } from "react-intl";
import { useLang } from "../../i18n";
import moment from "moment";

function DCSummaryWidgets({
  DCQuantitySummary,
  isLoading,
  handleDCSummaryFilter,
}) {
  const selectedLang = useLang();

  const [data] = DCQuantitySummary || [];
  const { Total_cost = 0, Po_and_Dc_summary = 0 } = data || {};
  // Predefined Range State
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [selectedDateOption, setselectedDateOption] = useState("This Month");

  const handleEvent = (event, picker) => {
    const { startDate, endDate, chosenLabel } = picker || {};
    const { _d: fromdate } = startDate || {};
    const { _d: todate } = endDate || {};
    handleDCSummaryFilter(
      chosenLabel,
      fromdate.toISOString(),
      todate.toISOString()
    );
    addRemoveRTLLTRForDateRangePicker();
    setFromDate(fromdate.toISOString());
    setToDate(todate.toISOString());
    setselectedDateOption(chosenLabel);
  };

  return (
    // TO DO :: customRange is by default selected
    <>
      <Card style={{ height: "calc(100% - 15px)" }}>
        <Card.Header className="d-flex justify-content-between flex-column flex-md-row">
          <Card.Title className="mb-0 modal-title">
            <FormattedMessage id="TITLE.DC.SUMMARY" />
          </Card.Title>
          <DateRangePicker
            key={"DC"}
            onApply={handleEvent}
            initialSettings={{
              startDate: moment().startOf("month"),
              endDate: moment().endOf("month"),
              ranges: customRange,
              opens: selectedLang === "ar" ? "right" : "left",
              applyButtonClasses: "btn-secondary",
            }}
          >
            <Button
              variant="tertiary"
              className="btn-icon btn-sm fs-14 f-w-600 mt-3 mt-md-0"
            >
              <Calendar size={16} />
              {selectedDateOption}
            </Button>
          </DateRangePicker>
        </Card.Header>
        <Card.Body
          className="p-0"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div className="inline-statistics">
            <div className="card-separator">
              <label className="d-block fs-14 text-center mb-2">
                <FormattedMessage id="TITLE.QUANTITY.DELIVERED" />
              </label>
              <h3 className="text-center text-primary font-weight-bold page-title">
                {Po_and_Dc_summary}
              </h3>
            </div>
            <div className="card-separator">
              <label className="d-block text-center mb-2">
                <FormattedMessage id="TITLE.TOTAL.COST" />
              </label>
              <h3 className="text-center text-primary font-weight-bold page-title">
                {amountFormatter(Total_cost)}
              </h3>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default DCSummaryWidgets;
