import React, { useState, useEffect } from "react";
import { Button, Modal, Row, Col, Form, Spinner } from "react-bootstrap";
import Select from "react-select";
import { X, Check } from "react-feather";
import { useFormik } from "formik";
import {
  editLocationApi,
  getSingleLocationDetailsApi,
} from "../axios/services/services";
import { EditLocationSchema } from "../helper/FormikSchema";
import { ErrorModal } from "../components/common/modals/ErrorModal";
import {
  getErrorMessageBasedOnLanguage,
  unauthorization,
} from "../constants/utils";
import { FormattedMessage, useIntl } from "react-intl";

const statusName = [
  { value: "1", label: "Active" },
  { value: "0", label: "Inactive" },
];

let EditLocationInitialValues = {
  name: "",
  description: "",
};

export function EditLocationModal({
  onCloseModal,
  open,
  data,
  id,
  handleCloseOfEditNewLocationModal,
  getParentLocationListing,
}) {
  // const { location = "Location 1", parentLocation = "Block 1" } = data || {};
  const [selectedOption, setSelectedOption] = useState({});
  const [isLoadingSingleLocation, setLoadingSingleLocation] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [locationDetails, setLocationDetails] = useState({});
  const [isSaveLoading, setSaveLoading] = useState(false);
  const [locationEdited, setLocationEdited] = useState(false);
  const [selectedParentLocation, setSelectedParentLocation] = useState(null);
  const parentList = getParentLocationListing.filter(function (element) {
    return element !== undefined;
  });

  const intl = useIntl();
  const formikEditLocation = useFormik({
    initialValues: EditLocationInitialValues,
    validationSchema: EditLocationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableSaveLoading();
      const { store_location } = values || {};
      const { value: status } = selectedOption;
      const { value: parent_location } = selectedParentLocation;
      let obj = {
        id,
        store_location,
        parent_location,
        status,
      };

      const editLocationResponse = await editLocationApi(obj);

      const { data, Error } = editLocationResponse || {};
      if (Error) {
        disableSaveLoading();
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setLocationEdited(true);
      window.scrollTo(0, 0);
      setTimeout(() => {
        disableSaveLoading();
        handleCloseOfEditNewLocationModal(data);
      }, 2500);
    },
  });

  async function getSingleLocationDetails() {
    enableLoadingSingleLocation();
    const singleLocationDetails = await getSingleLocationDetailsApi({ id });
    disableLoadingSingleLocation(false);
    const { Error, data } = singleLocationDetails || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setLocationDetails(data ? data : []);
    const { store_location, status } = data || {};
    let statusName = {};
    if (status === 1) {
      setSelectedOption({ value: "1", label: "Active" });
    } else {
      setSelectedOption({ value: "0", label: "Inactive" });
    }
    const { locationId } = data || {};
    const { store_location: loc, id: locId } = locationId || {};
    setSelectedParentLocation({ value: locId, label: loc });
    // setSelectedOption({ value: "1", label: "Active" });
    // console.log(selectedOption);

    formikEditLocation.setFieldValue("store_location", store_location);
  }

  useEffect(() => {
    getSingleLocationDetails();
  }, []);

  const enableSaveLoading = () => {
    setSaveLoading(true);
  };

  const disableSaveLoading = () => {
    setSaveLoading(false);
  };

  const enableLoadingSingleLocation = () => {
    setLoadingSingleLocation(true);
  };

  const disableLoadingSingleLocation = () => {
    setLoadingSingleLocation(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  return (
    <>
      <Modal
        size="md"
        show={open}
        onHide={onCloseModal}
        dialogClassName="inventory-small-modal"
      >
        <Modal.Header
          className="d-flex justify-content-between align-items-center"
          toggle={onCloseModal}
        >
          <h3 className="modal-title" id="exampleModalLabel2">
            <FormattedMessage id="TITLE.EDIT.LOCATION" />
            {isLoadingSingleLocation && (
              <Spinner animation="border" size="sm" className="ml-1" />
            )}
          </h3>
          <Button
            variant="link p-0 btn-icon"
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <X size={20} className="text-dark mr-0 pr-0" />
          </Button>
        </Modal.Header>
        <Modal.Body className="pb-0">
          {locationEdited && (
            <div className="notification icon inline success fs-16">
              <Check size={8} className="text-white"></Check>{" "}
              <FormattedMessage id="TITLE.LOCATION.EDITED.SUCCESSFULLY" />
            </div>
          )}
          <Form autoComplete="off">
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor="parentlocation">
                    <FormattedMessage id="TITLE.PARENT.LOCATION" />
                  </Form.Label>
                  <Select
                    value={selectedParentLocation}
                    onChange={setSelectedParentLocation}
                    isSearchable={true}
                    options={parentList}
                    placeholder={intl.formatMessage({
                      id: "TITLE.SELECT.PARENT.LOCATION",
                    })}
                    id="parentlocation"
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor="buildingnumber">
                    <FormattedMessage id="TITLE.STORE.LOCATION" />
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="buildingnumber"
                    placeholder={intl.formatMessage({
                      id: "TITLE.STORE.LOCATION",
                    })}
                    value={selectedParentLocation}
                    required
                    {...formikEditLocation.getFieldProps("store_location")}
                  />
                  {formikEditLocation.touched.store_location &&
                  formikEditLocation.errors.store_location ? (
                    <div className="text-danger">
                      {formikEditLocation.errors.store_location}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              {/* <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="street1">
                    Street 1<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="street1"
                    placeholder="Street 1"
                    value={street_1}
                    required
                  />
                </Form.Group>
              </Col> */}
              {/* <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="street2">Street 2</Form.Label>
                  <Form.Control
                    type="text"
                    id="street2"
                    placeholder="Street 2"
                    value={street_2}
                    required
                  />
                </Form.Group>
              </Col> */}
              {/* <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="country">
                    Country<span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    defaultValue={selectedCountry}
                    onChange={setSelectedCountry}
                    isSearchable={true}
                    options={countriesList}
                    placeholder="Select Country"
                    id="country"
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </Form.Group>
              </Col> */}
              {/* <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="state">
                    State<span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    defaultValue={selectedState}
                    onChange={setSelectedState}
                    isSearchable={true}
                    options={stateList}
                    placeholder="Select State"
                    id="state"
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </Form.Group>
              </Col> */}
              {/* <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="city">
                    City<span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    defaultValue={selectedCity}
                    onChange={setSelectedCity}
                    isSearchable={true}
                    options={cityList}
                    placeholder="Select City"
                    id="city"
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </Form.Group>
              </Col> */}

              {/* <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="zipcode">
                    Zipcode<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="zipcode"
                    placeholder="Zipcode"
                    value={zipcode}
                    required
                  />
                </Form.Group>
              </Col> */}
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor="status">
                    <FormattedMessage id="TITLE.STATUS" />
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    value={selectedOption}
                    onChange={(value) => {
                      handleOptionChange(value);
                    }}
                    isSearchable={true}
                    options={statusName}
                    placeholder={intl.formatMessage({
                      id: "TITLE.SELECT.STATUS",
                    })}
                    id="status"
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success mr-3 btn-inventory"
            onClick={() => {
              formikEditLocation.handleSubmit();
            }}
            disabled={isSaveLoading}
          >
            <FormattedMessage id="TITLE.SAVE" />
            {isSaveLoading && (
              <Spinner animation="border" size="sm" className="ml-1" />
            )}
          </Button>
          <Button
            type="button"
            variant="tertiary text-dark"
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <FormattedMessage id="TITLE.CANCEL" />
          </Button>
        </Modal.Footer>
      </Modal>
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
