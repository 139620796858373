/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { capitalizeFirstLetter } from "../constants/utils";

const CategorynameFormatter = (cellContent, row, rowIndex) => {
  const { name } = row;
  return capitalizeFirstLetter(name);
};

export default CategorynameFormatter;
