import React, { useState } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import { X, Check } from "react-feather";
import { addLocationApi } from "../axios/services/services";
import { useFormik } from "formik";
import { ButtonLoader } from "../helper/ButtonLoader";
import { AddLocationSchema } from "../helper/FormikSchema";
import { getErrorMessageBasedOnLanguage } from "../constants/utils";
import { FormattedMessage, useIntl } from "react-intl";
import { ErrorModal } from "../components/common/modals/ErrorModal";
import { unauthorization } from "../constants/utils";

let AddLocationInitialValues = {
  store_location: "",
};

export function AddNewLocationModal({
  onCloseModal,
  open,
  handleCloseOfAddNewLocationModal,
  locationList,
  parentLocationListing,
}) {
  const [selectedParentLocation, setSelectedParentLocation] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isNewLocationAdded, setNewLocationAdded] = useState(false);

  const formikAddLocation = useFormik({
    enableReinitialize: true,
    initialValues: AddLocationInitialValues,
    validationSchema: AddLocationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      const { store_location } = values || {};
      const { value: parent_location } = selectedParentLocation || {};
      let addLocationObj = {
        store_location,
        parent_location,
      };
      
      const addLocationResponse = await addLocationApi(addLocationObj);
      const { data, Error } = addLocationResponse || {};
      if (Error) {
        disableLoading();
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setNewLocationAdded(true);
      setTimeout(() => {
        disableLoading();
        window.scrollTo(0, 0);
        handleCloseOfAddNewLocationModal(data);
      }, 2500);
    },
    onReset: (values, { resetForm }) => {},
  });
  const intl = useIntl();
  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };
  return (
    <>
      <Modal
        size="md"
        show={open}
        onHide={onCloseModal}
        aria-labelledby="example-modal-sizes-title-sm"
        dialogClassName="inventory-small-modal modal-md"
      >
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <h3 className="modal-title" id="exampleModalLabel2">
            <FormattedMessage id="TITLE.ADD.NEW.LOCATION" />
          </h3>
          <Button
            variant="link p-0 btn-icon"
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <X size={20} className="text-dark mr-0 pr-0" />
          </Button>
        </Modal.Header>
        <Modal.Body className="pb-0">
          {isNewLocationAdded && (
            <div className="notification icon inline success fs-16">
              <Check size={8} className="text-white"></Check>{" "}
              <FormattedMessage id="TITLE.NEW.STORE.LOCATION.SUCCESSFULLY.CREATED" />
            </div>
          )}
          <Form autoComplete="off">
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor="parentlocation">
                    <FormattedMessage id="TITLE.PARENT.LOCATION" />
                  </Form.Label>
                  <Select
                    onChange={(data) => {
                      setSelectedParentLocation(data);
                    }}
                    isSearchable={true}
                    options={parentLocationListing}
                    placeholder={intl.formatMessage({
                      id: "TITLE.SELECT.PARENT.LOCATION",
                    })}
                    id="parentlocation"
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor="buildingnumber">
                    <FormattedMessage id="TITLE.STORE.LOCATION" />
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="buildingnumber"
                    placeholder={intl.formatMessage({
                      id: "TITLE.STORE.LOCATION",
                    })}
                    required
                    {...formikAddLocation.getFieldProps("store_location")}
                  />
                  {formikAddLocation.touched.store_location &&
                  formikAddLocation.errors.store_location ? (
                    <div className="text-danger">
                      {formikAddLocation.errors.store_location}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            variant="success"
            onClick={() => {
              window.scrollTo(0, 0);
              formikAddLocation.handleSubmit();
            }}
            disabled={isLoading}
          >
            <FormattedMessage id="TITLE.SAVE" />
            {isLoading && <ButtonLoader className="mr-1 ml-1" />}
          </Button>
          <Button
            type="button"
            variant="tertiary text-dark"
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <FormattedMessage id="TITLE.CANCEL" />
          </Button>
        </Modal.Footer>
      </Modal>

      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
