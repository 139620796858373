import React, { useEffect, useMemo, useState } from "react";
import { Card, Button, ButtonGroup } from "react-bootstrap";
import { Upload, Filter, Plus } from "react-feather";
import { CategoryFilter } from "./category-filter/CategoryFilter";
import { CategoryTable } from "./category-table/CategoryTable";
import { useCategoryUIContext } from "./CategoryUIContext";
import { FormattedMessage } from "react-intl";
import { categoryListExportApi } from "../../axios/services/services";

export function CategoryCard({
  isLoadingFilter,
  isLoading,
  isFilterOpen,
  categoryList,
  exportCSVButton,
  transactionListExport,
  categoryListTotalCount,
  currentPage,
  setCurrentPage,
  onClickOfCreateNew,
  handleClickOfRemoveFilterButton,
  handleCLickOfFilterButtonFilter,
}) {
  const categoryUIContext = useCategoryUIContext();
  const categoryUIProps = useMemo(() => {
    return {
      ids: categoryUIContext.ids,
      queryParams: categoryUIContext.queryParams,
      setQueryParams: categoryUIContext.setQueryParams,
      openViewDialog: categoryUIContext.openViewDialog,
      openEditDialog: categoryUIContext.openEditDialog,
      openDeleteDialog: categoryUIContext.openDeleteDialog,
    };
  }, [categoryUIContext]);

  async function handleExport() {
    await categoryListExportApi({
      filename: "Category",
    });
  }

  return (
    <>
      <Card className="card-custom card-stretch make-payment-wrapper">
        <Card.Header className="align-items-center d-flex justify-content-between">
          <h3 className="card-title page-title text-black mb-0">
            {" "}
            <FormattedMessage id="TITLE.CATEGORIES" />
          </h3>
          <div className="d-flex">
            <ButtonGroup className="mr-3">
              <Button
                variant="tertiary d-flex align-items-center btn-inventory"
                onClick={() => {
                  handleClickOfRemoveFilterButton();
                }}
              >
                <Filter size={16} style={{ color: "#CAA25C" }} />{" "}
                <span className="d-none d-md-flex">
                  {isFilterOpen ? (
                    <FormattedMessage id="TITLE.REMOVE" />
                  ) : (
                    <FormattedMessage id="TITLE.FILTER" />
                  )}
                </span>
              </Button>

              <Button
                variant="tertiary d-flex align-items-center btn-inventory"
                onClick={() => {
                  handleExport();
                }}
              >
                <Upload size={16} style={{ color: "#CAA25C" }} />
                <span className="d-none d-md-flex">
                  <FormattedMessage id="TITLE.EXPORT" />
                </span>
              </Button>
            </ButtonGroup>
            <Button
              variant="primary d-flex align-items-center btn-inventory"
              onClick={() => {
                onClickOfCreateNew();
              }}
            >
              <Plus size={16} style={{ color: "#CAA25C" }} />
              <span className="d-none d-md-flex">
                {" "}
                <FormattedMessage id="TITLE.CREATE.NEW" />
              </span>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          {isFilterOpen && (
            <CategoryFilter
              isLoadingFilter={isLoadingFilter}
              handleCLickOfFilterButtonFilter={handleCLickOfFilterButtonFilter}
            />
          )}
          <CategoryTable
            isLoading={isLoading}
            categoryList={categoryList}
            categoryListTotalCount={categoryListTotalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Card.Body>
      </Card>
    </>
  );
}
