import React, { createContext, useContext, useCallback } from "react";
import { isEqual, isFunction } from "lodash";

const OutwardUIContext = createContext();

export function useOutwardUIContext() {
  return useContext(OutwardUIContext);
}

export const OutwardUIConsumer = OutwardUIContext.Consumer;

export function OutwardUIProvider({
  outwardUIEvents,
  children,
  queryParams,
  setQueryParamsBase,
  ids,
  setIds,
}) {
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    openViewDialog: outwardUIEvents.openViewDialog,
    openEditDialog: outwardUIEvents.openEditDialog,
    openEditStatusDialog: outwardUIEvents.openEditStatusDialog,
    openDeleteDialog: outwardUIEvents.openDeleteDialog,
    redirectToViewPage: outwardUIEvents.redirectToViewPage,
    redirectToCustomerViewPage: outwardUIEvents.redirectToCustomerViewPage,
    openAgreeStatusDialog: outwardUIEvents.openAgreeStatusDialog,
    openDisagreeStatusDialog: outwardUIEvents.openDisagreeStatusDialog,
  };

  return (
    <OutwardUIContext.Provider value={value}>
      {children}
    </OutwardUIContext.Provider>
  );
}
