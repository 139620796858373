/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
const EmailColumnFormatter = (cellContent, row, rowIndex) => {
  const { email = "-" } = row || {};
  return (
    <>
      {!email || email === null ? "-" : email} <> </>
    </>
  );
};

export default EmailColumnFormatter;
