import * as Yup from "yup";
import { FormattedMessage } from "react-intl";

export const LoginSchema = Yup.object().shape({
  emp_id: Yup.string().trim("").required("TITLE.EMPLOYEE.ID.REQUIRED"),
  password: Yup.string()
    .trim("")
    // .min(8, "TITLE.PASSWORD.SHOULD.BE.ATLEAST.8.CHARACTERS")
    // .max(132, "TITLE.PASSWORD.SHOULD.NOT.BE.MORE.THAN.132.CHARACTERS")
    // .matches(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    //   "TITLE.PASSWORD.MUST.HAVE.LEAST.UPPERCASE.LETTER.LOWERCASE.LETTER.NUMBER.AND.SYMBOL"
    // )
    .required("TITLE.PASSWORD.IS.REQUIRED"),
});

export const ForgotPasswordSchema = Yup.object().shape({
  emp_id: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.EMPLOYEE.ID.IS.REQUIRED" />),
});

export const AddVendorSchema = Yup.object().shape({
  contact_name: Yup.string()
    .trim("")
    .min(1, "TITLE.VENDOR.DISPLAY.NAME.SHOULD.BE.GRATER.THAN.1.CHARACTER")
    .max(
      132,
      "TITLE.VENDOR.DISPLAY.NAME.SHOULD.NOT.BE.GRATER.THAN.132.CHARACTER"
    )
    .matches(/^[a-zA-Z\s]*$/, "TITLE.VENDOR.DISPLAY.NAME.SHOULD.BE.VALID")
    .required("TITLE.VENDOR.DISPLAY.NAME.IS.REQUIRED"),
  phone_number: Yup.string()
    .trim("")
    .matches(
      /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
      "TITLE.PHONE.NUMBER.SHOULD.BE.VALID"
    ),
  mobile_number: Yup.string()
    .trim("")
    .matches(
      /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
      "TITLE.MOBILE.NUMBER.SHOULD.BE.VALID"
    ),
  email: Yup.string()
    .email(<FormattedMessage id="TITLE.INVALID.EMAIL.ADDRESS" />)
    .trim(""),
  // .required(<FormattedMessage id="TITLE.VENDOR.EMAIL.ADDRESS.IS.REQUIRED" />),
  // phone_number: Yup.string()
  //   .trim("")
  //   .matches(
  //     /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
  //     "TITLE.PHONE.NUMBER.SHOULD.BE.VALID"
  //     // <FormattedMessage id="TITLE.PHONE.NUMBER.SHOULD.BE.VALID" />
  //   )
  //   .required("TITLE.VENDOR.PHONE.NUMBER.IS.REQUIRED"),
  // mobile_number: Yup.string()
  //   .trim("")
  //   .matches(
  //     /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
  //     "TITLE.MOBILE.NUMBER.SHOULD.BE.VALID"
  //     // <FormattedMessage id="TITLE.MOBILE.NUMBER.SHOULD.BE.VALID" />
  //   )
  //   .required("TITLE.VENDOR.MOBILE.NUMBER.IS.REQUIRED"),
  // currency: Yup.string()
  //   .trim("")
  //   .min(
  //     1,
  //     <FormattedMessage id="TITLE.CURRENCY.SHOULD.BE.GREATER.THAN.1.CHARACTERS" />
  //   )
  //   .min(
  //     3,
  //     <FormattedMessage id="TITLE.CURRENCY.SHOULD.NOT.BE.GREATER.THAN.3.CHARACTERS" />
  //   )
  //   .required(<FormattedMessage id="TITLE.CURRENCY.IS.REQUIRED" />),
  // billingAddress: Yup.string()
  //   .trim("")
  //   .required(<FormattedMessage id="TITLE.BILLING.ADDRESS.IS.REQUIRED" />),
  // billingCity: Yup.string()
  //   .trim("")
  //   .required(<FormattedMessage id="TITLE.BILLING.CITY.IS.REQUIRED" />),
  billingZipcode: Yup.string().max(
    5,
    <FormattedMessage id="TITLE.ZIP.POSTAL.CODE.SHOULD.NOT.BE.GREATER.THAN.5.CHARACTERS" />
  ),
  // .required(<FormattedMessage id="TITLE.ZIP.POSTAL.CODE.IS.REQUIRED" />),
  // shippingAddress: Yup.string()
  //   .trim("")
  //   .required(<FormattedMessage id="TITLE.SHIPPING.ADDRESS.IS.REQUIRED" />),
  // shippingCity: Yup.string()
  //   .trim("")
  //   .required(<FormattedMessage id="TITLE.SHIPPING.CITY.IS.REQUIRED" />),
  shippingZipcode: Yup.string()
    .trim("")
    .max(
      5,
      <FormattedMessage id="TITLE.SHIPPING.ZIPCODE.SHOULD.NOT.BE.GREATER.THAN.5.DIGITS" />
    ),
  // .required(<FormattedMessage id="TITLE.ZIP.POSTAL.CODE.IS.REQUIRED" />),
  contact_persons: Yup.array().of(
    Yup.object().shape({
      first_name: Yup.string().matches(
        /^[a-zA-Z\s]*$/,
        "TITLE.FIRST.NAME.SHOULD.BE.VALID"
      ),
      // .required("TITLE.REQUIRED"),
      last_name: Yup.string().matches(
        /^[a-zA-Z\s]*$/,
        "TITLE.LAST.NAME.SHOULD.BE.VALID"
      ),
      // .required("TITLE.REQUIRED"),
      email: Yup.string().email(
        <FormattedMessage id="TITLE.EMAIL.IS.INVALID" />
      ),
      // .required(<FormattedMessage id="TITLE.REQUIRED" />),
      phone: Yup.string()
        .trim("")
        .matches(
          /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
          "TITLE.PHONE.SHOULD.BE.VALID"
        ),
      // .required("TITLE.REQUIRED"),
      mobile: Yup.string()
        .trim("")
        .matches(
          /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
          "TITLE.MOBILE.SHOULD.BE.VALID"
        ),
      // .required("TITLE.REQUIRED"),
    })
  ),
  remarks: Yup.string()
    .trim("")
    .max(
      512,
      <FormattedMessage id="TITLE.REMARKS.SHOULD.NOT.BE.GREATER.THAN.512.CHARACTERS" />
    ),
});

export const AddCustomerSchema = Yup.object().shape({
  contact_name: Yup.string()
    .trim("")
    .min("1", "TITLE.CUSTOMER.DISPLAY.NAME.SHOULD.BE.GRATER.THAN.1.CHARACTER")
    .max(
      "132",
      "TITLE.CUSTOMER.DISPLAY.NAME.SHOULD.NOT.BE.GRATER.THAN.132.CHARACTER"
    )
    .matches(/^[a-zA-Z\s]*$/, "TITLE.CUSTOMER.DISPLAY.NAME.SHOULD.BE.VALID")
    .required("TITLE.CUSTOMER.DISPLAY.NAME.IS.REQUIRED"),
  phone_number: Yup.string()
    .trim("")
    .matches(
      /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
      "TITLE.PHONE.NUMBER.SHOULD.BE.VALID"
    ),
  mobile_number: Yup.string()
    .trim("")
    .matches(
      /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
      "TITLE.MOBILE.NUMBER.SHOULD.BE.VALID"
    ),
  email: Yup.string()
    .email(<FormattedMessage id="TITLE.INVALID.EMAIL.ADDRESS" />)
    .trim(""),
  // .required(
  //   <FormattedMessage id="TITLE.CUSTOMER.EMAIL.ADDRESS.IS.REQUIRED" />
  // ),
  // phone_number: Yup.string()
  //   .trim("")
  //   .matches(
  //     /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
  //     "TITLE.PHONE.NUMBER.SHOULD.BE.VALID"
  //     // <FormattedMessage id="TITLE.PHONE.NUMBER.SHOULD.BE.VALID" />
  //   )
  //   .required("TITLE.CUSTOMER.PHONE.NUMBER.IS.REQUIRED"),
  // mobile_number: Yup.string()
  //   .trim("")
  //   .matches(
  //     /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
  //     "TITLE.MOBILE.NUMBER.SHOULD.BE.VALID"
  //     // <FormattedMessage id="TITLE.MOBILE.NUMBER.SHOULD.BE.VALID" />
  //   )
  //   .required("TITLE.CUSTOMER.MOBILE.NUMBER.IS.REQUIRED"),
  // currency: Yup.string()
  //   .trim("")
  //   .min(
  //     1,
  //     <FormattedMessage id="TITLE.CURRENCY.SHOULD.BE.GREATER.THAN.1.CHARACTERS" />
  //   )
  //   .min(
  //     3,
  //     <FormattedMessage id="TITLE.CURRENCY.SHOULD.NOT.BE.GREATER.THAN.3.CHARACTERS" />
  //   )
  //   .required(<FormattedMessage id="TITLE.CURRENCY.IS.REQUIRED" />),
  // billingAddress: Yup.string()
  //   .trim("")
  //   .required(<FormattedMessage id="TITLE.BILLING.ADDRESS.IS.REQUIRED" />),
  // billingCity: Yup.string()
  //   .trim("")
  //   .required(<FormattedMessage id="TITLE.BILLING.CITY.IS.REQUIRED" />),
  billingZipcode: Yup.string().max(
    5,
    <FormattedMessage id="TITLE.ZIP.POSTAL.CODE.SHOULD.NOT.BE.GREATER.THAN.5.CHARACTERS" />
  ),
  //   .required(<FormattedMessage id="TITLE.ZIP.POSTAL.CODE.IS.REQUIRED" />),
  // shippingAddress: Yup.string()
  //   .trim("")
  //   .required(<FormattedMessage id="TITLE.SHIPPING.ADDRESS.IS.REQUIRED" />),
  // shippingCity: Yup.string()
  //   .trim("")
  //   .required(<FormattedMessage id="TITLE.SHIPPING.CITY.IS.REQUIRED" />),
  shippingZipcode: Yup.string()
    .trim("")
    .max(
      5,
      <FormattedMessage id="TITLE.SHIPPING.ZIPCODE.SHOULD.NOT.BE.GREATER.THAN.5.DIGITS" />
    ),
  //   .required(<FormattedMessage id="TITLE.ZIP.POSTAL.CODE.IS.REQUIRED" />),
  contact_persons: Yup.array().of(
    Yup.object().shape({
      first_name: Yup.string().matches(
        /^[a-zA-Z\s]*$/,
        "TITLE.FIRST.NAME.SHOULD.BE.VALID"
      ),
      // .required(<FormattedMessage id="TITLE.REQUIRED" />),
      last_name: Yup.string().matches(
        /^[a-zA-Z\s]*$/,
        "TITLE.LAST.NAME.SHOULD.BE.VALID"
      ),
      // .required(<FormattedMessage id="TITLE.REQUIRED" />),
      email: Yup.string().email(
        <FormattedMessage id="TITLE.EMAIL.IS.INVALID" />
      ),
      // .required(<FormattedMessage id="TITLE.REQUIRED" />),
      phone: Yup.string()
        .trim("")
        .matches(
          /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
          "TITLE.PHONE.SHOULD.BE.VALID"
        ),
      // .required(<FormattedMessage id="TITLE.REQUIRED" />),
      mobile: Yup.string()
        .trim("")
        .matches(
          /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
          "TITLE.MOBILE.SHOULD.BE.VALID"
        ),
      // .required(<FormattedMessage id="TITLE.REQUIRED" />),
    })
  ),
  remarks: Yup.string().trim(""),
});

export const AddProductSchema = Yup.object().shape({
  name: Yup.string()
    .trim("")
    .min(
      "1",
      <FormattedMessage id="TITLE.PRODUCT.NAME.SHOULD.BE.GREATER.THAN.1.CHARACTERS" />
    )
    .max(
      "255",
      <FormattedMessage id="TITLE.PRODUCT.NAME.SHOULD.NOT.BE.GREATER.THAN.255.CHARACTERS" />
    )
    .required(<FormattedMessage id="TITLE.PRODUCT.NAME.IS.REQUIRED" />),
  categoryId: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.CATEGORY.IS.REQUIRED" />),
  stock_keeping_unit: Yup.string().trim(""),
  // .required(<FormattedMessage id="TITLE.SKU.IS.REQUIRED" />),
  opening_stock: Yup.number(),
  // .required(<FormattedMessage id="TITLE.OPENING.STOCK.IS.REQUIRED" />),
  reorder_level: Yup.string()
    .trim("")
    .min(
      "1",
      <FormattedMessage id="TITLE.REORDER.LEVEL.SHOULD.BE.GREATER.THAN.1.UNIT" />
    )
    .max(
      4,
      <FormattedMessage id="TITLE.REORDER.LEVEL.SHOULD.NOT.BE.GREATER.THAN.4.UNIT" />
    ),
  // .required(<FormattedMessage id="TITLE.REORDER.LEVEL.IS.REQUIRED" />),
  price: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.PRICE.IS.REQUIRED" />),
  description: Yup.string()
    .trim("")
    .max(
      "512",
      <FormattedMessage id="TITLE.DESCRIPTION.SHOULD.NOT.BE.GREATER.THAN.512.CHARACTERS" />
    ),
  shelf_life: Yup.number()
    .min(
      0,
      <FormattedMessage id="TITLE.SHELF.LIFE.SHOULD.NOT.BE.LESS.THAN.0.DAY" />
    )
    .max(
      700,
      <FormattedMessage id="TITLE.SHELF.LIFE.SHOULD.NOT.BE.GREATER.THAN.700.DAYS" />
    ),
  // .required(<FormattedMessage id="TITLE.SHELF.LIFE.IS.REQUIRED" />),
  productunitId: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.PRODUCT.UNIT.IS.REQUIRED" />),
  product_media: Yup.string().trim(""),
  // .required(<FormattedMessage id="TITLE.PRODUCT.MEDIA.IS.REQUIRED" />),
});

export const AddInwardSchema = Yup.object().shape({
  contactId: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.VENDOR.IS.REQUIRED" />),
  date: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.DATE.IS.REQUIRED" />),
  notes: Yup.string().trim(""),
  // .max(
  //   255,
  //   <FormattedMessage id="TITLE.NOTES.SHOULD.NOT.BE.GREATER.THAN.255.CHARACTERS" />
  // ),
  expected_delivery: Yup.string().trim(""),
  // .required(
  //   <FormattedMessage id="TITLE.EXPECTED.DELIVERY.DATE.IS.REQUIRED" />
  // ),
  courier_company_name: Yup.string()
    .trim("")
    .max(
      132,
      <FormattedMessage id="TITLE.COURIER.COMPANY.NAME.SHOULD.NOT.BE.GREATER.THAN.132.CHARACTERS" />
    ),
  multiple_products: Yup.array().of(
    Yup.object().shape({
      productId: Yup.string().required(
        <FormattedMessage id="TITLE.REQUIRED" />
      ),
      quantity: Yup.string().required(<FormattedMessage id="TITLE.REQUIRED" />),
      price: Yup.string().required(<FormattedMessage id="TITLE.REQUIRED" />),
    })
  ),
});

export const AddOutwardSchema = Yup.object().shape({
  contactId: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.CUSTOMER.IS.REQUIRED" />),
  date: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.DATE.IS.REQUIRED" />),
  notes: Yup.string().trim(""),
  // .max(
  //   255,
  //   <FormattedMessage id="TITLE.NOTES.SHOULD.NOT.BE.GREATER.THAN.255.CHARACTERS" />
  // ),
  expected_delivery: Yup.string().trim(""),
  // .required(
  //   <FormattedMessage id="TITLE.EXPECTED.DELIVERY.DATE.IS.REQUIRED" />
  // ),
  courier_company_name: Yup.string()
    .trim("")
    .max(
      132,
      <FormattedMessage id="TITLE.COURIER.COMPANY.NAME.SHOULD.NOT.BE.GREATER.THAN.132.CHARACTERS" />
    ),
  multiple_products: Yup.array().of(
    Yup.object().shape({
      productId: Yup.string().required(
        <FormattedMessage id="TITLE.REQUIRED" />
      ),
      quantity: Yup.string().required(<FormattedMessage id="TITLE.REQUIRED" />),
      price: Yup.string().required(<FormattedMessage id="TITLE.REQUIRED" />),
    })
  ),
});

export const EditInwardSchema = Yup.object().shape({
  contactId: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.VENDOR.IS.REQUIRED" />),
  date: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.DATE.IS.REQUIRED" />),
  notes: Yup.string().trim(""),
  // .max(
  //   "255",
  //   <FormattedMessage id="TITLE.NOTES.SHOULD.NOT.BE.GREATER.THAN.255.CHARACTERS" />
  // ),
  expected_delivery: Yup.string().trim(""),
  // .required(
  //   <FormattedMessage id="TITLE.EXPECTED.DELIVERY.DATE.IS.REQUIRED" />
  // ),
  courier_company_name: Yup.string()
    .trim("")
    .min(
      "1",
      <FormattedMessage id="TITLE.COURIER.COMPANY.NAME.SHOULD.BE.GREATER.THAN.1.CHARACTERS" />
    )
    .max(
      "255",
      <FormattedMessage id="TITLE.COURIER.COMPANY.NAME.SHOULD.NOT.BE.GREATER.THAN.132.CHARACTERS" />
    ),
  multiple_products: Yup.array().of(
    Yup.object().shape({
      productId: Yup.string().required(
        <FormattedMessage id="TITLE.REQUIRED" />
      ),
      quantity: Yup.string().required(<FormattedMessage id="TITLE.REQUIRED" />),
      price: Yup.string().required(<FormattedMessage id="TITLE.REQUIRED" />),
      // description: Yup.string().required(
      //   <FormattedMessage id="TITLE.REQUIRED" />
      // ),
    })
  ),
});

export const AddCategorySchema = Yup.object().shape({
  name: Yup.string()
    .trim("")
    .min(
      1,
      <FormattedMessage id="TITLE.CATEGORY.SHOULD.BE.MORE.THAN.1.CHARACTER" />
    )
    .max(
      64,
      <FormattedMessage id="TITLE.CATEGORY.NAME.SHOULD.NOT.BE.MORE.THAN.64.CHARACTERS" />
    )
    .required(<FormattedMessage id="TITLE.CATEGORY.NAME.IS.REQUIRED" />),
  description: Yup.string()
    .trim("")
    .max(
      "512",
      <FormattedMessage id="TITLE.DESCRIPTION.SHOULD.NOT.BE.GREATER.THAN.512.CHARACTERS" />
    ),
});

export const AddUserSchema = Yup.object().shape({
  full_name: Yup.string()
    .trim("")
    .min(1, "TITLE.DISPLAY.USER.NAME.SHOULD.BE.GREATER.THAN.1.CHARACTERS")
    .max(64, "TITLE.DISPLAY.NAME.SHOULD.NOT.BE.MORE.THAN.64.CHARACTERS")
    .matches(/^[a-zA-Z\s]*$/, "TITLE.USER.DISPLAY.NAME.SHOULD.BE.VALID")
    .required("TITLE.DISPLAY.USER.NAME.IS.REQUIRED"),
  emp_id: Yup.string()
    .trim("")
    .min(
      1,
      <FormattedMessage id="TITLE.EMPLOYEE.ID.SHOULD.BE.GREATER.THAN.1.CHARACTERS" />
    )
    .max(
      64,
      <FormattedMessage id="TITLE.EMPLOYEE.ID.SHOULD.NOT.BE.GREATER.THAN.64.CHARACTERS" />
    )
    .required(<FormattedMessage id="TITLE.EMPLOYEE.ID.REQUIRED" />),
  email: Yup.string()
    .email(<FormattedMessage id="TITLE.INVALID.EMAIL.ADDRESS" />)
    .trim(""),
  cellnumber: Yup.string()
    .trim("")
    .matches(
      /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
      "TITLE.MOBILE.NUMBER.SHOULD.BE.VALID"
    )
    .required("TITLE.MOBILE.NUMBER.IS.REQUIRED"),
  role_id: Yup.string()
    .trim("")
    .min(
      1,
      <FormattedMessage id="TITLE.ROLE.SHOULD.BE.GREATER.THAN.1.CHARACTERS" />
    )
    .max(
      64,
      <FormattedMessage id="TITLE.ROLE.SHOULD.NOT.BE.GREATER.THAN.64.CHARACTERS" />
    )
    .required(<FormattedMessage id="TITLE.ROLE.IS.REQUIRED" />),
  password: Yup.string()
    .trim("")
    .min(8, "TITLE.PASSWORD.SHOULD.BE.ATLEAST.8.CHARACTERS")
    .max(132, "TITLE.PASSWORD.SHOULD.NOT.BE.MORE.THAN.132.CHARACTERS")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "TITLE.PASSWORD.MUST.HAVE.LEAST.UPPERCASE.LETTER.LOWERCASE.LETTER.NUMBER.AND.SYMBOL"
    )
    .required("TITLE.PASSWORD.IS.REQUIRED"),
});

export const EditProfileSchema = Yup.object().shape({
  full_name: Yup.string()
    .trim("")
    .min(1, "TITLE.DISPLAY.USER.NAME.SHOULD.BE.GREATER.THAN.1.CHARACTERS")
    .max(132, "TITLE.DISPLAY.NAME.SHOULD.NOT.BE.GREATER.THAN.132.CHARACTERS")
    .matches(/^[a-zA-Z\s]*$/, "TITLE.USER.DISPLAY.NAME.SHOULD.BE.VALID")
    .required("TITLE.DISPLAY.USER.NAME.IS.REQUIRED"),
  emp_id: Yup.string()
    .trim("")
    .min(
      1,
      <FormattedMessage id="TITLE.EMPLOYEE.ID.SHOULD.BE.GREATER.THAN.1.CHARACTERS" />
    )
    .max(
      64,
      <FormattedMessage id="TITLE.EMPLOYEE.ID.SHOULD.NOT.BE.GREATER.THAN.64.CHARACTERS" />
    )
    .required(<FormattedMessage id="TITLE.EMPLOYEE.ID.REQUIRED" />),
  email: Yup.string()
    .email(<FormattedMessage id="TITLE.INVALID.EMAIL.ADDRESS" />)
    .trim(""),
  // .required(<FormattedMessage id="TITLE.EMAIL.ADDRESS.IS.REQUIRED" />),
  cellnumber: Yup.string()
    .trim("")
    .matches(
      /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
      "TITLE.MOBILE.NUMBER.SHOULD.BE.VALID"
    ),
  // .required("TITLE.MOBILE.NUMBER.IS.REQUIRED"),
});

export const ChangePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .trim("")
    .min(8, "TITLE.PASSWORD.SHOULD.BE.ATLEAST.8.CHARACTERS")
    .max(132, "TITLE.PASSWORD.SHOULD.NOT.BE.MORE.THAN.132.CHARACTERS")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "TITLE.PASSWORD.MUST.HAVE.LEAST.UPPERCASE.LETTER.LOWERCASE.LETTER.NUMBER.AND.SYMBOL"
    )
    .required("TITLE.CURRENT.PASSWORD.IS.REQUIRED"),
  password: Yup.string()
    .trim("")
    .min(8, "TITLE.PASSWORD.SHOULD.BE.ATLEAST.8.CHARACTERS")
    .max(132, "TITLE.PASSWORD.SHOULD.NOT.BE.MORE.THAN.132.CHARACTERS")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "TITLE.PASSWORD.MUST.HAVE.LEAST.UPPERCASE.LETTER.LOWERCASE.LETTER.NUMBER.AND.SYMBOL"
    )
    .when("currentPassword", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().notOneOf(
        [Yup.ref("currentPassword")],
        "TITLE.NEW.PASSWORD.SHOULD.NOT.BE.SAME.AS.CURRENT.PASSWORD"
      ),
    })
    .required("TITLE.PASSWORD.IS.REQUIRED"),
  confirmPassword: Yup.string()
    .trim("")
    .min(8, "TITLE.CONFIRM.PASSWORD.SHOULD.BE.ATLEAST.8.CHARACTERS")
    .max(132, "TITLE.CONFIRM.PASSWORD.SHOULD.NOT.BE.MORE.THAN.132.CHARACTERS")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "TITLE.PASSWORDS.DO.NOT.MATCH"
      ),
    })
    .required("TITLE.CONFIRM.PASSWORD.IS.REQUIRED"),
});

export const ViewPasswordRequestSchema = Yup.object().shape({
  password: Yup.string()
    .trim("")
    .min(8, "TITLE.PASSWORD.SHOULD.BE.ATLEAST.8.CHARACTERS")
    .max(132, "TITLE.PASSWORD.SHOULD.NOT.BE.MORE.THAN.132.CHARACTERS")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "TITLE.PASSWORD.MUST.HAVE.LEAST.UPPERCASE.LETTER.LOWERCASE.LETTER.NUMBER.AND.SYMBOL"
    )
    .required("TITLE.PASSWORD.IS.REQUIRED"),
  confirmPassword: Yup.string()
    .trim("")
    .min(8, "TITLE.CONFIRM.PASSWORD.SHOULD.BE.ATLEAST.8.CHARACTERS")
    .max(132, "TITLE.CONFIRM.PASSWORD.SHOULD.NOT.BE.MORE.THAN.132.CHARACTERS")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "TITLE.PASSWORDS.DO.NOT.MATCH"
      ),
    })
    .required("TITLE.CONFIRM.PASSWORD.IS.REQUIRED"),
});

export const EditUserSchema = Yup.object().shape({
  full_name: Yup.string()
    .trim("")
    .min(1, "TITLE.DISPLAY.USER.NAME.SHOULD.BE.GREATER.THAN.1.CHARACTERS")
    .max(64, "TITLE.DISPLAY.NAME.SHOULD.NOT.BE.MORE.THAN.64.CHARACTERS")
    .matches(/^[a-zA-Z\s]*$/, "TITLE.USER.DISPLAY.NAME.SHOULD.BE.VALID")
    .required("TITLE.DISPLAY.USER.NAME.IS.REQUIRED"),
  emp_id: Yup.string()
    .trim("")
    .min(
      1,
      <FormattedMessage id="TITLE.EMPLOYEE.ID.SHOULD.BE.GREATER.THAN.1.CHARACTERS" />
    )
    .max(
      64,
      <FormattedMessage id="TITLE.EMPLOYEE.ID.SHOULD.NOT.BE.GREATER.THAN.64.CHARACTERS" />
    )
    .required(<FormattedMessage id="TITLE.EMPLOYEE.ID.REQUIRED" />),
  email: Yup.string()
    .email(<FormattedMessage id="TITLE.INVALID.EMAIL.ADDRESS" />)
    .trim(""),
  // .required(<FormattedMessage id="TITLE.EMAIL.ADDRESS.IS.REQUIRED" />),
  cellnumber: Yup.string()
    .trim("")
    .matches(
      /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
      "TITLE.MOBILE.NUMBER.SHOULD.BE.VALID"
    )
    .required("TITLE.MOBILE.NUMBER.IS.REQUIRED"),
  role_id: Yup.string()
    .trim("")
    .min(
      1,
      <FormattedMessage id="TITLE.ROLE.SHOULD.BE.GREATER.THAN.1.CHARACTERS" />
    )
    .max(
      64,
      <FormattedMessage id="TITLE.ROLE.SHOULD.NOT.BE.GREATER.THAN.64.CHARACTERS" />
    )
    .required(<FormattedMessage id="TITLE.ROLE.IS.REQUIRED" />),
  status: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.STATUS.IS.REQUIRED" />),
});

export const AddProductUnitSchema = Yup.object().shape({
  name: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.PRODUCT.UNIT.IS.REQURIED" />)
    .max(
      64,
      <FormattedMessage id="TITLE.PRODUCT.UNIT.SHOULD.NOT.BE.MORE.THAN.64.CHARACTERS" />
    ),
  description: Yup.string()
    .trim("")
    .max(
      "512",
      <FormattedMessage id="TITLE.DESCRIPTION.SHOULD.NOT.BE.GREATER.THAN.512.CHARACTERS" />
    ),
});

export const EditOutwardSchema = Yup.object().shape({
  contactId: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.CUSTOMER.IS.REQUIRED" />),
  date: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.DATE.IS.REQUIRED" />),
  notes: Yup.string().trim(""),
  // .max(
  //   "255",
  //   <FormattedMessage id="TITLE.NOTES.SHOULD.NOT.BE.GREATER.THAN.255.CHARACTERS" />
  // ),
  expected_delivery: Yup.string().trim(""),
  // .required(
  //   <FormattedMessage id="TITLE.EXPECTED.DELIVERY.DATE.IS.REQUIRED" />
  // ),
  courier_company_name: Yup.string()
    .trim("")
    .min(
      "1",
      <FormattedMessage id="TITLE.COURIER.COMPANY.NAME.SHOULD.BE.GREATER.THAN.1.CHARACTERS" />
    )
    .max(
      "255",
      <FormattedMessage id="TITLE.COURIER.COMPANY.NAME.SHOULD.NOT.BE.GREATER.THAN.132.CHARACTERS" />
    ),
  multiple_products: Yup.array().of(
    Yup.object().shape({
      productId: Yup.string().required(
        <FormattedMessage id="TITLE.REQUIRED" />
      ),
      quantity: Yup.string().required(<FormattedMessage id="TITLE.REQUIRED" />),
      price: Yup.string().required(<FormattedMessage id="TITLE.REQUIRED" />),
      // description: Yup.string().required(
      //   <FormattedMessage id="TITLE.REQUIRED" />
      // ),
    })
  ),
});

export const EditCategorySchema = Yup.object().shape({
  name: Yup.string()
    .trim("")
    .min(
      1,
      <FormattedMessage id="TITLE.CATEGORY.SHOULD.BE.MORE.THAN.1.CHARACTER" />
    )
    .max(
      64,
      <FormattedMessage id="TITLE.CATEGORY.NAME.SHOULD.NOT.BE.MORE.THAN.64.CHARACTERS" />
    )
    .required(<FormattedMessage id="TITLE.CATEGORY.NAME.IS.REQUIRED" />),
  description: Yup.string()
    .trim("")
    .max(
      "512",
      <FormattedMessage id="TITLE.DESCRIPTION.SHOULD.NOT.BE.GREATER.THAN.512.CHARACTERS" />
    ),
});

export const EditProductUnitSchema = Yup.object().shape({
  name: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.PRODUCT.UNIT.IS.REQURIED" />)
    .max(
      64,
      <FormattedMessage id="TITLE.PRODUCT.UNIT.SHOULD.NOT.BE.MORE.THAN.64.CHARACTERS" />
    ),
  description: Yup.string()
    .trim("")
    .max(
      512,
      <FormattedMessage id="TITLE.DESCRIPTION.SHOULD.NOT.BE.GREATER.THAN.512.CHARACTERS" />
    ),
});

export const EditProductSchema = Yup.object().shape({
  name: Yup.string()
    .trim("")
    .min(
      "1",
      <FormattedMessage id="TITLE.PRODUCT.NAME.SHOULD.BE.GREATER.THAN.1.CHARACTERS" />
    )
    .max(
      "255",
      <FormattedMessage id="TITLE.PRODUCT.NAME.SHOULD.NOT.BE.GREATER.THAN.255.CHARACTERS" />
    )
    .required(<FormattedMessage id="TITLE.PRODUCT.NAME.IS.REQUIRED" />),
  categoryId: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.CATEGORY.IS.REQUIRED" />),
  stock_keeping_unit: Yup.string().trim(""),
  // .required(<FormattedMessage id="TITLE.SKU.IS.REQUIRED" />),
  opening_stock: Yup.number(),
  // .required(<FormattedMessage id="TITLE.STOCK.ON.HAND.IS.REQUIRED" />),
  price: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.PRICE.IS.REQUIRED" />),
  shelf_life: Yup.number()
    .min(
      0,
      <FormattedMessage id="TITLE.SHELF.LIFE.SHOULD.NOT.BE.LESS.THAN.0.DAY" />
    )
    .max(
      700,
      <FormattedMessage id="TITLE.SHELF.LIFE.SHOULD.NOT.BE.GREATER.THAN.700.DAYS" />
    ),
  // .required(<FormattedMessage id="TITLE.SHELF.LIFE.IS.REQUIRED" />),
  reorder_level: Yup.string().trim(""),
  // .required(<FormattedMessage id="TITLE.REORDER.LEVEL.IS.REQUIRED" />),
  description: Yup.string()
    .trim("")
    .max(
      "512",
      <FormattedMessage id="TITLE.DESCRIPTION.SHOULD.NOT.BE.GREATER.THAN.512.CHARACTERS" />
    ),
  productunitId: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.PRODUCT.UNIT.IS.REQUIRED" />),
});

export const EditProductAdjustStockSchema = Yup.object().shape({
  reference_number: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.REFERENCE.NUMBER.IS.REQUIRED" />),
  new_quantity_on_hand: Yup.number()
    // .transform((currentValue, originalValue) => {
    //   return originalValue === "" ? null : currentValue;
    // })
    // .nullable()
    // .positive("New Quantity on should be positive")
    .typeError("New Quantity on hand should be a number")
    .min(0, <FormattedMessage id="TITLE.NEW.QUANTITY.SHOULD.BE.VALID" />)
    .required(<FormattedMessage id="TITLE.NEW.QUANTITY.ON.HAND.IS.REQUIRED" />),

  quantity_adjusted: Yup.number().required(
    <FormattedMessage id="TITLE.QUANTITY.ADJUSTED.IS.REQUIRED" />
  ),
  reason: Yup.string()
    .trim("")
    .max(
      "512",
      <FormattedMessage id="TITLE.REASON.SHOULD.NOT.BE.GREATER.THAN.512" />
    ),
  comments: Yup.string()
    .trim("")
    .max(
      "512",
      <FormattedMessage id="TITLE.COMMENTS.SHOULD.NOT.BE.GREATER.THAN.512" />
    ),
});

export const ReceivePOSchema = Yup.object().shape({
  delivery_date: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.RECEIVED.DATE.IS.REQUIRED" />),
  product_condition: Yup.array().of(
    Yup.object().shape({
      barcode_no: Yup.string()
        .max(14, "TITLE.REQUIRED.LENGTH.14")
        .matches(
          /^\d\d-[a-zA-Z][a-zA-Z][a-zA-Z]-\d\d\d\d\d\d\d$/,
          "TITLE.INVALID.BARCODE"
        )
        .required("TITLE.REQUIRED"),
      productId: Yup.string().required("TITLE.REQUIRED"),
      locationId: Yup.string().required("TITLE.REQUIRED"),
      // expiry_date: Yup.string().required(
      //   <FormattedMessage id="TITLE.REQUIRED" />
      // ),
      condition: Yup.string().required("TITLE.REQUIRED"),
      quantity: Yup.string().required("TITLE.REQUIRED"),
    })
  ),
  notes: Yup.string().trim(""),
});

export const DeliverDCSchema = Yup.object().shape({
  delivery_date: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.DELIVERED.DATE.IS.REQUIRED" />),
  product_condition: Yup.array().of(
    Yup.object().shape({
      barcode_no: Yup.string()
        .max(14, "TITLE.REQUIRED.LENGTH.14")
        .matches(
          /^\d\d-[a-zA-Z][a-zA-Z][a-zA-Z]-\d\d\d\d\d\d\d$/,
          "TITLE.INVALID.BARCODE"
        )
        .required("TITLE.REQUIRED"),
    })
  ),
  notes: Yup.string().trim(""),
});

export const AddLocationSchema = Yup.object().shape({
  store_location: Yup.string()
    .trim("")
    .max(
      "250",
      <FormattedMessage id="TITLE.LOCATION.NAME.SHOULD.NOT.BE.GRATER.THAN.250.CHARACTER" />
    )
    .required(<FormattedMessage id="TITLE.STORE.LOCATION.IS.REQUIRED" />),
});

export const EditLocationSchema = Yup.object().shape({
  store_location: Yup.string()
    .trim("")
    .max(
      "250",
      <FormattedMessage id="TITLE.LOCATION.NAME.SHOULD.NOT.BE.GRATER.THAN.250.CHARACTER" />
    )
    .required(<FormattedMessage id="TITLE.STORE.LOCATION.IS.REQUIRED" />),
});

export const EditVendorSchema = Yup.object().shape({
  contact_name: Yup.string()
    .trim("")
    .min("1", "TITLE.VENDOR.DISPLAY.NAME.SHOULD.BE.GRATER.THAN.1.CHARACTER")
    .max(
      "132",
      "TITLE.VENDOR.DISPLAY.NAME.SHOULD.NOT.BE.GRATER.THAN.132.CHARACTER"
    )
    .matches(/^[a-zA-Z\s]*$/, "TITLE.VENDOR.DISPLAY.NAME.SHOULD.BE.VALID")
    .required("TITLE.VENDOR.DISPLAY.NAME.IS.REQUIRED"),
  phone_number: Yup.string()
    .trim("")
    .matches(
      /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
      "TITLE.PHONE.NUMBER.SHOULD.BE.VALID"
    ),
  mobile_number: Yup.string()
    .trim("")
    .matches(
      /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
      "TITLE.MOBILE.NUMBER.SHOULD.BE.VALID"
    ),
  email: Yup.string()
    .email(<FormattedMessage id="TITLE.INVALID.EMAIL.ADDRESS" />)
    .trim(""),
  // .required(<FormattedMessage id="TITLE.VENDOR.EMAIL.ADDRESS.IS.REQUIRED" />),
  // phone_number: Yup.string()
  //   .trim("")
  //   .matches(
  //     /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
  //     "TITLE.PHONE.NUMBER.SHOULD.BE.VALID"
  //     // <FormattedMessage id="TITLE.PHONE.NUMBER.SHOULD.BE.VALID" />
  //   )
  //   .required(<FormattedMessage id="TITLE.VENDOR.PHONE.NUMBER.IS.REQUIRED" />),
  // mobile_number: Yup.string()
  //   .trim("")
  //   .matches(
  //     /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
  //     "TITLE.MOBILE.NUMBER.SHOULD.BE.VALID"
  //     // <FormattedMessage id="TITLE.MOBILE.NUMBER.SHOULD.BE.VALID" />
  //   )
  //   .required(<FormattedMessage id="TITLE.VENDOR.MOBILE.NUMBER.IS.REQUIRED" />),
  // currency: Yup.string()
  //   .trim("")
  //   .min(
  //     1,
  //     <FormattedMessage id="TITLE.CURRENCY.SHOULD.BE.GREATER.THAN.1.CHARACTERS" />
  //   )
  //   .min(
  //     3,
  //     <FormattedMessage id="TITLE.CURRENCY.SHOULD.NOT.BE.GREATER.THAN.3.CHARACTERS" />
  //   )
  //   .required(<FormattedMessage id="TITLE.CURRENCY.IS.REQUIRED" />),
  // billingAddress: Yup.string()
  //   .trim("")
  //   .required(<FormattedMessage id="TITLE.BILLING.ADDRESS.IS.REQUIRED" />),
  // billingCity: Yup.string()
  //   .trim("")
  //   .required(<FormattedMessage id="TITLE.BILLING.CITY.IS.REQUIRED" />),
  billingZipcode: Yup.string().max(
    5,
    <FormattedMessage id="TITLE.ZIP.POSTAL.CODE.SHOULD.NOT.BE.GREATER.THAN.5.CHARACTERS" />
  ),
  // .required(<FormattedMessage id="TITLE.ZIP.POSTAL.CODE.IS.REQUIRED" />),
  // shippingAddress: Yup.string()
  //   .trim("")
  //   .required(<FormattedMessage id="TITLE.SHIPPING.ADDRESS.IS.REQUIRED" />),
  // shippingCity: Yup.string()
  //   .trim("")
  //   .required(<FormattedMessage id="TITLE.SHIPPING.CITY.IS.REQUIRED" />),
  shippingZipcode: Yup.string()
    .trim("")
    .max(
      5,
      <FormattedMessage id="TITLE.SHIPPING.ZIPCODE.SHOULD.NOT.BE.GREATER.THAN.5.DIGITS" />
    ),
  // .required(<FormattedMessage id="TITLE.ZIP.POSTAL.CODE.IS.REQUIRED" />),
  contact_persons: Yup.array().of(
    Yup.object().shape({
      first_name: Yup.string().matches(
        /^[a-zA-Z\s]*$/,
        "TITLE.FIRST.NAME.SHOULD.BE.VALID"
      ),
      // .required(<FormattedMessage id="TITLE.REQUIRED" />),
      last_name: Yup.string().matches(
        /^[a-zA-Z\s]*$/,
        "TITLE.LAST.NAME.SHOULD.BE.VALID"
      ),
      // .required(<FormattedMessage id="TITLE.REQUIRED" />),
      email: Yup.string().email(
        <FormattedMessage id="TITLE.EMAIL.IS.INVALID" />
      ),
      // .required(<FormattedMessage id="TITLE.REQUIRED" />),
      phone: Yup.string()
        .trim("")
        .matches(
          /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
          "TITLE.PHONE.SHOULD.BE.VALID"
        ),
      // .required(<FormattedMessage id="TITLE.REQUIRED" />),
      mobile: Yup.string()
        .trim("")
        .matches(
          /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
          "TITLE.MOBILE.SHOULD.BE.VALID"
          // <FormattedMessage id="TITLE.MOBILE.SHOULD.BE.VALID" />
        ),
      // .required(<FormattedMessage id="TITLE.REQUIRED" />),
    })
  ),
  remarks: Yup.string()
    .trim("")
    .max(
      512,
      <FormattedMessage id="TITLE.REMARKS.SHOULD.NOT.BE.GREATER.THAN.512.CHARACTERS" />
    ),
});

export const EditCustomerSchema = Yup.object().shape({
  contact_name: Yup.string()
    .min("1", "TITLE.CUSTOMER.DISPLAY.NAME.SHOULD.BE.GRATER.THAN.1.CHARACTER")
    .max(
      "132",
      "TITLE.CUSTOMER.DISPLAY.NAME.SHOULD.NOT.BE.GRATER.THAN.132.CHARACTER"
    )
    .matches(/^[a-zA-Z\s]*$/, "TITLE.CUSTOMER.DISPLAY.NAME.SHOULD.BE.VALID")
    .required("TITLE.CUSTOMER.DISPLAY.NAME.IS.REQUIRED"),
  phone_number: Yup.string()
    .trim("")
    .matches(
      /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
      "TITLE.PHONE.NUMBER.SHOULD.BE.VALID"
    ),
  mobile_number: Yup.string()
    .trim("")
    .matches(
      /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
      "TITLE.MOBILE.NUMBER.SHOULD.BE.VALID"
    ),
  email: Yup.string().email(
    <FormattedMessage id="TITLE.INVALID.EMAIL.ADDRESS" />
  ),
  //   .required(
  //     <FormattedMessage id="TITLE.CUSTOMER.EMAIL.ADDRESS.IS.REQUIRED" />
  //   ),
  // phone_number: Yup.string()
  //   .matches(
  //     /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
  //     "TITLE.PHONE.NUMBER.SHOULD.BE.VALID"
  //     // <FormattedMessage id="TITLE.PHONE.NUMBER.SHOULD.BE.VALID" />
  //   )
  //   .required("TITLE.CUSTOMER.PHONE.NUMBER.IS.REQUIRED"),
  // mobile_number: Yup.string()
  //   .matches(
  //     /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
  //     "TITLE.MOBILE.NUMBER.SHOULD.BE.VALID"
  //     // <FormattedMessage id="TITLE.MOBILE.NUMBER.SHOULD.BE.VALID" />
  //   )
  //   .required("TITLE.CUSTOMER.MOBILE.NUMBER.IS.REQUIRED"),
  // currency: Yup.string()
  //   .min(
  //     1,
  //     <FormattedMessage id="TITLE.CURRENCY.SHOULD.BE.GREATER.THAN.1.CHARACTERS" />
  //   )
  //   .min(
  //     3,
  //     <FormattedMessage id="TITLE.CURRENCY.SHOULD.NOT.BE.GREATER.THAN.3.CHARACTERS" />
  //   )
  //   .required(<FormattedMessage id="TITLE.CURRENCY.IS.REQUIRED" />),
  // billingAddress: Yup.string().required(
  //   <FormattedMessage id="TITLE.BILLING.ADDRESS.IS.REQUIRED" />
  // ),
  // billingCity: Yup.string().required(
  //   <FormattedMessage id="TITLE.BILLING.CITY.IS.REQUIRED" />
  // ),
  billingZipcode: Yup.string().max(
    5,
    <FormattedMessage id="TITLE.ZIP.POSTAL.CODE.SHOULD.NOT.BE.GREATER.THAN.5.CHARACTERS" />
  ),
  // shippingAddress: Yup.string().required(
  //   <FormattedMessage id="TITLE.SHIPPING.ADDRESS.IS.REQUIRED" />
  // ),
  // shippingCity: Yup.string().required(
  //   <FormattedMessage id="TITLE.SHIPPING.CITY.IS.REQUIRED" />
  // ),
  shippingZipcode: Yup.string().max(
    5,
    <FormattedMessage id="TITLE.SHIPPING.ZIPCODE.SHOULD.NOT.BE.GREATER.THAN.5.DIGITS" />
  ),
  contact_persons: Yup.array().of(
    Yup.object().shape({
      first_name: Yup.string().matches(
        /^[a-zA-Z\s]*$/,
        "TITLE.FIRST.NAME.SHOULD.BE.VALID"
      ),
      last_name: Yup.string().matches(
        /^[a-zA-Z\s]*$/,
        "TITLE.LAST.NAME.SHOULD.BE.VALID"
      ),
      email: Yup.string().email(
        <FormattedMessage id="TITLE.EMAIL.IS.INVALID" />
      ),
      phone: Yup.string().matches(
        /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
        "TITLE.PHONE.SHOULD.BE.VALID"
      ),
      mobile: Yup.string().matches(
        /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
        "TITLE.MOBILE.SHOULD.BE.VALID"
      ),
    })
  ),
  remarks: Yup.string().max(
    512,
    <FormattedMessage id="TITLE.REMARKS.SHOULD.NOT.BE.GREATER.THAN.512.CHARACTERS" />
  ),
});

export const EditVendorRemarksSchema = Yup.object().shape({
  remarks: Yup.string()
    .trim("")
    .max(
      512,
      <FormattedMessage id="TITLE.REMARKS.SHOULD.NOT.BE.GREATER.THAN.512.CHARACTERS" />
    ),
});

export const EditCustomerRemarksSchema = Yup.object().shape({
  remarks: Yup.string()
    .trim("")
    .max(
      512,
      <FormattedMessage id="TITLE.REMARKS.SHOULD.NOT.BE.GREATER.THAN.512.CHARACTERS" />
    ),
});

export const EditVendorBillingAddressSchema = Yup.object().shape({
  billingAddress: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.BILLING.ADDRESS.IS.REQUIRED" />),
  billingCity: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.BILLING.CITY.IS.REQUIRED" />),
  billingZipcode: Yup.string()
    .min(
      1,
      <FormattedMessage id="TITLE.ZIP.POSTAL.CODE.SHOULD.BE.GREATER.THAN.1.DIGIT" />
    )
    .max(
      5,
      <FormattedMessage id="TITLE.ZIP.POSTAL.CODE.SHOULD.NOT.BE.GREATER.THAN.5.CHARACTERS" />
    )
    .required(<FormattedMessage id="TITLE.ZIP.POSTAL.CODE.IS.REQUIRED" />),
});

export const EditCustomerBillingAddressSchema = Yup.object().shape({
  billingAddress: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.BILLING.ADDRESS.IS.REQUIRED" />),
  billingCity: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.BILLING.CITY.IS.REQUIRED" />),
  billingZipcode: Yup.string()
    .min(
      1,
      <FormattedMessage id="TITLE.ZIP.POSTAL.CODE.SHOULD.BE.GREATER.THAN.1.DIGIT" />
    )
    .max(
      5,
      <FormattedMessage id="TITLE.ZIP.POSTAL.CODE.SHOULD.NOT.BE.GREATER.THAN.5.CHARACTERS" />
    )
    .required(<FormattedMessage id="TITLE.ZIP.POSTAL.CODE.IS.REQUIRED" />),
});

export const EditVendorShippingAddressSchema = Yup.object().shape({
  shippingAddress: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.SHIPPING.ADDRESS.IS.REQUIRED" />),
  shippingCity: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.SHIPPING.CITY.IS.REQUIRED" />),
  shippingZipcode: Yup.string()
    .min(
      1,
      <FormattedMessage id="TITLE.ZIP.POSTAL.CODE.SHOULD.BE.GREATER.THAN.1.DIGIT" />
    )
    .max(
      5,
      <FormattedMessage id="TITLE.ZIP.POSTAL.CODE.SHOULD.NOT.BE.GREATER.THAN.5.CHARACTERS" />
    )
    .required(<FormattedMessage id="TITLE.ZIP.POSTAL.CODE.IS.REQUIRED" />),
});

export const EditCustomerShippingAddressSchema = Yup.object().shape({
  shippingAddress: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.SHIPPING.ADDRESS.IS.REQUIRED" />),
  shippingCity: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.SHIPPING.CITY.IS.REQUIRED" />),
  shippingZipcode: Yup.string()
    .min(
      1,
      <FormattedMessage id="TITLE.ZIP.POSTAL.CODE.SHOULD.BE.GREATER.THAN.1.DIGIT" />
    )
    .max(
      5,
      <FormattedMessage id="TITLE.ZIP.POSTAL.CODE.SHOULD.NOT.BE.GREATER.THAN.5.CHARACTERS" />
    )
    .required(<FormattedMessage id="TITLE.ZIP.POSTAL.CODE.IS.REQUIRED" />),
});

export const AddContactPersonSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(/^[a-zA-Z\s]*$/, "TITLE.FIRST.NAME.SHOULD.BE.VALID")
    .required("TITLE.FIRST.NAME.IS.REQUIRED"),
  last_name: Yup.string().matches(
    /^[a-zA-Z\s]*$/,
    "TITLE.LAST.NAME.SHOULD.BE.VALID"
  ),
  //   .required("TITLE.LAST.NAME.IS.REQUIRED"),
  email: Yup.string().email(<FormattedMessage id="TITLE.EMAIL.IS.INVALID" />),
  //   .required(<FormattedMessage id="TITLE.EMAIL.IS.REQUIRED" />),
  phone: Yup.string()
    .trim("")
    .matches(
      /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
      "TITLE.PHONE.NUMBER.SHOULD.BE.VALID"
      // <FormattedMessage id="TITLE.PHONE.NUMBER.SHOULD.BE.VALID" />
    ),
  //   .required("TITLE.PHONE.NUMBER.IS.REQUIRED"),
  mobile: Yup.string()
    .trim("")
    .matches(
      /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
      "TITLE.MOBILE.NUMBER.SHOULD.BE.VALID"
    ),
  //   .required("TITLE.MOBILE.NUMBER.IS.REQUIRED"),
});

export const EditContactPersonSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(/^[a-zA-Z\s]*$/, "TITLE.FIRST.NAME.SHOULD.BE.VALID")
    .required("TITLE.FIRST.NAME.IS.REQUIRED"),
  last_name: Yup.string().matches(
    /^[a-zA-Z\s]*$/,
    "TITLE.LAST.NAME.SHOULD.BE.VALID"
  ),
  //   .required(<FormattedMessage id="TITLE.LAST.NAME.IS.REQUIRED" />),
  email: Yup.string().email(<FormattedMessage id="TITLE.EMAIL.IS.INVALID" />),
  //   .required(<FormattedMessage id="TITLE.EMAIL.IS.REQUIRED" />),
  phone: Yup.string()
    .trim("")
    .matches(
      /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
      "TITLE.PHONE.NUMBER.SHOULD.BE.VALID"
    ),
  //   .required("TITLE.PHONE.NUMBER.IS.REQUIRED"),
  mobile: Yup.string()
    .trim("")
    .matches(
      /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
      "TITLE.MOBILE.NUMBER.SHOULD.BE.VALID"
    ),
  //   .required("TITLE.MOBILE.NUMBER.IS.REQUIRED"),
});

export const DeleteCategorySchema = Yup.object().shape({
  categoryId: Yup.string()
    .trim("")
    .required(<FormattedMessage id="TITLE.CATEGORY.IS.REQUIRED" />),
});

export const ProductBarcodeSchema = Yup.object().shape({
    purchaseorder_barcode: Yup.array().of(
    Yup.object().shape({
      barcode_no: Yup.string()
        .max(14, "TITLE.REQUIRED.LENGTH.14")
        .matches(
          /^\d\d-[a-zA-Z][a-zA-Z][a-zA-Z]-\d\d\d\d\d\d\d$/,
          "TITLE.INVALID.BARCODE"
        )
        .required("TITLE.REQUIRED"),
      locationId: Yup.string().required("TITLE.REQUIRED"),
      condition: Yup.string().required("TITLE.REQUIRED"),
    })
  ),
});
