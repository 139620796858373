import React, { useMemo, useState } from "react";
import { Card, Button, ButtonGroup } from "react-bootstrap";
import { Upload, Filter, Plus } from "react-feather";
import { VendorFilter } from "./vendor-filter/VendorFilter";
import { VendorTable } from "./vendor-table/VendorTable";
import { useVendorUIContext } from "./VendorUIContext";
import { CSVLink } from "react-csv";
import { FormattedMessage } from "react-intl";
import { vendorListExportApi } from "../../axios/services/services";
import { unauthorization } from "../../constants/utils";

export function VendorCard({
  isLoadingFilter,
  isLoading,
  isFilterOpen,
  vendorList,
  exportCSVButton,
  transactionListExport,
  vendorListTotalCount,
  currentPage,
  setCurrentPage,
  onClickOfCreateNew,
  handleClickOfRemoveFilterButton,
  handleCLickOfFilterButtonFilter,
}) {
  const vendorUIContext = useVendorUIContext();
  const vendorUIProps = useMemo(() => {
    return {
      ids: vendorUIContext.ids,
      queryParams: vendorUIContext.queryParams,
      setQueryParams: vendorUIContext.setQueryParams,
      openViewDialog: vendorUIContext.openViewDialog,
      openEditDialog: vendorUIContext.openEditDialog,
      openDeleteDialog: vendorUIContext.openDeleteDialog,
    };
  }, [vendorUIContext]);

  const [vendorExportList, setVendorExportList] = useState();

  async function handleExport() {
    const vendorListExportResponse = await vendorListExportApi({
      filename: "Vendor",
    });
    const { data } = vendorListExportResponse || {};
    setVendorExportList(data);
  }

  // const exportOutwardListing = async () => {
  //   await exportOutwardListingApi();
  // };
  return (
    <>
      <Card className="card-custom card-stretch make-payment-wrapper">
        <Card.Header className="align-items-center d-flex justify-content-between">
          <h3 className="card-title page-title text-black mb-0">
            <FormattedMessage id="TITLE.VENDORS" />
          </h3>
          <div className="d-flex">
            <ButtonGroup className="mr-3">
              <Button
                variant="tertiary d-flex align-items-center btn-inventory"
                onClick={() => {
                  handleClickOfRemoveFilterButton();
                }}
              >
                <Filter size={16} style={{ color: "#CAA25C" }} />{" "}
                <span className="d-none d-md-flex">
                  {isFilterOpen ? (
                    <FormattedMessage id="TITLE.REMOVE" />
                  ) : (
                    <FormattedMessage id="TITLE.FILTER" />
                  )}
                </span>
              </Button>

              <Button
                variant="tertiary d-flex align-items-center btn-inventory"
                onClick={() => {
                  handleExport();
                }}
              >
                <Upload size={16} style={{ color: "#CAA25C" }} />
                <span className="d-none d-md-flex">
                  <FormattedMessage id="TITLE.EXPORT" />
                </span>
              </Button>

              {/* <CSVLink
                ref={exportCSVButton}
                className="btn btn-tertiary d-flex align-items-center btn-inventory"
                headers={[
                  { label: "Vendor Name", key: "contact_name" },
                  { label: "Email", key: "email" },
                  { label: "Phone Number", key: "phone_number" },
                  { label: "Currency", key: "currency" },
                  { label: "Status", key: "status_name" },
                ]}
                data={transactionListExport}
                filename={`Vendors-${new Date().getTime()}.csv`}
              >
                <Upload size={16} style={{ color: "#CAA25C" }} />
                <span className="d-none d-md-flex">
                  <FormattedMessage id="TITLE.EXPORT" />
                </span>
              </CSVLink> */}
            </ButtonGroup>
            <Button
              variant="primary d-flex align-items-center btn-inventory"
              onClick={() => {
                onClickOfCreateNew();
              }}
            >
              <Plus size={16} style={{ color: "#CAA25C" }} />
              <span className="d-none d-md-flex">
                <FormattedMessage id="TITLE.CREATE.NEW" />
              </span>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          {isFilterOpen && (
            <VendorFilter
              isLoadingFilter={isLoadingFilter}
              handleCLickOfFilterButtonFilter={handleCLickOfFilterButtonFilter}
            />
          )}
          <VendorTable
            isLoading={isLoading}
            vendorList={vendorList}
            vendorListTotalCount={vendorListTotalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Card.Body>
      </Card>
    </>
  );
}
