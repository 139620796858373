// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as uiHelpers from "../DelayedPODCUIHelpers";
import { useDelayedPODCUIContext } from "../DelayedPODCUIContext";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
} from "../../../../helper/helper";
import { Pagination } from "../../../../pagination/Pagination";
import ExpectedDateColumnFormatter from "../../../../columnformatter/ExpectedDateColumnFormatter";
import POWithLinkColumnFormatter from "../../../../columnformatter/POHyperLinkColumnFormatter";
import { useIntl } from "react-intl";
import DeliveryDateTimeColumnFormatter from "../../../../columnformatter/DeliveryDateTimeColumnFormatter";

export function DelayedPODCTable({
  isLoading,
  reportDelayedPODC,
  reportDelayedPODCTotalCount,
}) {
  const intl = useIntl();

  // DelayedPODC UI Context
  const delayedpodcUIContext = useDelayedPODCUIContext();
  const delayedpodcUIProps = useMemo(() => {
    return {
      ids: delayedpodcUIContext.ids,
      setIds: delayedpodcUIContext.setIds,
      queryParams: delayedpodcUIContext.queryParams,
      setQueryParams: delayedpodcUIContext.setQueryParams,
      openViewDialog: delayedpodcUIContext.openViewDialog,
      openEditDialog: delayedpodcUIContext.openEditDialog,
      openDeleteDialog: delayedpodcUIContext.openDeleteDialog,
      redirectToPODetailsPage: delayedpodcUIContext.redirectToPODetailsPage,
    };
  }, [delayedpodcUIContext]);

  // Table columns
  const columns = [
    {
      dataField: "purchaseorder_number",
      text: intl.formatMessage({
        id: "TITLE.PO.DC",
      }),
      style: {
        minWidth: "250px",
      },
      formatter: POWithLinkColumnFormatter,
      formatExtraData: {
        redirectToPODetailsPage: delayedpodcUIContext.redirectToPODetailsPage,
      },
    },
    {
      dataField: "delivery_date",
      text: intl.formatMessage({
        id: "TITLE.DELIVERY.DATE",
      }),
      style: {
        minWidth: "130px",
      },
      formatter: DeliveryDateTimeColumnFormatter,
    },
    {
      dataField: "expectedDeliveryDate",
      text: intl.formatMessage({
        id: "TITLE.EXPECTED.DELIVERY.DATE",
      }),
      style: {
        minWidth: "130px",
      },
      formatter: ExpectedDateColumnFormatter,
    },
    {
      dataField: "noofdays",
      text: intl.formatMessage({
        id: "TITLE.DELAYED.BY.THE.DAYS",
      }),
      style: {
        minWidth: "130px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: reportDelayedPODCTotalCount ? reportDelayedPODCTotalCount : 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: delayedpodcUIProps.queryParams.pageSize,
    onPageChange: (page) => {
      if (page === 0) return;
    },
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-bordered table-vertical-center inward-table table-inventory"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={reportDelayedPODC}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  delayedpodcUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
              <PleaseWaitMessage isLoading={isLoading} />
              <NoRecordsFoundMessage
                entities={reportDelayedPODC}
                isLoading={isLoading}
              />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
