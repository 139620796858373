import React from "react";
import { FormattedMessage } from "react-intl";

const renderMessage = ({ id }) => {
  try {
    if (id) {
      return <FormattedMessage id={id} />;
    }
  } catch {
    return "";
  }
  return "";
};

const ErrorMessage = ({ id }) => {
  return renderMessage({ id });
};

export default ErrorMessage;
