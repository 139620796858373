import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import CreatableSelect from "react-select/creatable";
import { Check, File, Plus, X, XCircle } from "react-feather";
import { AddNewVendorModal } from "../../../global-modal/AddNewVendorModal";
import ButtonAtl from "@atlaskit/button";
import { Card, Col, Form, Row, Table, Button, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useDropzone } from "react-dropzone";
import { AddNewProductModal } from "../../../global-modal/AddNewProductModal ";
import {
  ChevronDownMaster,
  DropdownMaster,
  DropdownIndicatorMaster,
} from "../../../helper/helper";
import {
  editInwardApi,
  ProductListApi,
  singlePODetailsApi,
  uploadDocumentApi,
  vendorListAllApi,
} from "../../../axios/services/services";
import { ErrorModal } from "../../common/modals/ErrorModal";
import {
  amountFormatter,
  capitalizeFirstLetter,
  getErrorMessageBasedOnLanguage,
  getProductMediaUrl,
  setActiveMenu,
  stringToDateWithFormat,
  totalAmountFromProductList,
  totalQuantityFromProductList,
  unauthorization,
} from "../../../constants/utils";
import { FieldArray, Formik } from "formik";
import { EditInwardSchema } from "../../../helper/FormikSchema";
import { SectionLoader } from "../../../helper/SectionLoader";
import { ContentLoader } from "../../../helper/ContentLoader";
import { ImageWrapper } from "../../../helper/ImageWrapper";
import {
  dd_MM_yyyy,
  FILE_EXTENTION,
  MB_20,
  YYYY_MM_DD,
} from "../../../constants/const";
import { ThumbnailWrapper } from "../../../helper/ThumbnailWrapper";
import { FormattedMessage, useIntl } from "react-intl";
import { MENUITEMS } from "../../../constants/menu";
import { UIContext } from "../../../helper/UIContext";
import moment from "moment";

function EditInward({ history, handlePDFViewValues, ...props }) {
  const intl = useIntl();
  const uiContext = useContext(UIContext);
  const { id } = props.match.params;
  const [isSaveLoading, setSaveLoading] = useState(false);
  const [isFileUploadLoading, setFileUploadLoading] = useState(false);
  const [isLoadingSinglePO, setLoadingSinglePO] = useState(false);
  const [isLoadingProduct, setLoadingProduct] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [isAddPrductTable, setAddPrductTable] = useState(true);
  const [selectedVendor, setSelectedVendor] = useState(null);
  // const [newlyAddedVendor, setNewlyAddedVendor] = useState(null);
  const [searchedVendorName, setSearchedVendorName] = useState("");
  const [searchedProductName, setSearchedProductName] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [isOpenAddNewVendorModal, setOpenAddNewVendorModal] = useState(false);
  const [isOpenAddNewProductModal, setOpenAddNewProductModal] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState(new Date());
  const [files, setFiles] = useState([]);
  const [uploadDocument, setUploadDocument] = useState([]);
  const [POEdited, setPOEdited] = useState(false);
  const [productList, setProductList] = useState([]);
  const [isUpdateVendorList, setUpdateVendorList] = useState(0);
  const [isUpdateProductList, setUpdateProductList] = useState(0);
  const [PODetails, setPODetails] = useState({});
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [initialValues, setInitialValues] = useState({
    contactId: "",
    date: new Date(),
    notes: "",
    expected_delivery: "",
    courier_company_name: "",
    multiple_products: [
      {
        productName: "",
        price: 0,
        quantity: 1,
        description: "",
        isOpenProductDropdown: false,
        selectedProduct: "",
        menuIsOpen: true,
        productId: "",
        product_media: "",
        totalAmount: 0,
      },
    ],
  });

  const handleAddMultipleProductRef = useRef([]);
  const handleProductDropdown = useRef([]);
  let boundArrayHelpers;

  async function getSinglePODetailsWithVendorListandProductList() {
    enableLoadingSinglePO();
    const singlePODetails = await singlePODetailsApi({ POId: id });
    const { Error, data: singlePOData, get_poitem } = singlePODetails || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    const {
      date: dateString,
      expected_delivery: expectedDeliveryString,
      notes,
      courier_company_name,
    } = singlePOData || {};

    const date = dateString ? stringToDateWithFormat(dateString) : "";
    const expectedDeliveryDate = expectedDeliveryString
      ? stringToDateWithFormat(expectedDeliveryString)
      : "";
    initialValues.date = date;
    initialValues.expected_delivery = expectedDeliveryDate;
    initialValues.notes = notes ? notes : "";
    initialValues.courier_company_name = courier_company_name;
    const vendorListResponse = await vendorListAllApi({});
    const { Error: ErrorVendorList, data: vendordataList } =
      vendorListResponse || {};
    if (ErrorVendorList) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(ErrorVendorList));
      handleErrorModalShow();
      return;
    }
    const vendors = vendordataList.map((vendor) => {
      const { id, contact_name } = vendor || {};
      vendor.value = id;
      vendor.label = capitalizeFirstLetter(contact_name);
      // const { get_contact } = newlyAddedVendor || {};
      // const { id: newAddedVendorId } = get_contact || {};
      // if (newAddedVendorId === id) {
      //   setSelectedVendor(vendor);
      //   initialValues.contactId = newAddedVendorId;
      //   setInitialValues(initialValues);
      // }
      return vendor;
    });
    const { contactId } = singlePOData || {};
    const [selectedVendor] = vendors.filter(
      (vendor) => vendor.id === contactId
    );
    let queryParams = { pageNumber: 1, pageSize: 100000 };
    const ProductListResponse = await ProductListApi({
      queryParams,
    });

    const { Error: ErrorProductList, data: productListData } =
      ProductListResponse || {};
    if (ErrorProductList) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(ErrorProductList));
      handleErrorModalShow();
      return;
    }
    const products = productListData.map((product) => {
      const { id, name } = product || {};
      product.value = id;
      product.label = capitalizeFirstLetter(name);
      return product;
    });
    const noOfRowss = [];
    const productRows = get_poitem.map((productItem) => {
      const { product, price, quantity, description, productId } =
        productItem || {};

      const { name, product_media, available_quantity } = product || {};
      const { name: productName } = product_media || {};
      const selecedProduct = getProductDetailsForSelectedProduct(
        products,
        productId
      );
      selecedProduct.isSelected = true;
      let obj = {
        productName: capitalizeFirstLetter(name),
        price: price,
        quantity: quantity,
        description: description,
        isOpenProductDropdown: false,
        selectedProduct: selecedProduct,
        menuIsOpen: true,
        productId: productId,
        product_media: productName,
        available_quantity: available_quantity,
      };
      noOfRowss.push(obj);
      return productItem;
    });
    initialValues.multiple_products = noOfRowss;
    if (selectedVendor) {
      setSelectedVendor(selectedVendor);
      initialValues.contactId = selectedVendor.id;
    }
    setPODetails(singlePOData ? singlePOData : []);
    setVendorList(vendors ? vendors : []);
    setProductList(products);
    setInitialValues(initialValues);
    const { purchase_media } = singlePOData || {};
    setFiles(purchase_media);
    setStartDate(date);
    setExpectedDeliveryDate(expectedDeliveryDate);
    disableLoadingSinglePO();
  }

  async function getVendorList() {
    enableLoadingSinglePO();
    const vendorListResponse = await vendorListAllApi({});
    disableLoadingSinglePO();
    const { Error, data } = vendorListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    const vendors = data.map((vendor) => {
      const { id, contact_name } = vendor || {};
      vendor.value = id;
      vendor.label = capitalizeFirstLetter(contact_name);
      // const { get_contact } = newlyAddedVendor || {};
      // const { id: newAddedVendorId } = get_contact || {};
      // if (newAddedVendorId === id) {
      //   setSelectedVendor(vendor);
      //   initialValues.contactId = newAddedVendorId;
      //   setInitialValues(initialValues);
      // }
      return vendor;
    });
    setVendorList(vendors ? vendors : []);
  }

  async function getProductList() {
    var queryParams = { pageNumber: 1, pageSize: 100000 };
    enableLoadingSinglePO();
    const ProductListResponse = await ProductListApi({
      queryParams,
    });
    disableLoadingSinglePO();
    const { Error, data } = ProductListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    const products = data.map((product) => {
      const { id, name } = product || {};
      product.value = id;
      product.label = capitalizeFirstLetter(name);
      return product;
    });
    setProductList(products ? products : []);
  }

  useEffect(() => {
    getSinglePODetailsWithVendorListandProductList();
  }, []);

  useEffect(() => {
    if (isUpdateVendorList) {
      getVendorList();
    }
  }, [isUpdateVendorList]);

  useEffect(() => {
    if (isUpdateProductList) {
      getProductList();
    }
  }, [isUpdateProductList]);

  // useEffect(() => {
  //   getSinglePODetails()
  // }, []);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // Below code is to close product selection dropdown
      if (handleProductDropdown.current) {
        handleProductDropdown.current.map((ref) => {
          if (ref && !ref.contains(e.target)) {
            const values = boundArrayHelpers.form.values;
            if (
              values.multiple_products &&
              values.multiple_products.length > 0
            ) {
              const mp = values.multiple_products;
              const multipleProducts = mp.map((product) => {
                product.isOpenProductDropdown = false;
                return product;
              });
              values.multiple_products = multipleProducts;
              setInitialValues(values);
            }
          }
          return ref;
        });
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  });

  const enableSaveLoading = () => {
    setSaveLoading(true);
  };

  const disableSaveLoading = () => {
    setSaveLoading(false);
  };

  const enableLoadingSinglePO = () => {
    setLoadingSinglePO(true);
  };

  const disableLoadingSinglePO = () => {
    setLoadingSinglePO(false);
  };

  const enableLoadingProduct = () => {
    setLoadingProduct(true);
  };

  const disableLoadingProduct = () => {
    setLoadingProduct(false);
  };

  const enableLoadingFileUpload = () => {
    setFileUploadLoading(true);
  };

  const disableLoadingFileUpload = () => {
    setFileUploadLoading(false);
  };

  const addNewVendorModalShow = () => {
    setOpenAddNewVendorModal(true);
  };

  const addNewVendorModalHide = () => {
    setOpenAddNewVendorModal(false);
  };

  const addNewProductModalShow = () => {
    setOpenAddNewProductModal(true);
  };

  const addNewProductModalHide = () => {
    setOpenAddNewProductModal(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleInputChangeVendor = (event) => {};

  const handleProductInputChange = (event) => {};

  const formatCreateLabelVendor = (inputValue) => {
    setSearchedVendorName(capitalizeFirstLetter(inputValue));
    return (
      "+" +
      intl.formatMessage({
        id: "TITLE.ADD.NEW.VENDOR",
      })
    );
  };

  const productFormatCreateLabel = (inputValue) => {
    setSearchedProductName(capitalizeFirstLetter(inputValue));
    return (
      "+" +
      intl.formatMessage({
        id: "TITLE.ADD.NEW.PRODUCT",
      })
    );
  };

  const toggleOpen = () => {
    setOpen(!isOpen);
  };

  const onSelectOfVendor = (setFieldValue, value) => {
    toggleOpen();
    if (value) {
      setSelectedVendor(value);
      setFieldValue("contactId", value.value);
    } else {
      setSelectedVendor(null);
      setFieldValue("contactId", "");
    }
  };

  const handleCloseOfAddNewVendorModal = (data) => {
    // setNewlyAddedVendor(data);
    setVendorList([]);
    setUpdateVendorList(Math.random());
    addNewVendorModalHide();
  };

  const handleCloseOfAddNewProductModal = (addNewVendorDetails) => {
    setProductList([]);
    setUpdateProductList(Math.random());
    addNewProductModalHide();
  };

  const onChangeOfQuantity = (setFieldValue, data, product, index) => {
    const value = data.target.value;
    setFieldValue(`multiple_products.${index}.quantity`, value);
  };

  const onChangeOfDescription = (setFieldValue, data, product, index) => {
    const value = data.target.value;
    setFieldValue(`multiple_products.${index}.description`, value);
  };

  const onSelectProductChange = (setFieldValue, data, product, index) => {
    const {
      value,
      name,
      price,
      available_quantity,
      product_media,
      description,
    } = data || {};
    if (data.isSelected) {
      setErrorMessage(
        intl.formatMessage({
          id: "TITLE.SELECTED.PRODUCT.IS.ALREADY.ADDED",
        })
      );
      handleErrorModalShow();
      return;
    }
    productList.map((prods) => {
      if (product.productId) {
        if (product.productId === prods.id) {
          prods.isSelected = false;
        }
      }
      return prods;
    });
    setProductList(productList)
    data.isSelected = true;
    const { name: productMediaName } = product_media || {};
    setFieldValue(`multiple_products.${index}.productId`, value);
    setFieldValue(
      `multiple_products.${index}.productName`,
      capitalizeFirstLetter(name)
    );
    setFieldValue(`multiple_products.${index}.price`, price);
    setFieldValue(`multiple_products.${index}.quantity`, 1);
    setFieldValue(
      `multiple_products.${index}.available_quantity`,
      available_quantity
    );
    setFieldValue(`multiple_products.${index}.product_media`, productMediaName);
    setFieldValue(`multiple_products.${index}.description`, description);
    setFieldValue(
      `multiple_products.${index}.isOpenProductDropdown`,
      !product.isOpenProductDropdown
    );
  };

  const getProductDetailsForSelectedProduct = (products, productId) => {
    const [selectedProduct] = products.filter((product) => {
      return product.id === productId;
    });
    return selectedProduct;
  };

  const attachDocument = async (acceptedFiles) => {
    const uploadedFilesArray = [];
    const maxLengthForDocUpload = 5 - files.length;
    enableLoadingFileUpload();
    for (let index = 0; index < acceptedFiles.length; index++) {
      if (maxLengthForDocUpload === index) {
        break;
      }
      if (MB_20 <= acceptedFiles[index].size) {
        setErrorMessage(
          intl.formatMessage({ id: "TITLE.ATTACHED.FILE.IS.TOO.LARGE" })
        );
        handleErrorModalShow();
        continue;
      }
      let files = {
        file: acceptedFiles[index],
        containername: "product",
      };
      const uploadDocumentResponse = await uploadDocumentApi(files);
      const [uploadDoc] = uploadDocumentResponse || {};
      uploadedFilesArray.push(uploadDoc);
    }
    disableLoadingFileUpload();
    const filesTemp = [...files, ...uploadedFilesArray];
    // const filesTemp = uploadedFilesArray;
    setFiles(filesTemp);
    setUploadDocument([...uploadDocument, ...uploadedFilesArray]);
  };

  const handleOnDeleteOfAttachment = (file, index) => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
    const newUploadedDocument = [...uploadDocument];
    newUploadedDocument.splice(newUploadedDocument[index], 1);
    setUploadDocument(newUploadedDocument);
  };

  const onDrop = useCallback((acceptedFiles) => {
    attachDocument(acceptedFiles);
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: FILE_EXTENTION,
    disabled: files.length === 5,
    maxFiles: 5,
    maxFilesize: MB_20,
    multiple: true,
    autoProcessQueue: true,
    onDrop,
  });

  const renderOpeningStock = (productItem) => {
    const { selectedProduct } = productItem || {};
    const { available_quantity } = selectedProduct || {};
    return available_quantity ? available_quantity : 0;
  };

  const renderProductImage = (rows) => {
    const { product_media } = rows || {};
    const fileName = product_media;

    return getProductMediaUrl(fileName);
  };

  const checkIsDisableNotEditableFormControls = () => {
    const { purchaseorderstatus } = PODetails || {};
    const { name } = purchaseorderstatus || {};
    if (name === "Waiting For Approval") {
      return false;
    }
    if (name === "Approved") {
      return true;
    }
    if (name === "Partially Fulfilled") {
      return true;
    }
    if (name === "Rejected") {
      return true;
    }
    if (name === "Received") {
      return true;
    }
    return true;
  };

  const editInward = async (values) => {
    if (!isAddPrductTable) {
      setErrorMessage(
        intl.formatMessage({
          id: "TITLE.YOU.NEED.TO.ADD.AT.LEAST.ONE.PRODUCT.TO.EDIT.PUCHASE.ORDER",
        })
      );
      handleErrorModalShow();
      return;
    }
    enableSaveLoading();

    const {
      contactId,
      date,
      expected_delivery,
      notes,
      courier_company_name,
      multiple_products,
    } = values || {};
    const docs = [];
    files.map((doc) => {
      const { filename, file_type, mimetype } = doc || {};

      let new_file_type;
      if (file_type) {
        new_file_type = file_type;
      }
      if (mimetype) {
        new_file_type = mimetype;
      }

      docs.push({ filename, file_type: new_file_type });
      return doc;
    });
    const productItems = [];
    multiple_products.map((product) => {
      const { productId, quantity, price, description } = product || {};
      productItems.push({
        productId,
        quantity: parseInt(quantity),
        price,
        description,
      });
      return product;
    });
    const totalAmount = totalAmountFromProductList(multiple_products);
    const totalQuantity = totalQuantityFromProductList(multiple_products);

    let obj = {
      id,
      date: date ? moment(date).format(YYYY_MM_DD) : null,
      contactId,
      expected_delivery: expected_delivery
        ? moment(expected_delivery).format(YYYY_MM_DD)
        : null,
      notes: notes ? notes : "",
      courier_company_name,
      purchaseorder_document: docs,
      purchaseorder_item: productItems,
      totalamount: totalAmount,
      totalquantity: totalQuantity,
      type: "po",
      files,
    };

    const editInwardResponse = await editInwardApi(obj);
    const { Error } = editInwardResponse || {};

    if (Error) {
      disableSaveLoading();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setPOEdited(true);
    window.scrollTo(0, 0);
    setTimeout(() => {
      disableSaveLoading();
      const path = "/inwards/inward-listing";
      onClickChangeRoute(path);
    }, 2500);
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  const bindArrayHelpers = (arrayHelpers) => {
    boundArrayHelpers = arrayHelpers;
  };

  const renderProductList = (setFieldValue, touched, errors, values) => {
    const { multiple_products } = values || [];
    if (!multiple_products) {
      return <></>;
    }
    return (
      <FieldArray
        name="multiple_products"
        render={(multiple_products) => {
          bindArrayHelpers(multiple_products);
          return values?.multiple_products.map((productItem, index) => (
            <tr key={index}>
              <td className="pl-2 pr-2 p-0">
                <div className="table-product-view pt-2">
                  <div className="product-thumb">
                    <div className="border-0">
                      <ImageWrapper
                        className={"img-radius align-top blur-up lazyloaded"}
                        src={renderProductImage(productItem)}
                      />
                    </div>
                  </div>
                  <div
                    className="product-name pl-3"
                    style={{ flex: "1" }}
                    ref={(ref) => (handleProductDropdown.current[index] = ref)}
                  >
                    <DropdownMaster
                      key={index}
                      isOpen={productItem?.isOpenProductDropdown}
                      target={
                        <ButtonAtl
                          iconAfter={<ChevronDownMaster />}
                          onClick={() => {
                            setFieldValue(
                              `multiple_products.${index}.isOpenProductDropdown`,
                              !productItem?.isOpenProductDropdown
                            );
                          }}
                          isSelected={productItem?.isOpenProductDropdown}
                          className="form-control w-100 bg-white custom-select-btn mb-0"
                        >
                          {productItem?.productName
                            ? productItem?.productName
                            : "Type or click to select an item"}
                        </ButtonAtl>
                      }
                      isDisabled={checkIsDisableNotEditableFormControls()}
                    >
                      <CreatableSelect
                        placeholder={intl.formatMessage({
                          id: "TITLE.SEARCH.PRODUCT",
                        })}
                        onChange={(data) => {
                          onSelectProductChange(
                            setFieldValue,
                            data,
                            productItem,
                            index
                          );
                        }}
                        onInputChange={handleProductInputChange}
                        options={productList}
                        createOptionPosition="top"
                        formatCreateLabel={productFormatCreateLabel}
                        isSearchable
                        onCreateOption={() => {
                          addNewProductModalShow();
                        }}
                        autoFocus
                        backspaceRemovesValue={true}
                        components={{
                          DropdownIndicatorMaster,
                          IndicatorSeparator: null,
                        }}
                        controlShouldRenderValue={true}
                        hideSelectedOptions={false}
                        isClearable={true}
                        menuIsOpen={productItem?.isOpenProductDropdown}
                        tabSelectsValue={true}
                        value={productItem?.productName}
                        className="react-custom-select-container"
                        classNamePrefix="react-select"
                      />
                    </DropdownMaster>
                    {touched.multiple_products &&
                    errors.multiple_products &&
                    errors.multiple_products.length > 0 &&
                    errors.multiple_products[index] ? (
                      <div className="text-danger">
                        {errors.multiple_products[index].productId}
                      </div>
                    ) : null}
                  </div>
                </div>
              </td>
              <td>
                <Form.Group>
                  <Form.Control
                    className="form-control-sm p-1 border-0"
                    type="number"
                    name="price"
                    placeholder={intl.formatMessage({
                      id: "TITLE.ENTER.PRICE",
                    })}
                    value={productItem?.price}
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    onPaste={(evt) => {
                      const values = evt.clipboardData.getData("text");
                      const nonAlpha = values.replace(/[^\d.-]/g, "");
                      evt.preventDefault();
                      setFieldValue("price", nonAlpha);
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      setFieldValue(`multiple_products.${index}.price`, value);
                    }}
                    disabled={
                      PODetails?.purchaseorderstatus?.name !==
                      "Waiting For Approval"
                    }
                  />
                  {touched.multiple_products &&
                  errors.multiple_products &&
                  errors.multiple_products.length > 0 &&
                  errors.multiple_products[index] ? (
                    <div className="text-danger">
                      {errors.multiple_products[index].price}
                    </div>
                  ) : null}
                </Form.Group>
              </td>
              <td>
                <Form.Group>
                  <Form.Control
                    className="form-control-sm p-1 border-0"
                    type="number"
                    placeholder={intl.formatMessage({
                      id: "TITLE.ENTER.QUANTITY",
                    })}
                    value={productItem?.quantity}
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    onPaste={(evt) => {
                      const values = evt.clipboardData.getData("text");
                      const nonAlpha = values.replace(/[^\d.-]/g, "");
                      evt.preventDefault();
                      setFieldValue(
                        `multiple_products.${index}.quantity`,
                        nonAlpha
                      );
                    }}
                    onChange={(data) => {
                      onChangeOfQuantity(
                        setFieldValue,
                        data,
                        productItem,
                        index
                      );
                    }}
                    disabled={checkIsDisableNotEditableFormControls()}
                  />
                  {touched.multiple_products &&
                  errors.multiple_products &&
                  errors.multiple_products.length > 0 &&
                  errors.multiple_products[index] ? (
                    <div className="text-danger">
                      {errors.multiple_products[index].quantity}
                    </div>
                  ) : null}
                </Form.Group>
              </td>
              <td>
                <Form.Group>
                  <Form.Control
                    className="form-control-sm p-1 border-0"
                    type="number"
                    placeholder={intl.formatMessage({
                      id: "TITLE.ENTER.AMOUNT",
                    })}
                    name="totalAmount"
                    value={productItem.price * productItem.quantity}
                    disabled
                    autoComplete="off"
                  />
                  {touched.multiple_products &&
                  errors.multiple_products &&
                  errors.multiple_products.length > 0 &&
                  errors.multiple_products[index] ? (
                    <div className="text-danger">
                      {errors.multiple_products[index].totalAmount}
                    </div>
                  ) : null}
                </Form.Group>
              </td>
              <td>
                <Form.Control
                  id="notes"
                  name="notes"
                  as="textarea"
                  disabled
                  placeholder={intl.formatMessage({
                    id: "TITLE.ENTER.DESCRIPTION",
                  })}
                  className="form-control form-control-sm p-1 border-0"
                  resize="none"
                  onChange={(data) => {
                    onChangeOfDescription(
                      setFieldValue,
                      data,
                      productItem,
                      index
                    );
                  }}
                  maxLength={255}
                  value={productItem?.description}
                />
                {/* {touched.multiple_products &&
                errors.multiple_products &&
                errors.multiple_products.length > 0 &&
                errors.multiple_products[index] ? (
                  <div className="text-danger">
                    {errors.multiple_products[index].description}
                  </div>
                ) : null} */}
              </td>
              <td>
                <Form.Group>
                  <Form.Control
                    className="form-control-sm p-1 border-0"
                    type="text"
                    placeholder="0"
                    value={productItem?.available_quantity}
                    disabled
                  />
                  {touched.multiple_products &&
                  errors.multiple_products &&
                  errors.multiple_products.length > 0 &&
                  errors.multiple_products[index] ? (
                    <div className="text-danger">
                      {errors.multiple_products[index].available_quantity}
                    </div>
                  ) : null}
                </Form.Group>
              </td>
              <td>
                <Button
                  variant="link p-0 m-0"
                  className="delete-table-row"
                  onClick={() => {
                    if (values.multiple_products.length === 1) {
                      setAddPrductTable(false);
                    }
                    if (
                      values.multiple_products &&
                      values.multiple_products.length > 0
                    ) {
                      const productId =
                        values.multiple_products[index].productId;
                      productList.map((product) => {
                        if (product.id === productId) {
                          product.isSelected = false;
                        }
                        return product;
                      });
                    }
                    multiple_products.remove(index);
                  }}
                  disabled={checkIsDisableNotEditableFormControls()}
                >
                  <XCircle
                    size={16}
                    style={{
                      color: "#990029",
                      marginRight: "0",
                    }}
                  />
                </Button>
                <Button
                  variant={`link p-0 ml-2 d-none`}
                  ref={(el) =>
                    (handleAddMultipleProductRef.current[index] = el)
                  }
                  onClick={() => {
                    const obj = {
                      productName: "",
                      price: 0,
                      quantity: 1,
                      description: "",
                      isOpenProductDropdown: false,
                      selectedProduct: "",
                      menuIsOpen: true,
                      productId: "",
                      product_media: "",
                    };
                    multiple_products.insert(
                      values.multiple_products.length,
                      obj
                    );
                  }}
                ></Button>
              </td>
            </tr>
          ));
        }}
      />
    );
  };

  const { purchaseorder_number = "-" } = PODetails || {};

  return (
    <>
      <Card>
        {isLoadingSinglePO && <SectionLoader />}
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={EditInwardSchema}
          onSubmit={(values) => {
            editInward(values);
          }}
          onReset={(values) => {}}
        >
          {({
            values,
            handleSubmit,
            handleBlur,
            handleChange,
            resetForm,
            handleReset,
            setFieldValue,
            errors,
            touched,
          }) => (
            <Form
              className="form form-label-right filter-card"
              autoComplete="off"
              onSubmit={handleSubmit}
              onReset={() => {
                resetForm(initialValues);
                handleReset();
              }}
            >
              <Card.Header className="d-flex align-items-center justify-content-between">
                <h3 className="card-title page-title text-black mb-0">
                  <FormattedMessage id="TITLE.EDIT.PURCHASE.ORDER" />
                  {`- ${purchaseorder_number}`}
                </h3>
                <Button
                  variant="link p-0 btn-icon"
                  onClick={() => {
                    const path = "/inwards/inward-listing";
                    onClickChangeRoute(path);
                  }}
                >
                  <X size={20} className="text-dark mr-0 pr-0" />
                </Button>
              </Card.Header>
              <Card.Body>
                {POEdited && (
                  <div className="notification icon inline success fs-16">
                    <Check size={8} className="text-white"></Check>{" "}
                    <FormattedMessage id="TITLE.PURCHASE.ORDER.HAS.BEEN.EDITED.SUCCESSFULLY" />
                  </div>
                )}
                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label htmlFor="selectVendor">
                        <FormattedMessage id="TITLE.SELECT.VENDOR" />{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <DropdownMaster
                        isOpen={isOpen}
                        onClose={toggleOpen}
                        target={
                          <ButtonAtl
                            iconAfter={<ChevronDownMaster />}
                            onClick={toggleOpen}
                            isSelected={isOpen}
                            className="form-control w-100 bg-white custom-select-btn mb-0"
                          >
                            {selectedVendor
                              ? `${selectedVendor.label}`
                              : intl.formatMessage({
                                  id: "TITLE.SELECT.VENDOR",
                                })}
                          </ButtonAtl>
                        }
                        isDisabled={checkIsDisableNotEditableFormControls()}
                      >
                        <CreatableSelect
                          onChange={(value) => {
                            onSelectOfVendor(setFieldValue, value);
                          }}
                          onInputChange={handleInputChangeVendor}
                          options={vendorList}
                          createOptionPosition="top"
                          formatCreateLabel={formatCreateLabelVendor}
                          isSearchable
                          onCreateOption={() => {
                            // console.log("onCreateOption");
                            addNewVendorModalShow();
                            setOpen(false);
                          }}
                          autoFocus
                          backspaceRemovesValue={true}
                          components={{
                            DropdownIndicatorMaster,
                            IndicatorSeparator: null,
                          }}
                          controlShouldRenderValue={true}
                          hideSelectedOptions={false}
                          isClearable={true}
                          menuIsOpen
                          placeholder={intl.formatMessage({
                            id: "TITLE.SELECT.VENDOR",
                          })}
                          // styles={selectStyles}
                          tabSelectsValue={true}
                          value={selectedVendor}
                          className="react-custom-select-container"
                          classNamePrefix="react-select"
                        />
                      </DropdownMaster>
                      {touched.contactId && errors.contactId ? (
                        <div className="text-danger">{errors.contactId}</div>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label htmlFor="PO-Number">
                        <FormattedMessage id="TITLE.PURCHASE.ORDER.NUMBER" />#
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="PO-Number"
                        value={purchaseorder_number}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>
                        <FormattedMessage id="TITLE.DATE" />
                      </Form.Label>
                      <DatePicker
                        selected={startDate}
                        dateFormat={dd_MM_yyyy}
                        onChange={(date) => {
                          setStartDate(date);
                          setFieldValue("date", date ? date : "");
                        }}
                        maxDate={new Date()}
                        placeholderText={intl.formatMessage({
                          id: "TITLE.DD/MM/YYYY",
                        })}
                        className="form-control w-100"
                        closeOnScroll={(e) => e.target === document}
                        disabled={checkIsDisableNotEditableFormControls()}
                      />
                      {touched.date && errors.date ? (
                        <div className="text-danger">{errors.date}</div>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <div className="d-flex align-items-center justify-content-between">
                        <Form.Label htmlFor="attach-qutation">
                          <FormattedMessage id="TITLE.ATTACH.FILE" />
                        </Form.Label>
                        <span className="small-text">
                          {" "}
                          <FormattedMessage id="TITLE.MAXIMUM.SIZE" />: 20MB
                        </span>
                      </div>
                      <section>
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <File
                            size={32}
                            className="text-dark"
                            style={{ strokeWidth: "1.5px" }}
                          ></File>
                          <p>
                            <FormattedMessage id="TITLE.DRAG.DROP.FILES.HERE.OR.CLICK.THE.ICON" />
                          </p>
                        </div>
                        {isFileUploadLoading && <ContentLoader />}
                        <ThumbnailWrapper
                          files={files}
                          handleOnDeleteOfAttachment={
                            handleOnDeleteOfAttachment
                          }
                        />
                      </section>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label htmlFor="notes">
                        <FormattedMessage id="TITLE.NOTES" />
                        <span className="mr-1 ml-1 text-muted">
                          (
                          <FormattedMessage id="TITLE.MAX.512.CHARACTERS" />)
                        </span>
                      </Form.Label>
                      <Form.Control
                        id="notes"
                        name="notes"
                        as="textarea"
                        placeholder={intl.formatMessage({
                          id: "TITLE.ENTER.YOUR.NOTES",
                        })}
                        className="form-control"
                        resize="none"
                        value={values.notes}
                        onChange={(e) => {
                          setFieldValue("notes", e.target.value);
                        }}
                        maxLength={512}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>
                        <FormattedMessage id="TITLE.EXPECTED.DELIVERY.DATE" />
                      </Form.Label>
                      <DatePicker
                        selected={expectedDeliveryDate}
                        dateFormat={dd_MM_yyyy}
                        onChange={(date) => {
                          setExpectedDeliveryDate(date);
                          setFieldValue("expected_delivery", date ? date : "");
                        }}
                        minDate={startDate}
                        placeholderText={intl.formatMessage({
                          id: "TITLE.DD/MM/YYYY",
                        })}
                        className="form-control w-100"
                        closeOnScroll={(e) => e.target === document}
                      />
                      {touched.expected_delivery && errors.expected_delivery ? (
                        <div className="text-danger">
                          {errors.expected_delivery}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label id="courier_company_name">
                        <FormattedMessage id="TITLE.COURIER.COMPANY.NAME" />
                      </Form.Label>
                      <Form.Control
                        id="courier_company_name"
                        name="courier_company_name"
                        type="text"
                        className="form-control"
                        placeholder={intl.formatMessage({
                          id: "TITLE.ENTER.COURIER.COMPANY",
                        })}
                        value={values.courier_company_name}
                        onChange={(e) => {
                          setFieldValue("courier_company_name", e.target.value);
                        }}
                      />
                      {touched.courier_company_name &&
                      errors.courier_company_name ? (
                        <div className="text-danger">
                          {errors.courier_company_name}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Table bordered className="inwardProduct mt-3">
                  <thead>
                    <tr>
                      <th>
                        <FormattedMessage id="TITLE.PRODUCT" />
                      </th>
                      <th style={{ width: "12%" }}>
                        <FormattedMessage id="TITLE.PRICE" />
                      </th>
                      <th style={{ width: "12%" }}>
                        <FormattedMessage id="TITLE.QUANTITY" />
                      </th>
                      <th style={{ width: "12%" }}>
                        <FormattedMessage id="TITLE.AMOUNT" />
                      </th>
                      <th style={{ width: "20%" }}>
                        <FormattedMessage id="TITLE.DESCRIPTION" />
                      </th>
                      <th style={{ width: "13%" }}>
                        <FormattedMessage id="TITLE.STOCK.ON.HAND" />
                      </th>
                      <th style={{ width: "2.5%" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {isAddPrductTable &&
                      renderProductList(setFieldValue, touched, errors, values)}
                    {!isAddPrductTable && (
                      <tr>
                        <td colSpan={7}>
                          <div className="d-grid gap-2">
                            <Button
                              variant="gray btn-icon btn-block"
                              onClick={() => {
                                if (values.multiple_products.length === 0) {
                                  const obj = {
                                    productName: "",
                                    price: 0,
                                    quantity: 1,
                                    description: "",
                                    isOpenProductDropdown: false,
                                    selectedProduct: "",
                                    menuIsOpen: true,
                                    productId: "",
                                    product_media: "",
                                  };
                                  values.multiple_products.push(obj);
                                }
                                setAddPrductTable(true);
                              }}
                            >
                              <Plus size={16} style={{ color: "#333333" }} />{" "}
                              <FormattedMessage id="TITLE.ADD.PRODUCT" />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <Row>
                  <Col md={6}>
                    {isAddPrductTable && (
                      <div className="pt3">
                        <Button
                          variant="tertiary"
                          className="btn-icon btn-icon text-dark inventory-btn"
                          onClick={() => {
                            if (
                              handleAddMultipleProductRef.current &&
                              handleAddMultipleProductRef.current.length > 0
                            ) {
                              handleAddMultipleProductRef.current[0].click();
                            }
                          }}
                          disabled={checkIsDisableNotEditableFormControls()}
                        >
                          <Plus size={14} className="text-dark"></Plus>{" "}
                          <FormattedMessage id="TITLE.ADD.ANOTHER.ITEM" />
                        </Button>
                      </div>
                    )}
                  </Col>
                  <Col md={6}>
                    <div className="p-3 bg-light">
                      <Row className="total-section">
                        <Col md={6}>
                          <h6 className="modal-title f-w-900">
                            {" "}
                            <FormattedMessage id="TITLE.TOTAL" />
                          </h6>
                          <span className="text-muted small-text text-dark">
                            <FormattedMessage id="TITLE.TOTAL.QUANTITY" /> :{" "}
                            {totalQuantityFromProductList(
                              values?.multiple_products
                            )}
                          </span>
                        </Col>
                        <Col md={6}>
                          <h6 className="modal-title f-w-900 text-right">
                            {amountFormatter(
                              totalAmountFromProductList(
                                values?.multiple_products
                              )
                            )}
                          </h6>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="d-flex justify-content-end">
                <Button
                  variant="success mr-3 btn-inventory"
                  onClick={() => handleSubmit()}
                  disabled={isSaveLoading}
                >
                  <FormattedMessage id="TITLE.SAVE" />
                  {isSaveLoading && (
                    <Spinner animation="border" size="sm" className="ml-1" />
                  )}
                </Button>
                <Button
                  variant="tertiary text-dark btn-inventory"
                  onClick={() => {
                    const path = "/inwards/inward-listing";
                    onClickChangeRoute(path);
                    window.scrollTo(0, 0);
                  }}
                >
                  <FormattedMessage id="TITLE.CANCEL" />
                </Button>
              </Card.Footer>
            </Form>
          )}
        </Formik>
      </Card>
      {isOpenAddNewProductModal && (
        <AddNewProductModal
          searchedProductName={searchedProductName}
          open={isOpenAddNewProductModal}
          onCloseModal={addNewProductModalHide}
          handleCloseOfAddNewProductModal={handleCloseOfAddNewProductModal}
        />
      )}

      {isOpenAddNewVendorModal && (
        <AddNewVendorModal
          searchedVendorName={searchedVendorName}
          open={isOpenAddNewVendorModal}
          onCloseModal={addNewVendorModalHide}
          handleCloseOfAddNewVendorModal={handleCloseOfAddNewVendorModal}
        />
      )}

      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}

export default EditInward;
