import { Spinner } from "react-bootstrap";
export const ContentLoader = ({ className, pleaseWait }) => (
  <div className="content-loader table-loader">
    <div className="d-flex justify-content-center flex-wrap">
      <div className="align-item-center">
        <Spinner
          animation="grow"
          size="sm"
          className="mr-1"
          variant="success"
        />
        <Spinner animation="grow" size="sm" className="mr-1" variant="danger" />
        <Spinner
          animation="grow"
          size="sm"
          className="mr-1"
          variant="warning"
        />
        <Spinner animation="grow" size="sm" className="mr-1" variant="info" />
        {/* {!pleaseWait && (
          <div className="text-center fs-18 m-2">Please wait...</div>
        )} */}
      </div>
    </div>
  </div>
);
