import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  OverlayTrigger,
  Table,
  Tooltip,
} from "react-bootstrap";

import { Edit2, Plus, X } from "react-feather";
import { Link } from "react-router-dom";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { AddNewProductModal } from "../../../global-modal/AddNewProductModal ";
import ExpectedDeliveryDateModal from "../../../global-modal/ExpectedDeliveryDateModal";
import { DeleteModal } from "../../common/modals/DeleteModal";
import AdjustStockForm from "./AdjustStockForm";
import DefaultProductView from "./DefaultProductView";
import {
  productListMastersApi,
  getSingleProductDetailsApi,
  editProductApi,
  getPoandDcSummaryApi,
  POListApi,
  DCListApi,
} from "../../../axios/services/services";
import { ErrorModal } from "../../common/modals/ErrorModal";
import { SectionLoader } from "../../../helper/SectionLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  capitalizeFirstLetter,
  getErrorMessageBasedOnLanguage,
  setActiveMenu,
  unauthorization,
} from "../../../constants/utils";
import { toAbsoluteUrl } from "../../../helper/helper";
import SVG from "react-inlinesvg";
import { ButtonLoader } from "../../../helper/ButtonLoader";
import { EditProductModal } from "../edit-product/EditProductModal";
import { SuccessModal } from "../../common/modals/SuccessModal";
import TransactionPOView from "./TransactionPOView";
import TransactionDCView from "./TransactionDCView";
import { FormattedMessage, useIntl } from "react-intl";
import { USER_DETAILS } from "../../../constants/const";
import { MENUITEMS } from "../../../constants/menu";
import { UIContext } from "../../../helper/UIContext";

const ViewProductDetails = ({ history, handlePDFViewValues, ...props }) => {
  const intl = useIntl();
  const uiContext = useContext(UIContext);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isOpenAddNewProductModal, setOpenAddNewProductModal] = useState(false);
  const [isAdjustStock, setAdjustStock] = useState(false);
  const [queryParams, setQueryParams] = useState({
    pageNumber: 1,
    pageSize: 20,
  });
  const [queryParamsPO, setQueryParamsPO] = useState({
    pageNumber: 1,
    pageSize: 20,
  });
  const [queryParamsDC, setQueryParamsDC] = useState({
    pageNumber: 1,
    pageSize: 20,
  });

  const [isLoadingProductListView, setLoadingProductListView] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [productList, setProductList] = useState([]);
  const [isLoadMoreProductListView, setLoadMoreProductListView] =
    useState(false);
  const [isLoadMorePOListView, setLoadMorePOListView] = useState(false);
  const [isLoadMoreDCListView, setLoadMoreDCListView] = useState(false);
  const { id } = props.match.params;
  const [isLoading, setLoading] = useState(false);
  const [productDetails, setProductDetails] = useState({});
  const [isUpdateSingleProductDetails, setUpdateSingleProductDetails] =
    useState(0);
  const [isUpdateProductList, setUpdateProductList] = useState(0);
  const [isUpdatePOList, setUpdatePOList] = useState(0);
  const [isOpenEditProductModal, setOpenEditProductModal] = useState(false);
  const [isLoadingEditProduct, setLoadingEditProduct] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [POSummary, setPOSummary] = useState([]);
  const [isPOView, setPOView] = useState(true);
  const [isLoadingPOView, setLoadingPOView] = useState(true);
  const [isLoadingDCView, setLoadingDCView] = useState(true);
  const [POList, setPOList] = useState([]);
  const [DCList, setDCList] = useState([]);
  const [isReceiveviewOpen, setReceiveviewOpen] = useState(false);
  const [isItemReceived, setItemReceived] = useState(false);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);

  useEffect(() => {
    getProductList();
  }, [queryParams, isUpdateProductList]);

  useEffect(() => {
    getSingleProductDetails();
  }, [id, isUpdateSingleProductDetails]);

  useEffect(() => {
    getPOSummary();
  }, []);

  useEffect(() => {
    getPODetails();
  }, [queryParamsPO, id, isUpdateSingleProductDetails]);

  useEffect(() => {
    getDCDetails();
  }, [queryParamsDC, id, isUpdateSingleProductDetails]);

  useEffect(() => {
    setPOList([]);
    setDCList([]);
  }, [id, isUpdateSingleProductDetails]);

  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const { role } = userDetails || {};
  const { name: userRole } = role || {};

  async function getPOSummary() {
    if (userRole !== "Admin") {
      const POSummaryResponse = await getPoandDcSummaryApi({
        chosenLabel: "thismonth",
        productId: id,
      });

      const { Error, data } = POSummaryResponse || {};
      if (Error) {
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setPOSummary(data);
    }
  }

  const onClickOfPOView = () => {
    setPOView(true);
  };

  async function getPODetails() {
    if (userRole !== "Admin") {
      const productId = id;
      setLoadingPOView(true);
      const POResponse = await POListApi({
        queryParams: queryParamsPO,
        productId,
      });
      setLoadingPOView(false);
      const { Error, data } = POResponse || {};
      if (Error) {
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setPOList((POList) => [...POList, ...data]);
      setUpdatePOList(Math.random());
      if (data.length >= 20) {
        setLoadMorePOListView(true);
      } else {
        setLoadMorePOListView(false);
      }
    }
  }

  async function getProductList() {
    setLoadingProductListView(true);
    const ProductListResponse = await productListMastersApi({
      queryParams,
    });
    setLoadingProductListView(false);
    const { Error, data } = ProductListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setProductList((productList) => [...productList, ...data]);
    if (data.length >= 20) {
      setLoadMoreProductListView(true);
    } else {
      setLoadMoreProductListView(false);
    }
  }

  async function getDCDetails() {
    if (userRole !== "Admin") {
      const productId = id;
      setLoadingDCView(true);
      var queryParams = { pageNumber: 1, pageSize: 20 };
      const POResponse = await DCListApi({
        queryParams,
        productId,
      });
      setLoadingDCView(false);
      const { Error, data } = POResponse || {};
      if (Error) {
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setDCList((DCList) => [...DCList, ...data]);
      if (data.length >= 20) {
        setLoadMoreDCListView(true);
      } else {
        setLoadMoreDCListView(false);
      }
    }
  }

  const handleSaveOfStatus = async () => {
    enableLoadingEditProduct();
    let status = productDetails.status === 0 ? 1 : 0;
    const editStatus = await editProductApi({
      id,
      status,
    });
    
    const { Error } = editStatus || {};
    if (Error) {
      disableLoadingEditProduct();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }

    if (status === 1) {
      setSuccessMessage(
        intl.formatMessage({ id: "TITLE.STATUS.MARKED.AS.ACTIVE" })
      );
    } else {
      setSuccessMessage(
        intl.formatMessage({ id: "TITLE.STATUS.MARKED.AS.INACTIVE" })
      );
    }
    onOpenSuccessModal();
    setUpdateSingleProductDetails(Math.random());
    disableLoadingEditProduct();
  };

  const onOpenSuccessModal = () => {
    setOpenSuccessModal(true);
  };

  const onCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const handleCloseOfEditNewProductModal = () => {
    setQueryParams({ pageNumber: 1 });
    setProductList([]);
    // history.push(`/view-product-details/${id}`);
    setUpdateSingleProductDetails(Math.random());
    editProductModalHide();
  };

  const enableLoadingEditProduct = () => {
    setLoadingEditProduct(true);
  };

  const disableLoadingEditProduct = () => {
    setLoadingEditProduct(false);
  };

  const editProductModalShow = () => {
    setOpenEditProductModal(true);
  };

  const editProductModalHide = () => {
    setOpenEditProductModal(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const addNewProductModalShow = () => {
    setOpenAddNewProductModal(true);
  };

  const addNewProductModalHide = () => {
    setOpenAddNewProductModal(false);
  };

  const adjustStockShow = () => {
    setAdjustStock(true);
  };

  const adjustStockHide = () => {
    getSingleProductDetails();
    setAdjustStock(false);
  };

  const handleDeleteModalShow = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalHide = () => {
    setIsDeleteModalOpen(false);
  };

  const CreateNew = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <FormattedMessage id="TITLE.CREATE.NEW" />
    </Tooltip>
  );

  const editTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <FormattedMessage id="TITLE.EDIT" />
    </Tooltip>
  );

  const handlePaginationOfProductListView = () => {
    const { pageNumber, pageSize } = queryParams || {};
    setQueryParams({ pageNumber: pageNumber + 1, pageSize });
  };

  const handlePaginationOfPOListView = () => {
    const { pageNumber, pageSize } = queryParamsPO || {};
    setQueryParamsPO({ pageNumber: pageNumber + 1, pageSize });
  };

  const handlePaginationOfDCListView = () => {
    const { pageNumber, pageSize } = queryParamsDC || {};
    setQueryParamsDC({ pageNumber: pageNumber + 1, pageSize });
  };

  const getSecondaryColorClass = (product) => {
    var className = "fs-14 text-primary font-weight-bold mb-0 cursor-pointer";

    const { id: PID } = product;
    if (PID === id) {
      className = className + " text-secondary";
    }
    return className;
  };

  const renderProductName = (product) => {
    const { name } = product || {};
    return <>{capitalizeFirstLetter(name === null ? "-" : name)}</>;
  };

  async function getSingleProductDetails() {
    setLoading(true);
    const singleProductDetails = await getSingleProductDetailsApi({
      id,
    });
    setLoading(false);
    const { Error, data } = singleProductDetails || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setProductDetails(data ? data : []);
  }

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  const handleCloseOfAddNewProductModal = () => {
    setUpdateSingleProductDetails(Math.random());
    setProductList([]);
    setUpdateProductList(Math.random());
    addNewProductModalHide();
  };

  return (
    <>
      <Card className="p-0 mb-0">
        {isLoading && <SectionLoader />}
        <div className="details-view d-block d-md-flex">
          {/* Listing */}
          <div className="column list-column border-right d-none d-lg-block">
            <div className="column-header d-flex align-items-center justify-content-between">
              <h2 className="modal-title">
                <FormattedMessage id="TITLE.ALL.PRODUCTS" />
              </h2>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={CreateNew}
              >
                <Button
                  variant="primary btn-inventory btn-icon"
                  size="sm"
                  onClick={() => {
                    addNewProductModalShow();
                  }}
                >
                  <Plus size={14} className="text-secondary"></Plus>{" "}
                  <FormattedMessage id="TITLE.NEW" />
                </Button>
              </OverlayTrigger>
            </div>
            {isLoadingProductListView && <SectionLoader />}
            <div id="scrollableDiva" className="column-body custom-scrollbar">
              {!isLoadingProductListView && (
                <InfiniteScroll
                  scrollableTarget="scrollableDiv"
                  dataLength={productList.length}
                  next={() => {
                    handlePaginationOfProductListView();
                  }}
                  hasMore={isLoadMoreProductListView}
                  loader={
                    <h4 style={{ textAlign: "center" }} className="p-1">
                      <FormattedMessage id="TITLE.LOADING" />
                      ...
                    </h4>
                  }
                >
                  {productList.map((product, index) => (
                    <div
                      className="column-box cursor-pointer"
                      key={index}
                      onClick={() => {
                        const path = `/master/products/view-product-details/${product?.id}`;
                        onClickChangeRoute(path);
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column">
                          <h5 className={getSecondaryColorClass(product)}>
                            {renderProductName(product)}
                          </h5>
                        </div>
                      </div>
                    </div>
                  ))}
                </InfiniteScroll>
              )}
            </div>
          </div>
          {/* Details */}

          {!isReceiveviewOpen && (
            <div className="column details-column">
              {isLoading && <SectionLoader />}

              <div className="column-header d-flex align-items-center justify-content-between">
                <h2 className="modal-title">
                  {capitalizeFirstLetter(productDetails?.name)}
                </h2>
                <div className="d-flex">
                  <ButtonGroup className="mr-2" aria-label="First group">
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={editTooltip}
                    >
                      <Button
                        variant="outline-tertiary btn-sm btn-icon"
                        onClick={() => editProductModalShow()}
                      >
                        <Edit2 size={16} className="p-0 m-0"></Edit2>
                      </Button>
                    </OverlayTrigger>
                  </ButtonGroup>
                  {/* <Button
                    variant="success btn-sm btn-icon mr-2"
                    onClick={() => adjustStockShow()}
                  >
                    <FormattedMessage id="TITLE.ADJUST.STOCK" />
                  </Button> */}
                  <Dropdown>
                    <Dropdown.Toggle variant="dropdown-sm" id="dropdown-basic">
                      {isLoadingEditProduct && (
                        <ButtonLoader className="mr-1 ml-1" />
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          handleSaveOfStatus();
                        }}
                        disabled={isLoadingEditProduct}
                      >
                        {isLoadingEditProduct && (
                          <ButtonLoader className="mr-1 ml-1" />
                        )}
                        {productDetails.status === 1 ? (
                          <FormattedMessage id="TITLE.MARK.AS.INACTIVE" />
                        ) : (
                          <FormattedMessage id="TITLE.MARK.AS.ACTIVE" />
                        )}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Button
                    variant="close btn-icon btn-sm"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      const path = "/master/products";
                      onClickChangeRoute(path);
                    }}
                  >
                    <X size={20} className="p-0 m-0 text-dark"></X>
                  </Button>
                </div>
              </div>
              <div
                id="scrollabledivOverview"
                className="column-body custom-scrollbar"
              >
                {isAdjustStock ? (
                  <AdjustStockForm
                    adjustStockHide={adjustStockHide}
                    productDetails={productDetails}
                    id={id}
                  />
                ) : (
                  <Tabs>
                    <TabList className="nav nav-tabs tab-coupon mb-0">
                      <Tab className="nav-link">
                        <FormattedMessage id="TITLE.OVERVIEW" />
                      </Tab>
                      {userRole !== "Admin" && (
                        <Tab className="nav-link">
                          <FormattedMessage id="TITLE.TRANSACTIONS" />
                        </Tab>
                      )}
                    </TabList>
                    <TabPanel>
                      <DefaultProductView
                        productDetails={productDetails}
                        id={id}
                        POSummary={POSummary}
                      />
                    </TabPanel>
                    {userRole !== "Admin" && (
                      <TabPanel>
                        <div className="highlighted-row">
                          <div className="d-flex align-items-center justify-content-between">
                            {!isItemReceived && (
                              <div className="">
                                <div className="d-flex justify-content-start">
                                  <Button
                                    // variant='secondary btn-icon'
                                    variant={`${
                                      isPOView
                                        ? "secondary btn-icon ml-2"
                                        : "tertiary btn-icon ml-2"
                                    }`}
                                    onClick={() => onClickOfPOView()}
                                  >
                                    <FormattedMessage id="TITLE.PURCHASE.ORDERS" />
                                  </Button>
                                  <Button
                                    // variant="tertiary btn-icon ml-2"
                                    variant={`${
                                      isPOView
                                        ? "tertiary btn-icon ml-2"
                                        : "secondary btn-icon ml-2"
                                    }`}
                                    onClick={() => setPOView(false)}
                                  >
                                    <FormattedMessage id="TITLE.DELIVERY.CHALLAN" />
                                  </Button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        {isPOView ? (
                          <TransactionPOView
                            POList={POList}
                            isLoadingPOView={isLoadingPOView}
                            handlePaginationOfPOListView={
                              handlePaginationOfPOListView
                            }
                            isLoadMorePOListView={isLoadMorePOListView}
                          />
                        ) : (
                          <TransactionDCView
                            DCList={DCList}
                            isLoadingDCView={isLoadingDCView}
                            handlePaginationOfDCListView={
                              handlePaginationOfDCListView
                            }
                            isLoadMoreDCListView={isLoadMoreDCListView}
                          />
                        )}
                      </TabPanel>
                    )}
                  </Tabs>
                )}
              </div>
            </div>
          )}
          {/*  */}
        </div>
      </Card>

      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteModal
          title={"Delete Inward?"}
          description={
            "Inward will be deleted forever and cannot be retrieved later. Are you sure about deleting it?"
          }
          isOpen={isDeleteModalOpen}
          onCloseModal={handleDeleteModalHide}
        />
      )}

      {isOpenAddNewProductModal && (
        <AddNewProductModal
          open={isOpenAddNewProductModal}
          onCloseModal={addNewProductModalHide}
          handleCloseOfAddNewProductModal={handleCloseOfAddNewProductModal}
        />
      )}

      {isOpenEditProductModal && (
        <EditProductModal
          open={isOpenEditProductModal}
          data={productDetails}
          onCloseModal={editProductModalHide}
          id={id}
          handleCloseOfEditNewProductModal={handleCloseOfEditNewProductModal}
        />
      )}

      {openSuccessModal && (
        <SuccessModal
          message={successMessage}
          open={openSuccessModal}
          onCloseModal={onCloseSuccessModal}
        />
      )}
    </>
  );
};

export default ViewProductDetails;
