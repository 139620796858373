//Api object url and calls will be listed here
import {
  deleteCall,
  getCall,
  patchCall,
  postCall,
  postCallWithMedia,
  getPDFCall,
  getPDFPrintCall,
  getExcelCall,
  getDownloadCall,
} from "../api";
import {
  addCustomerObj,
  addVendorObj,
  createDCObj,
  editCustomerObj,
  editVendorObj,
  uploadDocumentObj,
  editDCObj,
  addProductObj,
  receiveInwardListObj,
  addInwardObj,
  editPOObj,
  editExpectedDeliveryDateObj,
  editInwardObj,
  deliverOutwardListObj,
  addCategoryObj,
  editOutwardObj,
  editCategoryObj,
  editProductObj,
  addProductUnitObj,
  editProductUnitObj,
  addLocationObj,
  editLocationObj,
  editBillingAddressVendorObj,
  editShippingAddressVendorObj,
  addContactPersonVendorObj,
  editContactPersonVendorObj,
  editPOStatusObj,
  editDCStatusObj,
  editBillingAddressCustomerObj,
  editShippingAddressCustomerObj,
  addContactPersonCustomerObj,
  editContactPersonCustomerObj,
  addUserObj,
  editUserObj,
  ViewPasswordRequestUserObj,
  loginObj,
  forgotPasswordObj,
  editProfileObj,
  changePasswordObj,
  checkUserExistObj,
  addNewBarcodeObj,
} from "./apiObjects";
import {
  addCustomerUrl,
  addVendorUrl,
  customerListUrl,
  DCListUrl,
  deleteCustomerUrl,
  deleteVendorUrl,
  editCustomerUrl,
  editVendorUrl,
  POListUrl,
  vendorListUrl,
  deleteDCUrl,
  getDCPDFUrl,
  uploadDocumentUrl,
  ProductListUrl,
  getDownloadPdfUrl,
  getExportOutwardListingUrl,
  getSingleDcDetailsUrl,
  createDCUrl,
  vendorListAllUrl,
  cityListAllUrl,
  editDCUrl,
  addProductUrl,
  categoryListUrl,
  locationListUrl,
  receiveInwardListUrl,
  addInwardUrl,
  POStatusListUrl,
  singlePODetailsUrl,
  editPOUrl,
  deletePOUrl,
  editExpectedDeliveryDateUrl,
  receivePOLocationListUrl,
  getExportInwardListingUrl,
  productUnitListUrl,
  editInwardUrl,
  getSingleDeliverItemByBarcodeUrl,
  deliverOutwardListUrl,
  editOutwardUrl,
  singleDCDetailsUrl,
  customerListAllUrl,
  getDashboardcounterUrl,
  getTop5ProductsUrl,
  getTop5POUrl,
  getPoandDcStatusUrl,
  getPoandDcSummaryUrl,
  getPoandDcQuantitySummaryUrl,
  categoryListMastersUrl,
  deleteCategoryUrl,
  addCategoryUrl,
  editCategoryUrl,
  getSingleCategoryDetailsUrl,
  reportOutwardByCustomerUrl,
  reportOutwardByCustomerExportUrl,
  reportOutwardByCustomerPrintUrl,
  productListMastersUrl,
  editProductUrl,
  getSingleProductDetailsUrl,
  deleteProductUrl,
  productUnitListMastersUrl,
  addProductUnitUrl,
  getSingleProductUnitDetailsUrl,
  editProductUnitUrl,
  deleteProductUnitUrl,
  reportOutwardByProductUrl,
  reportOutwardByProductExportUrl,
  reportOutwardByProductPrintUrl,
  reportInventoryAgingUrl,
  reportInventoryAgingExportUrl,
  reportInventoryAgingPrintUrl,
  reportInventoryValuationUrl,
  reportInventoryValuationExportUrl,
  reportInventoryValuationPrintUrl,
  locationListMastersUrl,
  deleteLocationUrl,
  addLocationUrl,
  editLocationUrl,
  getLocationListBasedOnProductUrl,
  getSingleLocationDetailsUrl,
  getSingleVendorDetailsUrl,
  getPORecentFiveUrl,
  reportActivePOUrl,
  reportActivePOExportUrl,
  reportActivePOPrintUrl,
  reportLowStockItemsUrl,
  reportLowStockItemsExportUrl,
  reportLowStockItemsPrintUrl,
  reportDelayedPODCUrl,
  reportDelayedPODCExportUrl,
  reportDelayedPODCPrintUrl,
  reportInwardValuationUrl,
  reportInwardValuationExportUrl,
  reportInwardValuationPrintUrl,
  reportOutwardValuationUrl,
  reportOutwardValuationExportUrl,
  reportOutwardValuationPrintUrl,
  editBillingAddressVendorUrl,
  editShippingAddressVendorUrl,
  addContactPersonVendorUrl,
  editContactPersonVendorUrl,
  editPOStatusUrl,
  editDCStatusUrl,
  getSingleCustomerDetailsUrl,
  editBillingAddressCustomerUrl,
  editShippingAddressCustomerUrl,
  addContactPersonCustomerUrl,
  editContactPersonCustomerUrl,
  getRecentFiveDCUrl,
  userListUrl,
  addUserUrl,
  editUserUrl,
  roleListAllUrl,
  roleListUrl,
  deleteUserUrl,
  ViewPasswordRequestUserUrl,
  loginUrl,
  forgotPasswordUrl,
  logoutUrl,
  singleUserUrl,
  editProfileUrl,
  changePasswordUrl,
  getDownloadPdfPOUrl,
  vendorListExportUrl,
  customerListExportUrl,
  userListExportUrl,
  categoryListExportUrl,
  productListExportUrl,
  productunitListExportUrl,
  locationsListExportUrl,
  inventoryListExportUrl,
  downloadAttachmentUrl,
  getPORecentFiveCompletedUrl,
  getRecentFiveDCCompletedUrl,
  deletePasswordRequestUrl,
  POBasedOnProductListUrl,
  DCBasedOnProductListUrl,
  checkUserExistUrl,
  getRecentFiveUrl,
  getTop5NearExpiryProductUrl,
  productListForScannedProductsUrl,
  editHistoryListUrl,
  deleteBarcodeUrl,
  addNewBarcodeUrl,
} from "./apiUrl";

export function loginApi({ emp_id, password }) {
  return postCall(
    loginUrl(),
    loginObj({
      emp_id,
      password,
    })
  );
}

export function forgotPasswordApi({ emp_id, password }) {
  return postCall(
    forgotPasswordUrl(),
    forgotPasswordObj({
      emp_id,
    })
  );
}

export function logoutApi({ id }) {
  return deleteCall(logoutUrl({ id }));
}

export function customerListApi({ queryParams }) {
  return getCall(customerListUrl({ queryParams }));
}

export function deleteCustomerApi({ id }) {
  return deleteCall(deleteCustomerUrl({ id }));
}

export function editCustomerApi({
  id,
  contact_name,
  phone_number,
  currency,
  status,
  email,
  remarks,
  mobile_number,
  address,
  contact_person,
}) {
  return patchCall(
    editCustomerUrl({ id }),
    editCustomerObj({
      contact_name,
      phone_number,
      currency,
      status,
      email,
      remarks,
      mobile_number,
      address,
      contact_person,
    })
  );
}

export function addCustomerApi({
  contact_type,
  email,
  phone_number,
  mobile_number,
  contact_name,
  currency,
  billingaddressId,
  billing_cityId,
  billing_zipcode,
  shippingaddressId,
  shipping_cityId,
  shipping_zipcode,
  contact_person,
  remarks,
}) {
  return postCall(
    addCustomerUrl(),
    addCustomerObj({
      contact_type,
      email,
      phone_number,
      mobile_number,
      contact_name,
      currency,
      billingaddressId,
      billing_cityId,
      billing_zipcode,
      shippingaddressId,
      shipping_cityId,
      shipping_zipcode,
      contact_person,
      remarks,
    })
  );
}

export function addProductApi({
  name,
  categoryId,
  stock_keeping_unit,
  opening_stock,
  reorder_level,
  price,
  shelf_life,
  productunitId,
  product_media,
  description,
}) {
  return postCall(
    addProductUrl(),
    addProductObj({
      name,
      categoryId,
      stock_keeping_unit,
      opening_stock,
      reorder_level,
      price,
      shelf_life,
      productunitId,
      product_media,
      description,
    })
  );
}

export function vendorListApi({ queryParams }) {
  return getCall(vendorListUrl({ queryParams }));
}

export function vendorListAllApi({ vendorSearchValue }) {
  return getCall(vendorListAllUrl({ vendorSearchValue }));
}

export function roleListApi() {
  return getCall(roleListUrl());
}

//Export API

export function vendorListExportApi({ filename }) {
  return getExcelCall(vendorListExportUrl(), filename);
}

export function customerListExportApi({ filename }) {
  return getExcelCall(customerListExportUrl(), filename);
}

export function userListExportApi({ filename }) {
  return getExcelCall(userListExportUrl(), filename);
}

export function categoryListExportApi({ filename }) {
  return getExcelCall(categoryListExportUrl(), filename);
}

export function productListExportApi({ filename }) {
  return getExcelCall(productListExportUrl(), filename);
}

export function productunitListExportApi({ filename }) {
  return getExcelCall(productunitListExportUrl(), filename);
}

export function locationsListExportApi({ filename }) {
  return getExcelCall(locationsListExportUrl(), filename);
}

export function inventoryListExportApi({ filename }) {
  return getExcelCall(inventoryListExportUrl(), filename);
}

export function customerListAllApi({ customerSearchValue }) {
  return getCall(customerListAllUrl({ customerSearchValue }));
}

export function deleteVendorApi({ id }) {
  return deleteCall(deleteVendorUrl({ id }));
}

export function editVendorApi({
  id,
  contact_name,
  phone_number,
  currency,
  status,
  email,
  remarks,
  mobile_number,
  address,
  contact_person,
}) {
  return patchCall(
    editVendorUrl({ id }),
    editVendorObj({
      contact_name,
      phone_number,
      currency,
      status,
      email,
      remarks,
      mobile_number,
      address,
      contact_person,
    })
  );
}

export function editBillingAddressVendorApi({ id, address }) {
  return patchCall(
    editBillingAddressVendorUrl({ id }),
    editBillingAddressVendorObj({
      address,
    })
  );
}

export function editBillingAddressCustomerApi({ id, address }) {
  return patchCall(
    editBillingAddressCustomerUrl({ id }),
    editBillingAddressCustomerObj({
      address,
    })
  );
}

export function editShippingAddressVendorApi({ id, address }) {
  return patchCall(
    editShippingAddressVendorUrl({ id }),
    editShippingAddressVendorObj({
      address,
    })
  );
}

export function editShippingAddressCustomerApi({ id, address }) {
  return patchCall(
    editShippingAddressCustomerUrl({ id }),
    editShippingAddressCustomerObj({
      address,
    })
  );
}

export function addContactPersonVendorApi({
  contactId,
  first_name,
  last_name,
  email,
  phone,
  mobile,
}) {
  return postCall(
    addContactPersonVendorUrl(),
    addContactPersonVendorObj({
      contactId,
      first_name,
      last_name,
      email,
      phone,
      mobile,
    })
  );
}

export function addContactPersonCustomerApi({
  contactId,
  first_name,
  last_name,
  email,
  phone,
  mobile,
}) {
  return postCall(
    addContactPersonCustomerUrl(),
    addContactPersonCustomerObj({
      contactId,
      first_name,
      last_name,
      email,
      phone,
      mobile,
    })
  );
}

export function editContactPersonVendorApi({
  id,
  first_name,
  last_name,
  email,
  phone,
  mobile,
}) {
  return patchCall(
    editContactPersonVendorUrl({ id }),
    editContactPersonVendorObj({
      first_name,
      last_name,
      email,
      phone,
      mobile,
    })
  );
}

export function editContactPersonCustomerApi({
  id,
  first_name,
  last_name,
  email,
  phone,
  mobile,
}) {
  return patchCall(
    editContactPersonCustomerUrl({ id }),
    editContactPersonCustomerObj({
      first_name,
      last_name,
      email,
      phone,
      mobile,
    })
  );
}

export function addVendorApi({
  contact_type,
  email,
  phone_number,
  mobile_number,
  contact_name,
  currency,
  billingaddressId,
  billing_cityId,
  billing_zipcode,
  shippingaddressId,
  shipping_cityId,
  shipping_zipcode,
  contact_person,
  remarks,
}) {
  return postCall(
    addVendorUrl(),
    addVendorObj({
      contact_type,
      email,
      phone_number,
      mobile_number,
      contact_name,
      currency,
      billingaddressId,
      billing_cityId,
      billing_zipcode,
      shippingaddressId,
      shipping_cityId,
      shipping_zipcode,
      contact_person,
      remarks,
    })
  );
}

export function POListApi({ queryParams, productId }) {
  return getCall(POListUrl({ queryParams, productId }));
}

export function POBasedOnProductListApi({ queryParams, productId }) {
  return getCall(POBasedOnProductListUrl({ queryParams, productId }));
}

export function DCListApi({
  queryParams,
  filterValues,
  productId,
  customerIdViaFilter,
  statusId,
}) {
  return getCall(
    DCListUrl({
      queryParams,
      filterValues,
      productId,
      customerIdViaFilter,
      statusId,
    })
  );
}

export function DCBasedOnProductListApi({ queryParams, productId }) {
  return getCall(DCBasedOnProductListUrl({ queryParams, productId }));
}

export function deleteDCApi({ id }) {
  return deleteCall(deleteDCUrl({ id }));
}

export function deletePOApi({ id }) {
  return deleteCall(deletePOUrl({ id }));
}

export function getDCPDFApi({ id }) {
  return getCall(getDCPDFUrl({ id }));
}

export function uploadDocumentApi(files) {
  return postCallWithMedia(
    uploadDocumentUrl(files.containername),
    uploadDocumentObj(files)
  );
}

export function ProductListApi({ queryParams, filterValues }) {
  return getCall(ProductListUrl({ queryParams, filterValues }));
}

export function productListForScannedProductsApi({ id }) {
  return getCall(productListForScannedProductsUrl({ id }));
}

export function downloadPdfApi({ id, filename, extention }) {
  return getPDFCall(getDownloadPdfUrl({ id }), filename, extention);
}

export function downloadPdfPOApi({ id, filename, extention }) {
  return getPDFCall(getDownloadPdfPOUrl({ id }), filename, extention);
}

export function printPdfPOApi({ id, filename, extention }) {
  return getPDFPrintCall(getDownloadPdfPOUrl({ id }), filename, extention);
}

export function printPdfApi({ id }) {
  return getPDFPrintCall(getDownloadPdfUrl({ id }));
}

export function exportOutwardListingApi({ filename }) {
  return getExcelCall(getExportOutwardListingUrl(), filename);
}

export function exportInwardListingApi({ filename }) {
  return getExcelCall(getExportInwardListingUrl(), filename);
}

export function exportListingApi({ filename }) {
  return getExcelCall(getExportOutwardListingUrl(), filename);
}

export function getSingleDCDetailsApi({ id }) {
  return getCall(getSingleDcDetailsUrl({ id }));
}

export function createDCApi({
  date,
  expected_delivery,
  notes,
  purchaseorder_document,
  courier_company_name,
  contactId,
  purchaseorder_item,
  type,
}) {
  return postCall(
    createDCUrl(),
    createDCObj({
      date,
      expected_delivery,
      notes,
      purchaseorder_document,
      courier_company_name,
      contactId,
      purchaseorder_item,
      type,
    })
  );
}

export function cityListAllApi({}) {
  return getCall(cityListAllUrl({}));
}

export function editDCApi({ id, purchaseorder_document }) {
  return patchCall(
    editDCUrl({ id }),
    editDCObj({
      purchaseorder_document,
    })
  );
}

export function editPOApi({ id, purchaseorder_document }) {
  return patchCall(
    editPOUrl({ id }),
    editPOObj({
      purchaseorder_document,
    })
  );
}

export function editPOStatusApi({ id, purchaseorderstatusId }) {
  return patchCall(
    editPOStatusUrl({ id }),
    editPOStatusObj({
      purchaseorderstatusId,
    })
  );
}

export function agreeStatusPOApi({ id, purchaseorderstatusId }) {
  return patchCall(
    editPOStatusUrl({ id }),
    editPOStatusObj({
      purchaseorderstatusId,
    })
  );
}

export function editDCStatusApi({ id, purchaseorderstatusId }) {
  return patchCall(
    editDCStatusUrl({ id }),
    editDCStatusObj({
      purchaseorderstatusId,
    })
  );
}

export function editExpectedDeliveryDateApi({ id, expected_delivery, notes }) {
  return patchCall(
    editExpectedDeliveryDateUrl({ id }),
    editExpectedDeliveryDateObj({
      expected_delivery,
      notes,
    })
  );
}

export function categoryListApi({ status }) {
  return getCall(categoryListUrl({ status }));
}

export function productUnitListApi() {
  return getCall(productUnitListUrl());
}

export function locationListApi() {
  return getCall(locationListUrl());
}

export function receivePOLocationListApi() {
  return getCall(receivePOLocationListUrl());
}

export function receiveInwardApi({
  id,
  delivery_date,
  notes,
  product_condition,
}) {
  return patchCall(
    receiveInwardListUrl({ id }),
    receiveInwardListObj({
      delivery_date,
      notes,
      product_condition,
    })
  );
}

export function addInwardApi({
  date,
  expected_delivery,
  notes,
  purchaseorder_document,
  courier_company_name,
  contactId,
  purchaseorder_item,
  totalamount,
  totalquantity,
  type,
}) {
  return postCall(
    addInwardUrl({}),
    addInwardObj({
      date,
      expected_delivery,
      notes,
      purchaseorder_document,
      courier_company_name,
      contactId,
      purchaseorder_item,
      totalamount,
      totalquantity,
      type,
    })
  );
}

export function editInwardApi({
  id,
  date,
  expected_delivery,
  notes,
  purchaseorder_document,
  courier_company_name,
  contactId,
  purchaseorder_item,
  totalamount,
  totalquantity,
  type,
}) {
  return patchCall(
    editInwardUrl({ id }),
    editInwardObj({
      date,
      expected_delivery,
      notes,
      purchaseorder_document,
      courier_company_name,
      contactId,
      purchaseorder_item,
      totalamount,
      totalquantity,
      type,
    })
  );
}

export function editOutwardApi({
  id,
  date,
  expected_delivery,
  notes,
  purchaseorder_document,
  courier_company_name,
  contactId,
  purchaseorder_item,
  totalamount,
  totalquantity,
  type,
}) {
  return patchCall(
    editOutwardUrl({ id }),
    editOutwardObj({
      date,
      expected_delivery,
      notes,
      purchaseorder_document,
      courier_company_name,
      contactId,
      purchaseorder_item,
      totalamount,
      totalquantity,
      type,
    })
  );
}

export function POStatusListApi() {
  return getCall(POStatusListUrl());
}

export function editHistoryListApi({  queryParamsEditHistory, id }) {
  return getCall(editHistoryListUrl({ queryParamsEditHistory, id }));
}

export function singlePODetailsApi({ POId }) {
  return getCall(singlePODetailsUrl({ POId }));
}

export function singleDCDetailsApi({ DCId }) {
  return getCall(singleDCDetailsUrl({ DCId }));
}

export function getSingleDeliverItemByBarcodeApi(barcodeNo) {
  return getCall(getSingleDeliverItemByBarcodeUrl(barcodeNo));
}

export function deliverOutwardApi({
  id,
  delivery_date,
  notes,
  product_condition,
}) {
  return patchCall(
    deliverOutwardListUrl({ id }),
    deliverOutwardListObj({
      delivery_date,
      notes,
      product_condition,
    })
  );
}

export function getDashboardcounterApi() {
  return getCall(getDashboardcounterUrl());
}

export function getTop5ProductsApi() {
  return getCall(getTop5ProductsUrl());
}

export function getTop5NearExpiryProductApi() {
  return getCall(getTop5NearExpiryProductUrl());
}

export function getTop5POApi({ contactName, columnName }) {
  return getCall(getTop5POUrl({ contactName, columnName }));
}

export function getRecentFiveApi({ contactName }) {
  return getCall(getRecentFiveUrl({ contactName }));
}

export function getPoandDcStatusApi({ contactTypeName }) {
  return getCall(getPoandDcStatusUrl({ contactTypeName }));
}

export function getPoandDcSummaryApi({
  chosenLabel,
  fromdate,
  todate,
  productId,
}) {
  return getCall(
    getPoandDcSummaryUrl({ chosenLabel, fromdate, todate, productId })
  );
}

export function getPoandDcQuantitySummaryApi({
  contactTypeName,
  chosenLabel,
  fromdate,
  todate,
}) {
  return getCall(
    getPoandDcQuantitySummaryUrl({
      contactTypeName,
      chosenLabel,
      fromdate,
      todate,
    })
  );
}

export function categoryListMastersApi({ queryParams }) {
  return getCall(categoryListMastersUrl({ queryParams }));
}

export function userListApi({ queryParams, selectedTab }) {
  return getCall(userListUrl({ queryParams, selectedTab }));
}

export function locationListMastersApi({ queryParams }) {
  return getCall(locationListMastersUrl({ queryParams }));
}

export function productUnitListMastersApi({ queryParams }) {
  return getCall(productUnitListMastersUrl({ queryParams }));
}

export function productListMastersApi({ queryParams }) {
  return getCall(productListMastersUrl({ queryParams }));
}

export function deleteCategoryApi({ id, categoryId }) {
  return deleteCall(deleteCategoryUrl({ id, categoryId }));
}

export function deleteUserApi({ id }) {
  return deleteCall(deleteUserUrl({ id }));
}

export function deletePasswordRequestApi({ id }) {
  return deleteCall(deletePasswordRequestUrl({ id }));
}

export function deleteLocationApi({ id }) {
  return deleteCall(deleteLocationUrl({ id }));
}

export function deleteProductUnitApi({ id }) {
  return deleteCall(deleteProductUnitUrl({ id }));
}

export function deleteProductApi({ id }) {
  return deleteCall(deleteProductUrl({ id }));
}

export function addCategoryApi({ name, description }) {
  return postCall(
    addCategoryUrl(),
    addCategoryObj({
      name,
      description,
    })
  );
}

export function addUserApi({
  full_name,
  emp_id,
  email,
  cellnumber,
  role_id,
  password,
}) {
  return postCall(
    addUserUrl(),
    addUserObj({
      full_name,
      emp_id,
      email,
      cellnumber,
      role_id,
      password,
    })
  );
}

export function editUserApi({
  id,
  full_name,
  emp_id,
  email,
  cellnumber,
  role_id,
  status,
}) {
  return patchCall(
    editUserUrl({ id }),
    editUserObj({
      full_name,
      emp_id,
      email,
      cellnumber,
      role_id,
      status,
    })
  );
}

export function editProfileApi({ id, full_name, emp_id, email, cellnumber }) {
  return patchCall(
    editProfileUrl({ id }),
    editProfileObj({
      full_name,
      emp_id,
      email,
      cellnumber,
    })
  );
}

export function changePasswordApi({ id, currentPassword, password }) {
  return patchCall(
    changePasswordUrl(),
    changePasswordObj({
      id,
      currentPassword,
      password,
    })
  );
}

export function ViewPasswordRequestUserApi({ id, password }) {
  return patchCall(
    ViewPasswordRequestUserUrl(),
    ViewPasswordRequestUserObj({
      id,
      password,
    })
  );
}

export function addLocationApi({ store_location, parent_location }) {
  return postCall(
    addLocationUrl(),
    addLocationObj({
      store_location,
      parent_location,
    })
  );
}

export function addProductUnitApi({ name }) {
  return postCall(
    addProductUnitUrl(),
    addProductUnitObj({
      name,
    })
  );
}

export function editCategoryApi({ id, name, description, status }) {
  return patchCall(
    editCategoryUrl({ id }),
    editCategoryObj({
      name,
      description,
      status,
    })
  );
}

export function editLocationApi({
  id,
  parent_location,
  store_location,
  status,
}) {
  return patchCall(
    editLocationUrl({ id }),
    editLocationObj({
      parent_location,
      store_location,
      status,
    })
  );
}

export function editProductUnitApi({ id, name, status }) {
  return patchCall(
    editProductUnitUrl({ id }),
    editProductUnitObj({
      name,
      status,
    })
  );
}

export function getSingleCategoryDetailsApi({ id }) {
  return getCall(getSingleCategoryDetailsUrl({ id }));
}

export function getSingleLocationDetailsApi({ id }) {
  return getCall(getSingleLocationDetailsUrl({ id }));
}

export function getSingleProductUnitDetailsApi({ id }) {
  return getCall(getSingleProductUnitDetailsUrl({ id }));
}

export function reportOutwardByCustomerApi({ queryParams }) {
  return getCall(reportOutwardByCustomerUrl({ queryParams }));
}

export function reportInventoryAgingApi({ queryParams }) {
  return getCall(reportInventoryAgingUrl({ queryParams }));
}

export function reportLowStockItemsApi({ queryParams }) {
  return getCall(reportLowStockItemsUrl({ queryParams }));
}

export function reportInventoryValuationApi({ queryParams }) {
  return getCall(reportInventoryValuationUrl({ queryParams }));
}

export function reportActivePOApi({ queryParams }) {
  return getCall(reportActivePOUrl({ queryParams }));
}

export function reportInwardValuationApi({ queryParams }) {
  return getCall(reportInwardValuationUrl({ queryParams }));
}

export function reportOutwardValuationApi({ queryParams }) {
  return getCall(reportOutwardValuationUrl({ queryParams }));
}

export function reportDelayedPODCApi({ queryParams }) {
  return getCall(reportDelayedPODCUrl({ queryParams }));
}

export function reportOutwardByCustomerExportApi({
  queryParams,
  docType,
  filename,
  extention,
}) {
  return getPDFCall(
    reportOutwardByCustomerExportUrl({ queryParams, docType }),
    filename,
    extention
  );
}

export function reportInventoryAgingExportApi({
  queryParams,
  docType,
  filename,
  extention,
}) {
  return getPDFCall(
    reportInventoryAgingExportUrl({ queryParams, docType }),
    filename,
    extention
  );
}

export function reportLowStockItemsExportApi({
  queryParams,
  docType,
  filename,
  extention,
}) {
  return getPDFCall(
    reportLowStockItemsExportUrl({ queryParams, docType }),
    filename,
    extention
  );
}

export function reportInventoryValuationExportApi({
  queryParams,
  docType,
  filename,
  extention,
}) {
  return getPDFCall(
    reportInventoryValuationExportUrl({ queryParams, docType }),
    filename,
    extention
  );
}

export function reportActivePOExportApi({
  queryParams,
  docType,
  filename,
  extention,
}) {
  return getPDFCall(
    reportActivePOExportUrl({ queryParams, docType }),
    filename,
    extention
  );
}

export function reportInwardValuationExportApi({
  queryParams,
  docType,
  filename,
  extention,
}) {
  return getPDFCall(
    reportInwardValuationExportUrl({ queryParams, docType }),
    filename,
    extention
  );
}

export function reportOutwardValuationExportApi({
  queryParams,
  docType,
  filename,
  extention,
}) {
  return getPDFCall(
    reportOutwardValuationExportUrl({ queryParams, docType }),
    filename,
    extention
  );
}

export function reportDelayedPODCExportApi({
  queryParams,
  docType,
  filename,
  extention,
}) {
  return getPDFCall(
    reportDelayedPODCExportUrl({ queryParams, docType }),
    filename,
    extention
  );
}

export function reportOutwardByCustomerPrintApi({ queryParams }) {
  return getPDFPrintCall(reportOutwardByCustomerPrintUrl({ queryParams }));
}

export function reportInventoryAgingPrintApi({ queryParams }) {
  return getPDFPrintCall(reportInventoryAgingPrintUrl({ queryParams }));
}

export function reportLowStockItemsPrintApi({ queryParams }) {
  return getPDFPrintCall(reportLowStockItemsPrintUrl({ queryParams }));
}

export function reportInventoryValuationPrintApi({ queryParams }) {
  return getPDFPrintCall(reportInventoryValuationPrintUrl({ queryParams }));
}

export function reportActivePOPrintApi({ queryParams }) {
  return getPDFPrintCall(reportActivePOPrintUrl({ queryParams }));
}

export function reportInwardValuationPrintApi({ queryParams }) {
  return getPDFPrintCall(reportInwardValuationPrintUrl({ queryParams }));
}

export function reportOutwardValuationPrintApi({ queryParams }) {
  return getPDFPrintCall(reportOutwardValuationPrintUrl({ queryParams }));
}

export function reportDelayedPODCPrintApi({ queryParams }) {
  return getPDFPrintCall(reportDelayedPODCPrintUrl({ queryParams }));
}

export function reportOutwardByProductApi({ queryParams }) {
  return getCall(reportOutwardByProductUrl({ queryParams }));
}

export function reportOutwardByProductExportApi({
  queryParams,
  docType,
  filename,
  extention,
}) {
  return getPDFCall(
    reportOutwardByProductExportUrl({ queryParams, docType }),
    filename,
    extention
  );
}

export function reportOutwardByProductPrintApi({ queryParams }) {
  return getPDFPrintCall(reportOutwardByProductPrintUrl({ queryParams }));
}

export function editProductApi({
  id,
  name,
  stock_keeping_unit,
  status,
  opening_stock,
  reorder_level,
  shelf_life,
  price,
  categoryId,
  product_media,
  date,
  reference_number,
  new_quantity_on_hand,
  quantity_adjusted,
  reason,
  comments,
  adjust_stock,
  description,
  productunitId,
}) {
  return patchCall(
    editProductUrl({ id }),
    editProductObj({
      name,
      stock_keeping_unit,
      status,
      opening_stock,
      reorder_level,
      shelf_life,
      price,
      categoryId,
      product_media,
      date,
      reference_number,
      new_quantity_on_hand,
      quantity_adjusted,
      reason,
      comments,
      adjust_stock,
      description,
      productunitId,
    })
  );
}

export function getSingleProductDetailsApi({ id }) {
  return getCall(getSingleProductDetailsUrl({ id }));
}

export function getLocationListBasedOnProductApi({ queryParams, productId }) {
  return getCall(getLocationListBasedOnProductUrl({ queryParams, productId }));
}

export function getSingleVendorDetailsApi({ id }) {
  return getCall(getSingleVendorDetailsUrl({ id }));
}

export function getSingleCustomerDetailsApi({ id }) {
  return getCall(getSingleCustomerDetailsUrl({ id }));
}

export function getRecentFivePOApi({
  sortColumnName,
  waitingStatus,
  issueStatus,
  partialStatus,
  contact_name,
}) {
  return getCall(
    getPORecentFiveUrl({
      sortColumnName,
      waitingStatus,
      issueStatus,
      partialStatus,
      contact_name,
    })
  );
}

export function getRecentFiveCompletedPOApi({
  sortColumnName,
  status,
  contact_name,
}) {
  return getCall(
    getPORecentFiveCompletedUrl({
      sortColumnName,
      status,
      contact_name,
    })
  );
}

export function getRecentFiveDCApi({
  sortColumnName,
  waitingStatus,
  issueStatus,
  partialStatus,
  contact_name,
}) {
  return getCall(
    getRecentFiveDCUrl({
      sortColumnName,
      waitingStatus,
      issueStatus,
      partialStatus,
      contact_name,
    })
  );
}

export function getRecentFiveDCCompletedApi({
  sortColumnName,
  status,
  contact_name,
}) {
  return getCall(
    getRecentFiveDCCompletedUrl({
      sortColumnName,
      status,
      contact_name,
    })
  );
}

export function singleUserApi({ id }) {
  return getCall(singleUserUrl({ id }));
}

export function downloadAttachmentApi({ containername, filename, token }) {
  // return getDownloadCall(
  //   downloadAttachmentUrl({ containername, filename, token }, filename)
  // );

  return getDownloadCall(
    downloadAttachmentUrl({ containername, filename, token }),
    filename
  );
}

export function checkUserExistApi({ cellnumber }) {
  return postCall(checkUserExistUrl(), checkUserExistObj({ cellnumber }));
}

export function deleteBarcodeApi({ id }) {
  return deleteCall(deleteBarcodeUrl({ id }));
}

export function addNewBarcodeApi({ productId, purchaseorder_barcode }) {
  return postCall(
    addNewBarcodeUrl(),
    addNewBarcodeObj({ productId, purchaseorder_barcode })
  );
}
