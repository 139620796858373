/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
const RoleColumnFormatter = (cellContent, row, rowIndex) => {
  const { role } = row || {};
  const { name = "-" } = role || {};

  return (
    <>
      <div className="text-primary p-0">{name}</div>
      <> </>
    </>
  );
};

export default RoleColumnFormatter;
