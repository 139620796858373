import React, { useState, useRef, useEffect } from "react";
import { Button, Modal, Row, Col, Form, Spinner } from "react-bootstrap";
import Select from "react-select";
import { XCircle, Plus, X, Check } from "react-feather";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import {
  cityListAllApi,
  editVendorApi,
  getSingleVendorDetailsApi,
} from "../../../axios/services/services";
import {
  capitalizeFirstLetter,
  getErrorMessageBasedOnLanguage,
  InitLanguage,
  unauthorization,
} from "../../../constants/utils";
import { Formik, FieldArray } from "formik";
import { ButtonLoader } from "../../../helper/ButtonLoader";
import { ErrorModal } from "../../common/modals/ErrorModal";
import { EditVendorSchema } from "../../../helper/FormikSchema";
import { FormattedMessage, useIntl } from "react-intl";
import ErrorMessage from "../../../helper/ErrorMessage";

export function EditVendorModal({
  onCloseModal,
  open,
  data,
  id,
  handleCloseOfEditNewVendorModal,
}) {
  const [isLoading, setLoading] = useState(false);
  const [selectedBillingCity, setSelectedBillingCity] = useState("");
  const [selectedShippingCity, setSelectedShippingCity] = useState("");
  const [cityList, setCityList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoadingSingleVendor, setLoadingSingleVendor] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const [isVendorEdited, setVendorEdited] = useState(false);
  const [isAddContactPersonTable, setAddContactPersonTable] = useState(true);
  const [initialValues, setInitialValues] = useState({
    contact_name: "",
    email: "",
    phone_number: "",
    mobile_number: "",
    currency: "",
    billingAddress: "",
    billingCity: "",
    billingZipcode: "",
    shippingAddress: "",
    shippingCity: "",
    shippingZipcode: "",
    contact_persons: [
      {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        mobile: "",
      },
    ],
    remarks: "",
  });
  const statusName = [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ];
  const intl = useIntl();
  const handleEditContactPersonRef = useRef([]);

  async function getCityList() {
    const cityListResponse = await cityListAllApi({});
    const { Error, data } = cityListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    const cities = data.map((city) => {
      const { id, name } = city || {};
      city.value = id;
      city.label = capitalizeFirstLetter(name);
      return city;
    });
    setCityList(cities ? cities : []);
  }

  async function getSingleVendorDetails() {
    enableLoadingSingleVendor();
    const singleVendorDetails = await getSingleVendorDetailsApi({ id });
    disableLoadingSingleVendor(false);
    const { Error, data } = singleVendorDetails || {};
    const { get_contact } = data || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    const {
      contact_name,
      email,
      status,
      phone_number,
      mobile_number,
      currency,
      billingaddress,
      shippingaddress,
      remarks,
      contact_person,
    } = get_contact || {};
    const { building_no, city, zipcode } = billingaddress || {};
    const {
      building_no: shippingBuildingNo,
      city: shippingCity,
      zipcode: shippingZipcode,
    } = shippingaddress || {};

    if (status === 1) {
      setSelectedOption({ value: "1", label: "Active" });
    } else {
      setSelectedOption({ value: "0", label: "Inactive" });
    }

    setSelectedBillingCity({
      value: city && city?.id,
      label: capitalizeFirstLetter(city && city?.name),
    });
    setSelectedShippingCity({
      value: shippingCity && shippingCity?.id,
      label: capitalizeFirstLetter(shippingCity && shippingCity?.name),
    });

    const contactPersonsPrevs = [];
    contact_person.map((contactPerson) => {
      const { first_name, last_name, email, phone, mobile, id } =
        contactPerson || {};
      let obj = {
        id,
        first_name: first_name ? first_name : "",
        last_name: last_name ? last_name : "",
        email: email ? email : "",
        phone: phone ? phone : "",
        mobile: mobile ? mobile : "",
      };
      contactPersonsPrevs.push(obj);
      return contactPerson;
    });
    if (contactPersonsPrevs && contactPersonsPrevs.length === 0) {
      setAddContactPersonTable(false);
    }

    let vendorDetail = {
      contact_name: contact_name ? contact_name : "",
      email: email ? email : "",
      phone_number: phone_number ? phone_number : "",
      mobile_number: mobile_number ? mobile_number : "",
      currency: currency,
      billingAddress: building_no ? building_no : "",
      billingCity: city && city?.id,
      billingZipcode: zipcode ? zipcode : "",
      shippingAddress: shippingBuildingNo ? shippingBuildingNo : "",
      shippingCity: shippingCity && shippingCity?.id,
      shippingZipcode: shippingZipcode ? shippingZipcode : "",
      remarks: remarks ? remarks : "",
      contact_persons: contactPersonsPrevs,
    };
    setInitialValues(vendorDetail);
  }

  useEffect(() => {
    getCityList();
  }, []);

  useEffect(() => {
    getSingleVendorDetails();
  }, []);

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const handleOnClickOfCopyBillingAddress = (values, setFieldValue) => {
    const { billingAddress, billingCity, billingZipcode } = values || {};
    setFieldValue("shippingAddress", billingAddress);
    setFieldValue("shippingCity", billingCity);
    setFieldValue("shippingZipcode", billingZipcode);
    setSelectedShippingCity(selectedBillingCity);
  };

  const renderFirstNameValue = (values, index) => {
    const value = values.contact_persons[index].first_name;
    return value;
  };

  const renderLastNameValue = (values, index) => {
    const value = values.contact_persons[index].last_name;
    return value;
  };

  const renderContactEmailValue = (values, index) => {
    const value = values.contact_persons[index].email;
    return value;
  };

  const renderMobileValue = (values, index) => {
    const value = values.contact_persons[index].mobile;
    return value;
  };

  const renderPhoneValue = (values, index) => {
    const value = values.contact_persons[index].phone;
    return value;
  };

  const enableLoadingSingleVendor = () => {
    setLoadingSingleVendor(true);
  };

  const disableLoadingSingleVendor = () => {
    setLoadingSingleVendor(false);
  };

  const editVendor = async (values) => {
    enableLoading();
    const {
      contact_name,
      email,
      phone_number,
      mobile_number,
      currency,
      billingAddress,
      billingCity,
      billingZipcode,
      shippingAddress,
      shippingCity,
      shippingZipcode,
      remarks,
      contact_persons,
    } = values || {};

    const { value: status } = selectedOption;

    let obj = {
      id,
      contact_name,
      email,
      phone_number,
      mobile_number,
      currency,
      remarks,
      address: {
        billingaddressId: billingAddress,
        billing_zipcode: billingZipcode,
        billing_cityId: billingCity,
        shippingaddressId: shippingAddress,
        shipping_cityId: shippingCity,
        shipping_zipcode: shippingZipcode,
      },
      contact_person: contact_persons,
      status: parseInt(status),
    };

    const editCategoryResponse = await editVendorApi(obj);
    const { data, Error } = editCategoryResponse || {};

    if (Error) {
      disableLoading();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setVendorEdited(true);
    window.scrollTo(0, 0);
    setTimeout(() => {
      disableLoading();
      handleCloseOfEditNewVendorModal(data);
    }, 2500);
  };

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={EditVendorSchema}
        onSubmit={(values) => {
          editVendor(values);
        }}
        onReset={(values) => {}}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          resetForm,
          handleReset,
          setFieldValue,
          errors,
          touched,
        }) => (
          <Form
            autoComplete="off"
            className="form form-label-right filter-card"
            onSubmit={handleSubmit}
            onReset={() => {
              resetForm(initialValues);
              handleReset();
            }}
          >
            <Modal
              size="lg"
              show={open}
              onHide={onCloseModal}
              aria-labelledby="example-modal-sizes-title-lg"
              dialogClassName="addvendor-modal modal"
              scrollable={true}
            >
              <Modal.Header className="d-flex justify-content-between align-items-center">
                <h3 className="modal-title" id="exampleModalLabel2">
                  <FormattedMessage id="TITLE.EDIT.VENDOR" />
                  {isLoadingSingleVendor && (
                    <Spinner animation="border" size="sm" className="ml-1" />
                  )}
                </h3>
                <Button
                  variant="link p-0 btn-icon"
                  onClick={() => onCloseModal()}
                >
                  <X size={20} className="text-dark mr-0 pr-0" />
                </Button>
              </Modal.Header>
              <Modal.Body className="pb-0">
                {isVendorEdited && (
                  <div className="notification icon inline success fs-16">
                    <Check size={8} className="text-white"></Check>{" "}
                    <FormattedMessage id="TITLE.VENDOR.EDITED.SUCCESSFULLY" />
                  </div>
                )}

                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label htmlFor="vendorname">
                        <FormattedMessage id="TITLE.VENDOR.DISPLAY.NAME" />
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        id="contact_name"
                        name="contact_name"
                        placeholder={intl.formatMessage({
                          id: "TITLE.VENDOR.DISPLAY.NAME",
                        })}
                        className="form-control"
                        onChange={(e) => {
                          let value = e.target.value;
                          value = value.replace(/[0-9]/g, "");
                          setFieldValue(`contact_name`, value);
                        }}
                        value={values?.contact_name}
                        autoComplete="off"
                      />
                      {touched.contact_name && errors.contact_name ? (
                        <div className="text-danger">
                          {InitLanguage(errors.contact_name)}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label htmlFor="vendorname">
                        <FormattedMessage id="TITLE.VENDOR.EMAIL.ADDRESS" />
                        {/* <span className="text-danger">*</span> */}
                      </Form.Label>
                      <Form.Control
                        id="email"
                        name="email"
                        //  as="textarea"
                        placeholder={intl.formatMessage({
                          id: "TITLE.VENDOR.EMAIL.ADDRESS",
                        })}
                        className="form-control"
                        onChange={(e) => {
                          setFieldValue(`email`, e.target.value);
                        }}
                        value={values?.email}
                        autoComplete="off"
                      />
                      {touched.email && errors.email ? (
                        <div className="text-danger">{errors.email}</div>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label htmlFor="vendorname">
                        <FormattedMessage id="TITLE.VENDOR.PHONE.NUMBER" />
                        {/* <span className="text-danger">*</span> */}
                      </Form.Label>
                      <Form.Control
                        id="phone_number"
                        name="phone_number"
                        type="number"
                        //  as="textarea"
                        placeholder={intl.formatMessage({
                          id: "TITLE.VENDOR.PHONE.NUMBER",
                        })}
                        className="form-control"
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        onChange={(e) => {
                          setFieldValue(`phone_number`, e.target.value);
                        }}
                        onPaste={(evt) => {
                          const values = evt.clipboardData.getData("text");
                          const nonAlpha = values.replace(/[^\d.-]/g, "");
                          evt.preventDefault();
                          setFieldValue("phone_number", nonAlpha);
                        }}
                        value={values?.phone_number}
                        autoComplete="off"
                      />
                      {errors.phone_number ? (
                        <div className="text-danger">
                          {InitLanguage(errors.phone_number)}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label htmlFor="vendorname">
                        <FormattedMessage id="TITLE.VENDOR.MOBILE.NUMBER" />
                        {/* <span className="text-danger">*</span> */}
                      </Form.Label>
                      <Form.Control
                        id="mobile_number"
                        type="number"
                        name="mobile_number"
                        //  as="textarea"
                        placeholder={intl.formatMessage({
                          id: "TITLE.VENDOR.MOBILE.NUMBER",
                        })}
                        className="form-control"
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        onChange={(e) => {
                          setFieldValue(`mobile_number`, e.target.value);
                        }}
                        onPaste={(evt) => {
                          const values = evt.clipboardData.getData("text");
                          const nonAlpha = values.replace(/[^\d.-]/g, "");
                          evt.preventDefault();
                          setFieldValue("mobile_number", nonAlpha);
                        }}
                        value={values?.mobile_number}
                        autoComplete="off"
                      />
                      {errors.mobile_number ? (
                        <div className="text-danger">
                          {InitLanguage(errors.mobile_number)}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label htmlFor="vendorname">
                        <FormattedMessage id="TITLE.CURRENCY" />
                        {/* <span className="text-danger">*</span> */}
                      </Form.Label>
                      <Form.Control
                        id="currency"
                        name="currency"
                        //  as="textarea"
                        placeholder={intl.formatMessage({
                          id: "TITLE.CURRENCY",
                        })}
                        className="form-control"
                        disabled
                        onChange={(e) => {
                          setFieldValue(`currency`, e.target.value);
                        }}
                        value={values?.currency}
                        autoComplete="off"
                      />
                      {/* {touched.currency && errors.currency ? (
                        <div className="text-danger">{errors.currency}</div>
                      ) : null} */}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label htmlFor="status">
                        <FormattedMessage id="TITLE.STATUS" />
                        {/* <span className="text-danger">*</span> */}
                      </Form.Label>
                      <Select
                        value={selectedOption}
                        onChange={(value) => {
                          handleOptionChange(value);
                        }}
                        isSearchable={true}
                        options={statusName}
                        placeholder={intl.formatMessage({
                          id: "TITLE.SELECT.STATUS",
                        })}
                        id="status"
                        className="react-select-container"
                        classNamePrefix="react-select"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md={12}>
                    <Tabs>
                      <TabList className="nav nav-tabs tab-coupon">
                        <Tab className="nav-link">
                          <FormattedMessage id="TITLE.ADDRESS" />
                          {/* <span className="text-danger">*</span> */}
                        </Tab>
                        <Tab className="nav-link">
                          <FormattedMessage id="TITLE.CONTACT.PERSON" />
                          {/* <span className="text-danger">*</span> */}
                        </Tab>
                        <Tab className="nav-link">
                          <FormattedMessage id="TITLE.REMARKS" />
                        </Tab>
                      </TabList>
                      <TabPanel>
                        <Row>
                          <Col md={6}>
                            <h4 className="fs-16 font-weight-bold mb-3 text-black">
                              <FormattedMessage id="TITLE.BILLING.ADDRESS" />
                            </h4>
                            <Form.Group>
                              <Form.Label htmlFor="vendorname">
                                <FormattedMessage id="TITLE.ADDRESS" />
                                {/* <span className="text-danger">*</span> */}
                              </Form.Label>
                              <Form.Control
                                id="billingAddress"
                                name="billingAddress"
                                //  as="textarea"
                                placeholder={intl.formatMessage({
                                  id: "TITLE.BILLING.ADDRESS",
                                })}
                                className="form-control"
                                onChange={(e) => {
                                  setFieldValue(
                                    `billingAddress`,
                                    e.target.value
                                  );
                                }}
                                value={values?.billingAddress}
                                autoComplete="off"
                              />
                              {/* {touched.billingAddress &&
                              errors.billingAddress ? (
                                <div className="text-danger">
                                  {errors.billingAddress}
                                </div>
                              ) : null} */}
                            </Form.Group>
                            <Form.Group>
                              <Form.Label htmlFor="city">
                                <FormattedMessage id="TITLE.CITY" />
                                {/* <span className="text-danger">*</span> */}
                              </Form.Label>
                              <Select
                                value={selectedBillingCity}
                                onChange={(data) => {
                                  setSelectedBillingCity(data);
                                  setFieldValue("billingCity", data.value);
                                }}
                                isSearchable={true}
                                placeholder={intl.formatMessage({
                                  id: "TITLE.SELECT.CITY",
                                })}
                                options={cityList}
                                id="city"
                                name="billingCity"
                                className="react-select-container"
                                classNamePrefix="react-select"
                              />
                              {/* {touched.billingCity && errors.billingCity ? (
                                <div className="text-danger">
                                  {errors.billingCity}
                                </div>
                              ) : null} */}
                            </Form.Group>
                            <Form.Group>
                              <Form.Label htmlFor="vendorname">
                                <FormattedMessage id="TITLE.ZIP.POSTAL.CODE" />
                                {/* <span className="text-danger">*</span> */}
                              </Form.Label>
                              <Form.Control
                                id="billingZipcode"
                                name="billingZipcode"
                                type="number"
                                //  as="textarea"
                                placeholder={intl.formatMessage({
                                  id: "TITLE.ZIP.POSTAL.CODE",
                                })}
                                className="form-control"
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                onChange={(e) => {
                                  setFieldValue(
                                    `billingZipcode`,
                                    e.target.value
                                  );
                                }}
                                onPaste={(evt) => {
                                  const values =
                                    evt.clipboardData.getData("text");
                                  const nonAlpha = values.replace(
                                    /[^\d.-]/g,
                                    ""
                                  );
                                  evt.preventDefault();
                                  setFieldValue("billingZipcode", nonAlpha);
                                }}
                                value={values?.billingZipcode}
                                autoComplete="off"
                              />
                              {touched.billingZipcode &&
                              errors.billingZipcode ? (
                                <div className="text-danger">
                                  {errors.billingZipcode}
                                </div>
                              ) : null}
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <div className="d-flex justify-content-between align-items-center">
                              <h4 className="fs-16 font-weight-bold mb-3 text-black">
                                <FormattedMessage id="TITLE.SHIPPING.ADDRESS" />
                              </h4>
                              <div
                                className="text-primary small-text mb-3 cursor-pointer"
                                onClick={() =>
                                  handleOnClickOfCopyBillingAddress(
                                    values,
                                    setFieldValue
                                  )
                                }
                              >
                                <FormattedMessage id="TITLE.COPY.BILLING.ADDRESS" />
                              </div>
                            </div>
                            <Form.Group>
                              <Form.Label htmlFor="vendorname">
                                <FormattedMessage id="TITLE.ADDRESS" />
                                {/* <span className="text-danger">*</span> */}
                              </Form.Label>
                              <Form.Control
                                id="shippingAddress"
                                name="shippingAddress"
                                //  as="textarea"
                                placeholder={intl.formatMessage({
                                  id: "TITLE.SHIPPING.ADDRESS",
                                })}
                                className="form-control"
                                onChange={(e) => {
                                  setFieldValue(
                                    `shippingAddress`,
                                    e.target.value
                                  );
                                }}
                                value={values?.shippingAddress}
                                autoComplete="off"
                              />
                              {/* {touched.shippingAddress &&
                              errors.shippingAddress ? (
                                <div className="text-danger">
                                  {errors.shippingAddress}
                                </div>
                              ) : null} */}
                            </Form.Group>
                            <Form.Group>
                              <Form.Label htmlFor="city">
                                <FormattedMessage id="TITLE.CITY" />
                                {/* <span className="text-danger">*</span> */}
                              </Form.Label>
                              <Select
                                value={selectedShippingCity}
                                onChange={(data) => {
                                  setSelectedShippingCity(data);
                                  setFieldValue("shippingCity", data.value);
                                }}
                                isSearchable={true}
                                placeholder={intl.formatMessage({
                                  id: "TITLE.SELECT.CITY",
                                })}
                                options={cityList}
                                id="city"
                                name="shippingCity"
                                className="react-select-container"
                                classNamePrefix="react-select"
                              />
                              {/* {touched.shippingCity && errors.shippingCity ? (
                                <div className="text-danger">
                                  {errors.shippingCity}
                                </div>
                              ) : null} */}
                            </Form.Group>
                            <Form.Group>
                              <Form.Label htmlFor="vendorname">
                                <FormattedMessage id="TITLE.ZIP.POSTAL.CODE" />
                                {/* <span className="text-danger">*</span> */}
                              </Form.Label>
                              <Form.Control
                                id="shippingZipcode"
                                name="shippingZipcode"
                                type="number"
                                placeholder={intl.formatMessage({
                                  id: "TITLE.ZIP.POSTAL.CODE",
                                })}
                                className="form-control"
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                onPaste={(evt) => {
                                  const values =
                                    evt.clipboardData.getData("text");
                                  const nonAlpha = values.replace(
                                    /[^\d.-]/g,
                                    ""
                                  );
                                  evt.preventDefault();
                                  setFieldValue("shippingZipcode", nonAlpha);
                                }}
                                onChange={(e) => {
                                  setFieldValue(
                                    `shippingZipcode`,
                                    e.target.value
                                  );
                                }}
                                value={values?.shippingZipcode}
                                autoComplete="off"
                              />
                              {touched.shippingZipcode &&
                              errors.shippingZipcode ? (
                                <div className="text-danger">
                                  {errors.shippingZipcode}
                                </div>
                              ) : null}
                            </Form.Group>
                          </Col>
                        </Row>
                      </TabPanel>
                      <TabPanel>
                        {isAddContactPersonTable && (
                          <FieldArray
                            name="contact_persons"
                            render={(contactPersons) =>
                              values?.contact_persons?.map(
                                (contactPerson, index) => (
                                  <div key={index}>
                                    <div className="contact-person">
                                      <div className="contact-person-group">
                                        <Form.Group className="">
                                          <Form.Label htmlFor="firstName">
                                            <FormattedMessage id="TITLE.FIRST.NAME" />
                                            {/* <span className="text-danger">*</span> */}
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            id={`firstName`}
                                            name={`contact_persons.${index}.first_name`}
                                            placeholder={intl.formatMessage({
                                              id: "TITLE.FIRST.NAME",
                                            })}
                                            onChange={(e) => {
                                              let value = e.target.value;
                                              value = value.replace(
                                                /[0-9]/g,
                                                ""
                                              );
                                              setFieldValue(
                                                `contact_persons.${index}.first_name`,
                                                value
                                              );
                                            }}
                                            value={renderFirstNameValue(
                                              values,
                                              index
                                            )}
                                            autoComplete="off"
                                          />
                                          {touched.contact_persons &&
                                          errors.contact_persons &&
                                          errors.contact_persons.length > 0 &&
                                          errors.contact_persons[index] ? (
                                            <div className="text-danger">
                                              <ErrorMessage
                                                id={
                                                  errors.contact_persons[index]
                                                    .first_name
                                                }
                                              />
                                            </div>
                                          ) : null}
                                        </Form.Group>
                                        <Form.Group className="">
                                          <Form.Label htmlFor="lastName">
                                            <FormattedMessage id="TITLE.LAST.NAME" />
                                            {/* <span className="text-danger">*</span> */}
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            id={`lastName`}
                                            name={`contact_persons.${index}.last_name`}
                                            placeholder={intl.formatMessage({
                                              id: "TITLE.LAST.NAME",
                                            })}
                                            onChange={(e) => {
                                              let value = e.target.value;
                                              value = value.replace(
                                                /[0-9]/g,
                                                ""
                                              );
                                              setFieldValue(
                                                `contact_persons.${index}.last_name`,
                                                value
                                              );
                                            }}
                                            value={renderLastNameValue(
                                              values,
                                              index
                                            )}
                                            autoComplete="off"
                                          />
                                          {touched.contact_persons &&
                                          errors.contact_persons &&
                                          errors.contact_persons.length > 0 &&
                                          errors.contact_persons[index] ? (
                                            <div className="text-danger">
                                              <ErrorMessage
                                                id={
                                                  errors.contact_persons[index]
                                                    .last_name
                                                }
                                              />
                                            </div>
                                          ) : null}
                                        </Form.Group>
                                        <Form.Group className="">
                                          <Form.Label htmlFor="emailAddress">
                                            <FormattedMessage id="TITLE.EMAIL" />
                                            {/* <span className="text-danger">*</span> */}
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            id={`emailAddress`}
                                            name={`contact_persons.${index}.email`}
                                            placeholder={intl.formatMessage({
                                              id: "TITLE.EMAIL",
                                            })}
                                            onChange={(e) => {
                                              setFieldValue(
                                                `contact_persons.${index}.email`,
                                                e.target.value
                                              );
                                            }}
                                            value={renderContactEmailValue(
                                              values,
                                              index
                                            )}
                                            autoComplete="off"
                                          />
                                          {touched.contact_persons &&
                                          errors.contact_persons &&
                                          errors.contact_persons.length > 0 &&
                                          errors.contact_persons[index] ? (
                                            <div className="text-danger">
                                              {
                                                errors.contact_persons[index]
                                                  .email
                                              }
                                            </div>
                                          ) : null}
                                        </Form.Group>
                                        <Form.Group className="">
                                          <Form.Label htmlFor="workphone ">
                                            <FormattedMessage id="TITLE.PHONE" />
                                            {/* <span className="text-danger">*</span> */}
                                          </Form.Label>
                                          <Form.Control
                                            type="number"
                                            id={`workphone`}
                                            name={`contact_persons.${index}.phone`}
                                            placeholder={intl.formatMessage({
                                              id: "TITLE.PHONE",
                                            })}
                                            onKeyDown={(evt) =>
                                              ["e", "E", "+", "-"].includes(
                                                evt.key
                                              ) && evt.preventDefault()
                                            }
                                            onChange={(e) => {
                                              setFieldValue(
                                                `contact_persons.${index}.phone`,
                                                e.target.value
                                              );
                                            }}
                                            onPaste={(evt) => {
                                              const values =
                                                evt.clipboardData.getData(
                                                  "text"
                                                );
                                              const nonAlpha = values.replace(
                                                /[^\d.-]/g,
                                                ""
                                              );
                                              evt.preventDefault();
                                              setFieldValue(
                                                `contact_persons.${index}.phone`,
                                                nonAlpha
                                              );
                                            }}
                                            value={renderPhoneValue(
                                              values,
                                              index
                                            )}
                                            autoComplete="off"
                                          />
                                          {errors.contact_persons &&
                                          errors.contact_persons.length > 0 &&
                                          errors.contact_persons[index] ? (
                                            <div className="text-danger">
                                              <ErrorMessage
                                                id={
                                                  errors.contact_persons[index]
                                                    .phone
                                                }
                                              />
                                            </div>
                                          ) : null}
                                        </Form.Group>
                                        <Form.Group className="">
                                          <Form.Label htmlFor="mobile">
                                            <FormattedMessage id="TITLE.MOBILE" />
                                            {/* <span className="text-danger">*</span> */}
                                          </Form.Label>
                                          <Form.Control
                                            type="number"
                                            id={`mobile`}
                                            name={`contact_persons.${index}.mobile`}
                                            placeholder={intl.formatMessage({
                                              id: "TITLE.MOBILE",
                                            })}
                                            onKeyDown={(evt) =>
                                              ["e", "E", "+", "-"].includes(
                                                evt.key
                                              ) && evt.preventDefault()
                                            }
                                            onChange={(e) => {
                                              setFieldValue(
                                                `contact_persons.${index}.mobile`,
                                                e.target.value
                                              );
                                            }}
                                            onPaste={(evt) => {
                                              const values =
                                                evt.clipboardData.getData(
                                                  "text"
                                                );
                                              const nonAlpha = values.replace(
                                                /[^\d.-]/g,
                                                ""
                                              );
                                              evt.preventDefault();
                                              setFieldValue(
                                                `contact_persons.${index}.mobile`,
                                                nonAlpha
                                              );
                                            }}
                                            value={renderMobileValue(
                                              values,
                                              index
                                            )}
                                            autoComplete="off"
                                          />
                                          {errors.contact_persons &&
                                          errors.contact_persons.length > 0 &&
                                          errors.contact_persons[index] ? (
                                            <div className="text-danger">
                                              <ErrorMessage
                                                id={
                                                  errors.contact_persons[index]
                                                    .mobile
                                                }
                                              />
                                            </div>
                                          ) : null}
                                        </Form.Group>
                                      </div>

                                      <Button
                                        variant={`link p-0 ml-2 d-none`}
                                        ref={(el) =>
                                          (handleEditContactPersonRef.current[
                                            index
                                          ] = el)
                                        }
                                        onClick={() => {
                                          const obj = {
                                            first_name: "",
                                            last_name: "",
                                            email: "",
                                            phone: "",
                                            mobile: "",
                                          };
                                          contactPersons.insert(
                                            values.contact_persons.length,
                                            obj
                                          );
                                        }}
                                      ></Button>

                                      <Button
                                        variant={`link p-0 ml-2`}
                                        onClick={() => {
                                          if (
                                            values.contact_persons.length === 1
                                          ) {
                                            setAddContactPersonTable(false);
                                          }
                                          contactPersons.remove(index);
                                        }}
                                      >
                                        <XCircle
                                          size={20}
                                          style={{
                                            color: "#990029",
                                            marginRight: "0",
                                          }}
                                        />
                                      </Button>
                                    </div>
                                  </div>
                                )
                              )
                            }
                          />
                        )}

                        {!isAddContactPersonTable && (
                          <div className="d-grid gap-2 border border-solid">
                            <Button
                              variant="gray btn-icon btn-block"
                              onClick={() => {
                                if (values.contact_persons.length === 0) {
                                  const obj = {
                                    first_name: "",
                                    last_name: "",
                                    email: "",
                                    phone: "",
                                    mobile: "",
                                  };
                                  values.contact_persons.push(obj);
                                }
                                setAddContactPersonTable(true);
                              }}
                            >
                              <Plus size={16} style={{ color: "#333333" }} />
                              <FormattedMessage id="TITLE.ADD.CONTACT.PERSON" />
                            </Button>
                          </div>
                        )}

                        {isAddContactPersonTable && (
                          <Button
                            variant="link btn-icon text-primary px-0"
                            onClick={(e) => {
                              if (values.contact_persons.length === 0) {
                                const obj = {
                                  first_name: "",
                                  last_name: "",
                                  email: "",
                                  phone: "",
                                  mobile: "",
                                };
                                values.contact_persons.push(obj);
                                setAddContactPersonTable(true);
                              } else {
                                if (
                                  handleEditContactPersonRef.current &&
                                  handleEditContactPersonRef.current.length > 0
                                ) {
                                  handleEditContactPersonRef.current[0].click();
                                }
                              }
                            }}
                          >
                            <Plus size={16} className="text-primary" />
                            <FormattedMessage id="TITLE.ADD.CONTACT.PERSON" />
                          </Button>
                        )}
                      </TabPanel>
                      <TabPanel>
                        <Form.Group>
                          <Form.Label htmlFor="vendorname">
                            <FormattedMessage id="TITLE.REMARKS" />
                            <span className="mr-1 ml-1 text-muted">
                              (
                              <FormattedMessage id="TITLE.MAX.512.CHARACTERS" />
                              )
                            </span>
                          </Form.Label>
                          <Form.Control
                            id="remarks"
                            name="remarks"
                            as="textarea"
                            placeholder={intl.formatMessage({
                              id: "TITLE.REMARKS",
                            })}
                            className="form-control"
                            onChange={(e) => {
                              setFieldValue(`remarks`, e.target.value);
                            }}
                            value={values?.remarks}
                            autoComplete="off"
                            maxLength={512}
                          />
                          {touched.remarks && errors.remarks ? (
                            <div className="text-danger">{errors.remarks}</div>
                          ) : null}
                        </Form.Group>
                      </TabPanel>
                    </Tabs>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  type="submit"
                  variant="success"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    handleSubmit();
                  }}
                  disabled={isLoading}
                >
                  <FormattedMessage id="TITLE.SAVE" />
                  {isLoading && <ButtonLoader className="mr-1 ml-1" />}
                </Button>
                <Button
                  type="button"
                  variant="tertiary text-dark"
                  onClick={() => onCloseModal()}
                >
                  <FormattedMessage id="TITLE.CANCEL" />
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
        )}
      </Formik>

      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
