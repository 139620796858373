import React, { useContext, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { PhoneCall, Edit2, Plus, Mail, Smartphone } from "react-feather";
import { useHistory } from "react-router-dom";
import {
  amountFormatter,
  capitalizeFirstLetter,
  dateFormatter,
  getStatusPOClass,
  setActiveMenu,
} from "../../constants/utils";
import { AddContactPersonCustomerModal } from "./AddContactPersonCustomerModal";
import { AddShippingAddressCustomerModal } from "./AddShippingAddressCustomerModal";
import { EditBillingAddressCustomerModal } from "./EditBillingAddressCustomerModal";
import { EditContactPersonCustomerModal } from "./EditContactPersonCustomerModal";
import { FormattedMessage } from "react-intl";
import { USER_DETAILS } from "../../constants/const";
import { MENUITEMS } from "../../constants/menu";
import { UIContext } from "../../helper/UIContext";

export function DefaultCustomerView({
  customerDetails,
  id,
  recentFivePO,
  isLoading,
  recentFiveFulfilledPO,
  handleUpdateSingleCustomerDetails,
}) {
  const history = useHistory();
  const uiContext = useContext(UIContext);
  const [isOpenAddBillingAddressModal, setOpenAddBillingAddressModal] =
    useState(false);
  const [isOpenAddShippingAddressModal, setOpenAddShippingAddressModal] =
    useState(false);
  const [isOpenAddContactPersonModal, setOpenAddContactPersonModal] =
    useState(false);
  const [isOpenEditContactPersonModal, setOpenEditContactPersonModal] =
    useState(false);

  const [selectedContactPerson, setSelectedContactPerson] = useState({});
  const [mainmenu, setMainMenu] = useState(MENUITEMS);

  const addBillingAddressModalShow = () => {
    setOpenAddBillingAddressModal(true);
  };

  const addBillingAddressModalHide = () => {
    setOpenAddBillingAddressModal(false);
  };

  const addShippingAddressModalShow = () => {
    setOpenAddShippingAddressModal(true);
  };

  const addShippingAddressModalHide = () => {
    setOpenAddShippingAddressModal(false);
  };
  const addContactPersonModalShow = () => {
    setOpenAddContactPersonModal(true);
  };

  const addContactPersonModalHide = () => {
    setOpenAddContactPersonModal(false);
  };

  const editContactPersonModalShow = () => {
    setOpenEditContactPersonModal(true);
  };

  const editContactPersonModalHide = () => {
    setOpenEditContactPersonModal(false);
  };

  const renderContactName = (contact) => {
    const { first_name, last_name } = contact;
    let name = first_name ? first_name : "";
    let space = " ";
    const lastName = last_name ? last_name : "";
    if (last_name) name = name.concat(space, lastName);
    return capitalizeFirstLetter(name);
  };

  const handleCloseOfEditBillingAddressCustomerModal = () => {
    handleUpdateSingleCustomerDetails();
    addBillingAddressModalHide();
  };

  const handleCloseOfEditShippingAddressCustomerModal = () => {
    handleUpdateSingleCustomerDetails();
    addShippingAddressModalHide();
  };

  const handleCloseOfAddNewContactPersonCustomerModal = () => {
    handleUpdateSingleCustomerDetails();
    addContactPersonModalHide();
  };

  const handleCloseOfEditContactPersonCustomerModal = () => {
    handleUpdateSingleCustomerDetails();
    editContactPersonModalHide();
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  const renderStatusName = (purchaseorderstatus) => {
    const { name: status } = purchaseorderstatus || {};
    return (
      <span
        className={`${getStatusPOClass(
          status
        )} status-color text-capitalize cursor-pointer`}
      >
        {status ? status : "-"}
      </span>
    );
  };

  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const { role } = userDetails || {};
  const { name: userRole } = role || {};

  return (
    <>
      <div>
        <div className="highlighted-row">
          <div className="d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row">
            <div className="left">
              <h3 className="modal-title mb-2">
                {capitalizeFirstLetter(customerDetails?.contact_name)}
              </h3>
              <div className="text-dark mb-2">
                <Mail size={12} className="text-dark mr-2" />
                {customerDetails?.email ? customerDetails?.email : "-"}
              </div>

              <div className="text-dark mb-2">
                <Smartphone size={12} className="text-dark mr-2" />
                <span className="ltr-direction">
                  {customerDetails?.mobile_number
                    ? customerDetails?.mobile_number
                    : "-"}
                </span>
              </div>
              <div className="text-dark mb-2">
                <PhoneCall size={12} className="text-dark mr-2" />
                <span className="ltr-direction">
                  {customerDetails?.phone_number
                    ? customerDetails?.phone_number
                    : "-"}
                </span>
              </div>
            </div>
            <div className="right">
              <h5 className="fs-14 text-right text-dark">
                <FormattedMessage id="TITLE.ITEMS.TO.BE.DELIVERED" />
                <br />
                <span className="f-w-900 fs-16 text-primary">
                  {customerDetails?.outstanding_products}
                </span>
              </h5>
            </div>
          </div>
        </div>
        <div className="view-body">
          <Row className="mb-5">
            <Col md={12}>
              <div>
                <div className="text-dark mb-2 fs-16 text-black f-w-600">
                  <FormattedMessage id="TITLE.ADDRESS" />
                </div>
                <Row>
                  <Col md={6}>
                    <div className="text-dark f-w-600">
                      <FormattedMessage id="TITLE.BILLING.ADDRESS" />
                      <Edit2
                        className="cursor-pointer ml-2 text-dark"
                        size={14}
                        onClick={() => addBillingAddressModalShow()}
                      />
                    </div>
                    <div className="mb-2 mt-2">
                      {capitalizeFirstLetter(
                        customerDetails?.billingaddress?.building_no
                      )}
                    </div>
                    <div className="mb-2">
                      {capitalizeFirstLetter(
                        customerDetails?.billingaddress?.city?.name
                      )}
                    </div>
                    <div className="mb-2">
                      {capitalizeFirstLetter(
                        customerDetails?.billingaddress?.country?.name
                      )}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="text-black f-w-600">
                      <FormattedMessage id="TITLE.SHIPPING.ADDRESS" />
                      <Edit2
                        className="cursor-pointer ml-2 text-dark"
                        size={14}
                        onClick={() => addShippingAddressModalShow()}
                      />
                    </div>
                    <div className="mb-2 mt-2">
                      {capitalizeFirstLetter(
                        customerDetails?.shippingaddress?.building_no
                      )}
                    </div>
                    <div className="mb-2">
                      {capitalizeFirstLetter(
                        customerDetails?.shippingaddress?.city?.name
                      )}
                    </div>
                    <div className="mb-2">
                      {capitalizeFirstLetter(
                        customerDetails?.shippingaddress?.country?.name
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="address">
                <div className="text-black f-w-600">
                  <FormattedMessage id="TITLE.OTHER.DETAILS" />
                </div>
                <Row>
                  <Col xs={6}>
                    <h6 className="text-dark">
                      <FormattedMessage id="TITLE.CURRENCY.CODE" />
                    </h6>
                  </Col>
                  <Col xs={6} className="text-dark">
                    {customerDetails?.currency}
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <h6 className="text-dark">
                      <FormattedMessage id="TITLE.REMARKS" />
                    </h6>
                  </Col>
                  <Col xs={6} className="text-dark">
                    {customerDetails?.remarks}
                  </Col>
                </Row>
              </div>
              <div className="address border-top mt-3">
                <Row>
                  <Col xs={6}>
                    <h6 className="text-black f-w-600">
                      <FormattedMessage id="TITLE.CONTACT.PERSON" />
                    </h6>
                  </Col>
                  <Col xs={6} className="text-dark text-right">
                    <Plus
                      className="cursor-pointer text-dark"
                      size={16}
                      onClick={() => addContactPersonModalShow()}
                    />
                  </Col>
                </Row>
                <Row>
                  {customerDetails &&
                    customerDetails.contact_person &&
                    customerDetails.contact_person.map((contact, index) => {
                      return (
                        <Col md={4} key={index}>
                          <div className="mt-2">
                            <h3 className="fs-14 f-w-900 text-black">
                              {renderContactName(contact)}
                              <Edit2
                                className="cursor-pointer ml-2 text-dark"
                                size={14}
                                onClick={() => {
                                  setSelectedContactPerson(contact);
                                  editContactPersonModalShow();
                                }}
                              />
                            </h3>
                            {contact?.email && (
                              <div className="text-dark mb-2">
                                <Mail size={12} className="text-dark mr-2" />
                                {contact?.email}
                              </div>
                            )}
                            {contact?.mobile && (
                              <div className="text-dark mb-2">
                                <Smartphone
                                  size={12}
                                  className="text-dark mr-2"
                                />
                                <span className="ltr-direction">
                                  {contact?.mobile}
                                </span>
                              </div>
                            )}
                            {contact?.phone && (
                              <div className="text-dark mb-2">
                                <PhoneCall
                                  size={12}
                                  className="text-dark mr-2"
                                />
                                <span className="ltr-direction">
                                  {contact?.phone}
                                </span>
                              </div>
                            )}
                          </div>
                        </Col>
                      );
                    })}
                </Row>
              </div>
            </Col>
            {userRole !== "Admin" && (
              <>
                <Col md={12} className="mt-5">
                  <h3 className="fs-16 mb-3 text-black f-w-900">
                    <FormattedMessage id="TITLE.OPEN.DELIVERY.CHALLAN.RECENT.5" />
                  </h3>
                  <Table className="viewpo-table table">
                    <thead>
                      <tr>
                        <th style={{ width: "50%" }} scope="col">
                          <FormattedMessage id="TITLE.DC" />
                        </th>
                        <th scope="col">
                          <FormattedMessage id="TITLE.DATE" />
                        </th>
                        <th scope="col">
                          <FormattedMessage id="TITLE.QUANTITY" />
                        </th>
                        <th scope="col">
                          <FormattedMessage id="TITLE.AMOUNT" />
                        </th>
                        <th scope="col">
                          <FormattedMessage id="TITLE.STATUS" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {recentFivePO &&
                        recentFivePO.map((customer, index) => {
                          return (
                            <tr
                              key={index}
                              className="cursor-pointer"
                              onClick={() => {
                                window.scrollTo(0, 0);
                                const path = `/outwards/view-dc-details/${customer?.id}`;
                                onClickChangeRoute(path);
                              }}
                            >
                              <td>
                                <span className="text-info">
                                  {customer?.purchaseorder_number}
                                </span>
                              </td>
                              <td className="ltr-direction">
                                {dateFormatter(customer.date)}
                              </td>
                              <td className="ltr-direction">
                                {customer.totalquantity}
                              </td>
                              <td className="digits">
                                {amountFormatter(customer?.totalamount)}
                              </td>
                              <td className="ltr-direction">
                                {renderStatusName(
                                  customer?.purchaseorderstatus
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      {recentFivePO && recentFivePO.length === 0 && !isLoading && (
                        <>
                          <tr>
                            <td className="no-record" colSpan={5}>
                              <span className="d-block text-center">
                                <FormattedMessage id="TITLE.NO.RECORDS.FOUND" />
                              </span>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </Table>
                </Col>
                <Col md={12} className="mt-2">
                  <h3 className="fs-16 mb-3 text-black f-w-900">
                    <FormattedMessage id="TITLE.DELIVERED.DELIVERY.CHALLAN.RECENT.5" />
                  </h3>
                  <Table className="viewpo-table table">
                    <thead>
                      <tr>
                        <th style={{ width: "50%" }} scope="col">
                          <FormattedMessage id="TITLE.DC" />
                        </th>
                        <th scope="col">
                          <FormattedMessage id="TITLE.DATE" />
                        </th>
                        <th scope="col">
                          <FormattedMessage id="TITLE.QUANTITY" />
                        </th>
                        <th scope="col">
                          <FormattedMessage id="TITLE.AMOUNT" />
                        </th>
                        <th scope="col">
                          <FormattedMessage id="TITLE.STATUS" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {recentFiveFulfilledPO &&
                        recentFiveFulfilledPO.map((customer, index) => {
                          return (
                            <tr
                              key={index}
                              className="cursor-pointer"
                              onClick={() => {
                                const path = `/outwards/view-dc-details/${customer?.id}`;
                                onClickChangeRoute(path);
                                window.scrollTo(0, 0);
                              }}
                            >
                              <td>
                                <span className="text-info">
                                  {customer?.purchaseorder_number}
                                </span>
                              </td>
                              <td className="ltr-direction">
                                {dateFormatter(customer.date)}
                              </td>
                              <td className="ltr-direction">
                                {customer.totalquantity}
                              </td>
                              <td className="digits">
                                {amountFormatter(customer?.totalamount)}
                              </td>
                              <td className="ltr-direction">
                                {renderStatusName(
                                  customer?.purchaseorderstatus
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      {recentFiveFulfilledPO &&
                        recentFiveFulfilledPO.length === 0 &&
                        !isLoading && (
                          <>
                            <tr>
                              <td className="no-record" colSpan={5}>
                                <span className="d-block text-center">
                                  <FormattedMessage id="TITLE.NO.RECORDS.FOUND" />
                                </span>
                              </td>
                            </tr>
                          </>
                        )}
                    </tbody>
                  </Table>
                </Col>{" "}
              </>
            )}
          </Row>
          {/* <Table responsive className="viewpo-table">
          <thead>
            <tr>
              <th style={{ Width: "28%" }}>Product and SKU</th>
              <th style={{ Width: "15%" }}>Ordered</th>
              <th style={{ Width: "20%" }}>Status</th>
              <th style={{ Width: "17%" }}>Rate</th>
              <th style={{ Width: "20%" }}>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="table-product-view">
                  <div className="product-thumb">
                    <div>
                      <img
                        className="img-radius align-top blur-up lazyloaded"
                        src="https://store.storeimages.cdn-apple.com/4668/as-images.apple.com/is/iphone-13-blue-select-2021?wid=470&hei=556&fmt=jpeg&qlt=95&.v=1629842712000"
                        alt=""
                        data-original-title=""
                        title=""
                      />
                    </div>
                  </div>
                  <div className="product-name pl-3">
                    <Link href="" className="text-primary fs-14 mb-2">
                      Product Name
                    </Link>
                    <div className="small-description small-text text-dark">
                      It is a long established fact that a reader will be
                      distracted by the readable.
                    </div>
                  </div>
                </div>
              </td>
              <td className="text-nowrap">300 Pices</td>
              <td className="text-success text-nowrap">Recived</td>
              <td className="text-nowrap">AED-2,333.00</td>
              <td className="text-nowrap">AED-2,333.00</td>
            </tr>
          </tbody>
        </Table> */}
          {/* <div className="group pb-5">
          <Row>
            <Col md={3} lg={6}></Col>
            <Col md={9} lg={6}>
              <div className="total-section">
                <Row>
                  <Col md={6}>
                    <h6 className="modal-title f-w-900">Total</h6>
                    <span className="text-muted small-text text-dark">
                      Total Quantity : 300
                    </span>
                  </Col>
                  <Col md={6}>
                    <h6 className="modal-title f-w-900 text-right">
                      AED-2,333.00
                    </h6>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div> */}
          {isOpenAddBillingAddressModal && (
            <EditBillingAddressCustomerModal
              open={isOpenAddBillingAddressModal}
              onCloseModal={addBillingAddressModalHide}
              data={customerDetails}
              id={id}
              handleCloseOfEditBillingAddressCustomerModal={
                handleCloseOfEditBillingAddressCustomerModal
              }
            />
          )}

          {isOpenAddShippingAddressModal && (
            <AddShippingAddressCustomerModal
              open={isOpenAddShippingAddressModal}
              onCloseModal={addShippingAddressModalHide}
              data={customerDetails}
              id={id}
              handleCloseOfEditShippingAddressCustomerModal={
                handleCloseOfEditShippingAddressCustomerModal
              }
            />
          )}

          {isOpenAddContactPersonModal && (
            <AddContactPersonCustomerModal
              open={isOpenAddContactPersonModal}
              onCloseModal={addContactPersonModalHide}
              id={id}
              handleCloseOfAddNewContactPersonCustomerModal={
                handleCloseOfAddNewContactPersonCustomerModal
              }
            />
          )}

          {isOpenEditContactPersonModal && (
            <EditContactPersonCustomerModal
              open={isOpenEditContactPersonModal}
              onCloseModal={editContactPersonModalHide}
              id={id}
              selectedContactPerson={selectedContactPerson}
              handleCloseOfEditContactPersonCustomerModal={
                handleCloseOfEditContactPersonCustomerModal
              }
            />
          )}
        </div>
      </div>
    </>
  );
}

export default DefaultCustomerView;
