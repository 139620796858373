import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import { XCircle, Plus, X, Check } from "react-feather";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { FieldArray, Formik } from "formik";
import { AddCustomerSchema } from "../helper/FormikSchema";
import { addCustomerApi, cityListAllApi } from "../axios/services/services";
import { ButtonLoader } from "../helper/ButtonLoader";
import { ErrorModal } from "../components/common/modals/ErrorModal";
import {
  capitalizeFirstLetter,
  getErrorMessageBasedOnLanguage,
  InitLanguage,
  unauthorization,
} from "../constants/utils";
import { FormattedMessage, useIntl } from "react-intl";
import ErrorMessage from "../helper/ErrorMessage";

export function AddNewCustomerModal({
  searchedCustomerName,
  onCloseModal,
  open,
  handleCloseOfAddNewCustomerModal,
}) {
  const [isLoading, setLoading] = useState(false);
  const [isNewCustomerAdded, setNewCustomerAdded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [selectedShippingCity, setSelectedShippingCity] = useState("");
  const [cityList, setCityList] = useState([]);
  const [selectedBillingCity, setSelectedBillingCity] = useState("");
  const [isAddContactPersonTable, setAddContactPersonTable] = useState(true);
  const [multipleContactDetails, setMultipleContactDetails] = useState({
    contact_name: searchedCustomerName,
    email: "",
    phone_number: "",
    mobile_number: "",
    currency: "AED",
    billingAddress: "",
    billingCity: "",
    billingZipcode: "",
    shippingAddress: "",
    shippingCity: "",
    shippingZipcode: "",
    contact_persons: [
      {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        mobile: "",
      },
    ],
    remarks: "",
  });

  const handleAddContactPersonRef = useRef([]);

  async function getCityList() {
    const cityListResponse = await cityListAllApi({});
    const { Error, data } = cityListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    const cities = data.map((city) => {
      const { id, name } = city || {};
      city.value = id;
      city.label = capitalizeFirstLetter(name);
      return city;
    });
    setCityList(cities ? cities : []);
  }

  useEffect(() => {
    getCityList();
  }, []);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleOnClickOfCopyBillingAddress = (values, setFieldValue) => {
    const { billingAddress, billingCity, billingZipcode } = values || {};
    setFieldValue("shippingAddress", billingAddress);
    setFieldValue("shippingCity", billingCity);
    setFieldValue("shippingZipcode", billingZipcode);
    setSelectedShippingCity(selectedBillingCity);
  };

  const renderFirstNameValue = (values, index) => {
    const value = values.contact_persons[index].first_name;
    return value;
  };

  const renderLastNameValue = (values, index) => {
    const value = values.contact_persons[index].last_name;
    return value;
  };

  const renderContactEmailValue = (values, index) => {
    const value = values.contact_persons[index].email;
    return value;
  };

  const renderMobileValue = (values, index) => {
    const value = values.contact_persons[index].mobile;
    return value;
  };

  const renderPhoneValue = (values, index) => {
    const value = values.contact_persons[index].phone;
    return value;
  };

  // const formikAddCustomer = useFormik({
  //   initialValues: AddCustomerInitialValues,
  //   validationSchema: AddCustomerSchema,
  //   onSubmit: async (values, { setStatus, setSubmitting }) => {
  //     addCustomer(values, setSubmitting);
  //   },
  //   onReset: (values, { resetForm }) => {},
  // });

  const addCustomer = async (values) => {
    const {
      contact_name,
      email,
      phone_number,
      mobile_number,
      currency,
      billingAddress,
      billingCity,
      billingZipcode,
      shippingAddress,
      shippingCity,
      shippingZipcode,
      remarks,
      contact_persons,
    } = values || {};

    let addCustomerObj = {
      contact_type: "customer",
      email: email ? email : "",
      phone_number: phone_number ? phone_number : "",
      mobile_number: mobile_number ? mobile_number : "",
      contact_name: contact_name ? contact_name : "",
      currency,
      billingaddressId: billingAddress ? billingAddress : "",
      billing_cityId: billingCity ? billingCity : "",
      billing_zipcode: billingZipcode ? billingZipcode : "",
      shippingaddressId: shippingAddress ? shippingAddress : "",
      shipping_cityId: shippingCity ? shippingCity : "",
      shipping_zipcode: shippingZipcode ? shippingZipcode : "",
      contact_person: contact_persons,
      remarks,
    };
    enableLoading();
    const addCustomerResponse = await addCustomerApi(addCustomerObj);
    const { data, Error } = addCustomerResponse || {};
    if (Error) {
      disableLoading();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setNewCustomerAdded(true);
    window.scrollTo(0, 0);
    setTimeout(() => {
      disableLoading();
      handleCloseOfAddNewCustomerModal(data);
    }, 2500);
  };
  const intl = useIntl();
  return (
    <>
      <Formik
        initialValues={multipleContactDetails}
        validationSchema={AddCustomerSchema}
        onSubmit={(values) => {
          addCustomer(values);
        }}
        onReset={(values) => {}}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          resetForm,
          handleReset,
          setFieldValue,
          errors,
          touched,
        }) => (
          <Form
            className="form form-label-right filter-card"
            autoComplete="off"
            onSubmit={handleSubmit}
            onReset={() => {
              resetForm(multipleContactDetails);

              handleReset();
            }}
          >
            <Modal
              size="lg"
              show={open}
              onHide={onCloseModal}
              aria-labelledby="example-modal-sizes-title-lg"
              dialogClassName="addvendor-modal modal"
              scrollable={true}
            >
              <Modal.Header
                className="d-flex justify-content-between align-items-center"
                onHide={onCloseModal}
              >
                <h3 className="modal-title" id="exampleModalLabel2">
                  <FormattedMessage id="TITLE.ADD.NEW.CUSTOMER" />
                </h3>
                <Button
                  variant="link p-0 btn-icon"
                  onClick={() => onCloseModal()}
                >
                  <X size={20} className="text-dark mr-0 pr-0" />
                </Button>
              </Modal.Header>
              <Modal.Body className="pb-0">
                {isNewCustomerAdded && (
                  <div className="notification icon inline success fs-16">
                    <Check size={8} className="text-white"></Check>
                    <FormattedMessage id="TITLE.NEW.CUSTOMER.HAS.BEEN.SUCCESSFULLY.CREATED" />
                  </div>
                )}
                <Form autoComplete="off">
                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label htmlFor="vendorname">
                          <FormattedMessage id="TITLE.CUSTOMER.DISPLAY.NAME" />
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          id="customername"
                          name="contact_name"
                          placeholder={intl.formatMessage({
                            id: "TITLE.CUSTOMER.DISPLAY.NAME",
                          })}
                          onChange={(e) => {
                            let value = e.target.value;
                            value = value.replace(/[0-9]/g, "");
                            setFieldValue(`contact_name`, value);
                          }}
                          value={values.contact_name}
                        />
                        {touched.contact_name && errors.contact_name ? (
                          <div className="text-danger">
                            {InitLanguage(errors.contact_name)}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label htmlFor="customeremail">
                          <FormattedMessage id="TITLE.CUSTOMER.EMAIL.ADDRESS" />
                          {/* <span className="text-danger">*</span> */}
                        </Form.Label>
                        <Form.Control
                          type="email"
                          id="customeremail"
                          name="email"
                          placeholder={intl.formatMessage({
                            id: "TITLE.CUSTOMER.EMAIL.ADDRESS",
                          })}
                          onChange={(e) => {
                            setFieldValue(`email`, e.target.value);
                          }}
                          value={values.email}
                        />
                        {touched.email && errors.email ? (
                          <div className="text-danger">{errors.email}</div>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label htmlFor="vendorphone">
                          <FormattedMessage id="TITLE.CUSTOMER.PHONE.NUMBER" />
                          {/* <span className="text-danger">*</span> */}
                        </Form.Label>
                        <Form.Control
                          type="number"
                          id="vendorphone"
                          name="phone_number"
                          placeholder={intl.formatMessage({
                            id: "TITLE.CUSTOMER.PHONE.NUMBER",
                          })}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          onChange={(e) => {
                            setFieldValue(`phone_number`, e.target.value);
                          }}
                          onPaste={(evt) => {
                            const values = evt.clipboardData.getData("text");
                            const nonAlpha = values.replace(/[^\d.-]/g, "");
                            evt.preventDefault();
                            setFieldValue("phone_number", nonAlpha);
                          }}
                          value={values.phone_number}
                        />
                        {errors.phone_number ? (
                          <div className="text-danger">
                            {InitLanguage(errors.phone_number)}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label htmlFor="customermobile">
                          <FormattedMessage id="TITLE.CUSTOMER.MOBILE.NUMBER" />
                          {/* <span className="text-danger">*</span> */}
                        </Form.Label>
                        <Form.Control
                          type="number"
                          id="customermobile"
                          name="mobile_number"
                          placeholder={intl.formatMessage({
                            id: "TITLE.CUSTOMER.MOBILE.NUMBER",
                          })}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          onChange={(e) => {
                            setFieldValue(`mobile_number`, e.target.value);
                          }}
                          onPaste={(evt) => {
                            const values = evt.clipboardData.getData("text");
                            const nonAlpha = values.replace(/[^\d.-]/g, "");
                            evt.preventDefault();
                            setFieldValue("mobile_number", nonAlpha);
                          }}
                          value={values.mobile_number}
                        />
                        {errors.mobile_number ? (
                          <div className="text-danger">
                            {InitLanguage(errors.mobile_number)}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label htmlFor="Currency">
                          <FormattedMessage id="TITLE.CURRENCY" />
                          {/* <span className="text-danger">*</span> */}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          id="Currency"
                          name="currency"
                          placeholder={intl.formatMessage({
                            id: "TITLE.CURRENCY",
                          })}
                          onChange={(e) => {
                            setFieldValue(`currency`, e.target.value);
                          }}
                          value={values.currency}
                          disabled
                        />
                        {/* {touched.currency && errors.currency ? (
                          <div className="text-danger">{errors.currency}</div>
                        ) : null} */}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={12}>
                      <Tabs>
                        <TabList className="nav nav-tabs tab-coupon">
                          <Tab className="nav-link">
                            <FormattedMessage id="TITLE.ADDRESS" />
                            {/* <span className="text-danger">*</span> */}
                          </Tab>
                          <Tab className="nav-link">
                            <FormattedMessage id="TITLE.CONTACT.PERSON" />
                            {/* <span className="text-danger">*</span> */}
                          </Tab>
                          <Tab className="nav-link">
                            <FormattedMessage id="TITLE.REMARKS" />
                          </Tab>
                        </TabList>
                        <TabPanel>
                          <Row>
                            <Col md={6}>
                              <h4 className="fs-16 font-weight-bold mb-3 text-black">
                                <FormattedMessage id="TITLE.BILLING.ADDRESS" />
                              </h4>
                              <Form.Group>
                                <Form.Label htmlFor="address">
                                  <FormattedMessage id="TITLE.ADDRESS" />
                                  {/* <span className="text-danger">*</span> */}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  id="address"
                                  name="billingAddress"
                                  placeholder={intl.formatMessage({
                                    id: "TITLE.ADDRESS",
                                  })}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `billingAddress`,
                                      e.target.value
                                    );
                                  }}
                                  value={values.billingAddress}
                                />
                                {/* {touched.billingAddress &&
                                errors.billingAddress ? (
                                  <div className="text-danger">
                                    {errors.billingAddress}
                                  </div>
                                ) : null} */}
                              </Form.Group>
                              <Form.Group>
                                <Form.Label htmlFor="city">
                                  <FormattedMessage id="TITLE.CITY" />
                                </Form.Label>
                                {/* <span className="text-danger">*</span> */}
                                <Select
                                  defaultValue={selectedBillingCity}
                                  onChange={(data) => {
                                    setSelectedBillingCity(data);
                                    setFieldValue("billingCity", data.value);
                                  }}
                                  isSearchable={true}
                                  placeholder={intl.formatMessage({
                                    id: "TITLE.SELECT.CITY",
                                  })}
                                  options={cityList}
                                  id="city"
                                  name="billingCity"
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                />
                                {/* {touched.billingCity && errors.billingCity ? (
                                  <div className="text-danger">
                                    {errors.billingCity}
                                  </div>
                                ) : null} */}
                              </Form.Group>
                              <Form.Group>
                                <Form.Label htmlFor="zipCode">
                                  <FormattedMessage id="TITLE.ZIP.POSTAL.CODE" />
                                  {/* <span className="text-danger">*</span> */}
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  id="zipCode"
                                  name="billingZipcode"
                                  placeholder={intl.formatMessage({
                                    id: "TITLE.ZIP.POSTAL.CODE",
                                  })}
                                  onKeyDown={(evt) =>
                                    ["e", "E", "+", "-"].includes(evt.key) &&
                                    evt.preventDefault()
                                  }
                                  onPaste={(evt) => {
                                    const values =
                                      evt.clipboardData.getData("text");
                                    const nonAlpha = values.replace(
                                      /[^\d.-]/g,
                                      ""
                                    );
                                    evt.preventDefault();
                                    setFieldValue("billingZipcode", nonAlpha);
                                  }}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `billingZipcode`,
                                      e.target.value
                                    );
                                  }}
                                  value={values.billingZipcode}
                                />
                                {touched.billingZipcode &&
                                errors.billingZipcode ? (
                                  <div className="text-danger">
                                    {errors.billingZipcode}
                                  </div>
                                ) : null}
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <div className="d-flex justify-content-between align-items-center">
                                <h4 className="fs-16 font-weight-bold mb-3 text-black">
                                  <FormattedMessage id="TITLE.SHIPPING.ADDRESS" />
                                </h4>
                                <div
                                  className="text-primary small-text mb-3 cursor-pointer"
                                  onClick={() =>
                                    handleOnClickOfCopyBillingAddress(
                                      values,
                                      setFieldValue
                                    )
                                  }
                                >
                                  <FormattedMessage id="TITLE.COPY.BILLING.ADDRESS" />
                                </div>
                              </div>
                              <Form.Group>
                                <Form.Label htmlFor="address">
                                  <FormattedMessage id="TITLE.ADDRESS" />
                                  {/* <span className="text-danger">*</span> */}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  id="address"
                                  name="shippingAddress"
                                  placeholder={intl.formatMessage({
                                    id: "TITLE.ADDRESS",
                                  })}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `shippingAddress`,
                                      e.target.value
                                    );
                                  }}
                                  value={values.shippingAddress}
                                />
                                {/* {touched.shippingAddress &&
                                errors.shippingAddress ? (
                                  <div className="text-danger">
                                    {errors.shippingAddress}
                                  </div>
                                ) : null} */}
                              </Form.Group>
                              <Form.Group>
                                <Form.Label htmlFor="city">
                                  <FormattedMessage id="TITLE.CITY" />
                                </Form.Label>
                                {/* <span className="text-danger">*</span> */}
                                <Select
                                  value={selectedShippingCity}
                                  onChange={(data) => {
                                    setSelectedShippingCity(data);
                                    setFieldValue("shippingCity", data.value);
                                  }}
                                  isSearchable={true}
                                  placeholder={intl.formatMessage({
                                    id: "TITLE.SELECT.CITY",
                                  })}
                                  options={cityList}
                                  id="city"
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  name="shippingCity"
                                />
                                {/* {touched.shippingCity && errors.shippingCity ? (
                                  <div className="text-danger">
                                    {errors.shippingCity}
                                  </div>
                                ) : null} */}
                              </Form.Group>
                              <Form.Group>
                                <Form.Label htmlFor="zipCode">
                                  <FormattedMessage id="TITLE.ZIP.POSTAL.CODE" />
                                  {/* <span className="text-danger">*</span> */}
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  id="zipCode"
                                  name="shippingZipcode"
                                  placeholder={intl.formatMessage({
                                    id: "TITLE.ZIP.POSTAL.CODE",
                                  })}
                                  onKeyDown={(evt) =>
                                    ["e", "E", "+", "-"].includes(evt.key) &&
                                    evt.preventDefault()
                                  }
                                  onChange={(e) => {
                                    setFieldValue(
                                      `shippingZipcode`,
                                      e.target.value
                                    );
                                  }}
                                  onPaste={(evt) => {
                                    const values =
                                      evt.clipboardData.getData("text");
                                    const nonAlpha = values.replace(
                                      /[^\d.-]/g,
                                      ""
                                    );
                                    evt.preventDefault();
                                    setFieldValue("shippingZipcode", nonAlpha);
                                  }}
                                  value={values.shippingZipcode}
                                />
                                {touched.shippingZipcode &&
                                errors.shippingZipcode ? (
                                  <div className="text-danger">
                                    {errors.shippingZipcode}
                                  </div>
                                ) : null}
                              </Form.Group>
                            </Col>
                          </Row>
                        </TabPanel>
                        <TabPanel>
                          {isAddContactPersonTable && (
                            <FieldArray
                              name="contact_persons"
                              render={(contactPersons) =>
                                values.contact_persons.map(
                                  (contactPerson, index) => (
                                    <div key={index}>
                                      <div className="contact-person">
                                        <div className="contact-person-group">
                                          <Form.Group className="">
                                            <Form.Label htmlFor="firstName">
                                              <FormattedMessage id="TITLE.FIRST.NAME" />
                                              {/* <span className="text-danger">
                                              *
                                            </span> */}
                                            </Form.Label>
                                            <Form.Control
                                              type="text"
                                              id={`firstName`}
                                              name={`contact_persons.${index}.first_name`}
                                              placeholder={intl.formatMessage({
                                                id: "TITLE.FIRST.NAME",
                                              })}
                                              onChange={(e) => {
                                                let value = e.target.value;
                                                value = value.replace(
                                                  /[0-9]/g,
                                                  ""
                                                );
                                                setFieldValue(
                                                  `contact_persons.${index}.first_name`,
                                                  value
                                                );
                                              }}
                                              value={renderFirstNameValue(
                                                values,
                                                index
                                              )}
                                            />
                                            {touched.contact_persons &&
                                            errors.contact_persons &&
                                            errors.contact_persons.length > 0 &&
                                            errors.contact_persons[index] ? (
                                              <div className="text-danger">
                                                <ErrorMessage
                                                  id={
                                                    errors.contact_persons[
                                                      index
                                                    ].first_name
                                                  }
                                                />
                                              </div>
                                            ) : null}
                                          </Form.Group>
                                          <Form.Group className="">
                                            <Form.Label htmlFor="lastName">
                                              <FormattedMessage id="TITLE.LAST.NAME" />
                                              {/* <span className="text-danger">
                                              *
                                            </span> */}
                                            </Form.Label>
                                            <Form.Control
                                              type="text"
                                              id={`lastName`}
                                              name={`contact_persons.${index}.last_name`}
                                              placeholder={intl.formatMessage({
                                                id: "TITLE.LAST.NAME",
                                              })}
                                              onChange={(e) => {
                                                let value = e.target.value;
                                                value = value.replace(
                                                  /[0-9]/g,
                                                  ""
                                                );
                                                setFieldValue(
                                                  `contact_persons.${index}.last_name`,
                                                  value
                                                );
                                              }}
                                              value={renderLastNameValue(
                                                values,
                                                index
                                              )}
                                            />
                                            {touched.contact_persons &&
                                            errors.contact_persons &&
                                            errors.contact_persons.length > 0 &&
                                            errors.contact_persons[index] ? (
                                              <div className="text-danger">
                                                <ErrorMessage
                                                  id={
                                                    errors.contact_persons[
                                                      index
                                                    ].last_name
                                                  }
                                                />
                                              </div>
                                            ) : null}
                                          </Form.Group>
                                          <Form.Group className="">
                                            <Form.Label htmlFor="emailAddress">
                                              <FormattedMessage id="TITLE.EMAIL" />
                                              {/* <span className="text-danger">
                                              *
                                            </span> */}
                                            </Form.Label>
                                            <Form.Control
                                              type="text"
                                              id={`emailAddress`}
                                              name={`contact_persons.${index}.email`}
                                              placeholder={intl.formatMessage({
                                                id: "TITLE.EMAIL",
                                              })}
                                              onChange={(e) => {
                                                setFieldValue(
                                                  `contact_persons.${index}.email`,
                                                  e.target.value
                                                );
                                              }}
                                              value={renderContactEmailValue(
                                                values,
                                                index
                                              )}
                                            />
                                            {touched.contact_persons &&
                                            errors.contact_persons &&
                                            errors.contact_persons.length > 0 &&
                                            errors.contact_persons[index] ? (
                                              <div className="text-danger">
                                                {
                                                  errors.contact_persons[index]
                                                    .email
                                                }
                                              </div>
                                            ) : null}
                                          </Form.Group>
                                          <Form.Group className="">
                                            <Form.Label htmlFor="workphone ">
                                              <FormattedMessage id="TITLE.WORK.PHONE" />
                                              {/* <span className="text-danger">
                                              *
                                            </span> */}
                                            </Form.Label>
                                            <Form.Control
                                              type="number"
                                              id={`workphone`}
                                              name={`contact_persons.${index}.phone`}
                                              placeholder={intl.formatMessage({
                                                id: "TITLE.PHONE",
                                              })}
                                              onKeyDown={(evt) =>
                                                ["e", "E", "+", "-"].includes(
                                                  evt.key
                                                ) && evt.preventDefault()
                                              }
                                              onChange={(e) => {
                                                setFieldValue(
                                                  `contact_persons.${index}.phone`,
                                                  e.target.value
                                                );
                                              }}
                                              onPaste={(evt) => {
                                                const values =
                                                  evt.clipboardData.getData(
                                                    "text"
                                                  );
                                                const nonAlpha = values.replace(
                                                  /[^\d.-]/g,
                                                  ""
                                                );
                                                evt.preventDefault();
                                                setFieldValue(
                                                  `contact_persons.${index}.phone`,
                                                  nonAlpha
                                                );
                                              }}
                                              value={renderPhoneValue(
                                                values,
                                                index
                                              )}
                                            />
                                            {errors.contact_persons &&
                                            errors.contact_persons.length > 0 &&
                                            errors.contact_persons[index] ? (
                                              <div className="text-danger">
                                                <ErrorMessage
                                                  id={
                                                    errors.contact_persons[
                                                      index
                                                    ].phone
                                                  }
                                                />
                                              </div>
                                            ) : null}
                                          </Form.Group>
                                          <Form.Group className="">
                                            <Form.Label htmlFor="mobile">
                                              <FormattedMessage id="TITLE.MOBILE" />
                                              {/* <span className="text-danger">
                                              *
                                            </span> */}
                                            </Form.Label>
                                            <Form.Control
                                              type="number"
                                              id={`mobile`}
                                              name={`contact_persons.${index}.mobile`}
                                              placeholder={intl.formatMessage({
                                                id: "TITLE.MOBILE",
                                              })}
                                              onKeyDown={(evt) =>
                                                ["e", "E", "+", "-"].includes(
                                                  evt.key
                                                ) && evt.preventDefault()
                                              }
                                              onChange={(e) => {
                                                setFieldValue(
                                                  `contact_persons.${index}.mobile`,
                                                  e.target.value
                                                );
                                              }}
                                              onPaste={(evt) => {
                                                const values =
                                                  evt.clipboardData.getData(
                                                    "text"
                                                  );
                                                const nonAlpha = values.replace(
                                                  /[^\d.-]/g,
                                                  ""
                                                );
                                                evt.preventDefault();
                                                setFieldValue(
                                                  `contact_persons.${index}.mobile`,
                                                  nonAlpha
                                                );
                                              }}
                                              value={renderMobileValue(
                                                values,
                                                index
                                              )}
                                            />
                                            {errors.contact_persons &&
                                            errors.contact_persons.length > 0 &&
                                            errors.contact_persons[index] ? (
                                              <div className="text-danger">
                                                <ErrorMessage
                                                  id={
                                                    errors.contact_persons[
                                                      index
                                                    ].mobile
                                                  }
                                                />
                                              </div>
                                            ) : null}
                                          </Form.Group>
                                        </div>

                                        <Button
                                          variant={`link p-0 ml-2 d-none`}
                                          // ref={handleAddContactPersonRef}
                                          ref={(el) =>
                                            (handleAddContactPersonRef.current[
                                              index
                                            ] = el)
                                          }
                                          onClick={() => {
                                            const obj = {
                                              first_name: "",
                                              last_name: "",
                                              email: "",
                                              phone: "",
                                              mobile: "",
                                            };
                                            contactPersons.insert(
                                              values.contact_persons.length,
                                              obj
                                            );
                                          }}
                                        ></Button>

                                        <Button
                                          variant={`link p-0 ml-2`}
                                          // disabled={index === 0}
                                          onClick={() => {
                                            if (
                                              values.contact_persons.length ===
                                              1
                                            ) {
                                              setAddContactPersonTable(false);
                                            }
                                            contactPersons.remove(index);
                                          }}
                                        >
                                          <XCircle
                                            size={20}
                                            style={{
                                              color: "#990029",
                                              marginRight: "0",
                                            }}
                                          />
                                        </Button>
                                      </div>
                                    </div>
                                  )
                                )
                              }
                            />
                          )}

                          {!isAddContactPersonTable && (
                            <div className="d-grid gap-2 border border-solid">
                              <Button
                                variant="gray btn-icon btn-block"
                                onClick={() => {
                                  if (values.contact_persons.length === 0) {
                                    const obj = {
                                      first_name: "",
                                      last_name: "",
                                      email: "",
                                      phone: "",
                                      mobile: "",
                                    };
                                    values.contact_persons.push(obj);
                                  }
                                  setAddContactPersonTable(true);
                                }}
                              >
                                <Plus size={16} style={{ color: "#333333" }} />{" "}
                                <FormattedMessage id="TITLE.ADD.CONTACT.PERSON" />
                              </Button>
                            </div>
                          )}

                          {isAddContactPersonTable && (
                            <Button
                              variant="link btn-icon text-primary px-0"
                              onClick={(e) => {
                                if (values.contact_persons.length === 0) {
                                  const obj = {
                                    first_name: "",
                                    last_name: "",
                                    email: "",
                                    phone: "",
                                    mobile: "",
                                  };
                                  values.contact_persons.push(obj);
                                } else {
                                  if (
                                    handleAddContactPersonRef.current &&
                                    handleAddContactPersonRef.current.length > 0
                                  ) {
                                    handleAddContactPersonRef.current[0].click();
                                  }
                                }
                              }}
                            >
                              <Plus size={16} className="text-primary" />
                              <FormattedMessage id="TITLE.ADD.CONTACT.PERSON" />
                            </Button>
                          )}
                        </TabPanel>
                        <TabPanel>
                          <Form.Group className="">
                            <Form.Label htmlFor="remarks">
                              <FormattedMessage id="TITLE.REMARKS" />
                              <span className="mr-1 ml-1 text-muted">
                                (
                                <FormattedMessage id="TITLE.MAX.512.CHARACTERS" />
                                )
                              </span>
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              name="remarks"
                              rows={3}
                              placeholder={intl.formatMessage({
                                id: "TITLE.REMARKS",
                              })}
                              onChange={(e) => {
                                setFieldValue(`remarks`, e.target.value);
                              }}
                              value={values.remarks}
                              maxLength={512}
                            />
                            {touched.remarks && errors.remarks ? (
                              <div className="text-danger">
                                {errors.remarks}
                              </div>
                            ) : null}
                          </Form.Group>
                        </TabPanel>
                      </Tabs>
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="success"
                  type="button"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    handleSubmit();
                  }}
                  disabled={isLoading}
                >
                  <FormattedMessage id="TITLE.SAVE" />
                  {isLoading && <ButtonLoader className="mr-1 ml-1" />}
                </Button>
                <Button
                  type="button"
                  variant="tertiary text-dark"
                  onClick={() => onCloseModal("VaryingMdo")}
                >
                  <FormattedMessage id="TITLE.CANCEL" />
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
        )}
      </Formik>

      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
