import React, { useState, useEffect } from "react";
import { Button, Modal, Row, Col, Form, Spinner } from "react-bootstrap";
import Select from "react-select";
import { X, Check } from "react-feather";
import {
  getSingleCategoryDetailsApi,
  editCategoryApi,
} from "../../../axios/services/services";
import { ErrorModal } from "../../common/modals/ErrorModal";
import { useFormik } from "formik";
import { EditCategorySchema } from "../../../helper/FormikSchema";
import { unauthorization } from "../../../constants/utils";
import { FormattedMessage, useIntl } from "react-intl";
import { getErrorMessageBasedOnLanguage } from "../../../constants/utils";

//TODO ACTIVE AND DEACTIVE STATUS IN ARABIC
const statusName = [
  { value: "1", label: "Active" },
  { value: "0", label: "Inactive" },
];

let EditCategoryInitialValues = {
  name: "",
  description: "",
};

export function EditCategoryModal({
  onCloseModal,
  open,
  data,
  id,
  handleCloseOfEditNewCategoryModal,
}) {
  const [selectedOption, setSelectedOption] = useState({});
  const [isLoadingSingleCategory, setLoadingSingleCategory] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [categoryDetails, setCategoryDetails] = useState({});
  const [isSaveLoading, setSaveLoading] = useState(false);
  const [categoryEdited, setCategoryEdited] = useState(false);
  const intl = useIntl();

  const formikEditCategory = useFormik({
    initialValues: EditCategoryInitialValues,
    validationSchema: EditCategorySchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableSaveLoading();
      const { name, description } = values || {};
      const { value: status } = selectedOption;
      let obj = {
        id,
        name,
        description,
        status,
      };
      
      const editCategoryResponse = await editCategoryApi(obj);
      const { data, Error } = editCategoryResponse || {};
      if (Error) {
        disableSaveLoading();
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setCategoryEdited(true);
      window.scrollTo(0, 0);
      setTimeout(() => {
        disableSaveLoading();
        handleCloseOfEditNewCategoryModal(data);
      }, 2500);
    },
  });

  async function getSingleCategoryDetails() {
    enableLoadingSingleCategory();
    const singleCategoryDetails = await getSingleCategoryDetailsApi({ id });
    disableLoadingSingleCategory(false);
    const { Error, data } = singleCategoryDetails || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setCategoryDetails(data ? data : []);
    const { name, description, status } = data || {};
    if (status === 1) {
      setSelectedOption({ value: "1", label: "Active" });
    } else {
      setSelectedOption({ value: "0", label: "Inactive" });
    }
    formikEditCategory.setFieldValue("name", name);
    formikEditCategory.setFieldValue("description", description);
  }

  useEffect(() => {
    getSingleCategoryDetails();
  }, []);

  const enableSaveLoading = () => {
    setSaveLoading(true);
  };

  const disableSaveLoading = () => {
    setSaveLoading(false);
  };

  const enableLoadingSingleCategory = () => {
    setLoadingSingleCategory(true);
  };

  const disableLoadingSingleCategory = () => {
    setLoadingSingleCategory(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  return (
    <>
      <Modal
        size="md"
        show={open}
        onHide={onCloseModal}
        dialogClassName="inventory-small-modal"
      >
        <Modal.Header
          className="d-flex justify-content-between align-items-center"
          toggle={onCloseModal}
        >
          <h3 className="modal-title" id="exampleModalLabel2">
            <FormattedMessage id="TITLE.EDIT.CATEGORY" />
            {isLoadingSingleCategory && (
              <Spinner animation="border" size="sm" className="ml-1" />
            )}
          </h3>
          <Button
            variant="link p-0 btn-icon"
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <X size={20} className="text-dark mr-0 pr-0" />
          </Button>
        </Modal.Header>
        <Modal.Body className="pb-0">
          {categoryEdited && (
            <div className="notification icon inline success fs-16">
              <Check size={8} className="text-white"></Check>
              <FormattedMessage id="TITLE.CATEGORY.HAS.BEEN.SUCCESSFULL.EDITED" />
            </div>
          )}
          <Form autoComplete="off">
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor="name">
                    <FormattedMessage id="TITLE.CATEGORY.NAME" />
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="name"
                    required
                    placeholder={intl.formatMessage({
                      id: "TITLE.CATEGORY.NAME",
                    })}
                    {...formikEditCategory.getFieldProps("name")}
                  />
                  {formikEditCategory.touched.name &&
                  formikEditCategory.errors.name ? (
                    <div className="text-danger">
                      {formikEditCategory.errors.name}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor="description">
                    <FormattedMessage id="TITLE.DESCRIPTION" />
                    <span className="mr-1 ml-1 text-muted">
                      (
                      <FormattedMessage id="TITLE.MAX.512.CHARACTERS" />)
                    </span>
                  </Form.Label>
                  <Form.Control
                    id="description"
                    as="textarea"
                    placeholder={intl.formatMessage({
                      id: "TITLE.DESCRIPTION",
                    })}
                    className="form-control"
                    resize="none"
                    {...formikEditCategory.getFieldProps("description")}
                  />
                  {formikEditCategory.touched.description &&
                  formikEditCategory.errors.description ? (
                    <div className="text-danger">
                      {formikEditCategory.errors.description}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor="status">
                    <FormattedMessage id="TITLE.STATUS" />
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    value={selectedOption}
                    onChange={(value) => {
                      handleOptionChange(value);
                    }}
                    isSearchable={true}
                    options={statusName}
                    placeholder={intl.formatMessage({
                      id: "TITLE.SELECT.STATUS",
                    })}
                    id="status"
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success mr-3 btn-inventory"
            onClick={() => {
              formikEditCategory.handleSubmit();
            }}
            disabled={isSaveLoading}
          >
            <FormattedMessage id="TITLE.SAVE" />

            {isSaveLoading && (
              <Spinner animation="border" size="sm" className="ml-1" />
            )}
          </Button>
          <Button
            type="button"
            variant="tertiary text-dark"
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <FormattedMessage id="TITLE.CANCEL" />
          </Button>
        </Modal.Footer>
      </Modal>
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
