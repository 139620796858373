import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Edit2, Plus, X } from "react-feather";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { AddNewProductModal } from "../../../global-modal/AddNewProductModal ";
import { DeleteModal } from "../../common/modals/DeleteModal";
import DefaultProductView from "./DefaultProductView";
import {
  productListMastersApi,
  getSingleProductDetailsApi,
  editProductApi,
  getPoandDcSummaryApi,
  getLocationListBasedOnProductApi,
  POBasedOnProductListApi,
  DCBasedOnProductListApi,
  deleteBarcodeApi,
} from "../../../axios/services/services";
import { ErrorModal } from "../../common/modals/ErrorModal";
import { SectionLoader } from "../../../helper/SectionLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  capitalizeFirstLetter,
  getErrorMessageBasedOnLanguage,
  setActiveMenu,
  unauthorization,
} from "../../../constants/utils";
import { ButtonLoader } from "../../../helper/ButtonLoader";
import { EditProductModal } from "../edit-product/EditProductModal";
import { SuccessModal } from "../../common/modals/SuccessModal";
import TransactionPOView from "./TransactionPOView";
import LocationProductView from "./LocationProductView";
import TransactionDCView from "./TransactionDCView";
import { FormattedMessage, useIntl } from "react-intl";
import { USER_DETAILS } from "../../../constants/const";
import { MENUITEMS } from "../../../constants/menu";
import { UIContext } from "../../../helper/UIContext";
import { useHistory } from "react-router-dom";
import { AddNewBarcodeModal } from "../../../global-modal/AddNewBarcodeModal";

const initialQueryParams = {
  pageNumber: 1,
  pageSize: 20,
};

const initialQueryParamsPO = {
  pageNumber: 1,
  pageSize: 20,
};

const initialQueryParamsDC = {
  pageNumber: 1,
  pageSize: 20,
};

const initialQueryParamsLoc = {
  pageNumber: 1,
  pageSize: 20,
};

const ViewProductDetails = ({ handlePDFViewValues, ...props }) => {
  const intl = useIntl();
  const uiContext = useContext(UIContext);
  const history = useHistory();
  const [isOpenAddNewProductModal, setOpenAddNewProductModal] = useState(false);
  const [isOpenAddNewBarcodeModal, setOpenAddNewBarcodeModal] = useState(false);
  const [queryParams, setQueryParams] = useState(initialQueryParams);
  const [queryParamsPO, setQueryParamsPO] = useState(initialQueryParamsPO);
  const [queryParamsDC, setQueryParamsDC] = useState(initialQueryParamsDC);
  const [queryParamsLoc, setQueryParamsLoc] = useState(initialQueryParamsLoc);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingProductListView, setLoadingProductListView] = useState(false);
  const [isLoadingPOView, setLoadingPOView] = useState(false);
  const [isLoadingLocView, setLoadingLocView] = useState(false);
  const [isLoadingDCView, setLoadingDCView] = useState(false);
  const [isLoadingEditProduct, setLoadingEditProduct] = useState(false);
  const [isLoadingDeleteBarcode, setLoadingDeleteBarcode] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [productList, setProductList] = useState([]);
  const [isLoadMoreProductListView, setLoadMoreProductListView] =
    useState(false);
  const [isLoadMorePOListView, setLoadMorePOListView] = useState(false);
  const [isLoadMoreDCListView, setLoadMoreDCListView] = useState(false);
  const [isLoadMoreLocListView, setLoadMoreLocListView] = useState(false);
  const { id } = props.match.params;
  const [productDetails, setProductDetails] = useState({});

  const [isUpdateProductListing, setUpdateProductListing] = useState(0);
  const [isUpdateLocationList, setUpdateLocationList] = useState(0);
  const [isUpdateSingleProductDetails, setUpdateSingleProductDetails] =
    useState(0);
  const [
    isUpdatePaginationProductListDetails,
    setUpdatePaginationProductListDetails,
  ] = useState(0);
  const [isUpdatePaginationPOListDetails, setUpdatePaginationPOListDetails] =
    useState(0);
  const [isUpdatePaginationDCListDetails, setUpdatePaginationDCListDetails] =
    useState(0);
  const [isUpdatePaginationLocListDetails, setUpdatePaginationLocListDetails] =
    useState(0);
  const [isOpenEditProductModal, setOpenEditProductModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [POSummary, setPOSummary] = useState([]);
  const [isPOView, setPOView] = useState(false);
  const [POList, setPOList] = useState([]);
  const [DCList, setDCList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [isDeleteBarcodeModalOpen, setIsDeleteBarcodeModalOpen] =
    useState(false);
  const [selectedLocation, setSelectedLocation] = useState(false);

  async function getProductList() {
    const { pageNumber } = queryParams || {};
    if (pageNumber === 1) {
      setLoadingProductListView(true);
    }
    const ProductListResponse = await productListMastersApi({
      queryParams,
    });
    const { Error, data } = ProductListResponse || {};
    if (Error) {
      if (pageNumber === 1) {
        setLoadingProductListView(false);
      }
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    if (pageNumber === 1) {
      setProductList(data);
    } else {
      setProductList((productList) => [...productList, ...data]);
    }

    if (data.length >= 20) {
      setLoadMoreProductListView(true);
    } else {
      setLoadMoreProductListView(false);
    }
    if (pageNumber === 1) {
      setLoadingProductListView(false);
    }
  }

  async function getSingleProductDetails() {
    setLoading(true);
    const singleProductDetails = await getSingleProductDetailsApi({
      id,
    });
    const { Error, data } = singleProductDetails || {};
    if (Error) {
      setLoading(false);
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setProductDetails(data ? data : []);
    setLoading(false);
  }

  async function getPOSummary() {
    setLoading(true);
    const POSummaryResponse = await getPoandDcSummaryApi({
      chosenLabel: "thismonth",
      productId: id,
    });
    const { Error, data } = POSummaryResponse || {};
    if (Error) {
      setLoading(false);
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setPOSummary(data);
    setLoading(false);
  }

  async function getPODetails() {
    if (userRole !== "Admin" && userRole !== "Outward Manager") {
      const productId = id;
      if (POList && POList.length === 0) {
        setLoadingPOView(true);
      }
      const POResponse = await POBasedOnProductListApi({
        queryParams: queryParamsPO,
        productId,
      });
      const { Error, data } = POResponse || {};
      if (Error) {
        if (POList && POList.length === 0) {
          setLoadingPOView(false);
        }
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setPOList((POList) => [...POList, ...data]);
      if (data.length >= 20) {
        setLoadMorePOListView(true);
      } else {
        setLoadMorePOListView(false);
      }
      if (POList && POList.length === 0) {
        setLoadingPOView(false);
      }
    }
  }

  async function getDCDetails() {
    if (userRole === "Outward Manager" || userRole === "Inventory Manager") {
      const productId = id;
      if (DCList && DCList.length === 0) {
        setLoadingDCView(true);
      }
      const POResponse = await DCBasedOnProductListApi({
        queryParams: queryParamsDC,
        productId,
      });
      const { Error, data } = POResponse || {};
      if (Error) {
        if (DCList && DCList.length === 0) {
          setLoadingDCView(false);
        }
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setDCList((DCList) => [...DCList, ...data]);
      if (data.length >= 20) {
        setLoadMoreDCListView(true);
      } else {
        setLoadMoreDCListView(false);
      }
      if (DCList && DCList.length === 0) {
        setLoadingDCView(false);
      }
    }
  }

  async function getLocationDetails() {
    const productId = id;
    if (locationList && locationList.length === 0) {
      setLoadingLocView(true);
    }
    const locationResponse = await getLocationListBasedOnProductApi({
      queryParams: queryParamsLoc,
      productId,
    });
    const { Error, data } = locationResponse || {};
    if (Error) {
      if (locationList && locationList.length === 0) {
        setLoadingLocView(false);
      }
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setLocationList((locationList) => [...locationList, ...data]);
    if (data.length >= 20) {
      setLoadMoreLocListView(true);
    } else {
      setLoadMoreLocListView(false);
    }
    if (locationList && locationList.length === 0) {
      setLoadingLocView(false);
    }
  }

  useEffect(() => {
    getProductList();
  }, [isUpdatePaginationProductListDetails, isUpdateProductListing]);

  useEffect(() => {
    setPOList([]);
    setDCList([]);
    setLocationList([]);
  }, [id]);

  useEffect(() => {
    getSingleProductDetails();
  }, [id, isUpdateSingleProductDetails]);

  useEffect(() => {
    getPOSummary();
  }, [id, isUpdateSingleProductDetails]);

  useEffect(() => {
    getPODetails();
  }, [id, isUpdatePaginationPOListDetails]);

  useEffect(() => {
    getDCDetails();
  }, [id, isUpdatePaginationDCListDetails]);

  useEffect(() => {
    getLocationDetails();
  }, [id, isUpdatePaginationLocListDetails, isUpdateLocationList]);

  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const { role } = userDetails || {};
  const { name: userRole } = role || {};

  const onClickOfPOView = () => {
    setPOView(true);
  };

  const handleSaveOfStatus = async () => {
    enableLoadingEditProduct();
    let status = productDetails.status === 0 ? 1 : 0;
    const editStatus = await editProductApi({
      id,
      status,
    });
    const { Error } = editStatus || {};
    if (Error) {
      disableLoadingEditProduct();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setSuccessMessage(
      `${
        status === 0
          ? intl.formatMessage({
              id: "TITLE.STATUS.MARKED.AS.INACTIVE",
            })
          : intl.formatMessage({
              id: "TITLE.STATUS.MARKED.AS.ACTIVE",
            })
      }`
    );
    onOpenSuccessModal();
    setUpdateSingleProductDetails(Math.random());
    disableLoadingEditProduct();
  };

  const onOpenSuccessModal = () => {
    setOpenSuccessModal(true);
  };

  const onCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const handleCloseOfEditNewProductModal = () => {
    setQueryParams(initialQueryParams);
    setProductList([]);
    setUpdateProductListing(Math.random());
    setUpdateSingleProductDetails(Math.random());
    editProductModalHide();
  };

  const handleCancelOfAddNewBarcode = () => {
    addNewBarcodeModalHide();
  };

  const handleAddNewBarcode = () => {
    addNewBarcodeModalHide();
    setQueryParamsLoc(initialQueryParamsLoc);
    setLocationList([]);
    setUpdateLocationList(Math.random());
    setUpdateSingleProductDetails(Math.random());
  };

  const enableLoadingEditProduct = () => {
    setLoadingEditProduct(true);
  };

  const disableLoadingEditProduct = () => {
    setLoadingEditProduct(false);
  };

  const enableLoadingDeleteBarcode = () => {
    setLoadingDeleteBarcode(true);
  };

  const disableLoadingDeleteBarcode = () => {
    setLoadingDeleteBarcode(false);
  };

  const editProductModalShow = () => {
    setOpenEditProductModal(true);
  };

  const editProductModalHide = () => {
    setOpenEditProductModal(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const addNewProductModalShow = () => {
    setOpenAddNewProductModal(true);
  };

  const addNewProductModalHide = () => {
    setOpenAddNewProductModal(false);
  };

  const addNewBarcodeModalShow = () => {
    setOpenAddNewBarcodeModal(true);
  };

  const addNewBarcodeModalHide = () => {
    setOpenAddNewBarcodeModal(false);
  };

  const handleDeleteBarcodeModalShow = () => {
    setIsDeleteBarcodeModalOpen(true);
  };

  const handleDeleteBarcodeModalHide = () => {
    setIsDeleteBarcodeModalOpen(false);
  };

  const CreateNew = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <FormattedMessage id="TITLE.CREATE.NEW" />
    </Tooltip>
  );

  const editTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <FormattedMessage id="TITLE.EDIT" />
    </Tooltip>
  );

  const [isReceiveviewOpen, setReceiveviewOpen] = useState(false);
  const [isItemReceived, setItemReceived] = useState(false);

  const handleOnClickOfcancle = () => {
    setReceiveviewOpen(false);
  };

  const handlePaginationOfProductListView = () => {
    const { pageNumber, pageSize } = queryParams || {};
    setQueryParams({ pageNumber: pageNumber + 1, pageSize });
    setUpdatePaginationProductListDetails(Math.random());
  };

  const handlePaginationOfPOListView = () => {
    const { pageNumber, pageSize } = queryParamsPO || {};
    setQueryParamsPO({ pageNumber: pageNumber + 1, pageSize });
    setUpdatePaginationPOListDetails(Math.random());
  };

  const handlePaginationOfDCListView = () => {
    const { pageNumber, pageSize } = queryParamsDC || {};
    setQueryParamsDC({ pageNumber: pageNumber + 1, pageSize });
    setUpdatePaginationDCListDetails(Math.random());
  };

  const handlePaginationOfLocListView = () => {
    const { pageNumber, pageSize } = queryParamsLoc || {};
    setQueryParamsLoc({ pageNumber: pageNumber + 1, pageSize });
    setUpdatePaginationLocListDetails(Math.random());
  };

  const handleDeleteOfBarcodeNumber = (location) => {
    setSelectedLocation(location);
    handleDeleteBarcodeModalShow();
  };

  const getSecondaryColorClass = (product) => {
    let className = "fs-14 text-primary font-weight-bold mb-0 cursor-pointer";
    const { id: PID } = product;
    if (PID === id) {
      className = className + " text-secondary";
    }
    return className;
  };

  const renderProductName = (product) => {
    const { name } = product || {};
    return <>{capitalizeFirstLetter(name === null ? "-" : name)}</>;
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  const handleCloseOfAddNewProductModal = () => {
    setProductList([]);
    setQueryParams(initialQueryParams);
    setUpdateProductListing(Math.random());
    addNewProductModalHide();
  };

  const onClickOfDeleteBarcodeDeleteButton = async () => {
    const { id } = selectedLocation || {};
    enableLoadingDeleteBarcode();
    const deleteBarcodeResponse = await deleteBarcodeApi({
      id,
    });
    const { Error } = deleteBarcodeResponse || {};
    if (Error) {
      disableLoadingDeleteBarcode();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }

    setSuccessMessage(
      intl.formatMessage({
        id: "TITLE.PRODUCT.BARCODE.IS.DELETED.SUCCESSFULLY",
      })
    );
    onOpenSuccessModal();
    handleDeleteBarcodeModalHide();
    setLocationList([]);
    setQueryParamsLoc(initialQueryParamsLoc);
    setUpdateLocationList(Math.random());
    setUpdateSingleProductDetails(Math.random());
    disableLoadingDeleteBarcode();
  };

  return (
    <>
      <Card className="p-0 mb-0">
        <div className="details-view d-block d-md-flex">
          {/* Listing */}
          <div className="column list-column border-right d-none d-lg-block">
            <div className="column-header d-flex align-items-center justify-content-between">
              <h2 className="modal-title">
                <FormattedMessage id="TITLE.ALL.PRODUCTS" />
              </h2>
              {(userRole === "Admin" || userRole === "Inventory Manager") && (
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={CreateNew}
                >
                  <Button
                    variant="primary btn-inventory btn-icon"
                    size="sm"
                    onClick={() => {
                      addNewProductModalShow();
                    }}
                  >
                    <Plus size={14} className="text-secondary"></Plus>{" "}
                    <FormattedMessage id="TITLE.NEW" />
                  </Button>
                </OverlayTrigger>
              )}
            </div>
            {(isLoadingProductListView ||
              isLoadingPOView ||
              isLoading ||
              isLoadingLocView ||
              isLoadingDCView) && <SectionLoader />}
            <div id="scrollableDiva" className="column-body custom-scrollbar">
              {!isLoadingProductListView && (
                <InfiniteScroll
                  scrollableTarget="scrollableDiva"
                  dataLength={productList.length}
                  next={() => {
                    handlePaginationOfProductListView();
                  }}
                  hasMore={isLoadMoreProductListView}
                  loader={
                    <h4 style={{ textAlign: "center" }} className="p-1">
                      <FormattedMessage id="TITLE.LOADING" />
                      ...
                    </h4>
                  }
                >
                  {productList.map((product, index) => (
                    <div
                      className="column-box cursor-pointer"
                      key={index}
                      onClick={() => {
                        if (id !== product.id) {
                          setPOSummary([]);
                          setPOList([]);
                          setDCList([]);
                          setLocationList([]);
                          setQueryParamsPO(initialQueryParamsPO);
                          setQueryParamsDC(initialQueryParamsDC);
                          setQueryParamsLoc(initialQueryParamsLoc);
                        }
                        const path = `/inventoryproducts/view-inventoryproduct-details/${product.id}`;
                        onClickChangeRoute(path);
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column">
                          <h5 className={getSecondaryColorClass(product)}>
                            {renderProductName(product)}
                          </h5>
                        </div>
                      </div>
                    </div>
                  ))}
                </InfiniteScroll>
              )}
            </div>{" "}
          </div>
          {/* Details */}

          {!isReceiveviewOpen && (
            <div className="column details-column">
              {isLoading && <SectionLoader />}

              <div className="column-header d-flex align-items-center justify-content-between">
                <h2 className="modal-title">
                  {capitalizeFirstLetter(productDetails?.name)}
                </h2>
                <div className="d-flex">
                  {userRole !== "Inward Manager" &&
                    userRole !== "Outward Manager" && (
                      <ButtonGroup className="mr-2" aria-label="First group">
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={editTooltip}
                        >
                          <Button
                            variant="outline-tertiary btn-sm btn-icon"
                            onClick={() => editProductModalShow()}
                          >
                            <Edit2 size={16} className="p-0 m-0"></Edit2>
                          </Button>
                        </OverlayTrigger>
                      </ButtonGroup>
                    )}
                  {userRole !== "Inward Manager" &&
                    userRole !== "Outward Manager" && (
                      <Button
                        variant="success btn-sm btn-icon mr-2"
                        onClick={() => addNewBarcodeModalShow()}
                      >
                        <FormattedMessage id="TITLE.ADJUST.STOCK" />
                      </Button>
                    )}
                  {userRole !== "Inward Manager" &&
                    userRole !== "Outward Manager" && (
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="dropdown-sm"
                          id="dropdown-basic"
                        >
                          <FormattedMessage id="TITLE.MORE" />
                          {isLoadingEditProduct && (
                            <ButtonLoader className="mr-1 ml-1" />
                          )}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              handleSaveOfStatus();
                            }}
                            disabled={isLoadingEditProduct}
                          >
                            {productDetails?.status === 1 ? (
                              <FormattedMessage id="TITLE.MARK.AS.INACTIVE" />
                            ) : (
                              <FormattedMessage id="TITLE.MARK.AS.ACTIVE" />
                            )}
                            {isLoadingEditProduct && (
                              <ButtonLoader className="mr-1 ml-1" />
                            )}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}

                  <Button
                    variant="close btn-icon btn-sm"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      const path = "/inventoryproducts/products";
                      onClickChangeRoute(path);
                    }}
                  >
                    <X size={20} className="p-0 m-0 text-dark"></X>
                  </Button>
                </div>
              </div>
              <div
                id="scrollabledivOverview"
                className="column-body custom-scrollbar"
              >
                <Tabs>
                  <TabList className="nav nav-tabs tab-coupon mb-0">
                    <Tab className="nav-link">
                      <FormattedMessage id="TITLE.OVERVIEW" />
                    </Tab>
                    {userRole !== "Admin" && (
                      <Tab className="nav-link">
                        <FormattedMessage id="TITLE.TRANSACTIONS" />
                      </Tab>
                    )}
                    <Tab className="nav-link">
                      <FormattedMessage id="TITLE.LOCATIONS" />
                    </Tab>
                  </TabList>
                  <TabPanel>
                    <DefaultProductView
                      productDetails={productDetails}
                      id={id}
                      POSummary={POSummary}
                    />
                  </TabPanel>
                  {userRole !== "Admin" && (
                    <TabPanel>
                      <div className="highlighted-row">
                        <div className="d-flex align-items-center justify-content-between">
                          {!isItemReceived && (
                            <div className="">
                              <div className="d-flex justify-content-start">
                                {userRole !== "Outward Manager" && (
                                  <Button
                                    // variant='secondary btn-icon'
                                    variant={`${
                                      isPOView
                                        ? "secondary btn-icon ml-2"
                                        : "tertiary btn-icon ml-2"
                                    }`}
                                    onClick={() => onClickOfPOView()}
                                  >
                                    <FormattedMessage id="TITLE.PURCHASE.ORDERS" />
                                  </Button>
                                )}
                                {userRole !== "Inward Manager" && (
                                  <Button
                                    // variant="tertiary btn-icon ml-2"
                                    variant={`${
                                      isPOView
                                        ? "tertiary btn-icon ml-2"
                                        : "secondary btn-icon ml-2"
                                    }`}
                                    onClick={() => setPOView(false)}
                                  >
                                    <FormattedMessage id="TITLE.DELIVERY.CHALLAN" />
                                  </Button>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {isPOView ? (
                        <TransactionPOView
                          POList={POList}
                          isLoadingPOView={isLoadingPOView}
                          handlePaginationOfPOListView={
                            handlePaginationOfPOListView
                          }
                          isLoadMorePOListView={isLoadMorePOListView}
                        />
                      ) : (
                        <TransactionDCView
                          DCList={DCList}
                          isLoadingDCView={isLoadingDCView}
                          handlePaginationOfDCListView={
                            handlePaginationOfDCListView
                          }
                          isLoadMoreDCListView={isLoadMoreDCListView}
                        />
                      )}
                    </TabPanel>
                  )}

                  <TabPanel>
                    <LocationProductView
                      locationList={locationList}
                      isLoadingLocView={isLoadingLocView}
                      isLoadMoreLocListView={isLoadMoreLocListView}
                      handlePaginationOfLocListView={
                        handlePaginationOfLocListView
                      }
                      handleDeleteOfBarcodeNumber={handleDeleteOfBarcodeNumber}
                    />
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          )}
        </div>
      </Card>

      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}

      {isDeleteBarcodeModalOpen && (
        <DeleteModal
          isLoading={isLoadingDeleteBarcode}
          title={`${intl.formatMessage({
            id: "TITLE.DELETE.PRODUCT.BARCODE",
          })}?`}
          description={intl.formatMessage({
            id: "TITLE.PRODUCT.BARCODE.WILL.BE.DELETED.FOREVER.AND.CANNOT.BE.RETRIEVED.LATER",
          })}
          id={selectedLocation.barcode_no}
          isOpen={isDeleteBarcodeModalOpen}
          onCloseModal={handleDeleteBarcodeModalHide}
          onClickOfDeleteButton={onClickOfDeleteBarcodeDeleteButton}
        />
      )}

      {isOpenAddNewProductModal && (
        <AddNewProductModal
          open={isOpenAddNewProductModal}
          onCloseModal={addNewProductModalHide}
          handleCloseOfAddNewProductModal={handleCloseOfAddNewProductModal}
        />
      )}

      {isOpenEditProductModal && (
        <EditProductModal
          open={isOpenEditProductModal}
          data={productDetails}
          onCloseModal={editProductModalHide}
          id={id}
          handleCloseOfEditNewProductModal={handleCloseOfEditNewProductModal}
        />
      )}

      {isOpenAddNewBarcodeModal && (
        <AddNewBarcodeModal
          onCloseModal={addNewBarcodeModalHide}
          open={isOpenAddNewBarcodeModal}
          product={productDetails}
          handleCancelOfAddNewBarcode={handleCancelOfAddNewBarcode}
          handleAddNewBarcode={handleAddNewBarcode}
        />
      )}

      {openSuccessModal && (
        <SuccessModal
          message={successMessage}
          open={openSuccessModal}
          onCloseModal={onCloseSuccessModal}
        />
      )}
    </>
  );
};

export default ViewProductDetails;
