/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
const DCNumberHyperLinkColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { redirectToViewPage }
) => {
  const { purchaseorder_number } = row;

  return (
    <>
      <div
        className="text-primary btn btn-link p-0 cursor-pointer"
        onClick={() => {
          redirectToViewPage(row);
        }}
      >
        {purchaseorder_number}
      </div>
      <> </>
    </>
  );
};

export default DCNumberHyperLinkColumnFormatter;
