/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
const VendorNameColumnWithoutLinkFormatter = (cellContent, row, rowIndex) => {
  const { contact } = row;
  const { contact_name = "-" } = contact || {};

  return (
    <>
      <div className="p-0">{contact_name}</div>
      <> </>
    </>
  );
};

export default VendorNameColumnWithoutLinkFormatter;
