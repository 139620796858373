/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
const UserNameColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { redirectToViewPage }
) => {
  const { full_name = "-" } = row;

  return (
    <>
      <div
        className="text-primary btn-link p-0 cursor-pointer"
        onClick={() => redirectToViewPage(row)}
      >
        {full_name}
      </div>
      <> </>
    </>
  );
};

export default UserNameColumnFormatter;
