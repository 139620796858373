export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  // { text: "20", value: 20 },
  // { text: "50", value: 50 },
];
export const initialFilter = {
  filter: {},
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10,
};

export const statusName = [
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
];

export const stateList = [
  { value: "Dubai", label: "Dubai" },
  { value: "Abu Dhabi", label: "Abu Dhabi" },
  { value: "Sharjah", label: "Sharjah" },
  { value: "Ajman", label: "Ajman" },
  { value: "Ras Al Khaimah", label: "Ras Al Khaimah" },
  { value: "Fujairah", label: "Fujairah" },
  { value: "Umm al-Quwain", label: "Umm al-Quwain" },
];
