// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as uiHelpers from "../OutwardProductUIHelpers";
import { useOutwardProductUIContext } from "../OutwardProductUIContext";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
} from "../../../../helper/helper";
import { Pagination } from "../../../../pagination/Pagination";
import AmountColumnFormatter from "../../../../columnformatter/AmountColumnFormatter";
import {
  getTotalQuantityFromOutwardByProduct,
  getTotalTotalFromOutwardByProduct,
} from "../../../../constants/utils";
import { useIntl } from "react-intl";

export function OutwardProductTable({
  isLoading,
  reportOutwardByProduct,
  reportOutwardByProductTotalCount,
  reportOutwardByProductTotalAmount,
  reportOutwardByProductTotalQuantity,
}) {
  // OutwardProduct UI Context
  const intl = useIntl();
  const outwardproductUIContext = useOutwardProductUIContext();
  const outwardproductUIProps = useMemo(() => {
    return {
      ids: outwardproductUIContext.ids,
      setIds: outwardproductUIContext.setIds,
      queryParams: outwardproductUIContext.queryParams,
      setQueryParams: outwardproductUIContext.setQueryParams,
      openViewDialog: outwardproductUIContext.openViewDialog,
      openEditDialog: outwardproductUIContext.openEditDialog,
      openDeleteDialog: outwardproductUIContext.openDeleteDialog,
    };
  }, [outwardproductUIContext]);

  const totalQuantity = getTotalQuantityFromOutwardByProduct(
    reportOutwardByProduct
  );

  const totalAmount = getTotalTotalFromOutwardByProduct(reportOutwardByProduct);

  // Table columns
  const columns = [
    {
      dataField: "name",
      text: intl.formatMessage({
        id: "TITLE.PRODUCT.NAME",
      }),
      ...(reportOutwardByProductTotalCount &&
        reportOutwardByProductTotalCount > 0 && {
          footer: intl.formatMessage({
            id: "TITLE.TOTAL",
          }),
        }),
      style: {
        minWidth: "100px",
      },
    },
    {
      dataField: "stock_keeping_unit",
      text: intl.formatMessage({
        id: "TITLE.SKU",
      }),
      ...(reportOutwardByProductTotalCount &&
        reportOutwardByProductTotalCount > 0 && {
          footer: "-",
        }),
      style: {
        minWidth: "130px",
      },
    },
    {
      dataField: "count",
      text: intl.formatMessage({
        id: "TITLE.QUANTITY.DELIVERED",
      }),
      ...(reportOutwardByProductTotalCount &&
        reportOutwardByProductTotalCount > 0 && {
          footer: "" + reportOutwardByProductTotalQuantity,
        }),
      style: {
        minWidth: "130px",
      },
    },
    {
      dataField: "amount",
      text: intl.formatMessage({
        id: "TITLE.AMOUNT",
      }),
      ...(reportOutwardByProductTotalCount &&
        reportOutwardByProductTotalCount > 0 && {
          footer: new Intl.NumberFormat("en-EN", {
            style: "currency",
            currency: "AED",
          }).format(reportOutwardByProductTotalAmount),
        }),
      style: {
        minWidth: "150px",
      },
      formatter: AmountColumnFormatter,
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: reportOutwardByProductTotalCount
      ? reportOutwardByProductTotalCount
      : 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: outwardproductUIProps.queryParams.pageSize,
    onPageChange: (page) => {
      if (page === 0) return;
    },
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-bordered table-vertical-center inward-table table-inventory"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={reportOutwardByProduct}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  outwardproductUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
              <PleaseWaitMessage isLoading={isLoading} />
              <NoRecordsFoundMessage
                entities={reportOutwardByProduct}
                isLoading={isLoading}
              />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
