import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import { X, Check, File } from "react-feather";
import ButtonAtl from "@atlaskit/button";
import CreatableSelect from "react-select/creatable";
import { AddNewCategoryModal } from "../components/category-module/add-new-category/AddNewCategoryModal";
import { useFormik } from "formik";
import { AddProductSchema } from "../helper/FormikSchema";
import {
  addProductApi,
  productUnitListApi,
  uploadDocumentApi,
} from "../axios/services/services";
import { ButtonLoader } from "../helper/ButtonLoader";
import { categoryListApi } from "../axios/services/services";
import { ErrorModal } from "../components/common/modals/ErrorModal";
import {
  ChevronDownMaster,
  DropdownMaster,
  DropdownIndicatorMaster,
} from "../helper/helper";
import { useDropzone } from "react-dropzone";
import { ThumbnailWrapper } from "../helper/ThumbnailWrapper";
import { ContentLoader } from "../helper/ContentLoader";
import { FILE_EXTENTION_IMAGE, MB_5, SET_TIMEOUT } from "../constants/const";
import { FormattedMessage, useIntl } from "react-intl";
import {
  capitalizeFirstLetter,
  getErrorMessageBasedOnLanguage,
} from "../constants/utils";
import { unauthorization } from "../constants/utils";
import { AddNewUnitModal } from "./AddNewUnitModal";

export function AddNewProductModal({
  searchedProductName,
  categoryId,
  onCloseModal,
  open,
  handleCloseOfAddNewProductModal,
}) {
  let AddProductInitialValues = {
    name: searchedProductName,
    categoryId: "",
    stock_keeping_unit: "",
    opening_stock: 0,
    reorder_level: "",
    price: "",
    shelf_life: "",
    product_media: "",
    productunitId: "",
    description: "",
  };
  const intl = useIntl();
  const [isOpen, setOpen] = useState(false);
  const [isOpenProductUnitDropdown, setOpenProductUnitDropdown] =
    useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchedCategoryName, setSearchedCategoryName] = useState("");
  const [searchedProductUnitName, setSearchedProductUnitName] = useState("");
  const [selectedProductUnit, setSelectedProductUnit] = useState(null);
  const [newlyAddedCategory, setNewlyAddedCategory] = useState(null);
  const [newlyAddedProductUnit, setNewlyAddedProductUnit] = useState(null);
  const [isOpenAddNewCategoryModal, setOpenAddNewCategoryModal] =
    useState(false);
  const [isOpenAddNewProductUnitModal, setOpenAddNewProductUnitModal] =
    useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isNewProductAdded, setNewProductAdded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [productUnitList, setProductUnitList] = useState([]);
  const [isUpdateCategoryList, setUpdateCategoryList] = useState(0);
  const [isUpdateProductUnitList, setUpdateProductUnitList] = useState(0);
  const [uploadDocument, setUploadDocument] = useState([]);
  const [files, setFiles] = useState([]);
  const [isFileUploadLoading, setFileUploadLoading] = useState(false);

  async function getCategoryList() {
    const status = 1;
    const categoryListResponse = await categoryListApi({ status });
    const { data, Error } = categoryListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    const categories = data.map((category, index) => {
      category.value = category.id;
      category.label = category.name;
      if (categoryId && categoryId === category.id) {
        setSelectedCategory(category);
        formikAddProduct.setFieldValue("categoryId", category.id);
      }
      if (newlyAddedCategory) {
        const { created_data } = newlyAddedCategory || {};
        const { id } = created_data || {};
        if (id === category.id) {
          setSelectedCategory(category);
          formikAddProduct.setFieldValue("categoryId", id);
        }
      }
      return category;
    });
    setCategoryList(categories);
  }

  async function getProductUnitList() {
    const productUnitListResponse = await productUnitListApi();
    const { Error, data } = productUnitListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    const productUnits = data.map((productUnit, index) => {
      productUnit.value = productUnit.id;
      productUnit.label = productUnit.name;
      if(newlyAddedProductUnit){
        const { created_data } = newlyAddedProductUnit || {};
        const { id } = created_data || {};
        if (id === productUnit.id) {
          setSelectedProductUnit(productUnit);
          formikAddProduct.setFieldValue("productunitId", id);
        }
      }
      return productUnit;
    });
    setProductUnitList(productUnits);
  }

  useEffect(() => {
    getCategoryList();
  }, [categoryId, isUpdateCategoryList]);

  useEffect(() => {
    getProductUnitList();
  }, [isUpdateProductUnitList]);

  const enableLoadingFileUpload = () => {
    setFileUploadLoading(true);
  };

  const disableLoadingFileUpload = () => {
    setFileUploadLoading(false);
  };

  const attachDocument = async (acceptedFiles) => {
    const uploadedFilesArray = [];
    const maxLengthForDocUpload = 5 - files.length;
    enableLoadingFileUpload();
    for (let index = 0; index < acceptedFiles.length; index++) {
      if (maxLengthForDocUpload === index) {
        break;
      }
      if (MB_5 <= acceptedFiles[index].size) {
        setErrorMessage(
          intl.formatMessage({ id: "TITLE.ATTACHED.FILE.IS.TOO.LARGE" })
        );
        handleErrorModalShow();
        continue;
      }
      let files = {
        file: acceptedFiles[index],
        containername: "product",
      };
      const uploadDocumentResponse = await uploadDocumentApi(files);
      const [uploadDoc] = uploadDocumentResponse || {};
      formikAddProduct.setFieldValue("product_media", uploadDoc?.filename);
      uploadedFilesArray.push(uploadDoc);
    }
    disableLoadingFileUpload();
    const filesTemp = [...files, ...uploadedFilesArray];
    setFiles(filesTemp);
    setUploadDocument([...uploadDocument, ...uploadedFilesArray]);
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length === 0) {
      setErrorMessage(
        intl.formatMessage({
          id: "TITLE.UPLOAD.JPEG.JPG.PNG.FILES.ONLY.OR.UPLOAD.IMAGE.LESS.THAN.5.MB",
        })
      );
      handleErrorModalShow();
      return;
    }
    attachDocument(acceptedFiles);
  });

  const { getRootProps, getInputProps } = useDropzone({
    disabled: files.length >= 1,
    accept: FILE_EXTENTION_IMAGE,
    maxFiles: 1,
    maxSize: MB_5,
    maxFilesize: MB_5,
    uploadMultiple: false,
    multiple: false,
    // parallelUploads: 10,
    autoProcessQueue: true,
    onDrop,
  });

  const handleOnDeleteOfAttachment = (file, index) => {
    setFiles([]);
    setUploadDocument([]);
    formikAddProduct.setFieldValue("product_media", "");
  };

  const toggleOpen = () => {
    setOpen(!isOpen);
  };

  const toggleOpenProductUnitDropdown = () => {
    setOpenProductUnitDropdown(!isOpenProductUnitDropdown);
  };

  const onSelectChange = (e) => {
    toggleOpen();
    if (e) {
      setSelectedCategory(e);
      formikAddProduct.setFieldValue("categoryId", e.value);
    } else {
      setSelectedCategory(null);
      formikAddProduct.setFieldValue("categoryId");
    }
  };

  const onSelectChangeOfProductUnit = (e) => {
    toggleOpenProductUnitDropdown();
    if (e) {
      setSelectedProductUnit(e);
      formikAddProduct.setFieldValue("productunitId", e.value);
    } else {
      setSelectedProductUnit(null);
      formikAddProduct.setFieldValue("productunitId", "");
    }
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const formikAddProduct = useFormik({
    initialValues: AddProductInitialValues,
    validationSchema: AddProductSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      addProduct(values, setSubmitting);
    },
    onReset: (values, { resetForm }) => {},
  });

  const addProduct = async (values, setSubmitting) => {
    enableLoading();
    const {
      name,
      categoryId,
      stock_keeping_unit,
      opening_stock,
      reorder_level,
      price,
      shelf_life,
      productunitId,
      description,
    } = values || {};
    const docs = [];
    if (uploadDocument && uploadDocument.length > 0) {
      uploadDocument.map((doc) => {
        const { filename, mimetype } = doc || {};
        docs.push({ file_name: filename, file_type: mimetype });
        return doc;
      });
    }

    let addProductObj = {
      name,
      categoryId: categoryId,
      opening_stock,
      price,
      productunitId,
      product_media: docs,
      description,
      ...(stock_keeping_unit === ""
        ? { stock_keeping_unit: null }
        : { stock_keeping_unit }),
      ...(reorder_level === "" ? { reorder_level: null } : { reorder_level }),
      ...(shelf_life === "" ? { shelf_life: null } : { shelf_life }),
    };
    const addCustomerResponse = await addProductApi(addProductObj);
    const { data, Error } = addCustomerResponse || {};
    if (Error) {
      disableLoading();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setNewProductAdded(true);
    window.scrollTo(0, 0);
    setTimeout(() => {
      disableLoading();
      handleCloseOfAddNewProductModal(data);
    }, SET_TIMEOUT);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const handleInputChange = (event) => {};

  const handleInputChangeProductUnit = (event) => {};

  const formatCreateLabelProductUnit = (inputValue) => {
    setSearchedProductUnitName(capitalizeFirstLetter(inputValue));
    return (
      "+" +
      intl.formatMessage({
        id: "TITLE.ADD.NEW.PRODUCT.UNIT",
      })
    );
  };

  const formatCreateLabel = (inputValue) => {
    setSearchedCategoryName(capitalizeFirstLetter(inputValue));
    return (
      "+" +
      intl.formatMessage({
        id: "TITLE.ADD.NEW.CATEGORY",
      })
    );
  };

  const addNewCategoryModalShow = () => {
    setOpenAddNewCategoryModal(true);
  };

  const addNewCategoryModalHide = () => {
    setOpenAddNewCategoryModal(false);
  };

  const addNewProductUnitModalShow = () => {
    setOpenAddNewProductUnitModal(true);
  };

  const addNewProductUnitModalHide = () => {
    setOpenAddNewProductUnitModal(false);
  };

  const handleCloseOfAddNewCategoryModal = (data) => {
    setNewlyAddedCategory(data);
    setCategoryList([]);
    setUpdateCategoryList(Math.random());
    addNewCategoryModalHide();
  };

  const handleCloseOfAddNewProductUnitModal = (data) => {
    setNewlyAddedProductUnit(data)
    setProductUnitList([]);
    setUpdateProductUnitList(Math.random());
    addNewProductUnitModalHide();
  };

  const renderCategoryList = (categoryList) => {
    const options = [];
    categoryList &&
      categoryList.map((products, index) => {
        const { name, id } = products || "-";
        options.push({
          value: id,
          label: name,
        });
      });
    return options;
  };

  return (
    <>
      <Modal
        size="lg"
        show={open}
        onHide={onCloseModal}
        aria-labelledby="example-modal-sizes-title-lg"
        dialogClassName="addvendor-modal modal"
        scrollable={true}
      >
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <h3 className="modal-title" id="exampleModalLabel2">
            <FormattedMessage id="TITLE.ADD.NEW.PRODUCT" />
          </h3>
          <Button
            variant="link p-0 btn-icon"
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <X size={20} className="text-dark mr-0 pr-0" />
          </Button>
        </Modal.Header>
        <Modal.Body className="pb-0">
          {isNewProductAdded && (
            <div className="notification icon inline success fs-16">
              <Check size={8} className="text-white"></Check>
              <FormattedMessage id="TITLE.NEW.PRODUCT.HAS.BEEN.SUCCESSFULLY.CREATED" />
            </div>
          )}
          <Form autoComplete="off">
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="productName">
                    <FormattedMessage id="TITLE.PRODUCT.NAME" />
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="name"
                    name="name"
                    placeholder={intl.formatMessage({
                      id: "TITLE.PRODUCT.NAME",
                    })}
                    {...formikAddProduct.getFieldProps("name")}
                  />
                  {formikAddProduct.touched.name &&
                  formikAddProduct.errors.name ? (
                    <div className="text-danger">
                      {formikAddProduct.errors.name}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="category">
                    <FormattedMessage id="TITLE.SELECT.CATEGORY" />
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <DropdownMaster
                    isOpen={isOpen}
                    onClose={toggleOpen}
                    target={
                      <ButtonAtl
                        iconAfter={<ChevronDownMaster />}
                        onClick={toggleOpen}
                        isSelected={isOpen}
                        className="form-control w-100 bg-white custom-select-btn mb-0"
                      >
                        {selectedCategory ? (
                          `${selectedCategory.label}`
                        ) : (
                          <FormattedMessage id="TITLE.SELECT.CATEGORY" />
                        )}
                      </ButtonAtl>
                    }
                  >
                    <CreatableSelect
                      onChange={(e) => onSelectChange(e)}
                      onInputChange={handleInputChange}
                      options={renderCategoryList(categoryList)}
                      createOptionPosition="top"
                      formatCreateLabel={formatCreateLabel}
                      isSearchable
                      onCreateOption={() => {
                        addNewCategoryModalShow();
                        setOpen(false);
                      }}
                      autoFocus
                      backspaceRemovesValue={true}
                      components={{
                        DropdownIndicatorMaster,
                        IndicatorSeparator: null,
                      }}
                      controlShouldRenderValue={true}
                      hideSelectedOptions={false}
                      isClearable={true}
                      menuIsOpen
                      placeholder={intl.formatMessage({
                        id: "TITLE.SELECT.CATEGORY",
                      })}
                      tabSelectsValue={true}
                      value={selectedCategory}
                      className="react-custom-select-container"
                      classNamePrefix="react-select"
                    />
                  </DropdownMaster>
                  {formikAddProduct.touched.categoryId &&
                  formikAddProduct.errors.categoryId ? (
                    <div className="text-danger">
                      {formikAddProduct.errors.categoryId}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="sku">
                    <FormattedMessage id="TITLE.SKU" />
                    {/* <span className="text-danger">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="stock_keeping_unit"
                    name="stock_keeping_unit"
                    placeholder={intl.formatMessage({ id: "TITLE.SKU" })}
                    {...formikAddProduct.getFieldProps("stock_keeping_unit")}
                    autoComplete="off"
                  />
                  {formikAddProduct.touched.stock_keeping_unit &&
                  formikAddProduct.errors.stock_keeping_unit ? (
                    <div className="text-danger">
                      {formikAddProduct.errors.stock_keeping_unit}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="productStock">
                    <FormattedMessage id="TITLE.OPENING.STOCK" />
                  </Form.Label>
                  <Form.Control
                    type="number"
                    id="opening_stock"
                    name="opening_stock"
                    placeholder={intl.formatMessage({
                      id: "TITLE.OPENING.STOCK",
                    })}
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    disabled={true}
                    {...formikAddProduct.getFieldProps("opening_stock")}
                  />
                  {formikAddProduct.touched.opening_stock &&
                  formikAddProduct.errors.opening_stock ? (
                    <div className="text-danger">
                      {formikAddProduct.errors.opening_stock}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="reorderPoint">
                    <FormattedMessage id="TITLE.REORDER.LEVEL" />
                    {/* <span className="text-danger">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    id="reorder_level"
                    name="reorder_level"
                    placeholder={intl.formatMessage({
                      id: "TITLE.REORDER.LEVEL",
                    })}
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    onPaste={(evt) => {
                      const values = evt.clipboardData.getData("text");
                      const nonAlpha = values.replace(/[^\d.-]/g, "");
                      evt.preventDefault();
                      formikAddProduct.setFieldValue("reorder_level", nonAlpha);
                    }}
                    {...formikAddProduct.getFieldProps("reorder_level")}
                  />
                  {formikAddProduct.touched.reorder_level &&
                  formikAddProduct.errors.reorder_level ? (
                    <div className="text-danger">
                      {formikAddProduct.errors.reorder_level}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="price">
                    <FormattedMessage id="TITLE.PRICE" />
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    id="price"
                    name="price"
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    onPaste={(evt) => {
                      const values = evt.clipboardData.getData("text");
                      const nonAlpha = values.replace(/[^\d.-]/g, "");
                      evt.preventDefault();
                      formikAddProduct.setFieldValue("price", nonAlpha);
                    }}
                    placeholder={intl.formatMessage({ id: "TITLE.PRICE" })}
                    {...formikAddProduct.getFieldProps("price")}
                  />
                  {formikAddProduct.touched.price &&
                  formikAddProduct.errors.price ? (
                    <div className="text-danger">
                      {formikAddProduct.errors.price}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="shelflife">
                    <FormattedMessage id="TITLE.SHELF.LIFE.DAYS" />
                    {/* <span className="text-danger">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    id="shelflife"
                    name="shelf_life"
                    placeholder={intl.formatMessage({
                      id: "TITLE.SHELF.LIFE",
                    })}
                    min={1}
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    onPaste={(evt) => {
                      const values = evt.clipboardData.getData("text");
                      const nonAlpha = values.replace(/[^\d.-]/g, "");
                      evt.preventDefault();
                      formikAddProduct.setFieldValue("shelf_life", nonAlpha);
                    }}
                    {...formikAddProduct.getFieldProps("shelf_life")}
                  />
                  {formikAddProduct.touched.shelf_life &&
                  formikAddProduct.errors.shelf_life ? (
                    <div className="text-danger">
                      {formikAddProduct.errors.shelf_life}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="category">
                    <FormattedMessage id="TITLE.SELECT.PRODUCT.UNIT" />
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <DropdownMaster
                    isOpen={isOpenProductUnitDropdown}
                    onClose={toggleOpenProductUnitDropdown}
                    target={
                      <ButtonAtl
                        iconAfter={<ChevronDownMaster />}
                        onClick={toggleOpenProductUnitDropdown}
                        isSelected={isOpenProductUnitDropdown}
                        className="form-control w-100 bg-white custom-select-btn mb-0"
                      >
                        {selectedProductUnit ? (
                          `${selectedProductUnit.label}`
                        ) : (
                          <FormattedMessage id="TITLE.SELECT.PRODUCT.UNIT" />
                        )}
                      </ButtonAtl>
                    }
                  >
                    <CreatableSelect
                      onChange={(e) => onSelectChangeOfProductUnit(e)}
                      onInputChange={handleInputChangeProductUnit}
                      options={productUnitList}
                      createOptionPosition="top"
                      formatCreateLabel={formatCreateLabelProductUnit}
                      isSearchable
                      onCreateOption={() => {
                        addNewProductUnitModalShow();
                        setOpenProductUnitDropdown(false);
                      }}
                      // autoFocus
                      backspaceRemovesValue={true}
                      components={{
                        DropdownIndicatorMaster,
                        IndicatorSeparator: null,
                      }}
                      controlShouldRenderValue={true}
                      hideSelectedOptions={false}
                      isClearable={true}
                      menuIsOpen
                      placeholder={intl.formatMessage({
                        id: "TITLE.PRODUCT.UNITS",
                      })}
                      // styles={selectStyles}
                      tabSelectsValue={true}
                      value={selectedProductUnit}
                      className="react-custom-select-container"
                      classNamePrefix="react-select"
                    />
                  </DropdownMaster>
                  {formikAddProduct.touched.productunitId &&
                  formikAddProduct.errors.productunitId ? (
                    <div className="text-danger">
                      {formikAddProduct.errors.productunitId}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <div className="d-flex align-items-center justify-content-between">
                    <Form.Label htmlFor="attach-qutation">
                      <FormattedMessage id="TITLE.ATTACH.IMAGE" />
                      <span className="text-danger ml-1 mr-1">
                        <FormattedMessage id="TITLE.UPLOAD.JPEG.JPG.PNG.FILES.ONLY" />
                      </span>
                    </Form.Label>
                    <span className="small-text">
                      <FormattedMessage id="TITLE.MAXIMUM.SIZE" />: 5MB
                    </span>
                  </div>
                  <section>
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      <File
                        size={32}
                        className="text-dark"
                        style={{ strokeWidth: "1.5px" }}
                      ></File>
                      <p style={{ fontSize: "13px" }}>
                        <FormattedMessage id="TITLE.DRAG.DROP.IMAGES.HERE.OR.CLICK.THE.ICON" />
                      </p>
                    </div>
                    {isFileUploadLoading && <ContentLoader />}
                    <ThumbnailWrapper
                      files={files}
                      handleOnDeleteOfAttachment={handleOnDeleteOfAttachment}
                    />
                  </section>
                  {formikAddProduct.touched.product_media &&
                  formikAddProduct.errors.product_media ? (
                    <div className="text-danger">
                      {formikAddProduct.errors.product_media}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="description">
                    <FormattedMessage id="TITLE.DESCRIPTION" />
                  </Form.Label>
                  <Form.Control
                    id="description"
                    name="description"
                    as="textarea"
                    placeholder={intl.formatMessage({
                      id: "TITLE.DESCRIPTION",
                    })}
                    className="form-control"
                    resize="none"
                    {...formikAddProduct.getFieldProps("description")}
                  />
                  {formikAddProduct.touched.description &&
                  formikAddProduct.errors.description ? (
                    <div className="text-danger">
                      {formikAddProduct.errors.description}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            variant="success"
            onClick={() => {
              formikAddProduct.handleSubmit();
            }}
            disabled={isLoading}
          >
            <FormattedMessage id="TITLE.SAVE" />
            {isLoading && <ButtonLoader className="mr-1 ml-1" />}
          </Button>
          <Button
            type="button"
            variant="tertiary text-dark"
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <FormattedMessage id="TITLE.CANCEL" />
          </Button>
        </Modal.Footer>
      </Modal>
      {isOpenAddNewCategoryModal && (
        <AddNewCategoryModal
          searchedCategoryName={searchedCategoryName}
          open={isOpenAddNewCategoryModal}
          onCloseModal={addNewCategoryModalHide}
          handleCloseOfAddNewCategoryModal={handleCloseOfAddNewCategoryModal}
        />
      )}

      {isOpenAddNewProductUnitModal && (
        <AddNewUnitModal
          searchedProductUnitName={searchedProductUnitName}
          open={isOpenAddNewProductUnitModal}
          onCloseModal={addNewProductUnitModalHide}
          handleCloseOfAddNewProductUnitModal={
            handleCloseOfAddNewProductUnitModal
          }
        />
      )}

      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
