import React, { createContext, useContext, useCallback } from "react";
import { isEqual, isFunction } from "lodash";

const LowStockUIContext = createContext();

export function useLowStockUIContext() {
  return useContext(LowStockUIContext);
}

export const LowStockUIConsumer = LowStockUIContext.Consumer;

export function LowStockUIProvider({
  lowstockUIEvents,
  children,
  queryParams,
  setQueryParamsBase,
  ids,
  setIds,
}) {
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    openViewDialog: lowstockUIEvents.openViewDialog,
    openEditDialog: lowstockUIEvents.openEditDialog,
    openDeleteDialog: lowstockUIEvents.openDeleteDialog,
  };

  return (
    <LowStockUIContext.Provider value={value}>
      {children}
    </LowStockUIContext.Provider>
  );
}
