// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as uiHelpers from "../CountryUIHelpers";
import { useCountryUIContext } from "../CountryUIContext";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../helper/helper";
import { Pagination } from "../../../pagination/Pagination";
import StatusTypeFormatter from "../../../columnformatter/StatusTypeFormatter";
import ActionsColumnFormatter from "../../../columnformatter/ActionsColumnFormatter";
import HyperLinkColumnFormatter from "../../../columnformatter/HyperLinkColumnFormatter";
import StatusActiveDeactiveTypeFormatter from "../../../columnformatter/StatusActiveDeactiveTypeFormatter";
import IdHyperLinkColumnFormatter from "../../../columnformatter/IdHyperLinkColumnFormatter";

export function CountryTable({
  isLoading,
  countryList,
  countryListTotalCount,
  currentPage,
  setCurrentPage,
}) {
  // Country UI Context
  const countryUIContext = useCountryUIContext();
  const countryUIProps = useMemo(() => {
    return {
      ids: countryUIContext.ids,
      setIds: countryUIContext.setIds,
      queryParams: countryUIContext.queryParams,
      setQueryParams: countryUIContext.setQueryParams,
      openViewDialog: countryUIContext.openViewDialog,
      openEditDialog: countryUIContext.openEditDialog,
      openDeleteDialog: countryUIContext.openDeleteDialog,
    };
  }, [countryUIContext]);

  // Table columns
  const columns = [
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   style: {
    //     minWidth: "150px",
    //   },
    // },
    {
      dataField: "name",
      text: "Country Name",
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "170px",
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "100px",
      },
      formatter: StatusActiveDeactiveTypeFormatter,
    },
    {
      dataField: "action",
      text: "ACTIONS",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openViewDialog: countryUIProps.openViewDialog,
        openEditDialog: countryUIProps.openEditDialog,
        openDeleteDialog: countryUIProps.openDeleteDialog,
        isVisibleViewButton : false
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "120px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: countryListTotalCount ? countryListTotalCount : 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: countryUIProps.queryParams.pageSize,
    page: currentPage,
    onPageChange: (page) => {
      if (page === 0) return;
      setCurrentPage(page);
    },
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-head-bg table-borderless table-vertical-center country-table table-inventory"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={countryList}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  countryUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
              <PleaseWaitMessage isLoading={isLoading} />
              <NoRecordsFoundMessage entities={countryList} />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
