// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as uiHelpers from "../OutwardUIHelpers";
import { useOutwardUIContext } from "../OutwardUIContext";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../helper/helper";
import { Pagination } from "../../../pagination/Pagination";
import DateTimeColumnFormatter from "../../../columnformatter/DateTimeColumnFormatter";
import ActionsColumnFormatter from "../../../columnformatter/ActionsColumnFormatter";
import POStatusTypeFormatter from "../../../columnformatter/POStatusTypeFormatter";
import NameColumnFormatter from "../../../columnformatter/NameColumnFormatter";
import DCNumberHyperLinkColumnFormatter from "../../../columnformatter/DCNumberHyperLinkColumnFormatter";
import TotalAmountColumnFormatter from "../../../columnformatter/TotalAmountColumnFormatter";
import { useIntl } from "react-intl";
import { USER_DETAILS } from "../../../constants/const";
import ExpectedDateColumnFormatter from "../../../columnformatter/ExpectedDateColumnFormatter";

export function OutwardTable({ isLoading, DCList, DCListTotalCount }) {
  const intl = useIntl();
  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const { role } = userDetails || {};
  const { name: userRole } = role || {};

  // Outward UI Context
  const outwardUIContext = useOutwardUIContext();
  const outwardUIProps = useMemo(() => {
    return {
      ids: outwardUIContext.ids,
      setIds: outwardUIContext.setIds,
      queryParams: outwardUIContext.queryParams,
      setQueryParams: outwardUIContext.setQueryParams,
      openViewDialog: outwardUIContext.openViewDialog,
      openEditDialog: outwardUIContext.openEditDialog,
      openEditStatusDialog: outwardUIContext.openEditStatusDialog,
      openDeleteDialog: outwardUIContext.openDeleteDialog,
      redirectToViewPage: outwardUIContext.redirectToViewPage,
      redirectToCustomerViewPage: outwardUIContext.redirectToCustomerViewPage,
      openAgreeStatusDialog: outwardUIContext.openAgreeStatusDialog,
      openDisagreeStatusDialog: outwardUIContext.openDisagreeStatusDialog,
    };
  }, [outwardUIContext]);

  // Table columns
  const columns = [
    {
      dataField: "purchaseorder_number",
      text: intl.formatMessage({
        id: "TITLE.DC.NUMBER",
      }),
      sort: true,
      sortCaret: sortCaret,

      style: {
        minWidth: "150px",
      },
      formatter: DCNumberHyperLinkColumnFormatter,
      formatExtraData: {
        redirectToViewPage: outwardUIProps.redirectToViewPage,
      },
    },
    {
      dataField: "date",
      text: intl.formatMessage({
        id: "TITLE.DATE",
      }),
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "130px",
      },
      formatter: DateTimeColumnFormatter,
    },
    {
      dataField: "contact_name",
      text: intl.formatMessage({
        id: "TITLE.CUSTOMER.NAME",
      }),
      sort: true,
      style: {
        minWidth: "170px",
      },
      sortCaret: sortCaret,
      formatter: NameColumnFormatter,
      formatExtraData: {
        redirectToCustomerViewPage: outwardUIProps.redirectToCustomerViewPage,
      },
    },
    {
      dataField: "purchaseorder_status",
      text: intl.formatMessage({
        id: "TITLE.STATUS",
      }),
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "100px",
      },
      formatter: POStatusTypeFormatter,
      formatExtraData: {
        openEditStatusDialog: outwardUIProps.openEditStatusDialog,
        openAgreeStatusDialog: outwardUIProps.openAgreeStatusDialog,
        openDisagreeStatusDialog: outwardUIProps.openDisagreeStatusDialog,
        userRole: userRole,
      },
    },
    {
      dataField: "totalamount",
      text: intl.formatMessage({
        id: "TITLE.AMOUNT",
      }),
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "150px",
      },
      formatter: TotalAmountColumnFormatter,
    },
    {
      dataField: "expecteddeliverydate",
      text: intl.formatMessage({
        id: "TITLE.EXPECTED.DELIVERY.DATE_CAP",
      }),
      style: {
        minWidth: "150px",
      },
      formatter: ExpectedDateColumnFormatter,
    },
    {
      dataField: "action",
      text: intl.formatMessage({
        id: "TITLE.ACTIONS",
      }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openViewDialog: outwardUIProps.openViewDialog,
        openEditDialog: outwardUIProps.openEditDialog,
        openDeleteDialog: outwardUIProps.openDeleteDialog,
        isVisibleViewButton: true,
        isVisibleDeteletButton: userRole === "Outward Manager" ? false : true,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "120px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: DCListTotalCount ? DCListTotalCount : 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: outwardUIProps.queryParams.pageSize,
    onPageChange: (page) => {
      if (page === 0) return;
    },
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-head-bg table-borderless table-vertical-center outward-table table-inventory"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={DCList}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  outwardUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
              <PleaseWaitMessage isLoading={isLoading} />
              <NoRecordsFoundMessage entities={DCList} isLoading={isLoading} />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
