import { Spinner } from "react-bootstrap";
export const ButtonLoader = ({ className }) => (
  <Spinner
    className={`${className}`}
    as="span"
    animation="border"
    size="sm"
    role="status"
    aria-hidden="true"
  />
);
