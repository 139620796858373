import {
  Home,
  ShoppingBag,
  Users,
  User,
  PieChart,
  Menu,
  Server,
} from "react-feather";
import Inward from "../assets/fonts/feather/inward.svg";
import Outward from "../assets/fonts/feather/outward.svg";
import { FormattedMessage } from "react-intl";

export const MENUITEMS = [
  {
    path: "/dashboard",
    title: <FormattedMessage id="TITLE.DASHBOARD" />,
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: false,
    role: ["Inward Manager", "Outward Manager", "Inventory Manager"],
  },
  {
    path: "/inventoryproducts/products",
    title: <FormattedMessage id="TITLE.INVENTORY" />,
    icon: Server,
    type: "link",
    badgeType: "primary",
    active: false,
    role: ["Inward Manager", "Outward Manager", "Inventory Manager"],
  },
  {
    path: "/inwards/inward-listing",
    title: <FormattedMessage id="TITLE.INWARDS" />,
    icon: Inward,
    type: "link",
    badgeType: "primary",
    active: false,
    role: ["Inventory Manager", "Inward Manager"],
  },
  {
    path: "/outwards/outward-listing",
    title: <FormattedMessage id="TITLE.OUTWARDS" />,
    icon: Outward,
    type: "link",
    badgeType: "primary",
    active: false,
    role: ["Inventory Manager", "Outward Manager"],
  },
  {
    name: "reports",
    isOpen: false,
    title: <FormattedMessage id="TITLE.REPORTS" />,
    icon: PieChart,
    type: "sub",
    badgeType: "primary",
    active: false,
    role: ["Inventory Manager"],
    children: [
      {
        path: "/reports/outward-by-customer",
        title: <FormattedMessage id="TITLE.OUTWARDS.BY.CUSTOMER" />,
        type: "link",
        active: false,
      },
      {
        path: "/reports/outwards-by-product",
        title: <FormattedMessage id="TITLE.OUTWARDS.BY.PRODUCT" />,
        type: "link",
        active: false,
      },
      {
        path: "/reports/inventory-aging-summary",
        title: <FormattedMessage id="TITLE.INVENTORY.AGING" />,
        type: "link",
        active: false,
      },
      {
        path: "/reports/inventory-valuation-summary",
        title: <FormattedMessage id="TITLE.INVENTORY.VALUATION" />,
        type: "link",
        active: false,
      },
      {
        path: "/reports/active-po",
        title: <FormattedMessage id="TITLE.ACTIVE.PO" />,
        type: "link",
        active: false,
      },
      {
        path: "/reports/low-stock-items",
        title: <FormattedMessage id="TITLE.LOW.STOCK.ITEMS" />,
        type: "link",
        active: false,
      },
      {
        path: "/reports/delayed-po-dc",
        title: <FormattedMessage id="TITLE.DELAYED.PO.DC" />,
        type: "link",
        active: false,
      },
      {
        path: "/reports/inwards-valuation",
        title: <FormattedMessage id="TITLE.INWARDS.VALUATION" />,
        type: "link",
        active: false,
      },
      {
        path: "/reports/outwards-valuation",
        title: <FormattedMessage id="TITLE.OUTWARDS.VALUATION" />,
        type: "link",
        active: false,
      },
    ],
  },
  {
    name: "master",
    isOpen: false,
    title: <FormattedMessage id="TITLE.MASTER" />,
    icon: Menu,
    type: "sub",
    badgeType: "primary",
    active: false,
    role: ["Admin", "Inventory Manager"],
    children: [
      {
        path: "/master/categories/category-listing",
        title: <FormattedMessage id="TITLE.CATEGORIES" />,
        type: "link",
        active: false,
      },
      {
        path: "/master/productunits/product-unit-listing",
        title: <FormattedMessage id="TITLE.PRODUCT.UNITS" />,
        type: "link",
        active: false,
      },
      {
        path: "/master/products",
        title: <FormattedMessage id="TITLE.PRODUCTS" />,
        type: "link",
        active: false,
      },

      {
        path: "/master/locations/locations",
        title: <FormattedMessage id="TITLE.LOCATIONS" />,
        type: "link",
        active: false,
      },
    ],
  },
  {
    path: "/vendors/vendor-listing",
    title: <FormattedMessage id="TITLE.VENDORS" />,
    icon: ShoppingBag,
    type: "link",
    badgeType: "primary",
    active: false,
    role: ["Admin", "Inventory Manager", "Inward Manager"],
  },
  {
    path: "/customers/customer-listing",
    title: <FormattedMessage id="TITLE.CUSTOMERS" />,
    icon: Users,
    type: "link",
    badgeType: "primary",
    active: false,
    role: ["Admin", "Inventory Manager", "Outward Manager"],
  },
  {
    path: "/users/user-listing",
    title: <FormattedMessage id="TITLE.USERS" />,
    icon: User,
    type: "link",
    badgeType: "primary",
    active: false,
    role: ["Admin"],
  },
];
