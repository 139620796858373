import React, { Fragment } from "react";
import { reloadPage } from "../../../constants/utils";
import { addRemoveRTLLTRForDateRangePicker } from "../../../helper/helper";
import { setLanguage } from "../../../i18n/Metronici18n";
import { useLang } from "../../../i18n";

const Language = () => {
  const selectedLang = useLang();

  const ChangeRtl = () => {
    setLanguage("ar");
    document.body.classList.remove("ltr");
    document.body.classList.add("rtl");
    addRemoveRTLLTRForDateRangePicker();
    reloadPage();
  };

  const ChangeLtr = () => {
    setLanguage("en");
    document.body.classList.remove("rtl");
    document.body.classList.add("ltr");
    addRemoveRTLLTRForDateRangePicker();
    reloadPage();
  };

  return (
    <Fragment>
      <li className="onhover-dropdown border-0">
        <span className="txt-dark">
          <h6>{selectedLang === "en" ? "English" : "العربية"}</h6>
        </span>
        <ul className="language-dropdown onhover-show-div p-20">
          <li className="d-block">
            <span className="" onClick={() => ChangeLtr()}>
              English
            </span>
          </li>
          <li className="d-block">
            <span className="arabic-font" onClick={() => ChangeRtl()}>
              العربية
            </span>
          </li>
        </ul>
      </li>
    </Fragment>
  );
};

export default Language;
