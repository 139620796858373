export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: 10, value: 10 },
   { text: 20, value: 20 },
  { text: 50, value: 50 },
];
export const initialFilter = {
  filter: {},
  sortOrder: "",
  sortField: "",
  pageNumber: 1,
  pageSize: 20,
};
