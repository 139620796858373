import React, { useState, useEffect } from "react";
import { Button, Modal, Row, Col, Form, Spinner } from "react-bootstrap";
import Select from "react-select";
import { X, Check } from "react-feather";
import { useFormik } from "formik";
import {
  cityListAllApi,
  editBillingAddressCustomerApi,
} from "../../axios/services/services";
import {
  capitalizeFirstLetter,
  getErrorMessageBasedOnLanguage,
  unauthorization,
} from "../../constants/utils";
import { ErrorModal } from "../common/modals/ErrorModal";
import { EditCustomerBillingAddressSchema } from "../../helper/FormikSchema";
import { FormattedMessage } from "react-intl";

let EditCustomerBillingAddressInitialValues = {
  billingAddress: "",
  billingZipcode: "",
  billingCity: "",
};

export function EditBillingAddressCustomerModal({
  onCloseModal,
  open,
  data,
  id,
  handleCloseOfEditBillingAddressCustomerModal,
}) {
  const [selectedBillingCity, setSelectedBillingCity] = useState("");
  const [cityList, setCityList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isSaveLoading, setSaveLoading] = useState(false);
  const [addressEdited, setAddressEdited] = useState(false);

  async function getCityList() {
    const cityListResponse = await cityListAllApi({});
    const { Error, data } = cityListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    const cities = data.map((city) => {
      const { id, name } = city || {};
      city.value = id;
      city.label = capitalizeFirstLetter(name);
      return city;
    });
    setCityList(cities ? cities : []);
  }

  useEffect(() => {
    getCityList();
    const { billingaddress } = data || {};
    const { building_no, zipcode, city } = billingaddress || {};
    const { id, name } = city || {};
    formikEditCustomer.setFieldValue(
      "billingAddress",
      building_no ? building_no : ""
    );
    formikEditCustomer.setFieldValue("billingZipcode", zipcode ? zipcode : "");
    formikEditCustomer.setFieldValue("billingCity", id ? id : "");
    setSelectedBillingCity({ value: id, label: capitalizeFirstLetter(name) });
  }, []);

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const enableSaveLoading = () => {
    setSaveLoading(true);
  };

  const disableSaveLoading = () => {
    setSaveLoading(false);
  };

  const formikEditCustomer = useFormik({
    initialValues: EditCustomerBillingAddressInitialValues,
    validationSchema: EditCustomerBillingAddressSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const {
        billingAddress: billingaddressId,
        billingZipcode: billing_zipcode,
        billingCity: billing_cityId,
      } = values || {};
      let obj = {
        id,
        address: {
          billingaddressId,
          billing_zipcode,
          billing_cityId,
        },
      };

      enableSaveLoading();
      const editBillingAddressCustomer = await editBillingAddressCustomerApi(
        obj
      );
      disableSaveLoading();
      const { message, data, Error } = editBillingAddressCustomer || {};

      if (Error) {
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setAddressEdited(true);
      window.scrollTo(0, 0);
      setTimeout(() => {
        handleCloseOfEditBillingAddressCustomerModal();
      }, 2500);
    },
  });

  return (
    <>
      <Modal
        size="md"
        show={open}
        onHide={onCloseModal}
        aria-labelledby="example-modal-sizes-title-lg"
        // dialogClassName="addvendor-modal modal"
        scrollable={true}
      >
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <h3 className="modal-title" id="exampleModalLabel2">
            <FormattedMessage id="TITLE.EDIT.BILLING.ADDRESS" />
          </h3>
          <Button
            variant="link p-0 btn-icon"
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <X size={20} className="text-dark mr-0 pr-0" />
          </Button>
        </Modal.Header>
        <Modal.Body className="pb-0">
          {addressEdited && (
            <div className="notification icon inline success fs-16">
              <Check size={8} className="text-white"></Check>
              <FormattedMessage id="TITLE.BILLING.ADDRESS.EDITED.SUCCESSFULLY" />
            </div>
          )}
          <Form autoComplete="off">
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor="Status">
                    <FormattedMessage id="TITLE.ADDRESS" />
                  </Form.Label>{" "}
                  <span className="text-danger">*</span>
                  <Form.Control
                    id="billingAddress"
                    name="billingAddress"
                    placeholder="Billing Address"
                    className="form-control"
                    {...formikEditCustomer.getFieldProps("billingAddress")}
                  />
                  {formikEditCustomer.touched.billingAddress &&
                  formikEditCustomer.errors.billingAddress ? (
                    <div className="text-danger">
                      {formikEditCustomer.errors.billingAddress}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor="city">
                    <FormattedMessage id="TITLE.CITY" />
                  </Form.Label>
                  <Select
                    value={selectedBillingCity}
                    onChange={(data) => {
                      setSelectedBillingCity(data);
                      formikEditCustomer.setFieldValue(
                        "billingCity",
                        data.value
                      );
                    }}
                    isSearchable={true}
                    placeholder="Select City"
                    options={cityList}
                    id="city"
                    name="billingCity"
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                  {formikEditCustomer.touched.billingCity &&
                  formikEditCustomer.errors.billingCity ? (
                    <div className="text-danger">
                      {formikEditCustomer.errors.billingCity}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor="Status">
                    <FormattedMessage id="TITLE.ZIP.POSTAL.CODE" />
                  </Form.Label>{" "}
                  <span className="text-danger">*</span>
                  <Form.Control
                    id="billingZipcode"
                    name="billingZipcode"
                    type="number"
                    placeholder="Zip/Postal Code"
                    className="form-control"
                    resize="none"
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    onPaste={(evt) => {
                      const values = evt.clipboardData.getData("text");
                      const nonAlpha = values.replace(/[^\d.-]/g, "");
                      evt.preventDefault();
                      formikEditCustomer.setFieldValue(
                        "billingZipcode",
                        nonAlpha
                      );
                    }}
                    {...formikEditCustomer.getFieldProps("billingZipcode")}
                  />
                  {formikEditCustomer.touched.billingZipcode &&
                  formikEditCustomer.errors.billingZipcode ? (
                    <div className="text-danger">
                      {formikEditCustomer.errors.billingZipcode}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success mr-3 btn-inventory"
            onClick={() => {
              formikEditCustomer.handleSubmit();
            }}
            disabled={isSaveLoading}
          >
            <FormattedMessage id="TITLE.SAVE" />
            {isSaveLoading && (
              <Spinner animation="border" size="sm" className="ml-1" />
            )}
          </Button>
          <Button
            type="button"
            variant="tertiary text-dark"
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <FormattedMessage id="TITLE.CANCEL" />
          </Button>
        </Modal.Footer>
      </Modal>

      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
