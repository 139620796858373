import React, { useEffect, useMemo, useState } from "react";
import { Card, Button, ButtonGroup } from "react-bootstrap";
import { Filter, Plus, Upload } from "react-feather";
import { ProductUnitFilter } from "./productUnit-filter/ProductUnitFilter";
import { ProductUnitTable } from "./productUnit-table/ProductUnitTable";
import { useProductUnitUIContext } from "./ProductUnitUIContext";
import { FormattedMessage } from "react-intl";
import { productunitListExportApi } from "../../axios/services/services";

export function ProductUnitCard({
  isLoadingFilter,
  isLoading,
  isFilterOpen,
  productUnitList,
  exportCSVButton,
  transactionListExport,
  productUnitListTotalCount,
  currentPage,
  setCurrentPage,
  onClickOfCreateNew,
  handleClickOfRemoveFilterButton,
  handleCLickOfFilterButtonFilter,
}) {
  const productUnitUIContext = useProductUnitUIContext();
  const productUnitUIProps = useMemo(() => {
    return {
      ids: productUnitUIContext.ids,
      queryParams: productUnitUIContext.queryParams,
      setQueryParams: productUnitUIContext.setQueryParams,
      openViewDialog: productUnitUIContext.openViewDialog,
      openEditDialog: productUnitUIContext.openEditDialog,
      openDeleteDialog: productUnitUIContext.openDeleteDialog,
    };
  }, [productUnitUIContext]);

  const [isPageLoaderShow, setPageLoaderShow] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setPageLoaderShow(false);
    }, 500);
  }, []);

  const [productunitExportList, setProductUnitExportList] = useState();

  async function handleExport() {
    const productunitListExportResponse = await productunitListExportApi({
      filename: "Product_Units",
    });
    const { data } = productunitListExportResponse || {};
    setProductUnitExportList(data);
  }

  return (
    <>
      <Card className="card-custom card-stretch make-payment-wrapper">
        <Card.Header className="align-items-center d-flex justify-content-between">
          <h3 className="card-title page-title text-black mb-0">
            <FormattedMessage id="TITLE.PRODUCT.UNITS" />
          </h3>
          <div className="d-flex">
            <ButtonGroup className="mr-3">
              <Button
                variant="tertiary d-flex align-items-center btn-inventory"
                onClick={() => {
                  handleClickOfRemoveFilterButton();
                }}
              >
                <Filter size={16} style={{ color: "#CAA25C" }} />{" "}
                <span className="d-none d-md-flex">
                  {isFilterOpen ? (
                    <FormattedMessage id="TITLE.REMOVE" />
                  ) : (
                    <FormattedMessage id="TITLE.FILTER" />
                  )}
                </span>
              </Button>

              <Button
                variant="tertiary d-flex align-items-center btn-inventory"
                onClick={() => {
                  handleExport();
                }}
              >
                <Upload size={16} style={{ color: "#CAA25C" }} />
                <span className="d-none d-md-flex">
                  <FormattedMessage id="TITLE.EXPORT" />
                </span>
              </Button>
            </ButtonGroup>
            <Button
              variant="primary d-flex align-items-center btn-inventory"
              onClick={() => {
                onClickOfCreateNew();
              }}
            >
              <Plus size={16} style={{ color: "#CAA25C" }} />
              <span className="d-none d-md-flex">
                <FormattedMessage id="TITLE.CREATE.NEW" />
              </span>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          {isFilterOpen && (
            <ProductUnitFilter
              isLoadingFilter={isLoadingFilter}
              handleCLickOfFilterButtonFilter={handleCLickOfFilterButtonFilter}
            />
          )}
          <ProductUnitTable
            isLoading={isLoading}
            productUnitList={productUnitList}
            productUnitListTotalCount={productUnitListTotalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Card.Body>
      </Card>
    </>
  );
}
