import React, { useMemo, useState } from "react";
import { Card, Button, ButtonGroup } from "react-bootstrap";
import { Upload, Filter, Plus } from "react-feather";
import { CustomerFilter } from "./customer-filter/CustomerFilter";
import { CustomerTable } from "./customer-table/CustomerTable";
import { useCustomerUIContext } from "./CustomerUIContext";
import { FormattedMessage } from "react-intl";
import { customerListExportApi } from "../../axios/services/services";

export function CustomerCard({
  isLoading,
  customerList,
  customerListExport,
  exportCSVButton,
  customerListTotalCount,
  onClickOfCreateNew,
  handleClickOfRemoveFilterButton,
  isLoadingFilter,
  isFilterOpen,
  handleCLickOfFilterButtonFilter,
}) {
  const customerUIContext = useCustomerUIContext();
  const customerUIProps = useMemo(() => {
    return {
      ids: customerUIContext.ids,
      queryParams: customerUIContext.queryParams,
      setQueryParams: customerUIContext.setQueryParams,
      openViewDialog: customerUIContext.openViewDialog,
      openEditDialog: customerUIContext.openEditDialog,
      openDeleteDialog: customerUIContext.openDeleteDialog,
      redirectToViewPage: customerUIContext.redirectToViewPage,
    };
  }, [customerUIContext]);

  const [customerExportList, setCustomerExportList] = useState();

  async function handleExport() {
    const customerListExportResponse = await customerListExportApi({
      filename: "Customer",
    });
    const { data } = customerListExportResponse || {};
    setCustomerExportList(data);
  }

  return (
    <>
      <Card className="card-custom card-stretch make-payment-wrapper">
        <Card.Header className="align-items-center d-flex justify-content-between">
          <h3 className="card-title page-title text-black mb-0">
            <FormattedMessage id="TITLE.CUSTOMERS" />
          </h3>
          <div className="d-flex">
            <ButtonGroup className="mr-3">
              <Button
                variant="tertiary d-flex align-items-center btn-inventory"
                onClick={() => {
                  handleClickOfRemoveFilterButton();
                }}
              >
                <Filter size={16} style={{ color: "#CAA25C" }} />{" "}
                <span className="d-none d-md-flex">
                  {isFilterOpen ? (
                    <FormattedMessage id="TITLE.REMOVE" />
                  ) : (
                    <FormattedMessage id="TITLE.FILTER" />
                  )}
                </span>
              </Button>
              <Button
                variant="tertiary d-flex align-items-center btn-inventory"
                onClick={() => {
                  handleExport();
                }}
              >
                <Upload size={16} style={{ color: "#CAA25C" }} />
                <span className="d-none d-md-flex">
                  <FormattedMessage id="TITLE.EXPORT" />
                </span>
              </Button>
            </ButtonGroup>
            <Button
              variant="primary d-flex align-items-center btn-inventory"
              onClick={() => {
                onClickOfCreateNew();
              }}
            >
              <Plus size={16} style={{ color: "#CAA25C" }} />
              <span className="d-none d-md-flex">
                <FormattedMessage id="TITLE.CREATE.NEW" />
              </span>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          {isFilterOpen && (
            <CustomerFilter
              isLoadingFilter={isLoadingFilter}
              handleCLickOfFilterButtonFilter={handleCLickOfFilterButtonFilter}
            />
          )}
          <CustomerTable
            isLoading={isLoading}
            customerList={customerList}
            customerListTotalCount={customerListTotalCount}
          />
        </Card.Body>
      </Card>
    </>
  );
}
