import React from "react";
import { Table } from "react-bootstrap";
import { amountFormatter, dateFormatter } from "../../../constants/utils";
import InfiniteScroll from "react-infinite-scroll-component";
import { FormattedMessage } from "react-intl";
import { SectionLoader } from "../../../helper/SectionLoader";

export function TransactionPOView({
  POList,
  isLoadingPOView,
  handlePaginationOfPOListView,
  isLoadMorePOListView,
}) {
  return (
    <>
      {isLoadingPOView && <SectionLoader />}
      <InfiniteScroll
        scrollableTarget="scrollabledivOverview"
        dataLength={POList ? POList.length : 0}
        next={() => {
          handlePaginationOfPOListView();
        }}
        hasMore={isLoadMorePOListView}
        loader={
          <h4 style={{ textAlign: "center" }} className="p-1">
            <FormattedMessage id="TITLE.LOADING" />
            ...
          </h4>
        }
      >
        <div className="view-body">
          <Table responsive className="viewpo-table table">
            <thead>
              <tr>
                <th scope="col">
                  <FormattedMessage id="TITLE.DATE" />
                </th>
                <th scope="col">
                  <FormattedMessage id="TITLE.PURCHASE.ORDERS" />#
                </th>
                <th scope="col">
                  <FormattedMessage id="TITLE.VENDOR.NAME" />
                </th>
                <th scope="col">
                  <FormattedMessage id="TITLE.QUANTITY.PURCHASED" />
                </th>
                <th scope="col">
                  <FormattedMessage id="TITLE.TOTAL.AMOUNT" />
                </th>
                <th scope="col">
                  <FormattedMessage id="TITLE.STATUS" />
                </th>
              </tr>
            </thead>
            <tbody>
              {POList &&
                POList.map((PO, index) => {
                  return (
                    <tr key={index}>
                      <td className="digits">{dateFormatter(PO?.date)}</td>
                      <td>
                        <span className="text-info">
                          {PO?.purchaseorder_number}
                        </span>
                      </td>

                      <td>{PO?.contact?.contact_name}</td>
                      <td className="digits">{PO?.quantity_purchased}</td>
                      <td>
                        {amountFormatter(
                          PO?.quantity_purchased_data[0]?.totalamount
                        )}
                      </td>
                      <td>{PO?.purchaseorderstatus?.name}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </InfiniteScroll>
    </>
  );
}

export default TransactionPOView;
