import React, { useEffect, useMemo, useState } from "react";
import { Card, Button, Spinner, ButtonGroup } from "react-bootstrap";
import { Upload, Filter, Plus } from "react-feather";
import { useBlockLocationUIContext } from "./BlockLocationUIContext";
import { CSVLink } from "react-csv";
import { BlockLocationFilter } from "./block-location-filter/BlockLocationFilter";
import { BlockLocationTable } from "./block-location-table/BlockLocationTable";

export function BlockLocationCard({
  isLoading,
  counterList,
  transactionList,
  handleFilterValues,
  filterValues,
  loadingExportButton,
  exportCSVButton,
  transactionListExport,
  handleExportButton,
  transactionListTotalCount,
  currentPage,
  setCurrentPage,
  onClickOfCreateNew,
}) {
  const blockLocationUIContext = useBlockLocationUIContext();
  const blockLocationUIProps = useMemo(() => {
    return {
      ids: blockLocationUIContext.ids,
      queryParams: blockLocationUIContext.queryParams,
      setQueryParams: blockLocationUIContext.setQueryParams,
      openViewDialog: blockLocationUIContext.openViewDialog,
      openEditDialog: blockLocationUIContext.openEditDialog,
      openDeleteDialog: blockLocationUIContext.openDeleteDialog,
    };
  }, [blockLocationUIContext]);

  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isPageLoaderShow, setPageLoaderShow] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setPageLoaderShow(false);
    }, 500);
  }, []);

  return (
    <>
      <Card className="card-custom card-stretch make-payment-wrapper">
        {isPageLoaderShow && (
          <div className="section-loader">
            <Spinner animation="grow" size="sm" className="mr-1" />
            <Spinner animation="grow" size="sm" className="mr-1" />
            <Spinner animation="grow" size="sm" className="mr-1" />
            <Spinner animation="grow" size="sm" className="mr-1" />
            <Spinner animation="grow" size="sm" className="mr-1" />
          </div>
        )}
        <Card.Header className="align-items-center d-flex justify-content-between">
          <h3 className="card-title page-title text-black mb-0">Locations</h3>
          <div className="d-flex">
            <ButtonGroup className="mr-3">
              <Button
                variant="tertiary d-flex align-items-center btn-inventory"
                onClick={() => {
                  setFilterOpen(!isFilterOpen);
                }}
              >
                <Filter size={16} style={{ color: "#CAA25C" }} />{" "}
                <span className="d-none d-md-flex">
                  {isFilterOpen ? "Remove" : "Filter"}
                </span>
              </Button>
              <CSVLink
                ref={exportCSVButton}
                className="btn btn-tertiary d-flex align-items-center btn-inventory"
                data={transactionListExport}
                filename={`blockLocations-${new Date().getTime()}.csv`}
              >
                <Upload size={16} style={{ color: "#CAA25C" }} />
                <span className="d-none d-md-flex">Export</span>
              </CSVLink>
            </ButtonGroup>
            <Button
              variant="primary d-flex align-items-center btn-inventory"
              onClick={() => {
                onClickOfCreateNew();
              }}
            >
              <Plus size={16} style={{ color: "#CAA25C" }} />
              <span className="d-none d-md-flex">Create New</span>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          {isFilterOpen && <BlockLocationFilter filterValues={handleFilterValues} />}
          <BlockLocationTable
            isLoading={isLoading}
            transactionList={transactionList}
            filterValues={filterValues}
            transactionListTotalCount={transactionListTotalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Card.Body>
      </Card>
    </>
  );
}
