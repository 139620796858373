import React, { createContext, useContext, useCallback } from "react";
import { isEqual, isFunction } from "lodash";

const LocationUIContext = createContext();

export function useLocationUIContext() {
  return useContext(LocationUIContext);
}

export const LocationUIConsumer = LocationUIContext.Consumer;

export function LocationUIProvider({
  locationUIEvents,
  children,
  queryParams,
  setQueryParamsBase,
  ids,
  setIds,
}) {
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    openViewDialog: locationUIEvents.openViewDialog,
    openEditDialog: locationUIEvents.openEditDialog,
    openDeleteDialog: locationUIEvents.openDeleteDialog,
  };

  return (
    <LocationUIContext.Provider value={value}>
      {children}
    </LocationUIContext.Provider>
  );
}
