import React, { useContext, useState } from "react";
import { Image, Table } from "react-bootstrap";
import {
  amountFormatter,
  dateFormatter,
  setActiveMenu,
} from "../../../constants/utils";
import { FormattedMessage } from "react-intl";
import { SectionLoader } from "../../../helper/SectionLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import { MENUITEMS } from "../../../constants/menu";
import { UIContext } from "../../../helper/UIContext";
import { useHistory } from "react-router-dom";
import nodatafoundImage from "../../../assets/images/dashboard/nodata-found.svg";

export function TransactionPOView({
  POList,
  isLoadingPOView,
  handlePaginationOfPOListView,
  isLoadMorePOListView,
}) {
  const uiContext = useContext(UIContext);
  const history = useHistory();
  const [mainmenu, setMainMenu] = useState(MENUITEMS);

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  return (
    <>
      {isLoadingPOView && <SectionLoader />}
      <InfiniteScroll
        scrollableTarget="scrollabledivOverview"
        dataLength={POList ? POList.length : 0}
        next={() => {
          handlePaginationOfPOListView();
        }}
        hasMore={isLoadMorePOListView}
        loader={
          <h4 style={{ textAlign: "center" }} className="p-1">
            <FormattedMessage id="TITLE.LOADING" />
            ...
          </h4>
        }
      >
        <div className="view-body">
          <Table responsive className="viewpo-table table">
            <thead>
              <tr>
                <th scope="col">
                  <FormattedMessage id="TITLE.DATE" />
                </th>
                <th scope="col">
                  <FormattedMessage id="TITLE.PURCHASE.ORDERS" />#
                </th>
                <th scope="col">
                  <FormattedMessage id="TITLE.VENDOR.NAME" />
                </th>
                <th scope="col">
                  <FormattedMessage id="TITLE.QUANTITY.PURCHASED" />
                </th>
                <th scope="col">
                  <FormattedMessage id="TITLE.TOTAL.AMOUNT" />
                </th>
                <th scope="col">
                  <FormattedMessage id="TITLE.STATUS" />
                </th>
              </tr>
            </thead>
            <tbody>
              {POList &&
                POList.map((PO, index) => {
                  return (
                    <tr>
                      <td className="digits">{dateFormatter(PO?.date)}</td>
                      <td>
                        <span
                          className="text-primary btn-link p-0 cursor-pointer"
                          onClick={() => {
                            const path = `/inwards/view-po-details/${PO?.id}`;
                            onClickChangeRoute(path);
                            window.scrollTo(0, 0);
                          }}
                        >
                          {PO?.purchaseorder_number}
                        </span>
                      </td>
                      <td
                      // className="text-primary btn-link p-0 cursor-pointer"
                      // onClick={() => {
                      //   const path = `/vendors/view-vendor-details/${PO?.contact?.id}`;
                      //   onClickChangeRoute(path);
                      //   window.scrollTo(0, 0);
                      // }}
                      >
                        {PO?.contact_name}
                      </td>
                      <td className="digits">{PO?.quantity}</td>
                      <td>{amountFormatter(PO?.totalamount)}</td>
                      <td>{PO?.product_status}</td>
                    </tr>
                  );
                })}
              {POList && POList.length === 0 && !isLoadingPOView && (
                <tr>
                  <td className="no-record text-center" colSpan={6}>
                    <div className="d-flex align-items-center justify-content-center flex-column">
                      <Image
                        src={nodatafoundImage}
                        alt="No Data Found"
                        className="img-fluid"
                        style={{ maxHeight: "200px" }}
                      />
                      <span className="d-block text-center">
                        <FormattedMessage id="TITLE.NO.RECORDS.FOUND" />
                      </span>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </InfiniteScroll>
    </>
  );
}

export default TransactionPOView;
