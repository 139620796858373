import React, { useRef, useState } from "react";
import { StateLoadingDialog } from "./state-loading-dialog/StateLoadingDialog";
import { StateCard } from "./StateCard";
import { StateUIProvider } from "./StateUIContext";
import SVG from "react-inlinesvg";
import { initialFilter } from "./StateUIHelpers";
import { ErrorModal } from "../common/modals/ErrorModal";
import { toAbsoluteUrl } from "../../helper/helper";
import { DeleteModal } from "../common/modals/DeleteModal";
import { EditStateModal } from "../../global-modal/EditStateModal";
import { AddNewStateModal } from "../../global-modal/AddNewStateModal";

export function StatePage({ history }) {
  const [data, setData] = useState({});
  const [stateList, setstateList] = useState([]);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [filterValues, setFilterValues] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [loadingExportButton, setLoadingExportButton] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [stateListTotalCount, setstateListTotalCount] = useState(0);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const [stateListExport, setstateListExport] = useState([]);
  const exportCSVButton = useRef();

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleAddModalShow = () => {
    setAddModalOpen(true);
  };

  const handleAddModalHide = () => {
    setAddModalOpen(false);
  };

  const handleEditModalShow = () => {
    setEditModalOpen(true);
  };

  const handleEditModalHide = () => {
    setEditModalOpen(false);
  };

  const handleDeleteModalShow = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalHide = () => {
    setIsDeleteModalOpen(false);
  };

  const handleFilterValues = (values) => {
    setFilterValues(values);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingExportButton = () => {
    setLoadingExportButton(true);
  };

  const disableLoadingExportButton = () => {
    setLoadingExportButton(false);
  };

  const handleSetCurrentPage = (page) => {
    setCurrentPage(page);
  };

  const stateUIEvents = {
    openViewDialog: (row) => {
      history.push("/view-state-details");
    },
    openEditDialog: (row) => {
      setData(row);
      handleEditModalShow();
    },
    openDeleteDialog: (row) => {
      setData(row);
      handleDeleteModalShow();
    },
  };

  const handleExportButton = async () => {};

  const handleOnClickOfCreateNew = () => {
    handleAddModalShow();
  };

  return (
    <div>
      <>
        {/* <Breadcrumb title="Product List" parent="Products" /> */}
        <StateUIProvider
          stateUIEvents={stateUIEvents}
          queryParams={queryParams}
          setQueryParamsBase={setQueryParamsBase}
          ids={ids}
          setIds={setIds}
        >
          <StateLoadingDialog />

          {isAddModalOpen && (
            <AddNewStateModal
              open={isAddModalOpen}
              data={data}
              onCloseModal={handleAddModalHide}
            />
          )}

          {isEditModalOpen && (
            <EditStateModal
              open={isEditModalOpen}
              data={data}
              onCloseModal={handleEditModalHide}
            />
          )}

          <StateCard
            isLoading={isLoading}
            stateList={stateList}
            handleFilterValues={handleFilterValues}
            filterValues={filterValues}
            loadingExportButton={loadingExportButton}
            stateListExport={stateListExport}
            handleExportButton={handleExportButton}
            exportCSVButton={exportCSVButton}
            stateListTotalCount={stateListTotalCount}
            currentPage={currentPage}
            setCurrentPage={handleSetCurrentPage}
            onClickOfCreateNew={handleOnClickOfCreateNew}
          />
        </StateUIProvider>

        {isDeleteModalOpen && (
          <DeleteModal
            title={"Delete State?"}
            description={
              "State will be deleted forever and cannot be retrieved later. Are you sure about deleting it?"
            }
            isOpen={isDeleteModalOpen}
            onCloseModal={handleDeleteModalHide}
          />
        )}

        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
          errorIcon={
            <div className="danger-icon-wrapper text-center">
              <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                />
              </span>
            </div>
          }
          errorButtonLabel={"Close"}
          errorOnClick={handleErrorModalHide}
        />
      </>
    </div>
  );
}
