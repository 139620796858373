import React from "react";
import { Button, Image, Table } from "react-bootstrap";
import { capitalizeFirstLetter } from "../../../constants/utils";
import { FormattedMessage } from "react-intl";
import { DD_MM_YYYY } from "../../../constants/const";
import Moment from "react-moment";
import nodatafoundImage from "../../../assets/images/dashboard/nodata-found.svg";
import { useIntl } from "react-intl";
import { SectionLoader } from "../../../helper/SectionLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import { XCircle } from "react-feather";

export function LocationProductView({
  locationList,
  isLoadingLocView,
  handlePaginationOfLocListView,
  isLoadMoreLocListView,
  handleDeleteOfBarcodeNumber
}) {
  const intl = useIntl();

  const renderLocationList = (location) => {
    const { location_Id } = location;
    const {
      store_location: name,
      parent_location,
      locationId,
    } = location_Id || "-";
    const { locationId: li } = locationId || {};
    var locName = name;
    var comma = ",";
    if (parent_location) {
      locName = name.concat(comma, locationId?.store_location);
      if (li) {
        locName = locName.concat(comma, li?.store_location);
      }
    }
    return capitalizeFirstLetter(locName);
  };
  return (
    <>
      {isLoadingLocView && <SectionLoader />}
      <InfiniteScroll
        scrollableTarget="scrollabledivOverview"
        dataLength={locationList ? locationList.length : 0}
        next={() => {
          handlePaginationOfLocListView();
        }}
        hasMore={isLoadMoreLocListView}
        loader={
          <h4 style={{ textAlign: "center" }} className="p-1">
            <FormattedMessage id="TITLE.LOADING" />
            ...
          </h4>
        }
      >
        <div className="view-body">
          <Table responsive className="viewpo-table table">
            <thead>
              <tr>
                <th scope="col">
                  <FormattedMessage id="TITLE.ITEM" />
                </th>
                <th scope="col">
                  <FormattedMessage id="TITLE.EXPIRY.DATE" />
                </th>
                <th scope="col">
                  <FormattedMessage id="TITLE.LOCATION" />
                </th>
                <th scope="col">
                  <FormattedMessage id="TITLE.BARCODE.NUMBER" />
                </th>
                <th scope="col">
                  <FormattedMessage id="TITLE.CONDITION" />
                </th>
                <th scope="col">
                  <FormattedMessage id="TITLE.ACTIONS.SMALL" />
                </th>
              </tr>
            </thead>
            <tbody>
              {locationList &&
                locationList.map((location, index) => {
                  return (
                    <tr key={index}>
                      <td className="digits">{location?.product?.name}</td>
                      <td className="text-dark">
                        {location?.expiry_date ? (
                          <Moment
                            date={location?.expiry_date}
                            format={DD_MM_YYYY}
                          />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        <span className="text-dark">
                          {renderLocationList(location)}
                        </span>
                      </td>
                      <td className="text-dark">{location?.barcode_no}</td>

                      <td
                        className={`${
                          location?.condition === 1
                            ? "text-success"
                            : "text-danger"
                        }`}
                      >
                        {location?.condition === 1
                          ? intl.formatMessage({ id: "TITLE.GOOD" })
                          : intl.formatMessage({ id: "TITLE.NOT.GOOD" })}
                      </td>
                      <td className="text-center">
                        <Button variant="link p-0 m-0" onClick={() => {
                          handleDeleteOfBarcodeNumber(location)
                        }}>
                          <XCircle
                            size={16}
                            style={{
                              color: "#990029",
                            }}
                          />
                        </Button>
                      </td>
                    </tr>
                  );
                })}

              {locationList && locationList.length === 0 && !isLoadingLocView && (
                <tr>
                  <td className="no-record text-center" colSpan={6}>
                    <div className="d-flex align-items-center justify-content-center flex-column">
                      <Image
                        src={nodatafoundImage}
                        alt="No Data Found"
                        className="img-fluid"
                        style={{ maxHeight: "200px" }}
                      />
                      <span className="d-block text-center">
                        <FormattedMessage id="TITLE.NO.RECORDS.FOUND" />
                      </span>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </InfiniteScroll>
    </>
  );
}

export default LocationProductView;
