import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal, Row, Col, Form, Spinner } from "react-bootstrap";
import Select from "react-select";
import { X, Check, File } from "react-feather";
import {
  editProductApi,
  getSingleProductDetailsApi,
  categoryListApi,
  uploadDocumentApi,
  productUnitListApi,
} from "../../../axios/services/services";
import { useFormik } from "formik";
import { EditProductSchema } from "../../../helper/FormikSchema";
import { ErrorModal } from "../../common/modals/ErrorModal";
import CreatableSelect from "react-select/creatable";
import ButtonAtl from "@atlaskit/button";
import { useDropzone } from "react-dropzone";
import { FILE_EXTENTION_IMAGE, MB_20, MB_5 } from "../../../constants/const";
import { ContentLoader } from "../../../helper/ContentLoader";
import { ThumbnailWrapper } from "../../../helper/ThumbnailWrapper";
import {
  capitalizeFirstLetter,
  unauthorization,
} from "../../../constants/utils";
import { FormattedMessage, useIntl } from "react-intl";
import { getErrorMessageBasedOnLanguage } from "../../../constants/utils";
import {
  ChevronDownMaster,
  DropdownMaster,
  DropdownIndicatorMaster,
} from "../../../helper/helper";
import { AddNewUnitModal } from "../../../global-modal/AddNewUnitModal";
import { AddNewCategoryModal } from "../../category-module/add-new-category/AddNewCategoryModal";

const statusName = [
  { value: "1", label: "Active" },
  { value: "0", label: "Inactive" },
];

let EditProductInitialValues = {
  name: "",
  categoryId: "",
  stock_keeping_unit: "",
  opening_stock: 0,
  reorder_level: "",
  shelf_life: "",
  price: "",
  description: "",
  productunitId: "",
};

export function EditProductModal({
  onCloseModal,
  open,
  data,
  history,
  id,
  handleCloseOfEditNewProductModal,
}) {
  const intl = useIntl();
  const [selectedOption, setSelectedOption] = useState(null);
  const [productEdited, setProductEdited] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [productDetails, setProductDetails] = useState({});
  const [isSaveLoading, setSaveLoading] = useState(false);
  const [isLoadingSingleProduct, setLoadingSingleProduct] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [productUnitList, setProductUnitList] = useState([]);
  const [isUpdateCategoryList, setUpdateCategoryList] = useState(0);
  const [isUpdateProductUnitList, setUpdateProductUnitList] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchedCategoryName, setSearchedCategoryName] = useState("");
  const [searchedProductUnitName, setSearchedProductUnitName] = useState("");
  const [isOpenAddNewCategoryModal, setOpenAddNewCategoryModal] =
    useState(false);
  const [isOpenAddNewProductUnitModal, setOpenAddNewProductUnitModal] =
    useState(false);
  const [isOpenProductUnitDropdown, setOpenProductUnitDropdown] =
    useState(false);
  const [isUpdateSingleProductDetails, setUpdateSingleProductDetails] =
    useState(0);
  const [selectedProductUnit, setSelectedProductUnit] = useState(null);
  const [files, setFiles] = useState([]);
  const [isFileUploadLoading, setFileUploadLoading] = useState(false);
  const [uploadDocument, setUploadDocument] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length === 0) {
      setErrorMessage(
        intl.formatMessage({
          id: "TITLE.UPLOAD.JPEG.JPG.PNG.FILES.ONLY.OR.UPLOAD.IMAGE.LESS.THAN.5.MB",
        })
      );
      handleErrorModalShow();
      return;
    }
    attachDocument(acceptedFiles);
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: FILE_EXTENTION_IMAGE,
    disabled: files.length > 1,
    maxFiles: 1,
    maxSize: MB_5,
    // maxFilesize: MB_5,
    multiple: false,
    autoProcessQueue: true,
    onDrop,
  });

  async function getProductUnitList() {
    const productUnitListResponse = await productUnitListApi();
    const { Error, data } = productUnitListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    const productUnits = data.map((productUnit, index) => {
      productUnit.value = productUnit.id;
      productUnit.label = productUnit.name;
      return productUnit;
    });

    const { productunitId } = productDetails || {};
    const [selectedProductUnit] = productUnits.filter(
      (productUnit) => productUnit.id === productunitId
    );

    if (selectedProductUnit) {
      setSelectedProductUnit(selectedProductUnit);
    }

    setProductUnitList(productUnits);
  }

  useEffect(() => {
    if (isUpdateSingleProductDetails) {
      getProductUnitList();
    }
  }, [isUpdateProductUnitList, isUpdateSingleProductDetails]);

  const formikEditProduct = useFormik({
    initialValues: EditProductInitialValues,
    validationSchema: EditProductSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableSaveLoading();
      const {
        name,
        stock_keeping_unit,
        opening_stock,
        reorder_level,
        shelf_life,
        price,
        description,
        productunitId,
      } = values || {};
      const { value: status } = selectedOption || {};
      const { value: categoryId } = selectedCategory || {};
      const docs = [];
      files.map((doc) => {
        const { filename, name, file_type, mimetype } = doc || {};
        docs.push({
          ...(filename && { file_name: filename }),
          ...(name && { file_name: name }),
          file_type: mimetype ? mimetype : file_type,
        });
        return doc;
      });

      let obj = {
        id,
        name,
        status,
        opening_stock,
        price,
        categoryId,
        product_media: docs,
        description,
        productunitId,
        ...(stock_keeping_unit === ""
          ? { stock_keeping_unit: null }
          : { stock_keeping_unit }),
        ...(reorder_level === "" ? { reorder_level: null } : { reorder_level }),
        ...(shelf_life === "" ? { shelf_life: null } : { shelf_life }),
      };
      const editProductResponse = await editProductApi(obj);
      const { data, Error } = editProductResponse || {};
      if (Error) {
        disableSaveLoading();
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setProductEdited(true);
      window.scrollTo(0, 0);
      setTimeout(() => {
        disableSaveLoading();
        handleCloseOfEditNewProductModal(data);
      }, 2500);
    },
  });

  const attachDocument = async (acceptedFiles) => {
    const uploadedFilesArray = [];
    const maxLengthForDocUpload = 5 - files.length;
    enableLoadingFileUpload();
    for (let index = 0; index < acceptedFiles.length; index++) {
      if (maxLengthForDocUpload === index) {
        break;
      }
      if (MB_20 <= acceptedFiles[index].size) {
        setErrorMessage(
          intl.formatMessage({ id: "TITLE.ATTACHED.FILE.IS.TOO.LARGE" })
        );
        handleErrorModalShow();
        continue;
      }
      let files = {
        file: acceptedFiles[index],
        containername: "product",
      };
      const uploadDocumentResponse = await uploadDocumentApi(files);
      const [uploadedDoc] = uploadDocumentResponse || {};
      uploadedFilesArray.push(uploadedDoc);
    }
    disableLoadingFileUpload();
    // const filesTemp = [...files, ...uploadedFilesArray];
    const filesTemp = uploadedFilesArray;
    setFiles(filesTemp);
    setUploadDocument([...uploadDocument, ...uploadedFilesArray]);
  };

  async function getCategoryList() {
    const categoryListResponse = await categoryListApi(1);
    const { data, Error } = categoryListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    const categories = data.map((category, index) => {
      category.value = category.id;
      category.label = category.name;
      return category;
    });

    const { categoryId } = productDetails || {};
    const [selectedCategory] = categories.filter(
      (category) => category.id === categoryId
    );

    if (selectedCategory) {
      setSelectedCategory(selectedCategory);
      formikEditProduct.setFieldValue("categoryId", selectedCategory.id);
    }
    setCategoryList(categories);
  }

  useEffect(() => {
    if (isUpdateSingleProductDetails) getCategoryList();
  }, [isUpdateCategoryList, isUpdateSingleProductDetails]);

  const renderCategoryList = (categoryList) => {
    const options = [];
    categoryList &&
      categoryList.map((products, index) => {
        const { name, id } = products || "-";
        options.push({
          value: id,
          label: name,
        });
      });
    return options;
  };

  async function getSingleProductDetails() {
    enableLoadingSingleProduct();
    const singleProductDetails = await getSingleProductDetailsApi({ id });
    disableLoadingSingleProduct(false);
    const { Error, data } = singleProductDetails || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setProductDetails(data ? data : {});
    setUpdateSingleProductDetails(Math.random());

    const {
      name,
      stock_keeping_unit,
      status,
      opening_stock,
      reorder_level,
      shelf_life,
      price,
      category,
      description,
      productunitId,
      product_media,
    } = data || {};
    const { name: category_name } = category || {};
    let statusName = {};
    if (status === 1) {
      setSelectedOption({ value: "1", label: "Active" });
    } else {
      setSelectedOption({ value: "0", label: "Inactive" });
    }
    setFiles(product_media);
    formikEditProduct.setFieldValue("name", name ? name : "");
    formikEditProduct.setFieldValue(
      "stock_keeping_unit",
      stock_keeping_unit ? stock_keeping_unit : ""
    );
    formikEditProduct.setFieldValue(
      "opening_stock",
      opening_stock ? opening_stock : 0
    );
    formikEditProduct.setFieldValue(
      "reorder_level",
      reorder_level ? reorder_level : ""
    );
    formikEditProduct.setFieldValue("shelf_life", shelf_life ? shelf_life : "");
    formikEditProduct.setFieldValue("price", price ? price : "");
    formikEditProduct.setFieldValue("category_name", category_name);
    formikEditProduct.setFieldValue("description", description);
    formikEditProduct.setFieldValue("productunitId", productunitId);
  }

  useEffect(() => {
    getSingleProductDetails();
  }, []);

  const enableLoadingFileUpload = () => {
    setFileUploadLoading(true);
  };

  const disableLoadingFileUpload = () => {
    setFileUploadLoading(false);
  };

  const enableSaveLoading = () => {
    setSaveLoading(true);
  };

  const disableSaveLoading = () => {
    setSaveLoading(false);
  };

  const enableLoadingSingleProduct = () => {
    setLoadingSingleProduct(true);
  };

  const disableLoadingSingleProduct = () => {
    setLoadingSingleProduct(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  const toggleOpen = () => {
    setOpen(!isOpen);
  };

  const onSelectChange = (e) => {
    toggleOpen();
    if (e) {
      setSelectedCategory(e);
      formikEditProduct.setFieldValue("categoryId", e.value);
    } else {
      setSelectedCategory(null);
      formikEditProduct.setFieldValue("categoryId", "");
    }
  };

  const handleInputChange = (event) => {};

  const formatCreateLabel = (inputValue) => {
    setSearchedCategoryName(capitalizeFirstLetter(inputValue));
    return (
      "+" +
      intl.formatMessage({
        id: "TITLE.ADD.NEW.CATEGORY",
      })
    );
  };

  const formatCreateLabelProductUnit = (inputValue) => {
    setSearchedProductUnitName(capitalizeFirstLetter(inputValue));
    return (
      "+" +
      intl.formatMessage({
        id: "TITLE.ADD.NEW.PRODUCT.UNIT",
      })
    );
  };

  const addNewCategoryModalShow = () => {
    setOpenAddNewCategoryModal(true);
  };

  const addNewCategoryModalHide = () => {
    setOpenAddNewCategoryModal(false);
  };

  const addNewProductUnitModalShow = () => {
    setOpenAddNewProductUnitModal(true);
  };

  const addNewProductUnitModalHide = () => {
    setOpenAddNewProductUnitModal(false);
  };

  const toggleOpenProductUnitDropdown = () => {
    setOpenProductUnitDropdown(!isOpenProductUnitDropdown);
  };

  const onSelectChangeOfProductUnit = (e) => {
    toggleOpenProductUnitDropdown();
    if (e) {
      setSelectedProductUnit(e);
      formikEditProduct.setFieldValue("productunitId", e.value);
    } else {
      setSelectedProductUnit(null);
      formikEditProduct.setFieldValue("productunitId", "");
    }
  };

  const handleInputChangeProductUnit = (event) => {};

  const handleOnDeleteOfAttachment = (file, index) => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
    const newUploadedDocument = [...uploadDocument];
    newUploadedDocument.splice(newUploadedDocument[index], 1);
    setUploadDocument(newUploadedDocument);
  };

  const handleCloseOfAddNewProductUnitModal = () => {
    setProductUnitList([]);
    setUpdateProductUnitList(Math.random());
    addNewProductUnitModalHide();
  };

  const handleCloseOfAddNewCategoryModal = () => {
    setCategoryList([]);
    setUpdateCategoryList(Math.random());
    addNewCategoryModalHide();
  };

  return (
    <>
      <Modal
        size="lg"
        show={open}
        onHide={onCloseModal}
        aria-labelledby="example-modal-sizes-title-lg"
        dialogClassName="addvendor-modal modal"
        scrollable={true}
      >
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <h3 className="modal-title" id="exampleModalLabel2">
            <FormattedMessage id="TITLE.EDIT.PRODUCT" />
            {isLoadingSingleProduct && (
              <Spinner animation="border" size="sm" className="ml-1" />
            )}
          </h3>
          <Button
            variant="link p-0 btn-icon"
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <X size={20} className="text-dark mr-0 pr-0" />
          </Button>
        </Modal.Header>
        <Modal.Body className="pb-0">
          {productEdited && (
            <div className="notification icon inline success fs-16">
              <Check size={8} className="text-white"></Check>
              <FormattedMessage id="TITLE.PRODUCT.HAS.BEEN.SUCCESSFULLY.EDITED" />
            </div>
          )}
          <Form autoComplete="off">
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="Productname">
                    <FormattedMessage id="TITLE.PRODUCT.NAME" />
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="Productname"
                    placeholder={intl.formatMessage({
                      id: "TITLE.PRODUCT.NAME",
                    })}
                    required
                    name="name"
                    {...formikEditProduct.getFieldProps("name")}
                  />
                  {formikEditProduct.touched.name &&
                  formikEditProduct.errors.name ? (
                    <div className="text-danger">
                      {formikEditProduct.errors.name}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="category">
                    <FormattedMessage id="TITLE.SELECT.CATEGORY" />
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <DropdownMaster
                    isOpen={isOpen}
                    onClose={toggleOpen}
                    target={
                      <ButtonAtl
                        iconAfter={<ChevronDownMaster />}
                        onClick={toggleOpen}
                        isSelected={isOpen}
                        className="form-control w-100 bg-white custom-select-btn mb-0"
                      >
                        {selectedCategory ? (
                          `${selectedCategory.label}`
                        ) : (
                          <FormattedMessage id="TITLE.SELECT.CATEGORY" />
                        )}
                      </ButtonAtl>
                    }
                  >
                    <CreatableSelect
                      onChange={(e) => onSelectChange(e)}
                      onInputChange={handleInputChange}
                      options={renderCategoryList(categoryList)}
                      createOptionPosition="top"
                      formatCreateLabel={formatCreateLabel}
                      isSearchable
                      onCreateOption={() => {
                        addNewCategoryModalShow();
                        setOpen(false);
                      }}
                      autoFocus
                      backspaceRemovesValue={true}
                      components={{
                        DropdownIndicatorMaster,
                        IndicatorSeparator: null,
                      }}
                      controlShouldRenderValue={true}
                      hideSelectedOptions={false}
                      isClearable={true}
                      menuIsOpen
                      placeholder={intl.formatMessage({
                        id: "TITLE.SELECT.CATEGORY",
                      })}
                      tabSelectsValue={true}
                      value={selectedCategory}
                      className="react-custom-select-container"
                      classNamePrefix="react-select"
                    />
                  </DropdownMaster>
                  {formikEditProduct.touched.categoryId &&
                  formikEditProduct.errors.categoryId ? (
                    <div className="text-danger">
                      {formikEditProduct.errors.categoryId}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="Productphone">
                    <FormattedMessage id="TITLE.SKU" />
                    {/* <span className="text-danger">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="Productphone"
                    placeholder={intl.formatMessage({ id: "TITLE.SKU" })}
                    required
                    name="stock_keeping_unit"
                    {...formikEditProduct.getFieldProps("stock_keeping_unit")}
                  />
                  {formikEditProduct.touched.stock_keeping_unit &&
                  formikEditProduct.errors.stock_keeping_unit ? (
                    <div className="text-danger">
                      {formikEditProduct.errors.stock_keeping_unit}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="Productmobile">
                    <FormattedMessage id="TITLE.OPENING.STOCK" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="Productmobile"
                    placeholder={intl.formatMessage({
                      id: "TITLE.OPENING.STOCK",
                    })}
                    name="opening_stock"
                    disabled={true}
                    {...formikEditProduct.getFieldProps("opening_stock")}
                  />
                  {formikEditProduct.touched.opening_stock &&
                  formikEditProduct.errors.opening_stock ? (
                    <div className="text-danger">
                      {formikEditProduct.errors.opening_stock}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="reorder_level">
                    <FormattedMessage id="TITLE.REORDER.LEVEL" />
                  </Form.Label>
                  <Form.Control
                    type="number"
                    id="reorder_level"
                    placeholder={intl.formatMessage({
                      id: "TITLE.REORDER.LEVEL",
                    })}
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    onPaste={(evt) => {
                      const values = evt.clipboardData.getData("text");
                      const nonAlpha = values.replace(/[^\d.-]/g, "");
                      evt.preventDefault();
                      formikEditProduct.setFieldValue(
                        "reorder_level",
                        nonAlpha
                      );
                    }}
                    name="reorder_level"
                    {...formikEditProduct.getFieldProps("reorder_level")}
                  />
                  {formikEditProduct.touched.reorder_level &&
                  formikEditProduct.errors.reorder_level ? (
                    <div className="text-danger">
                      {formikEditProduct.errors.reorder_level}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="shelflife">
                    <FormattedMessage id="TITLE.SHELF.LIFE.DAYS" />
                    {/* <span className="text-danger">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    id="shelflife"
                    placeholder={intl.formatMessage({
                      id: "TITLE.SHELF.LIFE",
                    })}
                    name="shelf_life"
                    min={1}
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    onPaste={(evt) => {
                      const values = evt.clipboardData.getData("text");
                      const nonAlpha = values.replace(/[^\d.-]/g, "");
                      evt.preventDefault();
                      formikEditProduct.setFieldValue("shelf_life", nonAlpha);
                    }}
                    {...formikEditProduct.getFieldProps("shelf_life")}
                  />
                  {formikEditProduct.touched.shelf_life &&
                  formikEditProduct.errors.shelf_life ? (
                    <div className="text-danger">
                      {formikEditProduct.errors.shelf_life}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="price">
                    <FormattedMessage id="TITLE.PRICE" />
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    id="price"
                    placeholder={intl.formatMessage({ id: "TITLE.PRICE" })}
                    name="price"
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    onPaste={(evt) => {
                      const values = evt.clipboardData.getData("text");
                      const nonAlpha = values.replace(/[^\d.-]/g, "");
                      evt.preventDefault();
                      formikEditProduct.setFieldValue("price", nonAlpha);
                    }}
                    {...formikEditProduct.getFieldProps("price")}
                  />
                  {formikEditProduct.touched.price &&
                  formikEditProduct.errors.price ? (
                    <div className="text-danger">
                      {formikEditProduct.errors.price}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="category">
                    <FormattedMessage id="TITLE.SELECT.PRODUCT.UNIT" />
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <DropdownMaster
                    isOpen={isOpenProductUnitDropdown}
                    onClose={toggleOpenProductUnitDropdown}
                    target={
                      <ButtonAtl
                        iconAfter={<ChevronDownMaster />}
                        onClick={toggleOpenProductUnitDropdown}
                        isSelected={isOpenProductUnitDropdown}
                        className="form-control w-100 bg-white custom-select-btn mb-0"
                      >
                        {selectedProductUnit ? (
                          `${selectedProductUnit.label}`
                        ) : (
                          <FormattedMessage id="TITLE.SELECT.PRODUCT.UNIT" />
                        )}
                      </ButtonAtl>
                    }
                  >
                    <CreatableSelect
                      onChange={(e) => onSelectChangeOfProductUnit(e)}
                      onInputChange={handleInputChangeProductUnit}
                      options={productUnitList}
                      createOptionPosition="top"
                      formatCreateLabel={formatCreateLabelProductUnit}
                      isSearchable
                      onCreateOption={() => {
                        addNewProductUnitModalShow();
                        setOpenProductUnitDropdown(false);
                      }}
                      // autoFocus
                      backspaceRemovesValue={true}
                      components={{
                        DropdownIndicatorMaster,
                        IndicatorSeparator: null,
                      }}
                      controlShouldRenderValue={true}
                      hideSelectedOptions={false}
                      isClearable={true}
                      menuIsOpen
                      placeholder={intl.formatMessage({
                        id: "TITLE.PRODUCT.UNITS",
                      })}
                      // styles={selectStyles}
                      tabSelectsValue={true}
                      value={selectedProductUnit}
                      className="react-custom-select-container"
                      classNamePrefix="react-select"
                    />
                  </DropdownMaster>
                  {formikEditProduct.touched.productunitId &&
                  formikEditProduct.errors.productunitId ? (
                    <div className="text-danger">
                      {formikEditProduct.errors.productunitId}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="status">
                    <FormattedMessage id="TITLE.STATUS" />
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    value={selectedOption}
                    onChange={(value) => {
                      handleOptionChange(value);
                    }}
                    isSearchable={true}
                    options={statusName}
                    placeholder={intl.formatMessage({
                      id: "TITLE.SELECT.STATUS",
                    })}
                    id="status"
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </Form.Group>
              </Col>
              <Col md={6}></Col>
              <Col md={6}>
                <Form.Group>
                  <div className="d-flex align-items-center justify-content-between">
                    <Form.Label htmlFor="attach-qutation">
                      <FormattedMessage id="TITLE.ATTACH.IMAGE" />
                      <span className="text-danger ml-1 mr-1">
                        <FormattedMessage id="TITLE.UPLOAD.JPEG.JPG.PNG.FILES.ONLY" />
                      </span>
                    </Form.Label>
                    <span className="small-text">
                      <FormattedMessage id="TITLE.MAXIMUM.SIZE" />: 5MB
                    </span>
                  </div>
                  <section>
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      <File
                        size={32}
                        className="text-dark"
                        style={{ strokeWidth: "1.5px" }}
                      ></File>
                      <p style={{ fontSize: "13px" }}>
                        <FormattedMessage id="TITLE.DRAG.DROP.IMAGES.HERE.OR.CLICK.THE.ICON" />
                      </p>
                    </div>
                    {isFileUploadLoading && <ContentLoader />}
                    <ThumbnailWrapper
                      files={files}
                      handleOnDeleteOfAttachment={handleOnDeleteOfAttachment}
                    />
                  </section>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <div className="d-flex align-items-center justify-content-between">
                    <Form.Label htmlFor="description">
                      <FormattedMessage id="TITLE.DESCRIPTION" />
                    </Form.Label>
                  </div>
                  <Form.Control
                    as="textarea"
                    id="description"
                    resize="none"
                    placeholder={intl.formatMessage({
                      id: "TITLE.DESCRIPTION",
                    })}
                    name="description"
                    {...formikEditProduct.getFieldProps("description")}
                  />
                  {formikEditProduct.touched.description &&
                  formikEditProduct.errors.description ? (
                    <div className="text-danger">
                      {formikEditProduct.errors.description}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            variant="success mr-3 btn-inventory"
            onClick={() => {
              formikEditProduct.handleSubmit();
            }}
            disabled={isSaveLoading}
          >
            <FormattedMessage id="TITLE.SAVE" />
            {isSaveLoading && (
              <Spinner animation="border" size="sm" className="ml-1" />
            )}
          </Button>
          <Button
            type="button"
            variant="tertiary text-dark"
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <FormattedMessage id="TITLE.CANCEL" />
          </Button>
        </Modal.Footer>
      </Modal>
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
      {isOpenAddNewProductUnitModal && (
        <AddNewUnitModal
          searchedProductUnitName={searchedProductUnitName}
          open={isOpenAddNewProductUnitModal}
          onCloseModal={addNewProductUnitModalHide}
          handleCloseOfAddNewProductUnitModal={
            handleCloseOfAddNewProductUnitModal
          }
        />
      )}
      {isOpenAddNewCategoryModal && (
        <AddNewCategoryModal
          searchedCategoryName={searchedCategoryName}
          open={isOpenAddNewCategoryModal}
          onCloseModal={addNewCategoryModalHide}
          handleCloseOfAddNewCategoryModal={handleCloseOfAddNewCategoryModal}
        />
      )}
    </>
  );
}
