import React, { useContext, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import CountUp from "react-countup";
import { Box, ShoppingBag, Tag, Users } from "react-feather";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { USER_DETAILS } from "../../constants/const";
import { MENUITEMS } from "../../constants/menu";
import { setActiveMenu } from "../../constants/utils";
import { UIContext } from "../../helper/UIContext";

function DashboardStatistics({
  noOfProducts,
  noOfCategories,
  noOfCustomers,
  noOfVendors,
}) {
  const history = useHistory();
  const uiContext = useContext(UIContext);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);

  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const { role } = userDetails || {};
  const { name: userRole } = role || {};

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  return (
    <>
      <Row>
        <Col
          lg={3}
          md={3}
          className="col-6 cursor-pointer"
          onClick={() => {
            if (userRole === "Inventory Manager") {
              onClickChangeRoute("/inventoryproducts/products");
            }
            window.scrollTo(0, 0);
          }}
        >
          <Card className="statistic">
            <Card.Body className="bg-white text-center">
              <div
                className="icons-widgets d-flex justify-content-center mb-3 "
                size={20}
              >
                <div className="align-self-center text-center bg-primary">
                  <Box className="font-white" />
                </div>
              </div>
              <h3 className="mb-0">
                <CountUp
                  className="counter font-weight-bold"
                  end={noOfProducts}
                />
              </h3>
              <span className="m-0 small-text text-muted">
                <FormattedMessage id="TITLE.NO.OF.PRODUCTS" />
              </span>
            </Card.Body>
          </Card>
        </Col>

        <Col
          lg={3}
          md={3}
          className="col-6 cursor-pointer"
          onClick={() => {
            if (userRole === "Inventory Manager") {
              onClickChangeRoute("/master/categories/category-listing");
            }
            window.scrollTo(0, 0);
          }}
        >
          <Card className="statistic">
            <Card.Body className="bg-white text-center">
              <div className="icons-widgets d-flex justify-content-center mb-3 ">
                <div className="align-self-center text-center bg-info">
                  <Tag className="font-white" size={20} />
                </div>
              </div>
              <h3 className="mb-0">
                <CountUp
                  className="counter font-weight-bold"
                  end={noOfCategories}
                />
              </h3>
              <span className="m-0 small-text text-muted">
                <FormattedMessage id="TITLE.NO.OF.CATEGORIES" />
              </span>
            </Card.Body>
          </Card>
        </Col>
        <Col
          lg={3}
          md={3}
          className="col-6 cursor-pointer"
          onClick={() => {
            if (
              userRole !== "Inward Manager" &&
              userRole !== "Outward Manager"
            ) {
              onClickChangeRoute("/customers/customer-listing");
            }
            window.scrollTo(0, 0);
          }}
        >
          <Card className="statistic">
            <Card.Body className="bg-white text-center">
              <div className="icons-widgets d-flex justify-content-center mb-3">
                <div className="align-self-center text-center bg-success">
                  <Users className="font-white" size={20} />
                </div>
              </div>
              <h3 className="mb-0">
                <CountUp
                  className="counter font-weight-bold"
                  end={noOfCustomers}
                />
              </h3>
              <span className="m-0 small-text text-muted">
                <FormattedMessage id="TITLE.NO.OF.CUSTOMERS" />
              </span>
            </Card.Body>
          </Card>
        </Col>
        <Col
          lg={3}
          md={3}
          className="col-6 cursor-pointer"
          onClick={() => {
            if (
              userRole !== "Outward Manager" &&
              userRole !== "Inward Manager"
            ) {
              onClickChangeRoute("/vendors/vendor-listing");
            }
            window.scrollTo(0, 0);
          }}
        >
          <Card className="statistic">
            <Card.Body className="bg-white text-center">
              <div className="icons-widgets d-flex justify-content-center mb-3 cursor-pointer">
                <div className="align-self-center text-center bg-warning">
                  <ShoppingBag className="font-white" size={20} />
                </div>
              </div>
              <h3 className="mb-0">
                <CountUp
                  className="counter font-weight-bold"
                  end={noOfVendors}
                />
              </h3>
              <span className="m-0 small-text text-muted">
                <FormattedMessage id="TITLE.NO.OF.VENDORS" />
              </span>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default DashboardStatistics;
