import React, { useMemo } from "react";
import {
  Card,
  Button,
  OverlayTrigger,
  Dropdown,
  Tooltip,
} from "react-bootstrap";
import { Upload, Printer } from "react-feather";
import { LowStockTable } from "./lowstock-table/LowStockTable";
import { useLowStockUIContext } from "./LowStockUIContext";
import { LowStockFilter } from "./low-stock-filter/LowStockFilter";
import Moment from "react-moment";
import { DD_MM_YYYY } from "../../../constants/const";
import { FormattedMessage } from "react-intl";
import { useLang } from "../../../i18n/Metronici18n";

export function LowStockCard({
  isLoading,
  isLoadingFilter,
  startDate,
  endDate,
  selectedDateOption,
  reportLowStockItems,
  reportLowStockItemsTotalCount,
  reportLowStockItemsTotalStock,
  handleCLickOfFilterButtonFilter,
  handleCLickOfResetButtonFilter,
  handleClickOfDateFilterButton,
  handleExport,
  handlePrint,
  handleSelectedDateOption,
}) {
  const selectedLang = useLang();
  const lowstockUIContext = useLowStockUIContext();
  const lowstockUIProps = useMemo(() => {
    return {
      ids: lowstockUIContext.ids,
      queryParams: lowstockUIContext.queryParams,
      setQueryParams: lowstockUIContext.setQueryParams,
      openViewDialog: lowstockUIContext.openViewDialog,
      openEditDialog: lowstockUIContext.openEditDialog,
      openDeleteDialog: lowstockUIContext.openDeleteDialog,
    };
  }, [lowstockUIContext]);

  const handleApplyDatePicker = (event, picker) => {
    const { chosenLabel } = picker || {};
    const fromDate = picker.startDate._d.toISOString();
    const endDate = picker.endDate._d.toISOString();
    handleSelectedDateOption(picker.chosenLabel);
    handleClickOfDateFilterButton(chosenLabel, fromDate, endDate);
  };

  const printTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <FormattedMessage id="TITLE.PRINT.REPORT" />
    </Tooltip>
  );

  return (
    <>
      <Card className="card-custom card-stretch make-payment-wrapper">
        <Card.Header className="align-items-center d-flex justify-content-between small-header">
          <div>
            {/* <DateRangePicker
              onApply={handleApplyDatePicker}
              initialSettings={{
                ranges: customRangeReport,
                opens: selectedLang === "ar" ? "left" : "right",
                applyButtonClasses: "btn-secondary",
              }}
            >
              <Button
                variant="tertiary"
                className="btn-icon btn-sm fs-14 f-w-600"
              >
                <Calendar size={16} />
                {selectedDateOption}
              </Button>
            </DateRangePicker> */}
          </div>
          <div className="d-flex">
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={printTooltip}
            >
              <Button
                variant="outline-tertiary btn-sm fs-14 f-w-600 btn-icon mr-2"
                onClick={handlePrint}
              >
                <Printer size={16} className="p-0 mr-2" />
                <span className="d-none d-md-flex">
                  <FormattedMessage id="TITLE.PRINT" />
                </span>
              </Button>
            </OverlayTrigger>
            <Dropdown>
              <Dropdown.Toggle variant="dropdown-sm" id="dropdown-basic">
                <span className="d-none d-md-flex">
                  <FormattedMessage id="TITLE.EXPORT.AS" />
                </span>
                <Upload size={16} className="p-0 mr-2 d-block d-md-none" />
              </Dropdown.Toggle>

              <Dropdown.Menu alignRight>
                <Dropdown.Item
                  onClick={() => {
                    handleExport("PDF");
                  }}
                >
                  <FormattedMessage id="TITLE.PDF" />
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    handleExport("Excel");
                  }}
                >
                  <FormattedMessage id="TITLE.XLSX.MICROSOFT.EXCEL" />
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    handleExport("CSV");
                  }}
                >
                  <FormattedMessage id="TITLE.CSV.COMMA.SEPARATED.VALUE" />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="text-center pt-2 pb-5">
            <h3 className="page-title">
              {" "}
              <FormattedMessage id="TITLE.LOW.STOCK.ITEMS.REPORT" />
            </h3>
            <h6 className="fs-14 text-black">
              <span className="mr-1">
                <FormattedMessage id="TITLE.FROM" />
              </span>
              <span className="mr-1">
                <Moment date={startDate} format={DD_MM_YYYY} />
              </span>
              <span className="mr-1">
                <FormattedMessage id="TITLE.TO" />
              </span>
              <span className="mr-1">
                <Moment date={endDate} format={DD_MM_YYYY} />
              </span>
            </h6>
          </div>

          <LowStockFilter
            isLoadingFilter={isLoadingFilter}
            handleCLickOfFilterButtonFilter={handleCLickOfFilterButtonFilter}
            handleCLickOfResetButtonFilter={handleCLickOfResetButtonFilter}
          />

          <LowStockTable
            isLoading={isLoading}
            reportLowStockItems={reportLowStockItems}
            reportLowStockItemsTotalCount={reportLowStockItemsTotalCount}
            reportLowStockItemsTotalStock={reportLowStockItemsTotalStock}
          />
        </Card.Body>
      </Card>
    </>
  );
}
