// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as uiHelpers from "../CustomerUIHelpers";
import { useCustomerUIContext } from "../CustomerUIContext";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../helper/helper";
import { Pagination } from "../../../pagination/Pagination";
import StatusTypeFormatter from "../../../columnformatter/StatusTypeFormatter";
import ActionsColumnFormatter from "../../../columnformatter/ActionsColumnFormatter";
import EmailColumnFormatter from "../../../columnformatter/EmailColumnFormatter copy";
import PhoneNumberColumnFormatter from "../../../columnformatter/PhoneNumberColumnFormatter";
import CurrencyColumnFormatter from "../../../columnformatter/CurrencyColumnFormatter copy";
import CustomerNameColumnFormatter from "../../../columnformatter/CustomerNameColumnFormatter";
import { FormattedMessage } from "react-intl";

export function CustomerTable({
  isLoading,
  customerList,
  customerListTotalCount,
}) {
  // Customer UI Context
  const customerUIContext = useCustomerUIContext();
  const customerUIProps = useMemo(() => {
    return {
      ids: customerUIContext.ids,
      setIds: customerUIContext.setIds,
      queryParams: customerUIContext.queryParams,
      setQueryParams: customerUIContext.setQueryParams,
      openViewDialog: customerUIContext.openViewDialog,
      openEditDialog: customerUIContext.openEditDialog,
      openDeleteDialog: customerUIContext.openDeleteDialog,
      redirectToViewPage: customerUIContext.redirectToViewPage,
    };
  }, [customerUIContext]);

  // Table columns
  const columns = [
    {
      dataField: "contact_name",
      text: <FormattedMessage id="TITLE.CUSTOMER.NAME" />,
      sort: true,
      style: {
        minWidth: "170px",
      },
      sortCaret: sortCaret,
      formatter: CustomerNameColumnFormatter,
      formatExtraData: {
        redirectToViewPage: customerUIProps.redirectToViewPage,
      },
    },
    {
      dataField: "email",
      text: <FormattedMessage id="TITLE.EMAIL.ADDRESS" />,
      sort: true,
      style: {
        minWidth: "170px",
      },
      sortCaret: sortCaret,
      formatter: EmailColumnFormatter,
    },
    {
      dataField: "phone_number",
      text: <FormattedMessage id="TITLE.PHONE.NUMBER" />,
      classes: "ltr-direction",
      sort: true,
      style: {
        minWidth: "170px",
      },
      sortCaret: sortCaret,
      formatter: PhoneNumberColumnFormatter,
    },
    {
      dataField: "currency",
      text: <FormattedMessage id="TITLE.CURRENCY" />,
      sort: true,
      style: {
        minWidth: "170px",
      },
      sortCaret: sortCaret,
      formatter: CurrencyColumnFormatter,
    },
    {
      dataField: "status",
      text: <FormattedMessage id="TITLE.STATUS" />,
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "100px",
      },
      formatter: StatusTypeFormatter,
    },
    // {
    //   dataField: "amount",
    //   text: "Amount",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   style: {
    //     minWidth: "150px",
    //   },
    //   formatter: AmountColumnFormatter,
    // },
    // {
    //   dataField: "expectedDeliveryDate",
    //   text: "Expected Delivery Date",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   style: {
    //     minWidth: "130px",
    //   },
    //   formatter: ExpectedDateColumnFormatter,
    // },
    {
      dataField: "action",
      text: <FormattedMessage id="TITLE.ACTIONS" />,
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openViewDialog: customerUIProps.openViewDialog,
        openEditDialog: customerUIProps.openEditDialog,
        openDeleteDialog: customerUIProps.openDeleteDialog,
        isVisibleViewButton: true,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "120px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: customerListTotalCount ? customerListTotalCount : 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: customerUIProps.queryParams.pageSize,
    onPageChange: (page) => {
      if (page === 0) return;
    },
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-head-bg table-borderless table-vertical-center customer-table table-inventory"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={customerList ? customerList : []}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  customerUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
              <PleaseWaitMessage isLoading={isLoading} />
              <NoRecordsFoundMessage
                entities={customerList}
                isLoading={isLoading}
              />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
