/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { capitalizeFirstLetter } from "../constants/utils";

const ParentLocationColumnFormatter = (cellContent, row, rowIndex) => {
  const { locationId } = row || {};
  const { store_location } = locationId || {};
  return capitalizeFirstLetter(store_location);
};

export default ParentLocationColumnFormatter;
