import { FieldArray, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Row, Col, Form, Table } from "react-bootstrap";
import { X, Check, XCircle, Plus, FilePlus } from "react-feather";
import Select from "react-select";
import { FormattedMessage, useIntl } from "react-intl";
import { ProductBarcodeSchema } from "../helper/FormikSchema";
import ErrorMessage from "../helper/ErrorMessage";
import DatePicker from "react-datepicker";
import {
  DD_MM_YYYY,
  dd_MM_yyyy,
  SET_TIMEOUT,
  YYYY_MM_DD,
} from "../constants/const";
import Switch from "react-switch";
import moment from "moment";
import { ButtonLoader } from "../helper/ButtonLoader";
import {
  addNewBarcodeApi,
  receivePOLocationListApi,
} from "../axios/services/services";
import {
  getErrorMessageBasedOnLanguage,
  unauthorization,
} from "../constants/utils";
import { ContentLoader } from "../helper/ContentLoader";
import { ErrorModal } from "../components/common/modals/ErrorModal";
import { ConfirmationModal } from "../components/common/modals/ConfirmationModal";

export function AddNewBarcodeModal({
  onCloseModal,
  open,
  product,
  handleCancelOfAddNewBarcode,
  handleAddNewBarcode,
}) {
  const intl = useIntl();
  const [isAddPrductBarcodeTable, setAddPrductBarcodeTable] = useState(true);
  const [isFormSuccess, setFormSuccess] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [firstLocation, setFirstLocation] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoadingLocationList, setLoadingLocationList] = useState(false);
  const [isLoadingAddNewBarcode, setLoadingAddNewBarcode] = useState(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isLoadingConfirmation, setLoadingConfirmation] = useState(false);
  const [addNewBarcodeObj, setAddNewBarcodeObj] = useState(null);

  const [initialValues, setInitialValues] = useState({
    productId: product.id,
    purchaseorder_barcode: [
      {
        barcode_no: "",
        expiry_date: "",
        locationId: "",
        condition: true,
      },
    ],
  });

  const handleAddPurchaseorderBarcodeRef = useRef([]);
  const handleAddNewBarcodeItemRef = useRef([]);

  async function getLocationList() {
    enableLoadingLocationList();
    const locationListResponse = await receivePOLocationListApi();
    const { Error: ErrorLocation, data: locationData } =
      locationListResponse || {};
    if (ErrorLocation) {
      unauthorization(ErrorLocation);
      setErrorMessage(getErrorMessageBasedOnLanguage(ErrorLocation));
      handleErrorModalShow();
      return;
    }

    const locations = locationData.map((location) => {
      const { store_location, id, parent_location, locationId } =
        location || {};

      const { locationId: li } = locationId || {};
      let locName = store_location;
      var comma = ",";
      if (parent_location) {
        locName = store_location.concat(comma, locationId?.store_location);
        if (li) {
          locName = locName.concat(comma, li?.store_location);
        }
      }
      location.value = id;
      location.label = locName;
      return location;
    });
    const [firstLocation] = locations || {};
    const expiryDate = product?.shelf_life
      ? moment(new Date()).add(product?.shelf_life, "days").toDate()
      : "";
    let obj = {
      barcode_no: "",
      locationId: firstLocation?.id,
      expiry_date: expiryDate ? expiryDate : null,
      condition: true,
      quantity: 1,
      selectedLocation: firstLocation,
    };
    initialValues.purchaseorder_barcode = [obj];
    setInitialValues(initialValues);
    setFirstLocation(firstLocation);
    setLocationList(locations ? locations : []);
    disableLoadingLocationList();
  }

  useEffect(() => {
    getLocationList();
  }, []);

  const onChangeOfLocation = (setFieldValue, data, index) => {
    const { value } = data || {};
    setFieldValue(`purchaseorder_barcode.${index}.locationId`, value);
    setFieldValue(`purchaseorder_barcode.${index}.selectedLocation`, data);
  };

  const onChangeofExpiryDate = (setFieldValue, date, index) => {
    setFieldValue(`purchaseorder_barcode.${index}.expiry_date`, date);
  };

  const enableLoadingLocationList = () => {
    setLoadingLocationList(true);
  };

  const disableLoadingLocationList = () => {
    setLoadingLocationList(false);
  };

  const enableLoadingAddNewBarcode = () => {
    setLoadingAddNewBarcode(true);
  };

  const disableLoadingAddNewBarcode = () => {
    setLoadingAddNewBarcode(false);
  };

  const enableLoadingAddNewBarcodeConfirmation = () => {
    setLoadingConfirmation(true);
  };

  const disableLoadingAddNewBarcodeConfirmation = () => {
    setLoadingConfirmation(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleConfirmationModalShow = () => {
    setConfirmationModalOpen(true);
  };

  const handleConfirmationModalHide = () => {
    setConfirmationModalOpen(false);
  };

  const handleConfirmationPositiveButton = () => {
    addNewBarcodeAPICall();
  };

  const renderLocationValue = (product) => {
    const obj = {
      label: product?.selectedLocation?.label,
      value: product?.selectedLocation?.value,
    };
    return obj;
  };

  const addNewBarcodeAPICall = async () => {
    const { purchaseorder_barcode } = addNewBarcodeObj || {};
    const purchaseOrderBarcode = [];
    const poBarcode = purchaseorder_barcode.map((barcode) => {
      const { barcode_no, condition, expiry_date, locationId } = barcode || {};
      purchaseOrderBarcode.push({
        locationId,
        expiry_date: expiry_date
          ? moment(expiry_date).format(YYYY_MM_DD)
          : null,
        condition,
        barcode_no,
      });
      return barcode;
    });
    enableLoadingAddNewBarcode();
    enableLoadingAddNewBarcodeConfirmation();
    let obj = {
      productId: product.id,
      purchaseorder_barcode: purchaseOrderBarcode,
    };
    const addNewBarcodeResponse = await addNewBarcodeApi(obj);
    const { Error } = addNewBarcodeResponse || {};
    if (Error) {
      disableLoadingAddNewBarcode();
      disableLoadingAddNewBarcodeConfirmation();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    handleConfirmationModalHide();
    window.scrollTo(0, 0);
    setFormSuccess(true);
    setTimeout(() => {
      disableLoadingAddNewBarcode();
      disableLoadingAddNewBarcodeConfirmation();
      handleAddNewBarcode();
    }, SET_TIMEOUT);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={ProductBarcodeSchema}
        onSubmit={(values) => {
          setAddNewBarcodeObj(values);
          handleConfirmationModalShow();
        }}
        onReset={(values) => {}}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          resetForm,
          handleReset,
          setFieldValue,
          errors,
          touched,
        }) => (
          <Form
            className="form form-label-right filter-card"
            autoComplete="off"
            onSubmit={handleSubmit}
            onReset={() => {
              resetForm(initialValues);
              handleReset();
            }}
          >
            <Modal
              size="md"
              show={open}
              onHide={onCloseModal}
              aria-labelledby="example-modal-sizes-title-sm"
              dialogClassName="addvendor-modal modal"
              scrollable={true}
            >
              {isLoadingLocationList && <ContentLoader />}
              <Modal.Header className="d-flex justify-content-between align-items-center">
                <h3 className="modal-title" id="exampleModalLabel2">
                  <FormattedMessage id="TITLE.ADD.NEW.PRODUCT.BARCODE" />
                </h3>
                <Button
                  variant="link p-0 btn-icon"
                  onClick={() => onCloseModal("VaryingMdo")}
                >
                  <X size={20} className="text-dark mr-0 pr-0" />
                </Button>
              </Modal.Header>
              <Modal.Body>
                <div className="column-body with-footer custom-scrollbar">
                  {isFormSuccess && (
                    <div className="notification icon inline success fs-16">
                      <Check size={8} className="text-white"></Check>
                      <FormattedMessage id="TITLE.PRODUCT.BARCODE.ADDRED.SUCCESSFULLY" />
                    </div>
                  )}

                  <Row>
                    <Col md={12}>
                      <Table
                        className="receive-item-table"
                        style={{ tableLayout: "fixed" }}
                      >
                        <thead>
                          <tr>
                            <th>
                              <FormattedMessage id="TITLE.LOCATION" />
                            </th>
                            <th>
                              <FormattedMessage id="TITLE.EXPIRY.DATE" />
                            </th>
                            <th
                              className="text-center"
                              style={{ width: "15%" }}
                            >
                              <FormattedMessage id="TITLE.CONDITION" />
                            </th>
                            <th>
                              <FormattedMessage id="TITLE.BARCODE.NO" />
                            </th>
                            <th style={{ width: "5px" }}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {isAddPrductBarcodeTable && (
                            <FieldArray
                              name="purchaseorder_barcode"
                              render={(purchaseorder_barcode) =>
                                values?.purchaseorder_barcode?.map(
                                  (product, index) => (
                                    <tr key={index}>
                                      <td>
                                        <Select
                                          onChange={(data) =>
                                            onChangeOfLocation(
                                              setFieldValue,
                                              data,
                                              index
                                            )
                                          }
                                          isSearchable={true}
                                          options={locationList}
                                          placeholder="Select"
                                          id="state"
                                          className="react-select-container receive-product-select"
                                          classNamePrefix="react-select"
                                          value={renderLocationValue(product)}
                                        />
                                        {touched.purchaseorder_barcode &&
                                        errors.purchaseorder_barcode &&
                                        errors.purchaseorder_barcode.length >
                                          0 &&
                                        errors.purchaseorder_barcode[index] ? (
                                          <div className="text-danger">
                                            <ErrorMessage
                                              id={
                                                errors.purchaseorder_barcode[
                                                  index
                                                ].locationId
                                              }
                                            />
                                          </div>
                                        ) : null}
                                      </td>
                                      <td>
                                        <Form.Group>
                                          <DatePicker
                                            selected={product.expiry_date}
                                            dateFormat={dd_MM_yyyy}
                                            onChange={(date) =>
                                              onChangeofExpiryDate(
                                                setFieldValue,
                                                date,
                                                index
                                              )
                                            }
                                            placeholderText={DD_MM_YYYY}
                                            className="form-control w-100"
                                            closeOnScroll={(e) =>
                                              e.target === document
                                            }
                                            minDate={new Date()}
                                          />
                                          {touched.purchaseorder_barcode &&
                                          errors.purchaseorder_barcode &&
                                          errors.purchaseorder_barcode.length >
                                            0 &&
                                          errors.purchaseorder_barcode[
                                            index
                                          ] ? (
                                            <div className="text-danger">
                                              <ErrorMessage
                                                id={
                                                  errors.purchaseorder_barcode[
                                                    index
                                                  ].expiry_date
                                                }
                                              />
                                            </div>
                                          ) : null}
                                        </Form.Group>
                                      </td>
                                      <td
                                        style={{
                                          verticalAlign: "middle",
                                          textAlign: "center",
                                        }}
                                      >
                                        <Switch
                                          onChange={(event) => {
                                            setFieldValue(
                                              `purchaseorder_barcode.${index}.condition`,
                                              event
                                            );
                                          }}
                                          checked={product.condition}
                                          onColor="#009970"
                                          offHandleColor="#fff"
                                          onHandleColor="#fff"
                                          className="react-switch"
                                          height={20}
                                          width={40}
                                        />
                                      </td>
                                      <td>
                                        <Form.Control
                                          size="sm"
                                          id="barcodenumber"
                                          name="barcode_no"
                                          type="text"
                                          className="form-control small-text"
                                          placeholder="00-AAA-0000000"
                                          value={product?.barcode_no}
                                          onChange={(event) => {
                                            const value = event.target.value;
                                            setFieldValue(
                                              `purchaseorder_barcode.${index}.barcode_no`,
                                              value
                                            );
                                          }}
                                          maxLength={14}
                                          onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                              handleAddNewBarcodeItemRef.current.click();
                                            }
                                          }}
                                          autoFocus
                                        />
                                        {touched.purchaseorder_barcode &&
                                        errors.purchaseorder_barcode &&
                                        errors.purchaseorder_barcode.length >
                                          0 &&
                                        errors.purchaseorder_barcode[index] ? (
                                          <div className="text-danger">
                                            <ErrorMessage
                                              id={
                                                errors.purchaseorder_barcode[
                                                  index
                                                ].barcode_no
                                              }
                                            />
                                          </div>
                                        ) : null}
                                      </td>
                                      <td>
                                        <Button
                                          variant="link p-0 m-0"
                                          className="delete-table-row"
                                          onClick={() => {
                                            if (
                                              values.purchaseorder_barcode
                                                .length === 1
                                            ) {
                                              setAddPrductBarcodeTable(false);
                                            }
                                            purchaseorder_barcode.remove(index);
                                          }}
                                        >
                                          <XCircle
                                            size={16}
                                            style={{
                                              color: "#990029",
                                              marginRight: "0",
                                            }}
                                          />
                                        </Button>
                                        <Button
                                          variant={`link p-0 ml-2 d-none`}
                                          ref={(el) =>
                                            (handleAddPurchaseorderBarcodeRef.current[
                                              index
                                            ] = el)
                                          }
                                          onClick={() => {
                                            const lastProductCondition =
                                              values?.purchaseorder_barcode[
                                                values?.purchaseorder_barcode
                                                  ?.length - 1
                                              ];
                                            const {
                                              locationId,
                                              selectedLocation,
                                              expiry_date,
                                            } = lastProductCondition || {};
                                            const obj = {
                                              barcode_no: "",
                                              locationId,
                                              expiry_date,
                                              condition: true,
                                              selectedLocation,
                                            };
                                            purchaseorder_barcode.insert(
                                              values?.purchaseorder_barcode
                                                ?.length,
                                              obj
                                            );
                                          }}
                                        ></Button>
                                      </td>
                                    </tr>
                                  )
                                )
                              }
                            />
                          )}

                          {!isAddPrductBarcodeTable && (
                            <tr>
                              <td colSpan={5}>
                                <div className="d-grid gap-2">
                                  <Button
                                    variant="gray btn-icon btn-block"
                                    onClick={() => {
                                      if (
                                        values.purchaseorder_barcode.length ===
                                        0
                                      ) {
                                        const expiryDate = product?.shelf_life
                                          ? moment(new Date())
                                              .add(product?.shelf_life, "days")
                                              .toDate()
                                          : "";
                                        const obj = {
                                          barcode_no: "",
                                          locationId: firstLocation?.id,
                                          expiry_date: expiryDate,
                                          condition: true,
                                          selectedLocation: firstLocation,
                                        };
                                        values.purchaseorder_barcode.push(obj);
                                      }
                                      setAddPrductBarcodeTable(true);
                                    }}
                                  >
                                    <Plus
                                      size={16}
                                      style={{ color: "#333333" }}
                                    />
                                    <FormattedMessage id="TITLE.ADD.BARCODE" />
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>

                      {isAddPrductBarcodeTable && (
                        <Button
                          ref={handleAddNewBarcodeItemRef}
                          variant="link btn-icon text-primary px-0 fs-14"
                          onClick={() => {
                            if (
                              handleAddPurchaseorderBarcodeRef.current &&
                              handleAddPurchaseorderBarcodeRef.current.length >
                                0
                            ) {
                              handleAddPurchaseorderBarcodeRef.current[0].click();
                            }
                          }}
                        >
                          <Plus size={14} className="text-primary" />
                          <FormattedMessage id="TITLE.ADD.NEW.BARCODE" />
                        </Button>
                      )}
                    </Col>
                  </Row>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="column-footer">
                  <Button
                    type="submit"
                    variant="success mr-3 btn-inventory"
                    onClick={() => {
                      handleSubmit();
                    }}
                    disabled={isLoadingAddNewBarcode}
                  >
                    <FormattedMessage id="TITLE.SAVE" />
                    {isLoadingAddNewBarcode && (
                      <ButtonLoader className="mr-1 ml-1" />
                    )}
                  </Button>
                  <Button
                    variant="tertiary text-dark btn-inventory"
                    onClick={() => {
                      handleCancelOfAddNewBarcode();
                    }}
                    disabled={isLoadingAddNewBarcode}
                  >
                    <FormattedMessage id="TITLE.CANCEL" />
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
          </Form>
        )}
      </Formik>
      {isConfirmationModalOpen && (
        <ConfirmationModal
          isLoading={isLoadingConfirmation}
          title={""}
          isSuccess={"text-success"}
          variant={"success"}
          icon={
            <FilePlus
              size={60}
              className="text-success mb-2"
              style={{ strokeWidth: "1.5px" }}
            />
          }
          description={intl.formatMessage({
            id: "TITLE.ARE.YOU.SURE.YOU.WANT.TO.ADD.NEW.PRODUCT.ITEMS",
          })}
          cancelText={intl.formatMessage({ id: "TITLE.CANCEL" })}
          logoutText={intl.formatMessage({ id: "TITLE.YES" })}
          isOpen={isConfirmationModalOpen}
          onCloseModal={handleConfirmationModalHide}
          id={product.name}
          onClickOfConfirmationButton={handleConfirmationPositiveButton}
        />
      )}
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
