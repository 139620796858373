export const ProductStatusCssClasses = ["success", "info", ""];
export const ProductStatusTitles = ["Selling", "Sold"];
export const ProductConditionCssClasses = ["success", "danger", ""];
export const ProductConditionTitles = ["New", "Used"];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  // { text: "20", value: 20 },
  // { text: "50", value: 50 },
];
export const initialFilter = {
  filter: { date: "thismonth" },
  sortOrder: "asc", // asc||desc
  sortField: "ponumber",
  pageNumber: 1,
  pageSize: 10,
};
