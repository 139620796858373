import React, { useMemo, useState } from "react";
import { Formik } from "formik";
import { Search, RefreshCw } from "react-feather";
import { Form, Button, FormGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { ButtonLoader } from "../../../helper/ButtonLoader";
import { useInwardUIContext } from "../InwardUIContext";
import { isEqual } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import { dd_MM_yyyy, YYYY_MM_DD } from "../../../constants/const";
import { ErrorModal } from "../../common/modals/ErrorModal";

export function InwardFilter({
  isLoadingFilter,
  POStatusList,
  handleCLickOfFilterButtonFilter,
}) {
  const intl = useIntl();
  const [filterStatus, setFilterStatus] = useState(" ");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const initialFilterValues = {
    purchaseorder_number: "",
    startdate: startDate,
    enddate: endDate,
    contact_name: "",
    status: "",
    totalamountone: "",
    totalamounttwo: "",
  };

  const inwardUIContext = useInwardUIContext();
  const inwardUIUIProps = useMemo(() => {
    return {
      queryParams: inwardUIContext.queryParams,
      setQueryParams: inwardUIContext.setQueryParams,
    };
  }, [inwardUIContext]);

  const prepareFilter = (queryParams, values) => {
    const {
      purchaseorder_number,
      startdate,
      enddate,
      contact_name,
      status,
      totalamountone,
      totalamounttwo,
    } = values;

    const newQueryParams = { ...queryParams };
    const filter = {};

    if (startdate && enddate) {
      const date = {
        startdate: startdate ? moment(startdate).format(YYYY_MM_DD) : undefined,
        enddate: enddate ? moment(enddate).format(YYYY_MM_DD) : undefined,
      };
      filter.date = date !== "" ? date : undefined;
    }

    filter.purchaseorder_number =
      purchaseorder_number !== "" ? purchaseorder_number : undefined;
    filter.contact_name = contact_name !== "" ? contact_name : undefined;
    filter.status = status !== "" ? status : undefined;
    filter.totalamountone = totalamountone !== "" ? +totalamountone : undefined;
    filter.totalamounttwo = totalamounttwo !== "" ? +totalamounttwo : undefined;
    newQueryParams.filter = filter;
    return newQueryParams;
  };

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(inwardUIUIProps.queryParams, values);
    if (!isEqual(newQueryParams, inwardUIUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      inwardUIUIProps.setQueryParams(newQueryParams);
    }
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  return (
    <>
      <Formik
        initialValues={initialFilterValues}
        onSubmit={(values) => {
          const { startdate, enddate, totalamountone, totalamounttwo } =
            values || {};
          if (startdate && !enddate) {
            setErrorMessage(
              intl.formatMessage({
                id: "TITLE.SELECT.END.DATE",
              })
            );
            handleErrorModalShow();
            return;
          }
          if (!startdate && enddate) {
            setErrorMessage(
              intl.formatMessage({
                id: "TITLE.SELECT.START.DATE",
              })
            );
            handleErrorModalShow();
            return;
          }
          if (totalamountone && !totalamounttwo) {
            setErrorMessage(
              intl.formatMessage({
                id: "TITLE.ENTER.TO.AMOUNT",
              })
            );
            handleErrorModalShow();
            return;
          }
          if (!totalamountone && totalamounttwo) {
            setErrorMessage(
              intl.formatMessage({
                id: "TITLE.ENTER.FROM.AMOUNT",
              })
            );
            handleErrorModalShow();
            return;
          }
          handleCLickOfFilterButtonFilter();
          applyFilter(values);
        }}
        onReset={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          resetForm,
          handleReset,
          setFieldValue,
        }) => (
          <Form
            autoComplete="off"
            className="form form-label-right filter-card"
            onSubmit={handleSubmit}
            onReset={() => {
              resetForm(initialFilterValues);
              setStartDate(null);
              setEndDate(null);
              setFilterStatus("");
              handleReset();
            }}
          >
            <Form.Group className="form-group mb-md-0 filter-form-group">
              <div className="filter-item">
                <div className="">
                  <small className="form-text text-muted mb-1">
                    <b>
                      <FormattedMessage id="TITLE.PO.NUMBER" />
                    </b>
                  </small>
                  <Form.Control
                    type="text"
                    className="form-control"
                    name="purchaseorder_number"
                    placeholder={intl.formatMessage({
                      id: "TITLE.PO.NUMBER",
                    })}
                    onChange={(e) => {
                      setFieldValue("purchaseorder_number", e.target.value);
                    }}
                  />
                </div>
                <div className="">
                  <small className="form-text text-muted mb-1">
                    <b>
                      <FormattedMessage id="TITLE.CREATED.DATE" />
                    </b>
                  </small>
                  <FormGroup>
                    <DatePicker
                      dateFormat={dd_MM_yyyy}
                      className="mb-1"
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                        setFieldValue("startdate", date);
                      }}
                      placeholderText={intl.formatMessage({
                        id: "TITLE.START.DATE",
                      })}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                    />
                    <DatePicker
                      dateFormat={dd_MM_yyyy}
                      selected={endDate}
                      onChange={(date) => {
                        setEndDate(date);
                        setFieldValue("enddate", date);
                      }}
                      placeholderText={intl.formatMessage({
                        id: "TITLE.END.DATE",
                      })}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                    />
                  </FormGroup>
                </div>
                <div className="">
                  <small className="form-text text-muted mb-1">
                    <b>
                      <FormattedMessage id="TITLE.VENDOR.NAME" />
                    </b>
                  </small>
                  <Form.Control
                    type="text"
                    className="form-control"
                    name="contact_name"
                    placeholder={intl.formatMessage({
                      id: "TITLE.VENDOR.NAME",
                    })}
                    onChange={(e) => {
                      setFieldValue("contact_name", e.target.value);
                    }}
                    autoComplete="off"
                  />
                </div>
                <div className="">
                  <small className="form-text text-muted mb-1">
                    <b>
                      <FormattedMessage id="TITLE.STATUS" />
                    </b>
                  </small>
                  <Form.Control
                    name="status"
                    as="select"
                    className="select-control"
                    onChange={(event) => {
                      setFieldValue("status", event.target.value);
                      setFilterStatus(event.target.value);
                    }}
                    value={filterStatus}
                  >
                    <option value="">
                      {intl.formatMessage({
                        id: "TITLE.ALL",
                      })}
                    </option>
                    {POStatusList &&
                      POStatusList.map((status, index) => (
                        <option key={index} value={`${status.id}`}>
                          {status.name}
                        </option>
                      ))}
                  </Form.Control>
                </div>
                <div className="">
                  <small className="form-text text-muted mb-1">
                    <b>
                      <FormattedMessage id="TITLE.AMOUNT" />
                    </b>
                  </small>
                  <div className="d-flex">
                    <Form.Control
                      type="number"
                      className="form-control mr-1"
                      style={{ minWidth: "90px" }}
                      name="totalamountone"
                      placeholder={intl.formatMessage({
                        id: "TITLE.FROM",
                      })}
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onPaste={(evt) => {
                        const values = evt.clipboardData.getData("text");
                        const nonAlpha = values.replace(/[^\d.-]/g, "");
                        evt.preventDefault();
                        setFieldValue("totalamountone", nonAlpha);
                      }}
                      onChange={(event) => {
                        setFieldValue("totalamountone", event.target.value);
                      }}
                      value={values.totalamountone}
                      autoComplete="off"
                    />
                    <Form.Control
                      type="number"
                      className="form-control mr-2"
                      style={{ minWidth: "90px" }}
                      name="totalamounttwo"
                      placeholder={intl.formatMessage({
                        id: "TITLE.TO",
                      })}
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onPaste={(evt) => {
                        const values = evt.clipboardData.getData("text");
                        const nonAlpha = values.replace(/[^\d.-]/g, "");
                        evt.preventDefault();
                        setFieldValue("totalamounttwo", nonAlpha);
                      }}
                      onChange={(event) => {
                        setFieldValue("totalamounttwo", event.target.value);
                      }}
                      value={values.totalamounttwo}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              <div className="filter-action d-flex">
                <Button
                  variant="secondary d-flex align-items-center btn-inventory mr-2 ml-2"
                  type="submit"
                >
                  <Search
                    size={20}
                    style={{ color: "#fff", marginRight: "0" }}
                  />
                  {isLoadingFilter && <ButtonLoader className="mr-1 ml-1" />}
                </Button>
                <Button
                  variant="tertiary d-flex align-items-center btn-inventory"
                  type="reset"
                >
                  <RefreshCw
                    size={20}
                    style={{ color: "#CAA25C", marginRight: "0" }}
                  />
                </Button>
              </div>
            </Form.Group>
          </Form>
        )}
      </Formik>

      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
