import React, { useState } from "react";
import { Search, RefreshCw } from "react-feather";
import { Form, Button } from "react-bootstrap";

export function BlockLocationFilter({ filterValues }) {
  const [filterStatus, setFilterStatus] = useState(" ");
  const applyFilter = (values) => {
    filterValues(values);
  };

  return (
    <>
      <Form className="form form-label-right filter-card">
        <Form.Group className="form-group mb-md-0 filter-form-group">
          <div className="filter-item">
            <div className="">
              <small className="form-text text-muted mb-1">
                <b>Store Location</b>
              </small>
              <Form.Control
                type="text"
                className="form-control"
                name="blockLocation"
                placeholder="Store Location"
              />
            </div>
            <div className="">
              <small className="form-text text-muted mb-1">
                <b>Status</b>
              </small>
              <Form.Control
                as="select"
                className="select-control"
                onChange={(event) => {
                  setFilterStatus(event.target.value);
                }}
                value={filterStatus}
              >
                <option value="">All</option>
                <option value="active">Active</option>
                <option value="Inactive">Inactive</option>
              </Form.Control>
            </div>
          </div>
          <div className="filter-action d-flex align-items-center mt-3">
            <Button variant="secondary d-flex align-items-center btn-inventory mr-2">
              <Search
                className="m-0"
                size={20}
                style={{ color: "#fff", marginRight: "0" }}
              />
            </Button>
            <Button variant="tertiary d-flex align-items-center btn-inventory">
              <RefreshCw
                size={20}
                style={{ color: "#CAA25C", marginRight: "0" }}
                className="m-0"
              />
            </Button>
          </div>
        </Form.Group>
      </Form>
    </>
  );
}
