import React, { Fragment, useContext, useState } from "react";
import UserMenu from "./user-menu";
import Language from "./language";
import { Link, useHistory } from "react-router-dom";
import { AlignJustify } from "react-feather";

//images
import logo from "../../../assets/images/dashboard/favicon.png";
import { USER_DETAILS } from "../../../constants/const";
import { FormattedMessage } from "react-intl";
import { setActiveMenu } from "../../../constants/utils";
import { UIContext } from "../../../helper/UIContext";
import { MENUITEMS } from "../../../constants/menu";

const Header = ({ openCloseSidebar }) => {
  const history = useHistory();
  const uiContext = useContext(UIContext);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [navMenus, setNavMenus] = useState(false);

  const toggle = () => {
    setNavMenus((prevState) => ({
      navMenus: !prevState.navMenus,
    }));
  };
  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const { role } = userDetails || {};
  const { name: userRole } = role || {};

  const showRightSidebar = () => {
    if (rightSidebar) {
      setRightSidebar(false);
      document.querySelector(".right-sidebar").classList.add("show");
    } else {
      setRightSidebar(true);
      document.querySelector(".right-sidebar").classList.remove("show");
    }
  };
  const goFull = () => {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  };
  // const openCloseSidebar = () => {
  // 	if (sidebar) {
  // 		setSidebar(false);
  // 		document.querySelector(".page-main-header").classList.add("open");
  // 		document.querySelector(".page-sidebar").classList.add("open");
  // 	} else {
  // 		setSidebar(true);
  // 		document.querySelector(".page-main-header").classList.remove("open");
  // 		document.querySelector(".page-sidebar").classList.remove("open");
  // 	}
  // };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  return (
    <Fragment>
      {/* open */}
      <div className="page-main-header ">
        <div className="main-header-right row">
          <div className="main-header-left d-lg-none">
            {/* <div className="logo-wrapper">
              <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
                <img
                  className="blur-up lazyloaded responsive-img"
                  src={logo}
                  alt=""
                />
              </Link>
            </div> */}
          </div>
          <div className="mobile-sidebar">
            <div className="media-body text-right switch-sm">
              <label className="switch">
                <a href="#javaScript" onClick={openCloseSidebar}>
                  <AlignJustify />
                </a>
              </label>
            </div>
          </div>
          <div className="px-3 d-none d-md-block">
            {userRole === "Admin" ? (
              <Link
                to={`${process.env.PUBLIC_URL}/users/user-listing`}
                className="logo f-w-900 fs-16"
                onClick={() => {
                  const path = `${process.env.PUBLIC_URL}/users/user-listing`;
                  onClickChangeRoute(path);
                }}
              >
                <FormattedMessage id="TITLE.INVENTORY.MANAGEMENT.SYSTEM" />
              </Link>
            ) : (
              <Link
                to={`${process.env.PUBLIC_URL}/dashboard`}
                className="logo f-w-900 fs-16"
                onClick={() => {
                  const path = `${process.env.PUBLIC_URL}/dashboard`;
                  onClickChangeRoute(path);
                }}
              >
                <FormattedMessage id="TITLE.INVENTORY.MANAGEMENT.SYSTEM" />
              </Link>
            )}
          </div>
          ||
          <div className="pt-1 pl-1"> {userRole}</div>
          <div className="nav-right col">
            <ul className={"nav-menus " + (navMenus ? "open" : "")}>
              <li>{/* <SearchHeader /> */}</li>
              <li>
                {/* <a onClick={goFull} className="text-dark" href="#javaScript">
									<Maximize2 />
								</a> */}
              </li>
              <Language />

              {/* <li className="onhover-dropdown">
								<Bell />
								<span className="badge badge-pill badge-primary pull-right notification-badge">
									3
								</span>
								<span className="dot"></span>
								<Notification />
							</li> */}
              {/* <li>
								<a href="#javaScript" onClick={showRightSidebar}>
									<MessageSquare />
									<span className="dot"></span>
								</a>
							</li> */}
              <UserMenu />
            </ul>
            {/* <div
              className="d-lg-none mobile-toggle pull-right"
              onClick={() => toggle()}
            >
              <MoreHorizontal />
            </div> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Header;
