// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as uiHelpers from "../UserUIHelpers";
import { useUserUIContext } from "../UserUIContext";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../helper/helper";
import { Pagination } from "../../../pagination/Pagination";
import StatusTypeFormatter from "../../../columnformatter/StatusTypeFormatter";
import ActionsColumnFormatter from "../../../columnformatter/ActionsColumnFormatter";
import UserNameColumnFormatter from "../../../columnformatter/UserNameColumnFormatter";
import RoleColumnFormatter from "../../../columnformatter/RoleColumnFormatter";
import { FormattedMessage } from "react-intl";

export function UserTable({
  isLoading,
  userList,
  userListTotalCount,
  selectedTab,
}) {
  // User UI Context
  const userUIContext = useUserUIContext();
  const userUIProps = useMemo(() => {
    return {
      ids: userUIContext.ids,
      setIds: userUIContext.setIds,
      queryParams: userUIContext.queryParams,
      setQueryParams: userUIContext.setQueryParams,
      openViewDialog: userUIContext.openViewDialog,
      openEditDialog: userUIContext.openEditDialog,
      openDeleteDialog: userUIContext.openDeleteDialog,
      redirectToViewPage: userUIContext.redirectToViewPage,
    };
  }, [userUIContext]);

  // Table columns
  const columns = [
    {
      dataField: "full_name",
      text: <FormattedMessage id="TITLE.USER.NAME" />,
      sort: true,
      style: {
        minWidth: "170px",
      },
      sortCaret: sortCaret,
      formatter: UserNameColumnFormatter,
      formatExtraData: {
        redirectToViewPage: userUIProps.redirectToViewPage,
      },
    },
    {
      dataField: "emp_id",
      text: <FormattedMessage id="TITLE.EMPLOYEE.ID" />,
      sort: true,
      style: {
        minWidth: "170px",
      },
      sortCaret: sortCaret,
    },
    {
      dataField: "email",
      text: <FormattedMessage id="TITLE.EMAIL.ADDRESS" />,
      sort: true,
      style: {
        minWidth: "170px",
      },
      sortCaret: sortCaret,
    },
    {
      dataField: "cellnumber",
      text: <FormattedMessage id="TITLE.PHONE.NUMBER" />,
      classes: "ltr-direction",
      sort: true,
      style: {
        minWidth: "170px",
      },
      sortCaret: sortCaret,
    },
    {
      dataField: "role_id",
      text: <FormattedMessage id="TITLE.ROLE" />,
      sort: true,
      style: {
        minWidth: "170px",
      },
      sortCaret: sortCaret,
      formatter: RoleColumnFormatter,
    },
    {
      dataField: "status",
      text: <FormattedMessage id="TITLE.STATUS" />,
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "100px",
      },
      formatter: StatusTypeFormatter,
    },
    {
      dataField: "action",
      text: <FormattedMessage id="TITLE.ACTIONS" />,
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openViewDialog: userUIProps.openViewDialog,
        openEditDialog: userUIProps.openEditDialog,
        openDeleteDialog: userUIProps.openDeleteDialog,
        isVisibleViewButton: true,
        isVisibleEditButton: selectedTab === 0 ? true : false,
        isVisibleDeteletButton: true,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "120px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: userListTotalCount ? userListTotalCount : 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: userUIProps.queryParams.pageSize,
    onPageChange: (page) => {
      if (page === 0) return;
    },
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-head-bg table-borderless table-vertical-center user-table table-inventory"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={userList}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  userUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
              <PleaseWaitMessage isLoading={isLoading} />
              <NoRecordsFoundMessage
                entities={userList}
                isLoading={isLoading}
              />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
