import React, { createContext, useContext, useCallback } from "react";
import { isEqual, isFunction } from "lodash";

const StateUIContext = createContext();

export function useStateUIContext() {
  return useContext(StateUIContext);
}

export const StateUIConsumer = StateUIContext.Consumer;

export function StateUIProvider({
  stateUIEvents,
  children,
  queryParams,
  setQueryParamsBase,
  ids,
  setIds,
}) {
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    openViewDialog: stateUIEvents.openViewDialog,
    openEditDialog: stateUIEvents.openEditDialog,
    openDeleteDialog: stateUIEvents.openDeleteDialog,
  };

  return (
    <StateUIContext.Provider value={value}>
      {children}
    </StateUIContext.Provider>
  );
}
