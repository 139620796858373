import React, { useState } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import { X, Check } from "react-feather";
import {
  cityList,
  countriesList,
  parentLocationList,
  stateList,
} from "../components/location-module/LocationUIHelpers";

const statusName = [
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
];

export function AddNewBlockLocationModal({ onCloseModal, open }) {
  const [selectedOption, setSelectedOption] = useState({
    value: 1,
    label: "Active",
  });
  const [selectedParentLocation, setSelectedParentLocation] = useState(null);

  // const [selectedCountry, setSelectedCountry] = useState({
  //   value: "Select Country",
  //   label: "Select Country",
  // });
  // const [selectedState, setSelectedState] = useState({
  //   value: "Select State",
  //   label: "Select State",
  // });
  // const [selectedCity, setSelectedCity] = useState({
  //   value: "Select City",
  //   label: "Select City",
  // });
  const [isFormSuccess, setFormSuccess] = useState(false);

  return (
    <>
      <Modal
        size="md"
        show={open}
        onHide={onCloseModal}
        aria-labelledby="example-modal-sizes-title-sm"
        dialogClassName="inventory-small-modal modal-md"
        // scrollable={true}
      >
        <Modal.Header
          className="d-flex justify-content-between align-items-center"
          // toggle={onCloseModal}
        >
          <h3 className="modal-title" id="exampleModalLabel2">
            Add New Location
          </h3>
          <Button
            variant="link p-0 btn-icon"
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <X size={20} className="text-dark mr-0 pr-0" />
          </Button>
        </Modal.Header>
        <Modal.Body className="pb-0">
          {isFormSuccess && (
            <div className="notification icon inline success fs-16">
              <Check size={8} className="text-white"></Check>New location added
              successfully
            </div>
          )}
          <Form autoComplete="off">
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor="parentlocation">
                    Parent Location
                  </Form.Label>
                  <Select
                    defaultValue={selectedParentLocation}
                    onChange={setSelectedParentLocation}
                    isSearchable={true}
                    options={parentLocationList}
                    placeholder="Select Parent Location"
                    id="parentlocation"
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor="buildingnumber">
                    Store Location<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="buildingnumber"
                    placeholder="Store Location"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            variant="success"
            onClick={() => {
              window.scrollTo(0, 0);
              setFormSuccess(!isFormSuccess);
              setTimeout(() => {
                onCloseModal("VaryingMdo");
              }, 2500);
            }}
          >
            Save
          </Button>
          <Button
            type="button"
            variant="tertiary text-dark"
            onClick={() => onCloseModal("VaryingMdo")}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
