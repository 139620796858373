import React from "react";
import LoginTabset from "./loginTabset";
import logo from "../../assets/images/dashboard/login-logo.png";
import "../../assets/scss/slick.scss";
import "../../assets/scss/slick-theme.scss";
import { Card } from "react-bootstrap";
import { setLanguage, useLang } from "../../i18n";
import { addRemoveRTLLTRForDateRangePicker } from "../../helper/helper";
import { reloadPage } from "../../constants/utils";

const Login = (props) => {
  const selectedLang = useLang();

  const ChangeRtl = () => {
    setLanguage("ar");
    document.body.classList.remove("ltr");
    document.body.classList.add("rtl");
    addRemoveRTLLTRForDateRangePicker();
    reloadPage();
  };

  const ChangeLtr = () => {
    setLanguage("en");
    document.body.classList.remove("rtl");
    document.body.classList.add("ltr");
    addRemoveRTLLTRForDateRangePicker();
    reloadPage();
  };

  return (
    <>
      <ul className="w-100 position-relative" style={{ zIndex: 9 }}>
        <li className="language-onhover-dropdown border-0">
          <span>
            <h6 className="text-black">
              {selectedLang === "en" ? "English" : "العربية"}
            </h6>
          </span>
          <ul className="language-dropdown onhover-show-div p-20">
            <li className="d-block">
              <span className="" onClick={() => ChangeLtr()}>
                English
              </span>
            </li>
            <li className="d-block">
              <span className="arabic-font" onClick={() => ChangeRtl()}>
                العربية
              </span>
            </li>
          </ul>
        </li>
      </ul>

      <div className="page-wrapper">
        <div className="authentication-box flex-column">
          <div className="login-logo">
            {/* <h2 className="title text-white mb-4 text-primary">GSMOPA</h2> */}
            <img style={{filter:"blur(2px)"}}src={logo} alt=""></img>
          </div>
          <Card className="login-card">
            <Card.Body>
              <LoginTabset />
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Login;
