import { Trash2 } from "react-feather";
import { getProductMediaUrl } from "../constants/utils";
import {
  imgStyle,
  thumbInnerStyle,
  thumbsContainerStyle,
  thumbStyle,
} from "./CSSMaster";
import { ImageWrapper } from "./ImageWrapper";
import { SVGWrapper } from "./SVGWrapper";
import pdfSample from "../assets/images/files/pdf.svg";
import docSample from "../assets/images/files/doc.svg";
import xlsSample from "../assets/images/files/csv.svg";

export const ThumbnailWrapper = ({ files, handleOnDeleteOfAttachment }) => (
  <aside style={thumbsContainerStyle}>
    {files.map((file, index) => {
      const { preview, filename, file_type, mimetype, name, file_name } =
        file || {};
      if (filename) {
        var new_file = filename;
      } else {
        var new_file = name;
      }

      const uri = preview ? preview : getProductMediaUrl(new_file);

      return (
        <div style={{ width: "100px" }} key={index}>
          <div style={thumbStyle}>
            <div style={thumbInnerStyle}>
              {file_type === "image/jpeg" && (
                <ImageWrapper src={uri} style={imgStyle} />
              )}
              {file_type === "image/png" && (
                <ImageWrapper src={uri} style={imgStyle} />
              )}
              {file_type === "image/jpg" && (
                <ImageWrapper src={uri} style={imgStyle} />
              )}
              {file_type === "application/pdf" && (
                <SVGWrapper src={pdfSample} />
              )}
              {file_type === "application/msword" && (
                <SVGWrapper src={docSample} />
              )}
              {file_type === "application/vnd.ms-excel" && (
                <SVGWrapper src={xlsSample} />
              )}
              {mimetype === "image/jpeg" && (
                <ImageWrapper src={uri} style={imgStyle} />
              )}
              {mimetype === "image/png" && (
                <ImageWrapper src={uri} style={imgStyle} />
              )}
              {mimetype === "image/jpg" && (
                <ImageWrapper src={uri} style={imgStyle} />
              )}
              {mimetype === "application/pdf" && <SVGWrapper src={pdfSample} />}
              {mimetype === "application/msword" && (
                <SVGWrapper src={docSample} />
              )}
              {mimetype === "application/vnd.ms-excel" && (
                <SVGWrapper src={xlsSample} />
              )}
              <Trash2
                size="16"
                className="thumbnail-delete text-white"
                onClick={() => {
                  handleOnDeleteOfAttachment(file, index);
                }}
              ></Trash2>
            </div>
          </div>
          <p className="mt-2" style={{ fontSize: "12px", lineHeight: "16px" }}>
            {filename}
          </p>
        </div>
      );
    })}
  </aside>
);
