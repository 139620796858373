/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
const POWithLinkColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { redirectToPODetailsPage }
) => {
  const { purchaseorder_number } = row;
  return (
    <>
      <div
        className="text-primary btn btn-link p-0 cursor-pointer"
        onClick={() => {
          redirectToPODetailsPage(row);
        }}
      >
        {purchaseorder_number ? `${purchaseorder_number}` : "-"}
      </div>
      <> </>
    </>
  );
};

export default POWithLinkColumnFormatter;
