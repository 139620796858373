import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MENUITEMS } from "../../../constants/menu";
import SVG from "react-inlinesvg";
import { UIContext } from "../../../helper/UIContext";
import { USER_DETAILS, VERSION } from "../../../constants/const";

const Sidebar = ({ openSidebar }) => {
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const uiContext = useContext(UIContext);
  const userDetails = localStorage.getItem(USER_DETAILS);

  useEffect(() => {
    const currentUrl = window.location.pathname;
    const mainMenuString = currentUrl.split("/");
    const menus = mainmenu.map((menu) => {
      const menuPathString = menu.path ? menu.path.split("/") : "/";
      if (currentUrl === menu.path || mainMenuString[1] === menuPathString[1]) {
        menu.active = true;
      } else if (mainMenuString[1] === menu.name) {
        menu.active = true;
      } else if (menu.path === "/dashboard" && currentUrl === "/") {
        menu.active = true;
      } else {
        menu.active = false;
      }

      if (menu.children) {
        const menuChildren = menu.children.map((subMenu, index) => {
          const menuPathString = subMenu.path ? subMenu.path.split("/") : "/";
          const parentPath = menuPathString[1];
          if (parentPath === menu.name && menu.active) {
            menu.active = true;
            const childPath = menuPathString[2];
            if (childPath) {
              const subMenuItemPath = mainMenuString[2];
              if (subMenuItemPath) {
                if (childPath === subMenuItemPath) {
                  subMenu.active = true;
                } else {
                  subMenu.active = false;
                }
              }
            }
          } else {
            menu.active = false;
          }
          return subMenu;
        });
        menu.children = menuChildren;
      }
      return menu;
    });
    setMainMenus(menus);
  }, [uiContext]);

  const setMainMenus = (menus) => {
    setMainMenu(menus);
  };

  const setNavActive = (item) => {
    if (item.type === "link") {
      openSidebar();
    }
    let isTrueFound = false;
    let truedMenu = [];
    const menus = mainmenu.map((menu) => {
      if (item.path === menu.path && menu.type !== "sub") {
        if (menu.active) {
          menu.active = false;
        } else {
          menu.active = true;
        }
      } else {
        menu.active = false;
      }

      if (item.name === menu.name && menu.type === "sub") {
        if (menu.active) {
          menu.active = false;
        } else {
          menu.active = true;
        }
      }

      if (menu.type === "sub") {
        const menuChildren = menu.children.map((subMenu, index) => {
          const menuPathString = subMenu.path ? subMenu.path.split("/") : "/";
          const parentPath = menuPathString[1];
          if (parentPath === menu.name) {
            const childPath = menuPathString[2];
            if (childPath) {
              const subMenuItemPathString = item.path
                ? item.path.split("/")
                : "/";
              const subMenuItemPath = subMenuItemPathString[2];
              // console.log("subMenuItemPath", subMenuItemPath);
              if (subMenuItemPath) {
                if (childPath === subMenuItemPath) {
                  menu.active = true;
                  subMenu.active = true;
                } else {
                  subMenu.active = false;
                }
              }
            }
          }
          return subMenu;
        });
        menu.children = menuChildren;
      }

      // check if atleast one active menu found
      if (menu.active) {
        isTrueFound = true;
      }
      return menu;
    });

    if (!isTrueFound) {
      // None of menu selected, so selected from current row
      const currentUrl = window.location.pathname;
      const mainMenuString = currentUrl.split("/");
      const pathOfMainMenu = mainMenuString[1];
      truedMenu = mainmenu.map((menu) => {
        const menuPathString = menu.path ? menu.path.split("/") : "/";
        const parentPath = menuPathString[1];
        if (pathOfMainMenu === parentPath) {
          menu.active = true;
        }
        return menu;
      });
    }

    setMainMenus(isTrueFound ? menus : truedMenu);
  };

  const mainMenu = mainmenu.map((menuItem, i) => {
    const user = JSON.parse(userDetails);
    const { role } = user || {};
    const { name } = role || {};
    const isAllowedRole = menuItem.role.includes(name);

    return (
      isAllowedRole && (
        <li className={`${menuItem.active ? "active" : ""}`} key={i}>
          {menuItem.sidebartitle ? (
            <div className="sidebar-title">{menuItem.sidebartitle}</div>
          ) : (
            ""
          )}
          {menuItem.type === "sub" ? (
            <a
              className={`sidebar-header ${menuItem.active ? "active" : ""}`}
              href="#"
              onClick={() => {
                setNavActive(menuItem);
              }}
            >
              {menuItem.path === `/inwards/inward-listing` ? (
                menuItem.path ===
                `/outwards/outward-listing`(
                  <SVG
                    style={{ width: "20px", height: "20px" }}
                    src={`${menuItem.icon}`}
                  />
                )
              ) : (
                <menuItem.icon />
              )}

              <div
                className="d-flex justify-content-between align-items-center"
                style={{ width: "calc(100% - 36px)" }}
              >
                <span>{menuItem.title}</span>
                <i className="fa fa-angle-right pull-right"></i>
              </div>
            </a>
          ) : (
            ""
          )}
          {menuItem.type === "link" ? (
            <Link
              to={`${process.env.PUBLIC_URL}${menuItem.path}`}
              className={`sidebar-header ${menuItem.active ? "active" : ""}`}
              onClick={() => {
                uiContext.setCurrentPath(menuItem.path);
                setNavActive(menuItem);
              }}
            >
              {menuItem.path === `/inwards/inward-listing` ||
              menuItem.path === `/outwards/outward-listing` ? (
                <SVG
                  style={{ width: "20px", height: "20px" }}
                  src={`${menuItem.icon}`}
                />
              ) : (
                <menuItem.icon />
              )}
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ width: "calc(100% - 36px)" }}
              >
                <span>{menuItem.title}</span>
                {menuItem.children ? (
                  <i className="fa fa-angle-right pull-right"></i>
                ) : (
                  ""
                )}
              </div>
            </Link>
          ) : (
            ""
          )}
          {menuItem.children ? (
            <ul
              className={`sidebar-submenu ${
                menuItem.active ? "menu-open" : ""
              }`}
              style={
                menuItem.active
                  ? { opacity: 1, transition: "opacity 500ms ease-in" }
                  : {}
              }
            >
              {menuItem.children.map((childrenItem, index) => (
                <li
                  key={index}
                  className={
                    childrenItem.children
                      ? childrenItem.active
                        ? "active"
                        : ""
                      : ""
                  }
                >
                  {childrenItem.type === "sub" ? (
                    <a
                      href="#"
                      onClick={() => {
                        uiContext.setCurrentPath(childrenItem.path);
                        setNavActive(childrenItem);
                      }}
                    >
                      <i className="fa fa-circle"></i>
                      <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ width: "calc(100% - 36px)" }}
                      >
                        {childrenItem.title}{" "}
                        <i className="fa fa-angle-right pull-right"></i>
                      </div>
                    </a>
                  ) : (
                    ""
                  )}

                  {childrenItem.type === "link" ? (
                    <Link
                      to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                      className={
                        childrenItem.active
                          ? "active d-flex align-items-center"
                          : "d-flex align-items-center"
                      }
                      onClick={() => {
                        uiContext.setCurrentPath(childrenItem.path);
                        setNavActive(childrenItem);
                      }}
                    >
                      {/* <ChevronRight size={14} className="text-white mr-2" /> */}
                      {childrenItem.title}{" "}
                    </Link>
                  ) : (
                    ""
                  )}
                  {childrenItem.children ? (
                    <ul
                      className={`sidebar-submenu ${
                        childrenItem.active ? "menu-open" : "active"
                      }`}
                    >
                      {childrenItem.children.map((childrenSubItem, key) => (
                        <li
                          className={childrenSubItem.active ? "active" : ""}
                          key={key}
                        >
                          {childrenSubItem.type === "link" ? (
                            <Link
                              to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                              className={childrenSubItem.active ? "active" : ""}
                              onClick={() => {
                                uiContext.setCurrentPath(childrenItem.path);
                                setNavActive(childrenSubItem);
                              }}
                            >
                              {/* <i className="fa fa-circle"></i> */}
                              {childrenSubItem.title}
                            </Link>
                          ) : (
                            ""
                          )}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              ))}
            </ul>
          ) : (
            ""
          )}
        </li>
      )
    );
  });

  return (
    <Fragment>
      <div className="page-sidebar">
        <div className="main-header-left d-none d-lg-block">
          <div className="logo-wrapper">
            <Link
              to={`${process.env.PUBLIC_URL}/dashboard`}
              className="logo f-w-900 fs-16"
              onClick={() => {
                uiContext.setCurrentPath(`${process.env.PUBLIC_URL}/dashboard`);
              }}
            >
              Inventory Management System
            </Link>
            {/* <Link
              to={`${process.env.PUBLIC_URL}/dashboard`}
              className="small-logo"
            >
              <img
                className="blur-up lazyloaded img-fluid"
                src={smalllogo}
                alt=""
              />
            </Link> */}
          </div>
        </div>
        <div className="sidebar custom-scrollbar">
          {/* <UserPanel /> */}
          <ul className="sidebar-menu">{mainMenu}</ul>
          <div
            className="text-white w-100 d-flex justify-content-end pr-4"
            style={{
              position: "absolute",
              bottom: "15px",
              transformX: "translateX(-50%)",
            }}
          >
            {VERSION}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Sidebar;
