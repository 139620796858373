import React, { useRef, useState, useEffect, useContext } from "react";
import { CategoryLoadingDialog } from "./category-loading-dialog/CategoryLoadingDialog";
import { CategoryCard } from "./CategoryCard";
import { CategoryUIProvider } from "./CategoryUIContext";
import SVG from "react-inlinesvg";
import { initialFilter } from "./CategoryUIHelpers";
import { ErrorModal } from "../common/modals/ErrorModal";
import { toAbsoluteUrl } from "../../helper/helper";
import { EditCategoryModal } from "./edit-new-category/EditCategoryModal";
import { AddNewCategoryModal } from "./add-new-category/AddNewCategoryModal";
import {
  categoryListMastersApi,
  deleteCategoryApi,
} from "../../axios/services/services";
import { SuccessModal } from "../common/modals/SuccessModal";
import {
  capitalizeFirstLetter,
  getErrorMessageBasedOnLanguage,
  setActiveMenu,
  unauthorization,
} from "../../constants/utils";
import { useIntl } from "react-intl";
import { MENUITEMS } from "../../constants/menu";
import { UIContext } from "../../helper/UIContext";
import { DeleteCategoryModal } from "../common/modals/DeleteCategoryModal";
import { useFormik } from "formik";
import { DeleteCategorySchema } from "../../helper/FormikSchema";
import { DeleteModal } from "../common/modals/DeleteModal";

const DeleteCategoryInitialValues = {
  categoryId: "",
};

export function CategoryPage({ history }) {
  const intl = useIntl();
  const [data, setData] = useState({});
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [loadingExportButton, setLoadingExportButton] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const [isOpenAddNewCategoryModal, setOpenAddNewCategoryModal] =
    useState(false);
  const [isOpenEditCategoryModal, setOpenEditCategoryModal] = useState(false);
  const exportCSVButton = useRef();
  const [categoryList, setCategoryList] = useState([]);
  const [categoryListTotalCount, setCategoryListTotalCount] = useState();
  const [isUpdateCategoryDetails, setUpdateCategoryDetails] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [isLoadingDeleteCategory, setLoadingDeleteCategory] = useState(false);
  const [isFilter, setFilter] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isLoadingFilter, setLoadingFilter] = useState(false);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const uiContext = useContext(UIContext);

  async function getCategoryList() {
    enableLoading();
    if (isFilter) {
      enableLoadingFilter();
    }
    const CategoryListResponse = await categoryListMastersApi({
      queryParams,
    });
    disableLoading();
    if (isFilter) {
      disableLoadingFilter();
    }
    setFilter(false);
    const { Error, data, count } = CategoryListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setCategoryList(data ? data : []);
    setCategoryListTotalCount(count ? count : 0);
  }

  const deleteCategory = async (values) => {
    const { categoryId } = values || {};
    const { id } = data;
    enableLoadingDeleteCategory();
    const deleteCategoryResponse = await deleteCategoryApi({ id, categoryId });
    const { Error } = deleteCategoryResponse || {};
    if (Error) {
      disableLoadingDeleteCategory();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setIsDeleteModalOpen(false);
    setSuccessMessage(
      intl.formatMessage({ id: "TITLE.CATEGORY.DELETED.SUCCESSFULLY" })
    );
    onOpenSuccessModal();
    window.scrollTo(0, 0);
    setUpdateCategoryDetails(Math.random());
    disableLoadingDeleteCategory();
  };

  useEffect(() => {
    getCategoryList();
  }, [queryParams, isUpdateCategoryDetails]);

  const enableLoadingFilter = () => {
    setLoadingFilter(true);
  };

  const disableLoadingFilter = () => {
    setLoadingFilter(false);
  };

  const enableLoadingDeleteCategory = () => {
    setLoadingDeleteCategory(true);
  };

  const disableLoadingDeleteCategory = () => {
    setLoadingDeleteCategory(false);
  };

  const onOpenSuccessModal = () => {
    setOpenSuccessModal(true);
  };

  const onCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const addNewCategoryModalShow = () => {
    setOpenAddNewCategoryModal(true);
  };

  const addNewCategoryModalHide = () => {
    setOpenAddNewCategoryModal(false);
  };

  const editCategoryModalShow = () => {
    setOpenEditCategoryModal(true);
  };

  const editCategoryModalHide = () => {
    setOpenEditCategoryModal(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleDeleteModalShow = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalHide = () => {
    setIsDeleteModalOpen(false);
  };

  // const handleFilterValues = (values) => {
  //   setFilterValues(values);
  // };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const categoryUIEvents = {
    openViewDialog: (row) => {
      onClickChangeRoute(`/master/categories/view-category-details/${row.id}`);
    },
    openEditDialog: (row) => {
      setData(row);
      editCategoryModalShow();
    },
    openDeleteDialog: (row) => {
      setData(row);
      handleDeleteModalShow();
    },
  };

  const handleExportButton = async () => {};

  const handleOnClickOfCreateNew = () => {
    addNewCategoryModalShow();
  };

  const handleCloseOfAddNewCategoryModal = (addNewVendorDetails) => {
    setUpdateCategoryDetails(Math.random());
    addNewCategoryModalHide();
  };

  const addNewCategoryModalFromDelete = () => {
    setCategoryList([]);
    setUpdateCategoryDetails(Math.random());
  };

  const handleCloseOfEditNewCategoryModal = (editNewVendorDetails) => {
    setUpdateCategoryDetails(Math.random());
    editCategoryModalHide();
  };

  const handleCLickOfFilterButtonFilter = (values) => {
    setFilter(true);
  };

  const handleClickOfRemoveFilterButton = () => {
    setFilterOpen(!isFilterOpen);
    setFilter(false);
    setQueryParamsBase(initialFilter);
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  const onClickOfDeleteButton = () => {
    deleteCategory({});
  };

  const formikDeleteCategory = useFormik({
    initialValues: DeleteCategoryInitialValues,
    validationSchema: DeleteCategorySchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      deleteCategory(values);
    },
    onReset: (values, { resetForm }) => {},
  });

  return (
    <div>
      <>
        {/* <Breadcrumb title="Product List" parent="Products" /> */}
        <CategoryUIProvider
          categoryUIEvents={categoryUIEvents}
          queryParams={queryParams}
          setQueryParamsBase={setQueryParamsBase}
          ids={ids}
          setIds={setIds}
        >
          <CategoryLoadingDialog />

          {isOpenAddNewCategoryModal && (
            <AddNewCategoryModal
              open={isOpenAddNewCategoryModal}
              onCloseModal={addNewCategoryModalHide}
              handleCloseOfAddNewCategoryModal={
                handleCloseOfAddNewCategoryModal
              }
            />
          )}

          {isOpenEditCategoryModal && (
            <EditCategoryModal
              open={isOpenEditCategoryModal}
              data={data}
              onCloseModal={editCategoryModalHide}
              id={data.id}
              handleCloseOfEditNewCategoryModal={
                handleCloseOfEditNewCategoryModal
              }
            />
          )}

          <CategoryCard
            isLoading={isLoading}
            categoryList={categoryList}
            // handleFilterValues={handleFilterValues}
            // filterValues={filterValues}
            loadingExportButton={loadingExportButton}
            transactionListExport={categoryList}
            handleExportButton={handleExportButton}
            exportCSVButton={exportCSVButton}
            categoryListTotalCount={categoryListTotalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            onClickOfCreateNew={handleOnClickOfCreateNew}
            handleClickOfRemoveFilterButton={handleClickOfRemoveFilterButton}
            isLoadingFilter={isLoadingFilter}
            isFilterOpen={isFilterOpen}
            handleCLickOfFilterButtonFilter={handleCLickOfFilterButtonFilter}
          />
        </CategoryUIProvider>

        {/* {isDeleteModalOpen && (
          <DeleteModal
            isLoading={isLoadingDeleteCategory}
            title={intl.formatMessage({
              id: "TITLE.DELETE.CATEGORY",
            })}
            description={intl.formatMessage({
              id: "TITLE.CATEGORY.WILL.BE.DELETED.FOREVER.AND.CANNOT.BE.RETRIEVED.LATER",
            })}
            isOpen={isDeleteModalOpen}
            onCloseModal={handleDeleteModalHide}
            id={capitalizeFirstLetter(data?.name)}
            onClickOfDeleteButton={onClickOfDeleteButton}
          />
        )} */}
        {isDeleteModalOpen && data?.total_products && (
          <DeleteCategoryModal
            isLoading={isLoadingDeleteCategory}
            title={`${intl.formatMessage({
              id: "TITLE.DELETE.CATEGORY",
            })}`}
            description={intl.formatMessage({
              id: "TITLE.CATEGORY.WILL.BE.DELETED.FOREVER.AND.CANNOT.BE.RETRIEVED.LATER",
            })}
            reassignDescription={intl.formatMessage({
              id: "TITLE.ALL.THE.PRODUCTS.BELONGING.TO.THIS.CATEGORY.WILL.BE.ASSIGNED.TO.THE.SELECTED.CATEGORY.BELOW",
            })}
            isOpen={isDeleteModalOpen}
            onCloseModal={handleDeleteModalHide}
            id={capitalizeFirstLetter(data?.name)}
            categoryDetails={data}
            categoryList={categoryList}
            formikDeleteCategory={formikDeleteCategory}
            addNewCategoryModalFromDelete={addNewCategoryModalFromDelete}
          />
        )}

        {isDeleteModalOpen && !data?.total_products && (
          <DeleteModal
            isLoading={isLoadingDeleteCategory}
            title={`${intl.formatMessage({
              id: "TITLE.DELETE.CATEGORY",
            })}`}
            description={intl.formatMessage({
              id: "TITLE.CATEGORY.WILL.BE.DELETED.FOREVER.AND.CANNOT.BE.RETRIEVED.LATER",
            })}
            isOpen={isDeleteModalOpen}
            onCloseModal={handleDeleteModalHide}
            id={capitalizeFirstLetter(data?.name)}
            onClickOfDeleteButton={onClickOfDeleteButton}
          />
        )}

        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
          errorIcon={
            <div className="danger-icon-wrapper text-center">
              <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                />
              </span>
            </div>
          }
          errorButtonLabel={"Close"}
          errorOnClick={handleErrorModalHide}
        />
        {openSuccessModal && (
          <SuccessModal
            message={successMessage}
            open={openSuccessModal}
            onCloseModal={onCloseSuccessModal}
          />
        )}
      </>
    </div>
  );
}
