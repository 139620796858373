import moment from "moment";
import { AUTH_TOKEN, YYYY_MM_DD_HH_MM_SS } from "../../constants/const";
import { getEncodedURL } from "../../constants/utils";
import { AUTH } from "../api";

export function loginUrl() {
  var url = `users/login`;
  return getEncodedURL(url);
}

export function forgotPasswordUrl() {
  var url = `users/forgotPassword`;
  return getEncodedURL(url);
}

export function logoutUrl({ id }) {
  var url = `users/logOut/${id}`;
  return getEncodedURL(url);
}

export function customerListUrl({ queryParams }) {
  const { pageNumber, pageSize, sortField, sortOrder, filter } = queryParams;
  let skip = (pageNumber - 1) * pageSize;
  var url = `contacts?filter[offset]=${skip}&filter[limit]=${pageSize}&filter[where][contact_type]=customer`;

  const { contact_name, phone_number, currency, status, email } = filter || {};

  if (contact_name) {
    url = url.concat(`&filter[where][contact_name]=${contact_name}`);
  }
  if (phone_number) {
    url = url.concat(`&filter[where][phone_number]=${phone_number}`);
  }
  if (status) {
    url = url.concat(`&filter[where][status]=${status}`);
  }
  if (currency) {
    url = url.concat(`&filter[where][currency]=${currency}`);
  }
  if (email) {
    url = url.concat(`&filter[where][email]=${email}`);
  }
  if (sortField && sortOrder) {
    url = url.concat(
      `&filter[sort_column]=${sortField} ${sortOrder.toUpperCase()}`
    );
  }
  return getEncodedURL(url);
}

export function deleteCustomerUrl({ id }) {
  var url = `contacts/${id}`;
  return getEncodedURL(url);
}

export function editCustomerUrl({ id }) {
  var url = `contacts/${id}`;
  return getEncodedURL(url);
}

export function addCustomerUrl() {
  var url = "contacts";
  return getEncodedURL(url);
}

export function vendorListUrl({ queryParams }) {
  const { pageSize, pageNumber, filter, sortField, sortOrder } =
    queryParams || {};
  const { contact_name, phone_number, currency, status, email } = filter || {};
  const skip = pageSize * (pageNumber - 1);
  var url = `contacts?filter[offset]=${skip}&filter[limit]=${pageSize}&filter[where][contact_type]=vendor`;
  if (contact_name) {
    url = url.concat(`&filter[where][contact_name]=${contact_name}`);
  }
  if (email) {
    url = url.concat(`&filter[where][email]=${email}`);
  }
  if (phone_number) {
    url = url.concat(`&filter[where][phone_number]=${phone_number}`);
  }
  if (currency) {
    url = url.concat(`&filter[where][currency]=${currency}`);
  }
  if (status) {
    url = url.concat(`&filter[where][status]=${status}`);
  }

  if (sortField && sortOrder) {
    url = url.concat(
      `&filter[sort_column]=${sortField} ${sortOrder.toUpperCase()}`
    );
  }

  return getEncodedURL(url);
}
//export Api..
export function vendorListExportUrl() {
  var url = `contacts?filter[where][contact_type]=vendor&filter[type]=Excel`;
  return getEncodedURL(url);
}

export function customerListExportUrl() {
  var url = `contacts?filter[where][contact_type]=customer&filter[type]=Excel`;
  return getEncodedURL(url);
}

export function userListExportUrl() {
  var url = `users?filter[where][contact_type]=user&filter[type]=Excel`;
  return getEncodedURL(url);
}

export function categoryListExportUrl() {
  var url = `categories?filter[type]=Excel`;
  return getEncodedURL(url);
}

export function productListExportUrl() {
  var url = `products?filter[type]=Excel`;
  return getEncodedURL(url);
}

export function productunitListExportUrl() {
  var url = `productunits?filter[type]=Excel`;
  return getEncodedURL(url);
}

export function locationsListExportUrl() {
  var url = `locations/getLocationsListing?filter[type]=Excel`;
  return getEncodedURL(url);
}

export function inventoryListExportUrl() {
  var url = `products?filter[type]=Excel`;
  return getEncodedURL(url);
}
//end export

export function vendorListAllUrl({ vendorSearchValue }) {
  let url = `contacts?filter[offset]=${0}&filter[limit]=100000&filter[where][contact_type]=vendor&filter[where][status]=1`;
  if (vendorSearchValue) {
    url = url.concat(`&filter[where][contact_name]=${vendorSearchValue}`);
  }
  return getEncodedURL(url);
}

export function roleListUrl() {
  let url = `users/userRoleList`;
  return getEncodedURL(url);
}

export function customerListAllUrl({ customerSearchValue }) {
  let url = `contacts?filter[offset]=${0}&filter[limit]=100000&filter[where][contact_type]=customer&filter[where][status]=1`;
  if (customerSearchValue) {
    url = url.concat(`&filter[where][contact_name]=${customerSearchValue}`);
  }
  return getEncodedURL(url);
}

export function deleteVendorUrl({ id }) {
  var url = `contacts/${id}`;
  return getEncodedURL(url);
}

export function editVendorUrl({ id }) {
  var url = `contacts/${id}`;
  return getEncodedURL(url);
}

export function editBillingAddressVendorUrl({ id }) {
  var url = `contacts/${id}`;
  return getEncodedURL(url);
}

export function editBillingAddressCustomerUrl({ id }) {
  var url = `contacts/${id}`;
  return getEncodedURL(url);
}

export function editShippingAddressVendorUrl({ id }) {
  var url = `contacts/${id}`;
  return getEncodedURL(url);
}

export function editShippingAddressCustomerUrl({ id }) {
  var url = `contacts/${id}`;
  return getEncodedURL(url);
}

export function addContactPersonVendorUrl() {
  var url = `contacts/addContactPerson`;
  return getEncodedURL(url);
}

export function addContactPersonCustomerUrl() {
  var url = `contacts/addContactPerson`;
  return getEncodedURL(url);
}

export function editContactPersonVendorUrl({ id }) {
  var url = `contacts/editContactPerson/${id}`;
  return getEncodedURL(url);
}

export function editContactPersonCustomerUrl({ id }) {
  var url = `contacts/editContactPerson/${id}`;
  return getEncodedURL(url);
}

export function addVendorUrl() {
  var url = "contacts";
  return getEncodedURL(url);
}

export function POListUrl({ queryParams, productId }) {
  const { pageSize, pageNumber, filter, sortField, sortOrder } =
    queryParams || {};

  const {
    contact_name,
    date,
    purchaseorder_number,
    status,
    totalamountone,
    totalamounttwo,
  } = filter || {};

  const skip = pageSize * (pageNumber - 1);
  let url = `purchaseorders?filter[limit]=${pageSize}&filter[offset]=${skip}`;
  if (contact_name) {
    url = url.concat(`&filter[where][contact_name]=${contact_name}`);
  }

  if (date) {
    url = url.concat(`&filter[where][fromdate]=${date.startdate}`);
    url = url.concat(`&filter[where][todate]=${date.enddate}`);
  }
  // if (date) {
  //   url = url.concat(`&filter[where][date]=${date}`);
  // }
  // if (startDate) {
  //   url = url.concat(`&filter[where][fromdate]=${startDate}`);
  // }
  // if (endDate) {
  //   url = url.concat(`&filter[where][todate]=${endDate}`);
  // }
  if (purchaseorder_number) {
    url = url.concat(
      `&filter[where][purchaseorder_number]=${purchaseorder_number}`
    );
  }
  if (status) {
    url = url.concat(`&filter[where][purchaseorderstatusId]=${status}`);
  }
  if (totalamountone) {
    url = url.concat(`&filter[where][startamount]=${totalamountone}`);
  }
  if (totalamounttwo) {
    url = url.concat(`&filter[where][endamount]=${totalamounttwo}`);
  }
  if (productId) {
    url = url.concat(`&filter[where][productId]=${productId}`);
  }
  if (sortField && sortOrder) {
    url = url.concat(
      `&filter[sort_column]=${sortField} ${sortOrder.toUpperCase()}`
    );
  }

  return getEncodedURL(url);
}

export function POBasedOnProductListUrl({ queryParams, productId }) {
  const { pageSize, pageNumber } = queryParams || {};

  const skip = pageSize * (pageNumber - 1);
  let url = `purchaseorders/getProductWisePOAndDC`;
  if (productId) {
    url = url.concat(`?filter[where][productId]=${productId}`);
  }
  url = url.concat(
    `&filter[where][offset]=${skip}&filter[where][limit]=${pageSize}&filter[where][type]=po`
  );
  return getEncodedURL(url);
}

export function DCBasedOnProductListUrl({ queryParams, productId }) {
  const { pageSize, pageNumber } = queryParams || {};

  const skip = pageSize * (pageNumber - 1);
  let url = `purchaseorders/getProductWisePOAndDC`;
  if (productId) {
    url = url.concat(`?filter[where][productId]=${productId}`);
  }
  url = url.concat(
    `&filter[where][limit]=${pageSize}&filter[where][offset]=${skip}&filter[where][type]=dc`
  );
  return getEncodedURL(url);
}

export function DCListUrl({
  queryParams,
  productId,
  customerIdViaFilter,
  statusId,
}) {
  const { pageSize, pageNumber, filter, sortField, sortOrder } =
    queryParams || {};
  const {
    contact_name,
    date,
    purchaseorder_number,
    status,
    totalamountone,
    totalamounttwo,
  } = filter || {};
  const skip = pageSize * (pageNumber - 1);
  var url = `purchaseorders/getDCListing?filter[offset]=${skip}&filter[limit]=${pageSize}`;

  if (contact_name) {
    url = url.concat(`&filter[where][contact_name]=${contact_name}`);
  }

  if (customerIdViaFilter) {
    url = url.concat(
      `&filter[where][contacttypeId]=${customerIdViaFilter}&filter[where][purchaseorderstatusId]=${statusId}`
    );
  }

  if (date) {
    url = url.concat(`&filter[where][fromdate]=${date.startdate}`);
    url = url.concat(`&filter[where][todate]=${date.enddate}`);
  }
  // if (date) {
  //   url = url.concat(`&filter[where][date]=${dateFormt}`);
  // }
  if (purchaseorder_number) {
    url = url.concat(
      `&filter[where][purchaseorder_number]=${purchaseorder_number}`
    );
  }
  if (status) {
    url = url.concat(`&filter[where][purchaseorderstatusId]=${status}`);
  }
  if (totalamountone) {
    url = url.concat(`&filter[where][startamount]=${totalamountone}`);
  }
  if (totalamounttwo) {
    url = url.concat(`&filter[where][endamount]=${totalamounttwo}`);
  }
  if (sortField && sortOrder) {
    url = url.concat(
      `&filter[sort_column]=${sortField} ${sortOrder.toUpperCase()}`
    );
  }
  if (productId) {
    url = url.concat(`&filter[where][productId]=${productId}`);
  }

  return getEncodedURL(url);
}

export function deleteDCUrl({ id }) {
  var url = `purchaseorders/${id}`;
  return getEncodedURL(url);
}

export function deletePOUrl({ id }) {
  var url = `purchaseorders/${id}`;
  return getEncodedURL(url);
}

export function getDCPDFUrl({ id }) {
  var url = `purchaseorders/exportPDFForPoDetails/${id}`;
  return getEncodedURL(url);
}

export function uploadDocumentUrl(containername) {
  var url = `/api-v1/containers/upload/${containername}`;
  return getEncodedURL(url);
}

export function ProductListUrl({ queryParams, filterValues }) {
  const { pageNumber, pageSize } = queryParams;
  let skip = (pageNumber - 1) * pageSize;
  var url = `products?filter[offset]=${skip}&filter[limit]=${pageSize}&filter[where][status]=1`;
  return getEncodedURL(url);
}

export function productListForScannedProductsUrl({ id }) {
  let url = `purchaseorders/getPurchaseorderItemList/${id}`; // id = purchase order table
  return getEncodedURL(url);
}

export const getDownloadPdfUrl = ({ id }) => {
  let url = "";
  url = `purchaseorders/exportPDFForDcDetails/${id}`;
  return getEncodedURL(url);
};

export const getDownloadPdfPOUrl = ({ id }) => {
  let url = "";
  url = `purchaseorders/exportPDFForPoDetails/${id}`;
  return getEncodedURL(url);
};

export const getExportOutwardListingUrl = () => {
  let url = "";
  url = `purchaseorders/exportInwardListing?filter[where][contact_type]=customer`;
  return getEncodedURL(url);
};

export const getSingleDcDetailsUrl = ({ id }) => {
  let url = "";
  url = `purchaseorders/${id}`;
  return getEncodedURL(url);
};

export const createDCUrl = () => {
  let url = "";
  url = `purchaseorders`;
  return getEncodedURL(url);
};
export function cityListAllUrl({}) {
  var url = `cities?filter[offset]=0&filter[limit]=100000`;
  return getEncodedURL(url);
}

export function editDCUrl({ id }) {
  var url = `purchaseorders/${id}`;
  return getEncodedURL(url);
}

export function editPOUrl({ id }) {
  var url = `purchaseorders/${id}`;
  return getEncodedURL(url);
}

export function editPOStatusUrl({ id }) {
  var url = `purchaseorders/${id}`;
  return getEncodedURL(url);
}

export function editDCStatusUrl({ id }) {
  var url = `purchaseorders/${id}`;
  return getEncodedURL(url);
}

export function editExpectedDeliveryDateUrl({ id }) {
  var url = `purchaseorders/${id}`;
  return getEncodedURL(url);
}

export function addProductUrl() {
  var url = "products";
  return getEncodedURL(url);
}

export function categoryListUrl({ status }) {
  var url = `categories?filter[offset]=0&filter[limit]=100000`;
  if (status) {
    url = url.concat(`&filter[where][status]=${status}`);
  }

  return getEncodedURL(url);
}

export function productUnitListUrl() {
  var url = `productunits?filter[offset]=0&filter[limit]=100000&filter[where][status]=1`;
  return getEncodedURL(url);
}

export function locationListUrl() {
  var url = `locations?filter[offset]=0&filter[limit]=10`;
  return getEncodedURL(url);
}

export function receiveInwardListUrl({ id }) {
  var url = `purchaseorderreceive/${id}`;
  return getEncodedURL(url);
}

export function addInwardUrl() {
  var url = `purchaseorders`;
  return getEncodedURL(url);
}

export function editInwardUrl({ id }) {
  var url = `purchaseorders/${id}`;
  return getEncodedURL(url);
}

export function editOutwardUrl({ id }) {
  var url = `purchaseorders/${id}`;
  return getEncodedURL(url);
}

export function POStatusListUrl() {
  var url = `purchaseorders/getPoStatusListing`;
  return getEncodedURL(url);
}

export function editHistoryListUrl({ queryParamsEditHistory, id }) {
  const { pageNumber, pageSize } = queryParamsEditHistory || {};
  const skip = (pageNumber - 1) * pageSize;
  let url = `purchaseorders/getPurchaseorderHistory/${id}?filter[offset]=${skip}&filter[limit]=${pageSize}`;
  return getEncodedURL(url);
}

export function singlePODetailsUrl({ POId }) {
  var url = `purchaseorders/${POId}`;
  return getEncodedURL(url);
}

export function singleDCDetailsUrl({ DCId }) {
  var url = `purchaseorders/${DCId}`;
  return getEncodedURL(url);
}

export function receivePOLocationListUrl() {
  var url = `locations/getLocationsListing?filter[where][status]=1`;
  return getEncodedURL(url);
}

export const getExportInwardListingUrl = () => {
  let url = "";
  url = `purchaseorders/exportInwardListing?filter[where][contact_type]=vendor`;
  return getEncodedURL(url);
};

export const getSingleDeliverItemByBarcodeUrl = (barcodeNo) => {
  let url = "";
  url = `purchaseorderreceive/getSingleReceiveProduct/${barcodeNo}`;
  return getEncodedURL(url);
};

export function deliverOutwardListUrl({ id }) {
  var url = `purchaseorderreceive/updateDCReceiveDetails/${id}`;
  return getEncodedURL(url);
}

export function getDashboardcounterUrl() {
  var url = `users/dashboardcounter`;
  return getEncodedURL(url);
}

// export function getTop5ProductsUrl({ sortColumnName }) {
//   var url = `products?filter[sort_column]=${sortColumnName} ASC&filter[offset]=0&filter[limit]=5`;
//   return getEncodedURL(url);
// }

export function getTop5ProductsUrl() {
  let url = `purchaseorders/reportLowStockItems?filter[type]=Listing&filter[offset]=0&filter[limit]=5`;
  return getEncodedURL(url);
}
export function getTop5NearExpiryProductUrl() {
  let url = `purchaseorders/top5NearExpireProduct`;
  return getEncodedURL(url);
}

export function getTop5POUrl({ contactName, columnName }) {
  var url = `purchaseorders/recent5PoandDc?filter[where][name]=${contactName}`;
  if (columnName) {
    url = url.concat(
      `&filter[sort_column]=${columnName} DESC`
      // &filter[offset]=0&filter[limit]=5
    );
  }
  return getEncodedURL(url);
}

export function getRecentFiveUrl({ contactName }) {
  let url = `purchaseorders/recent5Transactions?filter[where][name]=${contactName}`;
  return getEncodedURL(url);
}

export function getPoandDcStatusUrl({ contactTypeName }) {
  var url = `users/getPoandDcStatus`;
  if (contactTypeName) {
    url = url.concat(`?filter[where][name]=${contactTypeName}`);
  }
  return getEncodedURL(url);
}

export function getPoandDcSummaryUrl({
  chosenLabel,
  fromdate,
  todate,
  productId,
}) {
  console.log(chosenLabel);
  var url = `users/getPoandDcSummary`;

  if (chosenLabel) {
    url = url.concat(`?filter[where][date]=${chosenLabel}`);
  }

  if (fromdate) {
    url = url.concat(
      `&filter[between]=["${moment(fromdate).format(
        YYYY_MM_DD_HH_MM_SS
      )}","${moment(todate).format(YYYY_MM_DD_HH_MM_SS)}"]`
    );
  }

  if (productId) {
    url = url.concat(`&filter[where][productId]=${productId}`);
  }
  // if (fromdate) {
  //   url = url.concat(
  //     `&filter[where][fromdate]=${moment(fromdate).format("YYYY-MM-DD")}`
  //   );
  // }

  // if (todate) {
  //   url = url.concat(
  //     `&filter[where][todate]=${moment(todate).format("YYYY-MM-DD")}`
  //   );
  // }

  return url;
}

export function getPoandDcQuantitySummaryUrl({
  contactTypeName,
  chosenLabel,
  fromdate,
  todate,
}) {
  var url = `users/getPoandDcQuantitySummary`;
  if (contactTypeName) {
    url = url.concat(`?filter[where][name]=${contactTypeName}`);
  }
  if (chosenLabel) {
    url = url.concat(`&filter[where][date]=${chosenLabel}`);
  }
  if (fromdate) {
    url = url.concat(
      `&filter[where][fromdate]=${moment(fromdate).format("YYYY-MM-DD")}`
    );
  }
  if (todate) {
    url = url.concat(
      `&filter[where][todate]=${moment(todate).format("YYYY-MM-DD")}`
    );
  }
  return getEncodedURL(url);
}

export function categoryListMastersUrl({ queryParams }) {
  const { pageSize, pageNumber, filter, sortField, sortOrder } =
    queryParams || {};
  const { name, status } = filter || {};
  const skip = pageSize * (pageNumber - 1);
  let url = `categories?filter[offset]=${skip}&filter[limit]=${pageSize}`;
  if (name) {
    url = url.concat(`&filter[where][name]=${name}`);
  }

  if (status) {
    url = url.concat(`&filter[where][status]=${status}`);
  }

  if (sortField && sortOrder) {
    url = url.concat(
      `&filter[sort_column]=${sortField} ${sortOrder.toUpperCase()}`
    );
  }

  return getEncodedURL(url);
}

export function userListUrl({ queryParams, selectedTab }) {
  const { pageSize, pageNumber, filter, sortField, sortOrder } =
    queryParams || {};
  const {
    full_name,
    status,
    ispasswordrequested,
    role_id,
    emp_id,
    cellnumber,
    email,
  } = filter || {};
  const skip = pageSize * (pageNumber - 1);
  let url = `users?filter[offset]=${skip}&filter[limit]=${pageSize}`;

  if (full_name) {
    url = url.concat(`&filter[where][full_name]=${full_name}`);
  }

  if (status) {
    url = url.concat(`&filter[where][status]=${status}`);
  }
  if (selectedTab === 1) {
    url = url.concat(`&filter[where][ispasswordrequested]=${1}`);
  }

  if (role_id) {
    url = url.concat(`&filter[where][role_id]=${role_id}`);
  }

  if (emp_id) {
    url = url.concat(`&filter[where][emp_id]=${emp_id}`);
  }

  if (cellnumber) {
    url = url.concat(`&filter[where][cellnumber]=${cellnumber}`);
  }

  if (email) {
    url = url.concat(`&filter[where][email]=${email}`);
  }

  if (sortField && sortOrder) {
    url = url.concat(
      `&filter[sort_column]=${sortField} ${sortOrder.toUpperCase()}`
    );
  }

  return getEncodedURL(url);
}

export function locationListMastersUrl({ queryParams }) {
  const { pageSize, pageNumber, filter, sortField, sortOrder } =
    queryParams || {};
  const { parent_location, status, store_location } = filter || {};
  // let dateFormt = moment(date).format("YYYY-MM-DD");
  const skip = pageSize * (pageNumber - 1);
  let url = `locations/getLocationsListing?filter[offset]=${skip}&filter[limit]=${pageSize}`;

  if (store_location) {
    url = url.concat(`&filter[where][store_location]=${store_location}`);
  }

  if (parent_location) {
    url = url.concat(`&filter[where][parent_location]=${parent_location}`);
  }

  if (status) {
    url = url.concat(`&filter[where][status]=${status}`);
  }

  if (sortField && sortOrder) {
    url = url.concat(
      `&filter[sort_column]=${sortField} ${sortOrder.toUpperCase()}`
    );
  }

  return url;
}

export function deleteCategoryUrl({ id, categoryId }) {
  let url = `categories/${id}`;
  if (categoryId) {
    url = url.concat(`?[filter][where][reassignId]=${categoryId}`);
  }
  return getEncodedURL(url);
}

export function deleteUserUrl({ id }) {
  let url = `users/${id}`;
  return getEncodedURL(url);
}

export function deletePasswordRequestUrl({ id }) {
  let url = `users/removeReqPassword/${id}`;
  return getEncodedURL(url);
}

export function deleteLocationUrl({ id }) {
  let url = `locations/${id}`;
  return url;
}

export function deleteProductUnitUrl({ id }) {
  let url = `productunits/${id}`;
  return getEncodedURL(url);
}

export function deleteProductUrl({ id }) {
  let url = `products/${id}`;
  return getEncodedURL(url);
}

export function addCategoryUrl() {
  let url = "categories";
  return getEncodedURL(url);
}

export function addUserUrl() {
  let url = "users/signup";
  return getEncodedURL(url);
}

export function editUserUrl({ id }) {
  let url = `users/${id}`;
  return getEncodedURL(url);
}

export function editProfileUrl({ id }) {
  let url = `users/${id}`;
  return getEncodedURL(url);
}

export function changePasswordUrl() {
  let url = `users/changePassword`;
  return getEncodedURL(url);
}

export function ViewPasswordRequestUserUrl() {
  let url = `users/changePassword`;
  return getEncodedURL(url);
}

export function addLocationUrl() {
  let url = "locations";
  return url;
}

export function addProductUnitUrl() {
  let url = "productunits";
  return getEncodedURL(url);
}

export function editCategoryUrl({ id }) {
  var url = `categories/${id}`;
  return getEncodedURL(url);
}

export function editLocationUrl({ id }) {
  var url = `locations/${id}`;
  return url;
}

export function editProductUnitUrl({ id }) {
  var url = `productunits/${id}`;
  return getEncodedURL(url);
}

export function editProductUrl({ id }) {
  var url = `products/${id}`;
  return getEncodedURL(url);
}

export const getSingleCategoryDetailsUrl = ({ id }) => {
  let url = "";
  url = `categories/${id}`;
  return getEncodedURL(url);
};

export const getSingleLocationDetailsUrl = ({ id }) => {
  let url = "";
  url = `locations/${id}`;
  return getEncodedURL(url);
};

export const getSingleProductUnitDetailsUrl = ({ id }) => {
  let url = "";
  url = `productunits/${id}`;
  return getEncodedURL(url);
};

export const getSingleProductDetailsUrl = ({ id }) => {
  let url = "";
  url = `products/${id}`;
  return getEncodedURL(url);
};

export const getSingleVendorDetailsUrl = ({ id }) => {
  let url = "";
  url = `contacts/${id}`;
  return getEncodedURL(url);
};

export const getSingleCustomerDetailsUrl = ({ id }) => {
  let url = "";
  url = `contacts/${id}`;
  return getEncodedURL(url);
};

export function reportOutwardByCustomerUrl({ queryParams }) {
  const { pageSize, pageNumber, filter } = queryParams || {};
  const { contact_name, phone_number, date, fromdate, todate } = filter || {};
  let skip = (pageNumber - 1) * pageSize;
  let url = `purchaseorders/reportOutwardByCustomer?filter[type]=Listing&filter[offset]=${skip}&filter[limit]=${pageSize}`;

  if (contact_name) {
    url = url.concat(`&filter[where][contact_name]=${contact_name}`);
  }
  if (phone_number) {
    url = url.concat(`&filter[where][phone_number]=${phone_number}`);
  }
  if (date) {
    url = url.concat(`&filter[where][date]=${date}`);
    if (date === "customrange") {
      url = url.concat(`&filter[where][fromdate]=${fromdate}`);
      url = url.concat(`&filter[where][todate]=${todate}`);
    }
  }
  return getEncodedURL(url);
}

export function reportInventoryAgingUrl({ queryParams }) {
  const { pageSize, pageNumber, filter } = queryParams || {};
  const { name, stock_keeping_unit, date, fromdate, todate } = filter || {};
  let skip = (pageNumber - 1) * pageSize;
  let url = `purchaseorders/reportInventoryAgingSummary?filter[type]=Listing&filter[offset]=${skip}&filter[limit]=${pageSize}`;

  if (name) {
    url = url.concat(`&filter[where][name]=${name}`);
  }
  if (stock_keeping_unit) {
    url = url.concat(
      `&filter[where][stock_keeping_unit]=${stock_keeping_unit}`
    );
  }
  if (date) {
    url = url.concat(`&filter[where][date]=${date}`);
    if (date === "customrange") {
      url = url.concat(`&filter[where][fromdate]=${fromdate}`);
      url = url.concat(`&filter[where][todate]=${todate}`);
    }
  }
  return getEncodedURL(url);
}

export function reportLowStockItemsUrl({ queryParams }) {
  const { pageSize, pageNumber, filter } = queryParams || {};
  const { name, stock_keeping_unit, date, fromdate, todate } = filter || {};
  let skip = (pageNumber - 1) * pageSize;
  let url = `purchaseorders/reportLowStockItems?filter[type]=Listing&filter[offset]=${skip}&filter[limit]=${pageSize}`;

  if (name) {
    url = url.concat(`&filter[where][name]=${name}`);
  }
  if (stock_keeping_unit) {
    url = url.concat(
      `&filter[where][stock_keeping_unit]=${stock_keeping_unit}`
    );
  }
  // if (date) {
  //   url = url.concat(`&filter[where][created]=${date}`);
  //   if (date === "customrange") {
  //     url = url.concat(`&filter[where][fromdate]=${fromdate}`);
  //     url = url.concat(`&filter[where][todate]=${todate}`);
  //   }
  // }
  return getEncodedURL(url);
}

export function reportInventoryValuationUrl({ queryParams }) {
  const { pageSize, pageNumber, filter } = queryParams || {};
  const { name, stock_keeping_unit, date, fromdate, todate } = filter || {};
  let skip = (pageNumber - 1) * pageSize;
  let url = `purchaseorders/reportInventoryValuationSummary?filter[type]=Listing&filter[offset]=${skip}&filter[limit]=${pageSize}`;

  if (name) {
    url = url.concat(`&filter[where][name]=${name}`);
  }
  if (stock_keeping_unit) {
    url = url.concat(
      `&filter[where][stock_keeping_unit]=${stock_keeping_unit}`
    );
  }
  if (date) {
    url = url.concat(`&filter[where][date]=${date}`);
    if (date === "customrange") {
      url = url.concat(`&filter[where][fromdate]=${fromdate}`);
      url = url.concat(`&filter[where][todate]=${todate}`);
    }
  }
  return getEncodedURL(url);
}

export function reportActivePOUrl({ queryParams }) {
  const { pageSize, pageNumber, filter } = queryParams || {};
  const {
    contact_name,
    purchaseorder_number,
    po_date,
    expected_delivery,
    status,
    date,
    fromdate,
    todate,
  } = filter || {};
  let skip = (pageNumber - 1) * pageSize;
  // let dateFormtp = moment(po_date).format("YYYY-MM-DD");
  let url = `purchaseorders/reportActivePo?filter[type]=Listing&filter[offset]=${skip}&filter[limit]=${pageSize}`;

  if (contact_name) {
    url = url.concat(`&filter[where][contact_name]=${contact_name}`);
  }

  if (purchaseorder_number) {
    url = url.concat(
      `&filter[where][purchaseorder_number]=${purchaseorder_number}`
    );
  }

  if (po_date) {
    url = url.concat(`&filter[where][po_date]=${po_date}`);
  }

  if (expected_delivery) {
    url = url.concat(`&filter[where][expected_delivery]=${expected_delivery}`);
  }

  if (status) {
    url = url.concat(`&filter[where][purchaseorderstatusId]=${status}`);
  }

  if (date) {
    url = url.concat(`&filter[where][date]=${date}`);
    if (date === "customrange") {
      url = url.concat(`&filter[where][fromdate]=${fromdate}`);
      url = url.concat(`&filter[where][todate]=${todate}`);
    }
  }
  return getEncodedURL(url);
}

export function reportInwardValuationUrl({ queryParams }) {
  const { pageSize, pageNumber, filter } = queryParams || {};
  const { name, stock_keeping_unit, date, fromdate, todate } = filter || {};
  let skip = (pageNumber - 1) * pageSize;
  let url = `purchaseorders/reportInwardValuation?filter[type]=Listing&filter[offset]=${skip}&filter[limit]=${pageSize}`;

  if (name) {
    url = url.concat(`&filter[where][name]=${name}`);
  }

  if (stock_keeping_unit) {
    url = url.concat(
      `&filter[where][stock_keeping_unit]=${stock_keeping_unit}`
    );
  }

  if (date) {
    url = url.concat(`&filter[where][date]=${date}`);
    if (date === "customrange") {
      url = url.concat(`&filter[where][fromdate]=${fromdate}`);
      url = url.concat(`&filter[where][todate]=${todate}`);
    }
  }
  return getEncodedURL(url);
}

export function reportOutwardValuationUrl({ queryParams }) {
  const { pageSize, pageNumber, filter } = queryParams || {};
  const { name, stock_keeping_unit, date, fromdate, todate } = filter || {};
  let skip = (pageNumber - 1) * pageSize;
  let url = `purchaseorders/reportOutwardValuation?filter[type]=Listing&filter[offset]=${skip}&filter[limit]=${pageSize}`;

  if (name) {
    url = url.concat(`&filter[where][name]=${name}`);
  }

  if (stock_keeping_unit) {
    url = url.concat(
      `&filter[where][stock_keeping_unit]=${stock_keeping_unit}`
    );
  }

  if (date) {
    url = url.concat(`&filter[where][date]=${date}`);
    if (date === "customrange") {
      url = url.concat(`&filter[where][fromdate]=${fromdate}`);
      url = url.concat(`&filter[where][todate]=${todate}`);
    }
  }
  return getEncodedURL(url);
}

export function reportDelayedPODCUrl({ queryParams }) {
  const { pageSize, pageNumber, filter } = queryParams || {};
  const {
    delivery_date,
    purchaseorder_number,
    expected_delivery,
    date,
    fromdate,
    todate,
  } = filter || {};

  let dateFormt = moment(delivery_date).format("YYYY-MM-DD");
  let dateFormte = moment(expected_delivery).format("YYYY-MM-DD");

  let skip = (pageNumber - 1) * pageSize;
  let url = `purchaseorders/reportDelayedPoandDc?filter[type]=Listing&filter[offset]=${skip}&filter[limit]=${pageSize}`;

  if (purchaseorder_number) {
    url = url.concat(
      `&filter[where][purchaseorder_number]=${purchaseorder_number}`
    );
  }

  if (expected_delivery) {
    url = url.concat(`&filter[where][expected_delivery]=${dateFormte}`);
  }

  if (delivery_date) {
    console.log("if");
    url = url.concat(`&filter[where][delivery_date]=${dateFormt}`);
  }

  if (date) {
    url = url.concat(`&filter[where][date]=${date}`);
    if (date === "customrange") {
      url = url.concat(`&filter[where][fromdate]=${fromdate}`);
      url = url.concat(`&filter[where][todate]=${todate}`);
    }
  }
  return getEncodedURL(url);
}

export function reportOutwardByProductUrl({ queryParams }) {
  const { pageSize, pageNumber, filter } = queryParams || {};
  const { name, stock_keeping_unit, date, fromdate, todate } = filter || {};
  let skip = (pageNumber - 1) * pageSize;
  let url = `purchaseorders/reportOutwardByProduct?filter[type]=Listing&filter[offset]=${skip}&filter[limit]=${pageSize}`;

  if (name) {
    url = url.concat(`&filter[where][name]=${name}`);
  }
  if (stock_keeping_unit) {
    url = url.concat(
      `&filter[where][stock_keeping_unit]=${stock_keeping_unit}`
    );
  }
  if (date) {
    url = url.concat(`&filter[where][date]=${date}`);
    if (date === "customrange") {
      url = url.concat(`&filter[where][fromdate]=${fromdate}`);
      url = url.concat(`&filter[where][todate]=${todate}`);
    }
  }
  return getEncodedURL(url);
}

export function reportOutwardByCustomerExportUrl({ queryParams, docType }) {
  const { filter } = queryParams || {};
  const { date, fromdate, todate } = filter || {};

  let url = `purchaseorders/reportOutwardByCustomer?filter[type]=${docType}`;
  if (date) {
    url = url.concat(`&filter[where][date]=${date}`);
  }
  return getEncodedURL(url);
}

export function reportInventoryAgingExportUrl({ queryParams, docType, date }) {
  let url = `purchaseorders/reportInventoryAgingSummary?filter[type]=${docType}&filter[where][date]=${queryParams.filter.date}`;
  return getEncodedURL(url);
}

export function reportLowStockItemsExportUrl({ queryParams, docType }) {
  const { filter } = queryParams || {};
  const { date, fromdate, todate } = filter || {};

  let url = `purchaseorders/reportLowStockItems?filter[type]=${docType}`;
  if (date) {
    url = url.concat(`&filter[where][created]=${date}`);
    if (date === "customrange") {
      url = url.concat(`&filter[where][fromdate]=${fromdate}`);
      url = url.concat(`&filter[where][todate]=${todate}`);
    }
  }
  return getEncodedURL(url);
}

export function reportInventoryValuationExportUrl({ queryParams, docType }) {
  const { filter } = queryParams || {};
  const { date, fromdate, todate } = filter || {};
  let url = `purchaseorders/reportInventoryValuationSummary?filter[type]=${docType}`;
  if (date) {
    url = url.concat(`&filter[where][date]=${date}`);
    if (date === "customrange") {
      url = url.concat(`&filter[where][fromdate]=${fromdate}`);
      url = url.concat(`&filter[where][todate]=${todate}`);
    }
  }
  return getEncodedURL(url);
}

export function reportActivePOExportUrl({ queryParams, docType }) {
  let url = `purchaseorders/reportActivePo?filter[type]=${docType}`;
  return getEncodedURL(url);
}

export function reportInwardValuationExportUrl({ queryParams, docType }) {
  const { filter } = queryParams || {};
  const { date, fromdate, todate } = filter || {};

  let url = `purchaseorders/reportInwardValuation?filter[type]=${docType}`;
  if (date) {
    url = url.concat(`&filter[where][date]=${date}`);
    if (date === "customrange") {
      url = url.concat(`&filter[where][fromdate]=${fromdate}`);
      url = url.concat(`&filter[where][todate]=${todate}`);
    }
  }
  return getEncodedURL(url);
}

export function reportOutwardValuationExportUrl({ queryParams, docType }) {
  const { filter } = queryParams || {};
  const { date, fromdate, todate } = filter || {};
  let url = `purchaseorders/reportOutwardValuation?filter[type]=${docType}`;
  if (date) {
    url = url.concat(`&filter[where][date]=${date}`);
    if (date === "customrange") {
      url = url.concat(`&filter[where][fromdate]=${fromdate}`);
      url = url.concat(`&filter[where][todate]=${todate}`);
    }
  }
  return getEncodedURL(url);
}

export function reportDelayedPODCExportUrl({ queryParams, docType }) {
  let url = `purchaseorders/reportDelayedPoandDc?filter[type]=${docType}&filter[where][date]=${queryParams.filter.date}`;
  return getEncodedURL(url);
}

export function reportOutwardByProductExportUrl({
  queryParams,
  docType,
  date,
}) {
  let url = `purchaseorders/reportOutwardByProduct?filter[type]=${docType}`;
  return getEncodedURL(url);
}

export function reportOutwardByCustomerPrintUrl({ queryParams }) {
  let url = `purchaseorders/reportOutwardByCustomer?filter[type]=PDF`;
  return getEncodedURL(url);
}

export function reportOutwardByProductPrintUrl({ queryParams }) {
  let url = `purchaseorders/reportOutwardByProduct?filter[type]=PDF`;
  return getEncodedURL(url);
}

export function reportInventoryAgingPrintUrl({ queryParams }) {
  let url = `purchaseorders/reportInventoryAgingSummary?filter[type]=PDF`;
  return getEncodedURL(url);
}

export function reportLowStockItemsPrintUrl({ queryParams }) {
  const { filter } = queryParams || {};
  const { date, fromdate, todate } = filter || {};
  let url = `purchaseorders/reportLowStockItems?filter[type]=PDF`;
  if (date) {
    url = url.concat(`&filter[where][created]=${date}`);
    if (date === "customrange") {
      url = url.concat(`&filter[where][fromdate]=${fromdate}`);
      url = url.concat(`&filter[where][todate]=${todate}`);
    }
  }
  return getEncodedURL(url);
}

export function reportInventoryValuationPrintUrl({ queryParams }) {
  const { filter } = queryParams || {};
  const { date, fromdate, todate } = filter || {};
  let url = `purchaseorders/reportInventoryValuationSummary?filter[type]=PDF`;
  if (date) {
    url = url.concat(`&filter[where][date]=${date}`);
    if (date === "customrange") {
      url = url.concat(`&filter[where][fromdate]=${fromdate}`);
      url = url.concat(`&filter[where][todate]=${todate}`);
    }
  }
  return getEncodedURL(url);
}

export function reportActivePOPrintUrl({ queryParams }) {
  let url = `purchaseorders/reportActivePo?filter[type]=PDF`;
  return getEncodedURL(url);
}

export function reportInwardValuationPrintUrl({ queryParams }) {
  const { filter } = queryParams || {};
  const { date, fromdate, todate } = filter || {};

  let url = `purchaseorders/reportInwardValuation?filter[type]=PDF`;
  if (date) {
    url = url.concat(`&filter[where][date]=${date}`);
    if (date === "customrange") {
      url = url.concat(`&filter[where][fromdate]=${fromdate}`);
      url = url.concat(`&filter[where][todate]=${todate}`);
    }
  }
  return getEncodedURL(url);
}

export function reportOutwardValuationPrintUrl({ queryParams }) {
  const { filter } = queryParams || {};
  const { date, fromdate, todate } = filter || {};
  let url = `purchaseorders/reportOutwardValuation?filter[type]=PDF`;
  if (date) {
    url = url.concat(`&filter[where][date]=${date}`);
    if (date === "customrange") {
      url = url.concat(`&filter[where][fromdate]=${fromdate}`);
      url = url.concat(`&filter[where][todate]=${todate}`);
    }
  }
  return getEncodedURL(url);
}

export function reportDelayedPODCPrintUrl({ queryParams }) {
  let url = `purchaseorders/reportDelayedPoandDc?filter[type]=PDF`;
  return getEncodedURL(url);
}

export function productListMastersUrl({ queryParams }) {
  const { pageSize, pageNumber, filter, sortField, sortOrder } =
    queryParams || {};
  const {
    name,
    status,
    amount,
    stock_keeping_unit,
    reorder_level,
    category_name,
    available_quantity,
  } = filter || {};
  // let dateFormt = moment(date).format("YYYY-MM-DD");
  const skip = pageSize * (pageNumber - 1);
  let url = `products?filter[offset]=${skip}&filter[limit]=${pageSize}`;
  if (name) {
    url = url.concat(`&filter[where][name]=${name}`);
  }

  if (status) {
    url = url.concat(`&filter[where][status]=${status}`);
  }

  if (amount) {
    url = url.concat(
      `&filter[where][startamount]=${amount.priceOne}&filter[where][endamount]=${amount.priceTwo}`
    );
  }

  if (stock_keeping_unit) {
    url = url.concat(
      `&filter[where][stock_keeping_unit]=${stock_keeping_unit}`
    );
  }

  if (reorder_level) {
    url = url.concat(`&filter[where][reorder_level]=${reorder_level}`);
  }

  if (category_name) {
    url = url.concat(`&filter[where][category_name]=${category_name}`);
  }

  if (available_quantity) {
    url = url.concat(
      `&filter[where][available_quantity]=${available_quantity}`
    );
  }

  if (sortField && sortOrder) {
    url = url.concat(
      `&filter[sort_column]=${sortField} ${sortOrder.toUpperCase()}`
    );
  }

  return getEncodedURL(url);
}

export function productUnitListMastersUrl({ queryParams }) {
  const { pageSize, pageNumber, filter, sortField, sortOrder } =
    queryParams || {};
  const { name, status } = filter || {};
  // let dateFormt = moment(date).format("YYYY-MM-DD");
  const skip = pageSize * (pageNumber - 1);
  let url = `productunits?filter[offset]=${skip}&filter[limit]=${pageSize}`;
  if (name) {
    url = url.concat(`&filter[where][name]=${name}`);
  }

  if (status) {
    url = url.concat(`&filter[where][status]=${status}`);
  }

  if (sortField && sortOrder) {
    url = url.concat(
      `&filter[sort_column]=${sortField} ${sortOrder.toUpperCase()}`
    );
  }

  return getEncodedURL(url);
}

export function getLocationListBasedOnProductUrl({ queryParams, productId }) {
  const { pageSize, pageNumber } = queryParams || {};

  const skip = pageSize * (pageNumber - 1);
  var url = `purchaseorders/inventoryLocationProduct?filter[offset]=${skip}&filter[limit]=${pageSize}`;
  if (productId) {
    url = url.concat(`&filter[where][productId]=${productId}`);
  }
  return getEncodedURL(url);
}

export function getPORecentFiveUrl({
  sortColumnName,
  waitingStatus,
  issueStatus,
  partialStatus,
  contact_name,
}) {
  var url = `purchaseorders?filter[limit]=5&filter[offset]=0&filter[where][contact_name]=${contact_name}&filter[where][purchaseorderstatusId]=${waitingStatus}&filter[where][purchaseorderstatusId]=${issueStatus}&filter[where][purchaseorderstatusId]=${partialStatus}&filter[sort_column]=${sortColumnName} DESC`;
  return getEncodedURL(url);
}

export function getPORecentFiveCompletedUrl({
  sortColumnName,
  status,
  contact_name,
}) {
  var url = `purchaseorders?filter[limit]=5&filter[offset]=0&filter[where][contact_name]=${contact_name}&filter[where][purchaseorderstatusId]=${status}&filter[sort_column]=${sortColumnName} DESC`;
  return getEncodedURL(url);
}

export function getRecentFiveDCUrl({
  sortColumnName,
  waitingStatus,
  issueStatus,
  partialStatus,
  contact_name,
}) {
  var url = `purchaseorders/getDCListing?filter[limit]=5&filter[offset]=0&filter[where][contact_name]=${contact_name}&filter[where][purchaseorderstatusId]=${waitingStatus}&filter[where][purchaseorderstatusId]=${issueStatus}&filter[where][purchaseorderstatusId]=${partialStatus}&filter[sort_column]=${sortColumnName} DESC`;
  return getEncodedURL(url);
}

export function getRecentFiveDCCompletedUrl({
  sortColumnName,
  status,
  contact_name,
}) {
  var url = `purchaseorders/getDCListing?filter[limit]=5&filter[offset]=0&filter[where][contact_name]=${contact_name}&filter[where][purchaseorderstatusId]=${status}&filter[sort_column]=${sortColumnName} DESC`;
  return getEncodedURL(url);
}

export function singleUserUrl({ id }) {
  var url = `users/${id}`;
  return getEncodedURL(url);
}

export function downloadAttachmentUrl({ containername, filename, token }) {
  console.log(AUTH_TOKEN);
  var url = `containers/download?containername=${containername}&filename=${filename}&token=${AUTH}`;
  return getEncodedURL(url);
}

export function checkUserExistUrl() {
  var url = `users/checkUserExists`;
  return getEncodedURL(url);
}

export function deleteBarcodeUrl({ id }) {
  let url = `purchaseorderreceive/deleteBarcode/${id}`;
  return getEncodedURL(url);
}

export function addNewBarcodeUrl() {
  let url = `purchaseorderreceive/addNewBarcode`;
  return getEncodedURL(url);
}