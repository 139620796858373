import React from "react";
import ViewSingleDetailModal from "../../../global-modal/ViewSingleDetailModal";
import { FormattedMessage } from "react-intl";

export function UserViewModal({ data, handleConfirmHide, isConfirmModal }) {
  const { id, full_name = "", emp_id, email, cellnumber, role, status } = data;
  const { name: roleName } = role || {};
  const dataArray = [];
  if (full_name) {
    dataArray.push({
      key: <FormattedMessage id="TITLE.USER.DISPLAY.NAME" />,
      value: full_name,
    });
  }

  if (emp_id) {
    dataArray.push({
      key: <FormattedMessage id="TITLE.EMPLOYEE.ID" />,
      value: emp_id,
    });
  }

  if (email) {
    dataArray.push({
      key: <FormattedMessage id="TITLE.EMAIL" />,
      value: email,
    });
  }

  if (cellnumber) {
    dataArray.push({
      key: <FormattedMessage id="TITLE.PHONE.NUMBER" />,
      value: cellnumber,
    });
  }

  if (roleName) {
    dataArray.push({
      key: <FormattedMessage id="TITLE.ROLE" />,
      value: roleName,
    });
  }

  const renderCustomerStatus = () => {
    let statusClass = "";
    statusClass =
      status === 1
        ? "badge badge-pill badge-success fs-14"
        : "badge badge-pill badge-warning fs-14";
    return (
      <span className={`${statusClass} status-color text-capitalize`}>
        {status === 1 ? (
          <FormattedMessage id="TITLE.ACTIVE" />
        ) : (
          <FormattedMessage id="TITLE.INACTIVE" />
        )}
      </span>
    );
  };

  if (status !== null) {
    dataArray.push({
      key: <FormattedMessage id="TITLE.STATUS" />,
      value: renderCustomerStatus(),
    });
  }

  return (
    <>
      <ViewSingleDetailModal
        handleConfirmHide={handleConfirmHide}
        isConfirmModal={isConfirmModal}
        title={
          <>
            <FormattedMessage id="TITLE.VIEW.PROFILE" /> - {full_name}
          </>
        }
        dataArray={dataArray}
      />
    </>
  );
}
