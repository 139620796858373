import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Trash2 } from "react-feather";
import { ButtonLoader } from "../../../helper/ButtonLoader";
import { FormattedMessage } from "react-intl";

export function DeleteModal({
  title,
  description,
  id,
  onCloseModal,
  isOpen,
  onClickOfDeleteButton,
  isLoading,
}) {
  return (
    <>
      <Modal
        show={isOpen}
        onHide={onCloseModal}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Body>
          <div className="text-center p-3">
            <h3
              className="modal-title text-center mb-3 text-danger"
              style={{ overflowWrap: "anywhere" }}
            >
              {`${id}`}
            </h3>
            <Trash2
              size={60}
              className="text-danger mb-2"
              style={{ strokeWidth: "1.5px" }}
            ></Trash2>
            <h3
              className="modal-title text-center mb-3 text-danger"
              style={{ overflowWrap: "anywhere" }}
            >
              {`${title}`}
            </h3>
            <p className="text-center text-dark mb-0">{description}</p>
          </div>
          <div className="d-flex align-items-center justify-content-center mb-3">
            <Button
              variant="danger btn-inventory mr-3 btn-inventory"
              onClick={onClickOfDeleteButton}
              disabled={isLoading}
            >
              <FormattedMessage id="TITLE.YES.DELETE" />
              {isLoading && <ButtonLoader className="mr-1 ml-1" />}
            </Button>
            <Button
              variant="tertiary btn-inventory text-dark btn-inventory"
              onClick={() => onCloseModal()}
            >
              <FormattedMessage id="TITLE.CANCEL" />
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
