/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { getStatusForActiveDeactiveClass } from "../constants/utils";
import { CategoryStatus } from "../components/category-module/CategoryUIHelpers";
const StatusTypeFormatter = (cellContent, row, rowIndex) => {
  const { status } = row;

  const renderStatus = () => {
    return (
      <span
        className={`${getStatusForActiveDeactiveClass(
          status
        )} status-color text-capitalize`}
      >
        {CategoryStatus[status]}
      </span>
    );
  };

  return renderStatus();
};

export default StatusTypeFormatter;
