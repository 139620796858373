import React, { useContext, useEffect, useRef, useState } from "react";
import { CustomerCard } from "./CustomerCard";
import { CustomerUIProvider } from "./CustomerUIContext";
import { initialFilter } from "./CustomerUIHelpers";
import { ErrorModal } from "../common/modals/ErrorModal";
import { DeleteModal } from "../common/modals/DeleteModal";
import {
  customerListApi,
  deleteCustomerApi,
} from "../../axios/services/services";
import { CustomerViewModal } from "./customer-view-modal/CustomerViewModal";
import { CustomerEditModal } from "./customer-edit-modal/CustomerEditModal";
import { AddNewCustomerModal } from "../../global-modal/AddNewCustomerModal";
import { SuccessModal } from "../common/modals/SuccessModal";
import {
  capitalizeFirstLetter,
  getErrorMessageBasedOnLanguage,
  setActiveMenu,
  unauthorization,
} from "../../constants/utils";
import { useIntl } from "react-intl";
import { MENUITEMS } from "../../constants/menu";
import { UIContext } from "../../helper/UIContext";

export function CustomerPage({ history }) {
  const [data, setData] = useState({});
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isLoadingDeleteCustomer, setLoadingDeleteCustomer] = useState(false);
  const [loadingExportButton, setLoadingExportButton] = useState(false);
  const [customerListTotalCount, setCustomerListTotalCount] = useState(0);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [isOpenViewCustomerModal, setOpenViewCustomerModal] = useState(false);
  const [isOpenAddCustomerModal, setOpenAddCustomerModal] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [isUpdateCustomerDetails, setUpdateCustomerDetails] = useState(0);
  const [isOpenEditCustomerModal, setOpenEditCustomerModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isFilter, setFilter] = useState(false);
  const [isLoadingFilter, setLoadingFilter] = useState(false);
  const [ids, setIds] = useState([]);
  const intl = useIntl();
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const uiContext = useContext(UIContext);
  const exportCSVButton = useRef();

  async function getCustomerList() {
    enableLoading();
    if (isFilter) {
      enableLoadingFilter();
    }
    const customerListResponse = await customerListApi({
      queryParams,
    });
    disableLoading();
    if (isFilter) {
      disableLoadingFilter();
    }
    setFilter(false);
    const { Error, data, count } = customerListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setCustomerList(data ? data : []);
    setCustomerListTotalCount(count ? count : 0);
  }

  useEffect(() => {
    getCustomerList();
  }, [queryParams, isUpdateCustomerDetails]);

  const enableLoadingFilter = () => {
    setLoadingFilter(true);
  };

  const disableLoadingFilter = () => {
    setLoadingFilter(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleDeleteModalShow = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalHide = () => {
    setIsDeleteModalOpen(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingDeleteCustomer = () => {
    setLoadingDeleteCustomer(true);
  };

  const disableLoadingDeleteCustomer = () => {
    setLoadingDeleteCustomer(false);
  };

  const viewCustomerModalShow = () => {
    setOpenViewCustomerModal(true);
  };

  const viewCustomerModalHide = () => {
    setOpenViewCustomerModal(false);
  };

  const addCustomerModalShow = () => {
    setOpenAddCustomerModal(true);
  };

  const addCustomerModalHide = () => {
    setOpenAddCustomerModal(false);
  };

  const onClickOfDeleteButton = () => {
    deleteCustomer();
  };

  const editCustomerModalShow = () => {
    setOpenEditCustomerModal(true);
  };

  const editCustomerModalHide = () => {
    setOpenEditCustomerModal(false);
  };

  const onOpenSuccessModal = () => {
    setOpenSuccessModal(true);
  };

  const onCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const handleExportButton = async () => {};

  const handleOnClickOfCreateNew = () => {
    addCustomerModalShow();
  };

  const handleCLickOfFilterButtonFilter = (values) => {
    setFilter(true);
  };

  const handleClickOfRemoveFilterButton = () => {
    setFilterOpen(!isFilterOpen);
    setFilter(false);
    setQueryParamsBase(initialFilter);
  };

  const handleCloseOfAddNewCustomerModal = (addNewVendorDetails) => {
    setUpdateCustomerDetails(Math.random());
    addCustomerModalHide();
  };

  const handleCloseOfEditCustomerModal = (editNewVendorDetails) => {
    setUpdateCustomerDetails(Math.random());
    editCustomerModalHide();
  };

  const customerUIEvents = {
    openViewDialog: (row) => {
      setData(row);
      onClickChangeRoute(`/customers/view-customer-details/${row?.id}`);
    },
    openEditDialog: (row) => {
      setData(row);
      editCustomerModalShow();
    },
    openDeleteDialog: (row) => {
      setData(row);
      handleDeleteModalShow();
    },
    redirectToViewPage: (row) => {
      setData(row);
      onClickChangeRoute(`/customers/view-customer-details/${row?.id}`);
    },
  };

  const deleteCustomer = async () => {
    const { id } = data;
    enableLoadingDeleteCustomer();
    const deleteCustmerResponse = await deleteCustomerApi({ id });
    const { Error } = deleteCustmerResponse || {};
    if (Error) {
      disableLoadingDeleteCustomer();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setIsDeleteModalOpen(false);
    setSuccessMessage(
      intl.formatMessage({ id: "TITLE.CUSTOMER.DELETED.SUCCESSFULLY" })
    );
    onOpenSuccessModal();
    setUpdateCustomerDetails(Math.random());
    disableLoadingDeleteCustomer();
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  return (
    <div>
      <>
        <CustomerUIProvider
          customerUIEvents={customerUIEvents}
          queryParams={queryParams}
          setQueryParamsBase={setQueryParamsBase}
          ids={ids}
          setIds={setIds}
        >
          <CustomerCard
            isLoading={isLoading}
            customerList={customerList}
            loadingExportButton={loadingExportButton}
            customerListExport={customerList}
            handleExportButton={handleExportButton}
            exportCSVButton={exportCSVButton}
            customerListTotalCount={customerListTotalCount}
            onClickOfCreateNew={handleOnClickOfCreateNew}
            handleClickOfRemoveFilterButton={handleClickOfRemoveFilterButton}
            isLoadingFilter={isLoadingFilter}
            isFilterOpen={isFilterOpen}
            handleCLickOfFilterButtonFilter={handleCLickOfFilterButtonFilter}
          />
        </CustomerUIProvider>

        {isOpenAddCustomerModal && (
          <AddNewCustomerModal
            open={isOpenAddCustomerModal}
            onCloseModal={addCustomerModalHide}
            handleCloseOfAddNewCustomerModal={handleCloseOfAddNewCustomerModal}
          />
        )}

        {isDeleteModalOpen && (
          <DeleteModal
            isLoading={isLoadingDeleteCustomer}
            title={intl.formatMessage({ id: "TITLE.DELETE.CUSTOMER" })}
            description={intl.formatMessage({
              id: "TITLE.CUSTOMER.WILL.BE.DELETED.FOREVER.AND.CANNOT.BE.RETRIEVED.LATER",
            })}
            isOpen={isDeleteModalOpen}
            id={capitalizeFirstLetter(data?.contact_name)}
            onCloseModal={handleDeleteModalHide}
            onClickOfDeleteButton={onClickOfDeleteButton}
          />
        )}

        {isOpenViewCustomerModal && (
          <CustomerViewModal
            isConfirmModal={isOpenViewCustomerModal}
            handleConfirmHide={viewCustomerModalHide}
            title={"View Customer Details"}
            nameTitle={"Customer Name"}
            data={data}
          />
        )}

        {isOpenEditCustomerModal && (
          <CustomerEditModal
            data={data}
            isEditModal={isOpenEditCustomerModal}
            onCloseModal={editCustomerModalHide}
            id={data.id}
            handleCloseOfEditCustomerModal={handleCloseOfEditCustomerModal}
          />
        )}

        {isErrorModalOpen && (
          <ErrorModal
            message={errorMessage}
            isErrorModalOpen={isErrorModalOpen}
            hideErrorModal={handleErrorModalHide}
          />
        )}

        {openSuccessModal && (
          <SuccessModal
            message={successMessage}
            open={openSuccessModal}
            onCloseModal={onCloseSuccessModal}
          />
        )}

        {isOpenViewCustomerModal && (
          <CustomerViewModal
            isConfirmModal={isOpenViewCustomerModal}
            handleConfirmHide={viewCustomerModalHide}
            title={"View Customer Details"}
            nameTitle={"Customer Name"}
            data={data}
          />
        )}
      </>
    </div>
  );
}
