// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as uiHelpers from "../InventoryValuationUIHelpers";
import { useInventoryValuationUIContext } from "../InventoryValuationUIContext";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
} from "../../../../helper/helper";
import { Pagination } from "../../../../pagination/Pagination";
import TotalAmountUnderscoreColumnFormatter from "../../../../columnformatter/TotalAmountUnderscoreColumnFormatter";
import { useIntl } from "react-intl";

export function InventoryValuationTable({
  isLoading,
  reportInventoryValuation,
  reportInventoryValuationTotalCount,
  reportInventoryValuationTotalStock,
  reportInventoryValuationTotalAmount,
}) {
  // InventoryValuation UI Context
  const intl = useIntl();
  const inventoryvaluationUIContext = useInventoryValuationUIContext();
  const inventoryvaluationUIProps = useMemo(() => {
    return {
      ids: inventoryvaluationUIContext.ids,
      setIds: inventoryvaluationUIContext.setIds,
      queryParams: inventoryvaluationUIContext.queryParams,
      setQueryParams: inventoryvaluationUIContext.setQueryParams,
      openViewDialog: inventoryvaluationUIContext.openViewDialog,
      openEditDialog: inventoryvaluationUIContext.openEditDialog,
      openDeleteDialog: inventoryvaluationUIContext.openDeleteDialog,
    };
  }, [inventoryvaluationUIContext]);

  // Table columns
  const columns = [
    {
      dataField: "product_name",
      text: intl.formatMessage({
        id: "TITLE.PRODUCT.NAME",
      }),
      ...(reportInventoryValuationTotalCount &&
        reportInventoryValuationTotalCount > 0 && {
          footer: "Total",
        }),
      style: {
        minWidth: "100px",
      },
    },
    {
      dataField: "price",
      text: intl.formatMessage({
        id: "TITLE.PRICE",
      }),
      ...(reportInventoryValuationTotalCount &&
        reportInventoryValuationTotalCount > 0 && {
          footer: "",
        }),
      style: {
        minWidth: "130px",
      },
    },
    {
      dataField: "barcodecount",
      text: intl.formatMessage({
        id: "TITLE.STOCK.ON.HAND",
      }),
      ...(reportInventoryValuationTotalCount &&
        reportInventoryValuationTotalCount > 0 && {
          footer: JSON.stringify(reportInventoryValuationTotalStock),
        }),
      style: {
        minWidth: "150px",
      },
    },
    {
      dataField: "total_amount",
      text: intl.formatMessage({
        id: "TITLE.TOTAL.AMOUNT",
      }),
      ...(reportInventoryValuationTotalCount &&
        reportInventoryValuationTotalCount > 0 && {
          footer: new Intl.NumberFormat("en-EN", {
            style: "currency",
            currency: "AED",
          }).format(reportInventoryValuationTotalAmount),
        }),
      style: {
        minWidth: "130px",
      },
      formatter: TotalAmountUnderscoreColumnFormatter,
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: reportInventoryValuationTotalCount
      ? reportInventoryValuationTotalCount
      : 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: inventoryvaluationUIProps.queryParams.pageSize,
    onPageChange: (page) => {
      if (page === 0) return;
    },
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-bordered table-vertical-center inward-table table-inventory"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={reportInventoryValuation}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  inventoryvaluationUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
              <PleaseWaitMessage isLoading={isLoading} />
              <NoRecordsFoundMessage
                entities={reportInventoryValuation}
                isLoading={isLoading}
              />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
