import axios from "axios";
import { SERVER_URL } from "../ServerConfig";
import download from "downloadjs";
import { AUTH_TOKEN, i18nConfig } from "../constants/const";
import { getLanguageCode } from "../constants/utils";
const API = "/api/";
const APIV = "/api-v1/";

export const AUTH = localStorage.getItem(AUTH_TOKEN);
export const language_code = JSON.parse(localStorage.getItem(i18nConfig));

export const getCall = (url) =>
  new Promise((resolve) => {
    const baseUrl = `${SERVER_URL}${API}${url}`;
    axios
      .get(baseUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: AUTH,
          language: getLanguageCode(language_code),
        },
        credentials: "include",
      })
      .then((res) => (res.status === 204 ? {} : res.data))
      .then((json) => {
        return resolve(json);
      })
      .catch((error) => {
        if (error && !error.response) {
          const errorObj = {
            Error: {
              message: "NETWORK_ERROR",
            },
          };
          resolve(errorObj);
        }
        if (error && error.response) {
          resolve(error.response.data);
        }
      });
  });

export const getPDFCall = (url, customPdfName, extention) =>
  new Promise(async (resolve) => {
    const baseUrl = `${SERVER_URL}${API}${url}`;
    axios
      .get(baseUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: AUTH,
          language: getLanguageCode(language_code),
        },
        credentials: "include",
        responseType: "blob",
      })
      .then((res) => {
        const content = res.headers["content-type"];
        download(res.data, `${customPdfName}.${extention}`, content);
        resolve({ res });
      })
      .catch((error) => {
        if (error && error.response) {
          resolve(error.response.data);
        }
      });
  });

export const getDownloadCall = (url, customPdfName) =>
  new Promise(async (resolve) => {
    const baseUrl = `${SERVER_URL}${APIV}${url}`;
    axios
      .get(baseUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: AUTH,
          language: getLanguageCode(language_code),
        },
        credentials: "include",
        responseType: "blob",
      })
      .then((res) => {
        const content = res.headers["content-type"];
        download(res.data, `${customPdfName}`, content);
        resolve({ res });
      })
      .catch((error) => {
        if (error && error.response) {
          resolve(error.response.data);
        }
      });
  });

export const getPDFPrintCall = (url) =>
  new Promise((resolve) => {
    const baseUrl = `${SERVER_URL}${API}${url}`;
    axios
      .get(baseUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: AUTH,
          language: getLanguageCode(language_code),
        },
        credentials: "include",
        responseType: "blob",
      })
      .then((res) => {
        resolve({ res });
      })
      .catch((error) => {
        if (error && !error.response) {
          const errorObj = {
            Error: {
              message: "NETWORK_ERROR",
            },
          };
          resolve(errorObj);
        }
        if (error && error.response) {
          resolve(error.response.data);
        }
      });
  });

export const postCall = (url, data = {}) =>
  new Promise((resolve) => {
    const baseUrl = `${SERVER_URL}${API}${url}`;
    axios
      .post(baseUrl, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: AUTH,
          language: getLanguageCode(language_code),
        },
        credentials: "include",
      })
      .then((res) => (res.status === 204 ? {} : res.data))
      .then((json) => {
        return resolve(json);
      })
      .catch((error) => {
        if (error && !error.response) {
          const errorObj = {
            Error: {
              message: "NETWORK_ERROR",
            },
          };
          resolve(errorObj);
        }
        if (error && error.response) {
          resolve(error.response.data);
        }
      });
  });

export const patchCall = (url, data = {}) =>
  new Promise((resolve) => {
    const baseUrl = `${SERVER_URL}${API}${url}`;
    axios
      .patch(baseUrl, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: AUTH,
          language: getLanguageCode(language_code),
        },
        credentials: "include",
      })
      .then((res) => (res.status === 204 ? {} : res.data))
      .then((json) => {
        // console.log("API URL - ", baseUrl);
        // console.log("API Response - ", json);
        return resolve(json);
      })
      .catch((error) => {
        if (error && !error.response) {
          const errorObj = {
            Error: {
              message: "NETWORK_ERROR",
            },
          };
          resolve(errorObj);
        }
        if (error && error.response) {
          resolve(error.response.data);
        }
      });
  });

export const deleteCall = (url, data = {}) =>
  new Promise((resolve) => {
    const baseUrl = `${SERVER_URL}${API}${url}`;
    axios
      .delete(baseUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: AUTH,
          language: getLanguageCode(language_code),
        },
        credentials: "include",
      })
      .then((res) => (res.status === 204 ? {} : res.data))
      .then((json) => {
        // console.log("API URL - ", baseUrl);
        // console.log("API Response - ", json);
        return resolve(json);
      })
      .catch((error) => {
        if (error && !error.response) {
          const errorObj = {
            Error: {
              message: "NETWORK_ERROR",
            },
          };
          resolve(errorObj);
        }
        if (error && error.response) {
          resolve(error.response.data);
        }
      });
  });

export const postCallWithMedia = (url, data = {}) =>
  new Promise((resolve) => {
    const baseUrl = `${SERVER_URL}${url}`;
    const formData = new FormData();
    formData.append(data.containername, data.file);
    axios({
      method: "POST",
      url: baseUrl,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: AUTH,
        language: getLanguageCode(language_code),
      },
    })
      .then((res) => (res.status === 204 ? {} : res.data))
      .then((json) => resolve(json))
      .catch((error) => {
        if (error && error.response) {
          resolve(error.response.data);
        }
      });
  });

export const getExcelCall = (url, filename) =>
  new Promise(async (resolve) => {
    const baseUrl = `${SERVER_URL}${API}${url}`;
    axios
      .get(baseUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: AUTH,
          language: getLanguageCode(language_code),
        },
        credentials: "include",
        responseType: "blob",
      })
      .then((res) => {
        const content = res.headers["content-type"];
        download(
          res.data,
          filename + "_" + new Date().getTime() + ".xlsx",
          content
        );

        resolve({ content });
      })
      .catch((error) => {
        if (error && error.response) {
          resolve(error.response.data);
        }
      });
  });
