import placeholderImage from "../assets/images/placeholderimage.jpg";
export const ImageWrapper = ({ src, style, className }) => (
  <img
    style={style}
    className={className}
    src={src}
    alt=""
    data-original-title=""
    title=""
    onError={(e) => {
      e.target.onerror = null;
      e.target.src = placeholderImage;
    }}
  />
);
