import React, { useContext, Fragment, useState } from "react";
import { ChevronDown, LogOut, User } from "react-feather";
import { Link } from "react-router-dom";
import { logoutApi } from "../../../axios/services/services";
import { AUTH_TOKEN, USER_DETAILS } from "../../../constants/const";
import {
  capitalizeFirstLetter,
  getErrorMessageBasedOnLanguage,
  InitialCharacter,
  setActiveMenu,
  unauthorization,
} from "../../../constants/utils";
import { ConfirmationModal } from "../modals/ConfirmationModal";
import { ErrorModal } from "../modals/ErrorModal";
import { SuccessModal } from "../modals/SuccessModal";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { UIContext } from "../../../helper/UIContext";
import { MENUITEMS } from "../../../constants/menu";

const UserMenu = () => {
  const intl = useIntl();
  const history = useHistory();
  const uiContext = useContext(UIContext);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [isLoadingConfirmation, setLoadingConfirmation] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));

  const hanleClickOnLogout = async () => {
    handleConfirmationModalShow();
  };

  const enableLoadingLogout = () => {
    setLoadingConfirmation(true);
  };

  const disableLoadingLogout = () => {
    setLoadingConfirmation(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const onOpenSuccessModal = () => {
    setOpenSuccessModal(true);
  };

  const onCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const handleConfirmationModalShow = () => {
    setIsConfirmationModalOpen(true);
  };

  const handleConfirmationModalHide = () => {
    setIsConfirmationModalOpen(false);
  };

  const onClickOfConfirmationButton = async () => {
    const { id } = userDetails || {};
    let logoutObj = {
      id,
    };
    enableLoadingLogout();
    const logoutResponse = await logoutApi(logoutObj);
    const { Error } = logoutResponse || {};
    if (Error) {
      disableLoadingLogout();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    onOpenSuccessModal();
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(USER_DETAILS);
    disableLoadingLogout();
    window.location.reload();
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  const { full_name = "-", cellnumber = "-" } = userDetails || {};

  return (
    <>
      <Fragment>
        <li className="onhover-dropdown">
          <div className="media align-items-center">
            <div className="user-name bg-secondary">
              {InitialCharacter(full_name)}
            </div>
            <div className="pl-3 pr-3  text-left d-none d-md-inline">
              <h5
                className="f-w-600 f-14 mb-0 text-dark"
                style={{ lineHeight: "14px" }}
              >
                {capitalizeFirstLetter(full_name)}
              </h5>
              <span className="small-text text-dark">{cellnumber}</span>
            </div>
            <div className="d-none d-md-inline">
              <ChevronDown size={14} className="text-dark"></ChevronDown>
            </div>
          </div>
          <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
            <li>
              <Link
                to={`${process.env.PUBLIC_URL}/settings/profile`}
                onClick={() => {
                  const path = `${process.env.PUBLIC_URL}/settings/profile`;
                  onClickChangeRoute(path);
                }}
              >
                <User size="16" className="text-dark"></User>
                <i data-feather="user"></i>
                <FormattedMessage id="TITLE.EDIT.PROFILE" />
              </Link>
            </li>
            <li
              onClick={() => {
                hanleClickOnLogout();
              }}
            >
              <LogOut size="16" className="text-dark"></LogOut>
              <FormattedMessage id="TITLE.LOGOUT" />
            </li>
          </ul>
        </li>
      </Fragment>
      {isConfirmationModalOpen && (
        <ConfirmationModal
          isLoading={isLoadingConfirmation}
          title={intl.formatMessage({ id: "TITLE.LOGOUT" })}
          description={intl.formatMessage({
            id: "TITLE.ARE.YOU.SURE.WANT.TO.LOGOUT",
          })}
          variant={"danger"}
          isSuccess={"text-danger"}
          icon={
            <LogOut
              size={60}
              className="text-danger mb-2"
              style={{ strokeWidth: "1.5px" }}
            ></LogOut>
          }
          cancelText={intl.formatMessage({ id: "TITLE.CANCEL" })}
          logoutText={intl.formatMessage({ id: "TITLE.LOGOUT" })}
          isOpen={isConfirmationModalOpen}
          onCloseModal={handleConfirmationModalHide}
          id={capitalizeFirstLetter(userDetails?.full_name)}
          onClickOfConfirmationButton={onClickOfConfirmationButton}
        />
      )}
      {openSuccessModal && (
        <SuccessModal
          open={openSuccessModal}
          onCloseModal={onCloseSuccessModal}
          message={intl.formatMessage({
            id: "TITLE.YOU.ARE.LOGGED.OUT.SUCCESSFULLY",
          })}
        />
      )}
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
};

export default UserMenu;
