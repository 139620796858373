import React, { Fragment, useContext, useState } from "react";
import { Card, Image, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  dateFormatter,
  amountFormatter,
  setActiveMenu,
} from "../../constants/utils";
import { ContentLoader } from "../../helper/ContentLoader";
import nodatafoundImage from "../../assets/images/dashboard/nodata-found.svg";
import { FormattedMessage } from "react-intl";
import { USER_DETAILS } from "../../constants/const";
import { MENUITEMS } from "../../constants/menu";
import { UIContext } from "../../helper/UIContext";

function RecentPOWidgets({ top5PO, isLoading }) {
  const history = useHistory();
  const uiContext = useContext(UIContext);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);

  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const { role } = userDetails || {};
  const { name: userRole } = role || {};

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  return (
    <>
      <Card style={{ height: "calc(100% - 15px)" }}>
        <Card.Header>
          <Card.Title className="mb-0 modal-title">
            <FormattedMessage id="TITLE.PURCHASE.ORDERS.RECENT.FIVE" />
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive">
            {isLoading && (
              <ContentLoader isLoading={isLoading} pleaseWait={true} />
            )}
            <Table className="viewpo-table table">
              <thead>
                <tr>
                  <th scope="col">
                    <FormattedMessage id="TITLE.PO" /> #
                  </th>
                  <th scope="col">
                    <FormattedMessage id="TITLE.DATE" />
                  </th>
                  <th scope="col">
                    <FormattedMessage id="TITLE.VENDOR.NAME" />
                  </th>
                  <th scope="col" className="text-right">
                    <FormattedMessage id="TITLE.AMOUNT" />
                  </th>
                </tr>
              </thead>

              <tbody>
                {top5PO &&
                  top5PO.map((po, index) => {
                    return (
                      <tr key={index}>
                        <td
                          className="cursor-pointer"
                          onClick={() => {
                            if (
                              userRole !== "Inward Manager" &&
                              userRole !== "Outward Manager"
                            ) {
                              const path = `/inwards/view-po-details/${po?.id}`;
                              onClickChangeRoute(path);
                              window.scrollTo(0, 0);
                            }
                          }}
                        >
                          <span className="text-info">
                            {po?.purchaseorder_number}
                          </span>
                        </td>
                        <td className="digits">{dateFormatter(po?.date)}</td>
                        <td
                          className="font-danger cursor-pointer"
                          onClick={() => {
                            if (
                              userRole !== "Inward Manager" &&
                              userRole !== "Outward Manager"
                            ) {
                              const path = `/vendors/view-vendor-details/${po?.contact?.id}`;
                              onClickChangeRoute(path);
                              window.scrollTo(0, 0);
                            }
                          }}
                        >
                          <span className="text-info">
                            {po?.contact?.contact_name}
                          </span>
                        </td>
                        <td className="digits text-right">
                          {amountFormatter(po?.totalamount)}
                        </td>
                      </tr>
                    );
                  })}
                {top5PO && top5PO.length === 0 && !isLoading && (
                  <>
                    <tr>
                      <td className="no-record text-center" colSpan={4}>
                        <Image
                          src={nodatafoundImage}
                          alt="No Data Found"
                          className="img-fluid"
                          style={{ maxHeight: "200px" }}
                        />
                        <span className="d-block">
                          <FormattedMessage id="TITLE.NO.RECORDS.FOUND" />
                        </span>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default RecentPOWidgets;
