// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as uiHelpers from "../VendorUIHelpers";
import { useVendorUIContext } from "../VendorUIContext";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../helper/helper";
import { Pagination } from "../../../pagination/Pagination";
import StatusTypeFormatter from "../../../columnformatter/StatusTypeFormatter";
import ActionsColumnFormatter from "../../../columnformatter/ActionsColumnFormatter";

import EmailColumnFormatter from "../../../columnformatter/EmailColumnFormatter copy";
import PhoneNumberColumnFormatter from "../../../columnformatter/PhoneNumberColumnFormatter";
import CurrencyColumnFormatter from "../../../columnformatter/CurrencyColumnFormatter copy";
import { FormattedMessage } from "react-intl";
import VendorColumnFormatter from "../../../columnformatter/VendorColumnFormatter";

export function VendorTable({
  isLoading,
  vendorList,
  vendorListTotalCount,
  currentPage,
  setCurrentPage,
}) {
  // Vendor UI Context
  const vendorUIContext = useVendorUIContext();
  const vendorUIProps = useMemo(() => {
    return {
      ids: vendorUIContext.ids,
      setIds: vendorUIContext.setIds,
      queryParams: vendorUIContext.queryParams,
      setQueryParams: vendorUIContext.setQueryParams,
      openViewDialog: vendorUIContext.openViewDialog,
      openEditDialog: vendorUIContext.openEditDialog,
      openDeleteDialog: vendorUIContext.openDeleteDialog,
      redirectToVendorViewPage: vendorUIContext.redirectToVendorViewPage,
    };
  }, [vendorUIContext]);

  // Table columns
  const columns = [
    {
      dataField: "contact_name",
      text: <FormattedMessage id="TITLE.VENDOR.NAME" />,
      sort: true,
      style: {
        minWidth: "170px",
      },
      sortCaret: sortCaret,
      formatter: VendorColumnFormatter,
      formatExtraData: {
        redirectToVendorViewPage: vendorUIProps.redirectToVendorViewPage,
      },
    },
    {
      dataField: "email",
      text: <FormattedMessage id="TITLE.EMAIL.ADDRESS" />,
      sort: true,
      style: {
        minWidth: "170px",
      },
      sortCaret: sortCaret,
      formatter: EmailColumnFormatter,
    },
    {
      dataField: "phone_number",
      text: <FormattedMessage id="TITLE.PHONE.NUMBER" />,
      classes: "ltr-direction",
      sort: true,
      style: {
        minWidth: "170px",
      },
      sortCaret: sortCaret,
      formatter: PhoneNumberColumnFormatter,
    },
    {
      dataField: "currency",
      text: <FormattedMessage id="TITLE.CURRENCY" />,
      sort: true,
      style: {
        minWidth: "170px",
      },
      sortCaret: sortCaret,
      formatter: CurrencyColumnFormatter,
    },
    {
      dataField: "status",
      text: <FormattedMessage id="TITLE.STATUS" />,
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "100px",
      },
      formatter: StatusTypeFormatter,
    },
    // {
    //   dataField: "amount",
    //   text: "Amount",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   style: {
    //     minWidth: "150px",
    //   },
    //   formatter: AmountColumnFormatter,
    // },
    // {
    //   dataField: "expectedDeliveryDate",
    //   text: "Expected Delivery Date",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   style: {
    //     minWidth: "130px",
    //   },
    //   formatter: ExpectedDateColumnFormatter,
    // },
    {
      dataField: "action",
      text: <FormattedMessage id="TITLE.ACTIONS" />,
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openViewDialog: vendorUIProps.openViewDialog,
        openEditDialog: vendorUIProps.openEditDialog,
        openDeleteDialog: vendorUIProps.openDeleteDialog,
        isVisibleViewButton: true,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "120px",
      },
    },
  ];
  const paginationOptions = {
    custom: true,
    totalSize: vendorListTotalCount ? vendorListTotalCount : 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: vendorUIProps.queryParams.pageSize,
    page: currentPage,
    onPageChange: (page) => {
      if (page === 0) return;
      setCurrentPage(page);
    },
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-head-bg table-borderless table-vertical-center category-table table-inventory"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={vendorList ? vendorList : []}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  vendorUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
              <PleaseWaitMessage isLoading={isLoading} />
              <NoRecordsFoundMessage
                entities={vendorList}
                isLoading={isLoading}
              />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
