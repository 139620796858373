import React, { useState } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import { X, Check } from "react-feather";
import { parentLocationList, statusName } from "../components/location-module/LocationUIHelpers";

export function EditBlockLocationModal({ onCloseModal, open, data }) {
  const {
    location = "Location 1",
    parentLocation = "Block 1",
    city,
    country,
    state,
    status = "Active",
    street_1,
    street_2,
    zipcode,
  } = data || {};
  const [selectedOption, setSelectedOption] = useState({
    value: "Active",
    label: "Active",
  });
  const [selectedParentLocation, setSelectedParentLocation] = useState({
    value: parentLocation,
    label: parentLocation,
  });

  // const [selectedCountry, setSelectedCountry] = useState({
  //   value: country,
  //   label: country,
  // });
  // const [selectedState, setSelectedState] = useState({
  //   value: state,
  //   label: state,
  // });
  // const [selectedCity, setSelectedCity] = useState({
  //   value: city,
  //   label: city,
  // });

  const [isFormSuccess, setFormSuccess] = useState(false);

  return (
    <>
      <Modal
        size="md"
        show={open}
        onHide={onCloseModal}
        aria-labelledby="example-modal-sizes-title-sm"
        dialogClassName="inventory-small-modal modal-md"
        scrollable={true}
      >
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <h3 className="modal-title" id="exampleModalLabel2">
            Edit Location
          </h3>
          <Button
            variant="link p-0 btn-icon"
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <X size={20} className="text-dark mr-0 pr-0" />
          </Button>
        </Modal.Header>
        <Modal.Body className="pb-0">
          {isFormSuccess && (
            <div className="notification icon inline success fs-16">
              <Check size={8} className="text-white"></Check> Location edited
              successfully
            </div>
          )}
          <Form autoComplete="off">
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor="parentlocation">
                    Parent Location
                  </Form.Label>
                  <Select
                    defaultValue={selectedParentLocation}
                    onChange={setSelectedParentLocation}
                    isSearchable={true}
                    options={parentLocationList}
                    placeholder="Select Parent Location"
                    id="parentlocation"
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor="buildingnumber">
                    Store Location<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="buildingnumber"
                    placeholder="Store Location"
                    value={location}
                    required
                  />
                </Form.Group>
              </Col>
              {/* <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="street1">
                    Street 1<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="street1"
                    placeholder="Street 1"
                    value={street_1}
                    required
                  />
                </Form.Group>
              </Col> */}
              {/* <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="street2">Street 2</Form.Label>
                  <Form.Control
                    type="text"
                    id="street2"
                    placeholder="Street 2"
                    value={street_2}
                    required
                  />
                </Form.Group>
              </Col> */}
              {/* <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="country">
                    Country<span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    defaultValue={selectedCountry}
                    onChange={setSelectedCountry}
                    isSearchable={true}
                    options={countriesList}
                    placeholder="Select Country"
                    id="country"
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </Form.Group>
              </Col> */}
              {/* <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="state">
                    State<span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    defaultValue={selectedState}
                    onChange={setSelectedState}
                    isSearchable={true}
                    options={stateList}
                    placeholder="Select State"
                    id="state"
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </Form.Group>
              </Col> */}
              {/* <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="city">
                    City<span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    defaultValue={selectedCity}
                    onChange={setSelectedCity}
                    isSearchable={true}
                    options={cityList}
                    placeholder="Select City"
                    id="city"
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </Form.Group>
              </Col> */}

              {/* <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="zipcode">
                    Zipcode<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="zipcode"
                    placeholder="Zipcode"
                    value={zipcode}
                    required
                  />
                </Form.Group>
              </Col> */}
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor="status">
                    Status<span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    isSearchable={true}
                    options={statusName}
                    placeholder="Select Status"
                    id="status"
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            variant="success"
            onClick={() => {
              window.scrollTo(0, 0);
              setFormSuccess(!isFormSuccess);
              setTimeout(() => {
                onCloseModal("VaryingMdo");
              }, 1500);
            }}
          >
            Edit
          </Button>
          <Button
            type="button"
            variant="tertiary text-dark"
            onClick={() => onCloseModal("VaryingMdo")}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
