import React, { createContext, useContext, useCallback } from "react";
import { isEqual, isFunction } from "lodash";

const CountryUIContext = createContext();

export function useCountryUIContext() {
  return useContext(CountryUIContext);
}

export const CountryUIConsumer = CountryUIContext.Consumer;

export function CountryUIProvider({
  countryUIEvents,
  children,
  queryParams,
  setQueryParamsBase,
  ids,
  setIds,
}) {
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    openViewDialog: countryUIEvents.openViewDialog,
    openEditDialog: countryUIEvents.openEditDialog,
    openDeleteDialog: countryUIEvents.openDeleteDialog,
  };

  return (
    <CountryUIContext.Provider value={value}>
      {children}
    </CountryUIContext.Provider>
  );
}
