import React, { createContext, useContext, useCallback } from "react";
import { isEqual, isFunction } from "lodash";

const BlockLocationUIContext = createContext();

export function useBlockLocationUIContext() {
  return useContext(BlockLocationUIContext);
}

export const BlockLocationUIConsumer = BlockLocationUIContext.Consumer;

export function BlockLocationUIProvider({
  blockLocationUIEvents,
  children,
  queryParams,
  setQueryParamsBase,
  ids,
  setIds,
}) {
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    openViewDialog: blockLocationUIEvents.openViewDialog,
    openEditDialog: blockLocationUIEvents.openEditDialog,
    openDeleteDialog: blockLocationUIEvents.openDeleteDialog,
  };

  return (
    <BlockLocationUIContext.Provider value={value}>
      {children}
    </BlockLocationUIContext.Provider>
  );
}
