import React from "react";
import { Table } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { amountFormatter, dateFormatter } from "../../../constants/utils";
import { SectionLoader } from "../../../helper/SectionLoader";
import { FormattedMessage } from "react-intl";

export function TransactionDCView({
  DCList,
  isLoadingDCView,
  handlePaginationOfDCListView,
  isLoadMoreDCListView,
}) {
  return (
    <>
      {isLoadingDCView && <SectionLoader />}
      <InfiniteScroll
        scrollableTarget="scrollabledivOverview"
        dataLength={DCList ? DCList.length : 0}
        next={() => {
          handlePaginationOfDCListView();
        }}
        hasMore={isLoadMoreDCListView}
        loader={
          <h4 style={{ textAlign: "center" }} className="p-1">
            <FormattedMessage id="TITLE.LOADING" />
            ...
          </h4>
        }
      >
        <div className="view-body">
          <Table responsive className="viewpo-table table">
            <thead>
              <tr>
                <th scope="col">
                  <FormattedMessage id="TITLE.DATE" />
                </th>
                <th scope="col">
                  <FormattedMessage id="TITLE.DELIVERY.CHALLAN" />#
                </th>
                <th scope="col">
                  <FormattedMessage id="TITLE.CUSTOMER.NAME" />
                </th>
                <th scope="col">
                  <FormattedMessage id="TITLE.QUANTITY.DELIVERED" />
                </th>
                <th scope="col">
                  <FormattedMessage id="TITLE.TOTAL.AMOUNT" />
                </th>
                <th scope="col">
                  <FormattedMessage id="TITLE.STATUS" />
                </th>
              </tr>
            </thead>
            <tbody>
              {DCList &&
                DCList.map((PO, index) => {
                  return (
                    <tr key={index}>
                      <td className="digits">{dateFormatter(PO?.date)}</td>
                      <td>
                        <span className="text-info">
                          {PO?.purchaseorder_number}
                        </span>
                      </td>

                      <td>{PO?.contact?.contact_name}</td>
                      <td className="digits">{PO?.quantity_purchased}</td>
                      <td>
                        {amountFormatter(
                          PO?.quantity_purchased_data[0]?.totalamount
                        )}
                      </td>
                      <td>{PO?.purchaseorderstatus?.name}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </InfiniteScroll>
    </>
  );
}

export default TransactionDCView;
