import React from "react";
import { defaultTheme } from "react-select";
import { ContentLoader } from "./ContentLoader";
import { FormattedMessage } from "react-intl";
const { colors } = defaultTheme;

export function getHandlerTableChange(setQueryParams) {
  return (type, { page, sizePerPage, sortField, sortOrder, data }) => {
    const pageNumber = page || 1;
    setQueryParams((prev) =>
      type === "sort"
        ? { ...prev, sortOrder, sortField }
        : type === "pagination"
        ? { ...prev, pageNumber, pageSize: sizePerPage }
        : prev
    );
  };
}

export function PleaseWaitMessage({ isLoading, pleaseWait }) {
  return isLoading && <ContentLoader pleaseWait={pleaseWait} />;
}

export function NoRecordsFoundMessage({ entities, isLoading }) {
  return (
    <>
      {entities && entities.length === 0 && !isLoading && (
        <div className="d-flex justify-content-center flex-wrap mb-5">
          <div className="align-item-center">
            <img
              src={toAbsoluteUrl("/media/illustration/no_data_found.jpg")}
              alt=""
              height="350"
            />
            <div className="text-center fs-18">
              <FormattedMessage id="TITLE.NO.RECORDS.FOUND" />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export const sortCaret = (order, column) => {
  if (!order)
    return (
      <span className="svg-icon svg-icon-sm svg-icon-primary ml-2 svg-icon-sort noshorting">
        {/* <ArrowUp size={16} style={{ color: "#CAA25C" }} /> */}
        <i className="fa fa-caret-up" aria-hidden="true"></i>
        <i className="fa fa-caret-down" aria-hidden="true"></i>
      </span>
    );
  else if (order === "asc")
    return (
      <span className="svg-icon svg-icon-sm svg-icon-primary ml-2">
        {/* <ArrowUp size={16} style={{ color: "#CAA25C" }} /> */}
        <i className="fa fa-caret-up" aria-hidden="true"></i>
      </span>
    );
  else if (order === "desc")
    return (
      <span className="svg-icon svg-icon-sm svg-icon-primary ml-2">
        {/* <ArrowDown size={16} style={{ color: "#CAA25C" }} /> */}
        <i className="fa fa-caret-down" aria-hidden="true"></i>
      </span>
    );
  return null;
};

export const headerSortingClasses = (
  column,
  sortOrder,
  isLastSorting,
  colIndex
) => (sortOrder === "asc" || sortOrder === "desc" ? "sortable-active" : "");

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

export const getPagesCount = (totalSize, sizePerPage) => {
  return Math.ceil(totalSize / sizePerPage);
};

export const getPages = (page, pagesCount, paginationSize) => {
  const result = [];
  if (!page) {
    return result;
  }

  if (pagesCount === 1) {
    result.push(1);
    return result;
  }

  if (pagesCount < page) {
    return result;
  }

  if (pagesCount < paginationSize + 1) {
    for (let i = 1; i < pagesCount + 1; i++) {
      result.push(i);
    }
    return result;
  }

  if (page === 1) {
    for (let i = 1; i < paginationSize + 1; i++) {
      if (i < pagesCount) {
        result.push(i);
      }
    }
    return result;
  }

  if (page === pagesCount) {
    for (let i = pagesCount - paginationSize + 1; i <= pagesCount; i++) {
      if (i <= pagesCount) {
        result.push(i);
      }
    }
    return result;
  }

  const shiftCount = Math.floor(paginationSize / 2);
  if (shiftCount < 1) {
    result.push(page);
    return result;
  }

  //
  if (page < shiftCount + 2) {
    for (let i = 1; i < paginationSize + 1; i++) {
      result.push(i);
    }
    return result;
  }

  if (pagesCount - page < shiftCount + 2) {
    for (let i = pagesCount - paginationSize; i < pagesCount + 1; i++) {
      result.push(i);
    }
    return result;
  }

  for (let i = page - shiftCount; i < page; i++) {
    if (i > 0) {
      result.push(i);
    }
  }
  result.push(page);
  for (let i = page + 1; i < page + shiftCount + 1; i++) {
    if (i <= pagesCount) {
      result.push(i);
    }
  }

  return result;
};

export const addRemoveRTLLTRForDateRangePicker = () => {
  const dateRangePicker = document.querySelectorAll(".daterangepicker");
  const bodyClassList = document.body.classList.contains("rtl");
  if (dateRangePicker) {
    if (bodyClassList) {
      dateRangePicker.forEach((element) => {
        element.classList.remove("ltr");
        element.classList.add("rtl");
      });
    } else {
      dateRangePicker.forEach((element) => {
        element.classList.remove("rtl");
        element.classList.add("ltr");
      });
    }
  }
};

export const SvgMaster = (p) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);

export const MenuMaster = (props) => {
  const shadow = "hsla(218, 50%, 10%, 0.1)";
  return <div {...props} />;
};

export const BlanketMaster = (props) => <div {...props} />;

export const DropdownMaster = ({
  children,
  isOpen,
  target,
  onClose,
  isDisabled = false,
  ref,
}) => (
  <div
    ref={ref}
    aria-disabled={isDisabled}
    className={isDisabled ? "is-disabled" : ""}
  >
    {target}
    {isOpen ? <MenuMaster>{children}</MenuMaster> : null}
    {isOpen ? <BlanketMaster onClick={onClose} /> : null}
  </div>
);

export const ChevronDownMaster = () => (
  <svg
    viewBox="0 0 24 24"
    width="24"
    height="24"
    stroke="currentColor"
    strokeWidth="2"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="css-i6dzq1"
  >
    <polyline points="6 9 12 15 18 9"></polyline>
  </svg>
);

export const DropdownIndicatorMaster = () => (
  <div css={{ color: colors.neutral20, height: 24, width: 32 }}>
    <SvgMaster>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgMaster>
  </div>
);
