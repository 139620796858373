import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import { X, Check } from "react-feather";
import { FormattedMessage, useIntl } from "react-intl";
import { editContactPersonVendorApi } from "../../axios/services/services";
import {
  getErrorMessageBasedOnLanguage,
  InitLanguage,
  unauthorization,
} from "../../constants/utils";
import { ButtonLoader } from "../../helper/ButtonLoader";
import { EditContactPersonSchema } from "../../helper/FormikSchema";
import { ErrorModal } from "../common/modals/ErrorModal";

let EditContactPersonVendorInitialValues = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  mobile: "",
};

export function EditContactPersonModal({
  onCloseModal,
  open,
  id,
  selectedContactPerson,
  handleCloseOfEditContactPersonVendorModal,
}) {
  const intl = useIntl();
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isContactPersonEdited, setContactPersonEdited] = useState(false);

  useEffect(() => {
    const { first_name, last_name, email, phone, mobile } =
      selectedContactPerson || {};
    formikEditContactPerson.setFieldValue(
      "first_name",
      first_name ? first_name : ""
    );
    formikEditContactPerson.setFieldValue(
      "last_name",
      last_name ? last_name : ""
    );
    formikEditContactPerson.setFieldValue("email", email ? email : "");
    formikEditContactPerson.setFieldValue("phone", phone ? phone : "");
    formikEditContactPerson.setFieldValue("mobile", mobile ? mobile : "");
  }, []);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const formikEditContactPerson = useFormik({
    initialValues: EditContactPersonVendorInitialValues,
    validationSchema: EditContactPersonSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const { first_name, last_name, email, phone, mobile } = values || {};
      let obj = {
        id: selectedContactPerson?.id,
        first_name,
        last_name,
        email,
        phone,
        mobile,
      };
      enableLoading();
      const editContactPersonResponse = await editContactPersonVendorApi(obj);

      disableLoading();
      const { Error } = editContactPersonResponse || {};
      if (Error) {
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setContactPersonEdited(true);
      window.scrollTo(0, 0);
      setTimeout(() => {
        handleCloseOfEditContactPersonVendorModal();
      }, 2500);
    },
    onReset: (values, { resetForm }) => {},
  });

  return (
    <>
      <Modal
        size="lg"
        show={open}
        onHide={onCloseModal}
        aria-labelledby="example-modal-sizes-title-lg"
        dialogClassName="addvendor-modal modal"
        scrollable={true}
      >
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <h3 className="modal-title" id="exampleModalLabel2">
            <FormattedMessage id="TITLE.EDIT.CONTACT.PERSON" />
          </h3>
          <Button
            variant="link p-0 btn-icon"
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <X size={20} className="text-dark mr-0 pr-0" />
          </Button>
        </Modal.Header>
        <Modal.Body className="pb-0">
          {isContactPersonEdited && (
            <div className="notification icon inline success fs-16">
              <Check size={8} className="text-white"></Check>{" "}
              <FormattedMessage id="TITLE.CONTACT.PERSON.EDITED.SUCCESSFULLY" />
            </div>
          )}
          <Form autoComplete="off">
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="First Name">
                    <FormattedMessage id="TITLE.FIRST.NAME" />
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="First Name"
                    placeholder={intl.formatMessage({ id: "TITLE.FIRST.NAME" })}
                    name="first_name"
                    required
                    {...formikEditContactPerson.getFieldProps("first_name")}
                  />
                  {formikEditContactPerson.touched.first_name &&
                  formikEditContactPerson.errors.first_name ? (
                    <div className="text-danger">
                      {InitLanguage(formikEditContactPerson.errors.first_name)}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="Last Name">
                    <FormattedMessage id="TITLE.LAST.NAME" />
                    {/* <span className="text-danger">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="Last Name"
                    placeholder={intl.formatMessage({ id: "TITLE.LAST.NAME" })}
                    name="last_name"
                    {...formikEditContactPerson.getFieldProps("last_name")}
                  />
                  {formikEditContactPerson.touched.last_name &&
                  formikEditContactPerson.errors.last_name ? (
                    <div className="text-danger">
                      {InitLanguage(formikEditContactPerson.errors.last_name)}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="Email">
                    <FormattedMessage id="TITLE.EMAIL" />
                    {/* <span className="text-danger">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="Email"
                    placeholder={intl.formatMessage({ id: "TITLE.EMAIL" })}
                    name="email"
                    {...formikEditContactPerson.getFieldProps("email")}
                  />
                  {formikEditContactPerson.touched.email &&
                  formikEditContactPerson.errors.email ? (
                    <div className="text-danger">
                      {formikEditContactPerson.errors.email}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="Work Phone">
                    <FormattedMessage id="TITLE.WORK.PHONE" />
                    {/* <span className="text-danger">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    id="Work Phone"
                    name={"phone"}
                    placeholder={intl.formatMessage({ id: "TITLE.WORK.PHONE" })}
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    onPaste={(evt) => {
                      const values = evt.clipboardData.getData("text");
                      const nonAlpha = values.replace(/[^\d.-]/g, "");
                      evt.preventDefault();
                      formikEditContactPerson.setFieldValue("phone", nonAlpha);
                    }}
                    {...formikEditContactPerson.getFieldProps("phone")}
                  />
                  {formikEditContactPerson.touched.phone &&
                  formikEditContactPerson.errors.phone ? (
                    <div className="text-danger">
                      {InitLanguage(formikEditContactPerson.errors.phone)}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor="Mobile">
                    <FormattedMessage id="TITLE.MOBILE" />
                    {/* <span className="text-danger">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    id="Mobile"
                    name="mobile"
                    placeholder={intl.formatMessage({ id: "TITLE.MOBILE" })}
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    onPaste={(evt) => {
                      const values = evt.clipboardData.getData("text");
                      const nonAlpha = values.replace(/[^\d.-]/g, "");
                      evt.preventDefault();
                      formikEditContactPerson.setFieldValue("mobile", nonAlpha);
                    }}
                    {...formikEditContactPerson.getFieldProps("mobile")}
                  />
                  {formikEditContactPerson.touched.mobile &&
                  formikEditContactPerson.errors.mobile ? (
                    <div className="text-danger">
                      {InitLanguage(formikEditContactPerson.errors.mobile)}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            variant="success"
            onClick={() => {
              window.scrollTo(0, 0);
              console.log(formikEditContactPerson.errors);
              formikEditContactPerson.handleSubmit();
            }}
            disabled={isLoading}
          >
            <FormattedMessage id="TITLE.SAVE" />
            {isLoading && <ButtonLoader className="mr-1 ml-1" />}
          </Button>
          <Button
            type="button"
            variant="tertiary text-dark"
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <FormattedMessage id="TITLE.CANCEL" />
          </Button>
        </Modal.Footer>
      </Modal>
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
