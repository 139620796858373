import React, { useContext, useEffect, useState } from "react";
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  OverlayTrigger,
  Table,
  Tooltip,
} from "react-bootstrap";
import {
  Edit2,
  FileText,
  Plus,
  X,
  Printer,
  Check,
  AlertCircle,
} from "react-feather";
import ExpectedDeliveryDateModal from "../../global-modal/ExpectedDeliveryDateModal";
import { DeleteModal } from "../common/modals/DeleteModal";
import PDFDCView from "./PDFDCView";
import DeliverOutwardForm from "./DeliverOutwardForm";
import {
  DCListApi,
  downloadPdfApi,
  printPdfApi,
  deleteDCApi,
  uploadDocumentApi,
  editDCApi,
  singlePODetailsApi,
  editExpectedDeliveryDateApi,
  POStatusListApi,
  agreeStatusPOApi,
  editHistoryListApi,
} from "../../axios/services/services";
import InfiniteScroll from "react-infinite-scroll-component";
import Moment from "react-moment";
import {
  DD_MM_YYYY,
  STR_PDF,
  USER_DETAILS,
  YYYY_MM_DD,
} from "../../constants/const";
import {
  amountFormatter,
  capitalizeFirstLetter,
  getErrorMessageBasedOnLanguage,
  getHistoryActionsDC,
  getHistoryActionsUsername,
  getStatusPOClass,
  getUTCTime,
  setActiveMenu,
  unauthorization,
} from "../../constants/utils";
import { SuccessModal } from "../common/modals/SuccessModal";
import { ErrorModal } from "../common/modals/ErrorModal";
import { SectionLoader } from "../../helper/SectionLoader";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import { MENUITEMS } from "../../constants/menu";
import { UIContext } from "../../helper/UIContext";
import { ConfirmationModal } from "../common/modals/ConfirmationModal";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";

const ViewDCDetails = ({ history, handlePDFViewValues, ...props }) => {
  const intl = useIntl();
  const uiContext = useContext(UIContext);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isExpectedDeliveryDateModalOpen, setExpectedDeliveryDateModalOpen] =
    useState(false);
  const [DCList, setDCList] = useState([]);
  const [pdfUrl, setPdfUrl] = useState("");
  const { id } = props.match.params;
  const [uploadDocument, setUploadDocument] = useState(null);
  const [queryParams, setQueryParams] = useState({
    pageNumber: 1,
    pageSize: 20,
  });
  const [queryParamsEditHistory, setQueryParamsEditHistory] = useState({
    pageNumber: 1,
    pageSize: 20,
  });
  const [isLoadingPurchaseOrderListView, setLoadingPurchaseOrderListView] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoadMorePurchaseOrderListView, setLoadMorePurchaseOrderListView] =
    useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingDelete, setLoadingDelete] = useState(false);
  const [isLoadingConfirmationAgreeStatus, setLoadingConfirmationAgreeStatus] =
    useState(false);
  const [
    isLoadingConfirmationDisagreeStatus,
    setLoadingConfirmationDisagreeStatus,
  ] = useState(false);
  const [
    isConfirmationAgreeStatusModalOpen,
    setConfirmationAgreeStatusModalOpen,
  ] = useState(false);
  const [
    isConfirmationDisagreeStatusModalOpen,
    setConfirmationDisagreeStatusModalOpen,
  ] = useState(false);
  const [editHistoryList, setEditHistoryList] = useState([]);
  const [isLoadingEditHistory, setLoadingEditHistory] = useState(false);
  const [DCDetails, setDCDetails] = useState({});
  const [DCScanNotes, setDCScanNotes] = useState([]);
  const [purchaseOrderProductList, setPurchaseOrderProductList] = useState([]);
  const [purchaseOrderReceiveList, setPurchaseOrderReceiveList] = useState([]);
  const [isUpdateSingleDCDetails, setUpdateSingleDCDetails] = useState(0);
  const [isUpdateEditHistoryList, setUpdateEditHistoryList] = useState(0);
  const [isLoadMoreEditHistory, setLoadMoreEditHistory] = useState(false);
  const [isUpdateDCList, setUpdateDCList] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [isLoadingExpectedDeliveryDate, setLoadingExpectedDeliveryDate] =
    useState(false);
  const [noteForExpectedDeliveryDate, setNoteForExpectedDeliveryDate] =
    useState("");
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState(new Date());
  const [singleDCPurchaseItem, setSingleDcPurchaseItem] = useState([]);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [POStatusList, setPOStatusList] = useState([]);
  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const { role } = userDetails || {};
  const { name: userRole } = role || {};

  async function getSingleDCDetails() {
    setLoading(true);
    const singleDCDetails = await singlePODetailsApi({ POId: id });
    setLoading(false);
    const {
      Error,
      data,
      get_poitem,
      get_purchaseorder_receive,
      po_scan_notes,
    } = singleDCDetails || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setDCDetails(data ? data : []);
    setDCScanNotes(po_scan_notes ? po_scan_notes : []);
    setSingleDcPurchaseItem(get_poitem);
    setPurchaseOrderReceiveList(get_purchaseorder_receive);
  }

  async function getDCList() {
    const { pageNumber } = queryParams || {};

    if (pageNumber === 1) {
      setLoadingPurchaseOrderListView(true);
    }

    const DCListResponse = await DCListApi({
      queryParams,
    });
    if (pageNumber === 1) {
      setLoadingPurchaseOrderListView(false);
    }

    const { Error, data } = DCListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setDCList((dcList) => [...dcList, ...data]);
    if (data.length >= 20) {
      setLoadMorePurchaseOrderListView(true);
    } else {
      setLoadMorePurchaseOrderListView(false);
    }
  }

  async function getPOStatusList() {
    const POStatusListResponse = await POStatusListApi({});
    const { Error, get_po_status } = POStatusListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    const poStatusList = get_po_status.map((status) => {
      const { id, name } = status || {};
      status.label = name;
      status.value = id;
      return status;
    });
    setPOStatusList(poStatusList ? poStatusList : []);
  }

  async function getEditHistoryList() {
    if (userRole !== "Admin") {
      const { pageNumber } = queryParamsEditHistory || {};
      if (pageNumber === 1) {
        enableLoadingEditHistory();
      }
      const editHistoryListResponse = await editHistoryListApi({
        queryParamsEditHistory,
        id,
      });
      if (pageNumber === 1) {
        disableLoadingEditHistory();
      }
      const { Error, data } = editHistoryListResponse || {};
      if (Error) {
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setEditHistoryList((historyList) => [...historyList, ...data]);
      if (data.length >= 20) {
        setLoadMoreEditHistory(true);
      } else {
        setLoadMoreEditHistory(false);
      }
    }
  }

  useEffect(() => {
    getDCList();
  }, [queryParams, isUpdateDCList]);

  useEffect(() => {
    getEditHistoryList();
  }, [id, isUpdateEditHistoryList]);

  useEffect(() => {
    getSingleDCDetails();
  }, [id, isUpdateSingleDCDetails]);

  useEffect(() => {
    getPOStatusList();
  }, []);

  useEffect(() => {
    setEditHistoryList([]);
  }, [id]);

  const enableLoadingEditHistory = () => {
    setLoadingEditHistory(true);
  };

  const disableLoadingEditHistory = () => {
    setLoadingEditHistory(false);
  };

  const enableLoadingExpectedDeliveryDate = () => {
    setLoadingExpectedDeliveryDate(true);
  };

  const disableLoadingExpectedDeliveryDate = () => {
    setLoadingExpectedDeliveryDate(false);
  };

  const showSuccessModal = () => {
    setOpenSuccessModal(true);
  };

  const hideSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleDeleteModalShow = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalHide = () => {
    setIsDeleteModalOpen(false);
  };

  const handleExpectedDeliveryDateModalShow = () => {
    setExpectedDeliveryDateModalOpen(true);
  };

  const handleExpectedDeliveryDateModalHide = () => {
    setExpectedDeliveryDateModalOpen(false);
  };

  const enableLoadingConfirmationAgree = () => {
    setLoadingConfirmationAgreeStatus(true);
  };

  const disableLoadingConfirmationAgree = () => {
    setLoadingConfirmationAgreeStatus(false);
  };

  const enableLoadingConfirmationDisagree = () => {
    setLoadingConfirmationDisagreeStatus(true);
  };

  const disableLoadingConfirmationDisagree = () => {
    setLoadingConfirmationDisagreeStatus(false);
  };

  const handleConfirmationAgreeStatusModalShow = () => {
    setConfirmationAgreeStatusModalOpen(true);
  };

  const handleConfirmationAgreeStatusModalHide = () => {
    setConfirmationAgreeStatusModalOpen(false);
  };

  const handleConfirmationDisagreeStatusModalShow = () => {
    setConfirmationDisagreeStatusModalOpen(true);
  };

  const handleConfirmationDisagreeStatusModalHide = () => {
    setConfirmationDisagreeStatusModalOpen(false);
  };

  const onClickOfConfirmationAgreeStatusButton = () => {
    AgreePurchaseOrderStatus();
  };

  const onClickOfConfirmationDisagreeStatusButton = () => {
    DisagreePurchaseOrderStatus();
  };

  const AgreePurchaseOrderStatus = async () => {
    enableLoadingConfirmationAgree();
    const [POStatus] = POStatusList.filter((status) => {
      return status.name === "Approved";
    });
    const agreeStatusPOResponse = await agreeStatusPOApi({
      id,
      purchaseorderstatusId: POStatus.id,
    });
    const { Error } = agreeStatusPOResponse || {};
    if (Error) {
      disableLoadingConfirmationAgree();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    disableLoadingConfirmationAgree();
    handleConfirmationAgreeStatusModalHide();
    setUpdateSingleDCDetails(Math.random());
    setDCList([]);
    const { pageNumber } = queryParams || {};
    if (pageNumber !== 1) {
      setQueryParams({ pageNumber: 1, pageSize: 20 });
    } else {
      setUpdateDCList(Math.random());
    }
  };

  const DisagreePurchaseOrderStatus = async () => {
    enableLoadingConfirmationDisagree();

    const [POStatus] = POStatusList.filter((status) => {
      return status.name === "Rejected";
    });
    const disagreeStatusPOResponse = await agreeStatusPOApi({
      id,
      purchaseorderstatusId: POStatus.id,
    });
    const { Error } = disagreeStatusPOResponse || {};
    if (Error) {
      disableLoadingConfirmationDisagree();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    disableLoadingConfirmationDisagree();
    handleConfirmationDisagreeStatusModalHide();
    setUpdateSingleDCDetails(Math.random());
    setDCList([]);
    const { pageNumber } = queryParams || {};
    if (pageNumber !== 1) {
      setQueryParams({ pageNumber: 1, pageSize: 20 });
    } else {
      setUpdateDCList(Math.random());
    }
  };

  const CreateNewDC = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <FormattedMessage id="TITLE.CREATE.NEW.DELIVERY.CHALLAN" />
    </Tooltip>
  );

  const editTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <FormattedMessage id="TITLE.EDIT" />
    </Tooltip>
  );
  const downloadTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <FormattedMessage id="TITLE.DOWNLOAD.PDF" />
    </Tooltip>
  );
  const print = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <FormattedMessage id="TITLE.PRINT" />
    </Tooltip>
  );
  const attachefileTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <FormattedMessage id="TITLE.ATTACH.FILE" />
    </Tooltip>
  );

  const renderContactName = (DC) => {
    const { contact } = DC || {};
    const { contact_name } = contact || {};
    return (
      <>{contact_name === null ? "-" : capitalizeFirstLetter(contact_name)}</>
    );
  };

  const renderDCNumber = (DC) => {
    const { purchaseorder_number } = DC || {};
    return <>{purchaseorder_number === null ? "-" : purchaseorder_number}</>;
  };

  const renderDCDate = (DC) => {
    const { date } = DC || {};
    return (
      <>{date === null ? "-" : <Moment date={date} format={DD_MM_YYYY} />}</>
    );
  };

  const handleSaveOfExpectedDeliveryDate = async (expected_delivery, note) => {
    enableLoadingExpectedDeliveryDate();
    const editExpectedDeliveryDate = await editExpectedDeliveryDateApi({
      id,
      expected_delivery: expected_delivery
        ? moment(expected_delivery).format(YYYY_MM_DD)
        : null,
      notes: note,
    });
    const { Error } = editExpectedDeliveryDate || {};
    if (Error) {
      disableLoadingExpectedDeliveryDate();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    handleExpectedDeliveryDateModalHide();
    setSuccessMessage(
      intl.formatMessage({
        id: "TITLE.EXPECTED.DELIVERY.DATE.UPDATED.SUCCESSFULLY",
      })
    );
    showSuccessModal();
    setUpdateSingleDCDetails(Math.random());
    disableLoadingExpectedDeliveryDate();
  };

  const renderDCStatus = (DC) => {
    const { purchaseorderstatus } = DC || {};
    const { name } = purchaseorderstatus || {};
    return (
      <>
        {name === null ? (
          "-"
        ) : (
          <span
            className={`${getStatusPOClass(
              name
            )} status-color text-capitalize py-1 px-2`}
          >
            {name}
          </span>
        )}
      </>
    );
  };

  const downloadPdf = async () => {
    const { id } = props.match.params;
    await downloadPdfApi({
      id,
      filename:
        purchaseorder_number + " " + moment(new Date()).format("YYYY-MM-DD"),
      extention: STR_PDF,
    });
  };

  const printPdf = async () => {
    const { id } = props.match.params;
    const printPdfResponse = await printPdfApi({
      id,
    });
    if (printPdfResponse.Error) {
      // setErrorMessage(printPdfResponse.Error.message);
      // handleErrorModalShow();
      return;
    }
    var fils = new Blob([printPdfResponse.res.data], {
      type: "application/pdf",
    });
    var fileURLs = URL.createObjectURL(fils);
    setPdfUrl(fileURLs);
    setTimeout(() => {
      const iframe = document.getElementById("myframe");
      const iframeWindow = iframe.contentWindow || iframe;
      iframe.focus();
      iframeWindow.print();
    }, 50);
  };

  const [isReceiveviewOpen, setReceiveviewOpen] = useState(false);

  const handleOnClickOfcancle = () => {
    setReceiveviewOpen(false);
  };

  const enableLoading = () => {
    setLoadingDelete(true);
  };

  const disableLoading = () => {
    setLoadingDelete(false);
  };

  const deleteOutward = async () => {
    enableLoading();
    const deleteDCResponse = await deleteDCApi({ id });
    const { Error } = deleteDCResponse || {};
    if (Error) {
      disableLoading();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setIsDeleteModalOpen(false);
    setSuccessMessage(
      intl.formatMessage({
        id: "TITLE.DELIVERY.CHALLAN.DELETED.SUCCESSFULLY",
      })
    );
    window.scrollTo(0, 0);
    showSuccessModal();
    setTimeout(() => {
      disableLoading();
      const path = "/outwards/outward-listing";
      onClickChangeRoute(path);
    }, 1500);
  };

  const onClickOfDeleteButton = () => {
    deleteOutward();
  };

  const attachDocument = async (event) => {
    // console.log(event[0].path);
    let files = {
      file: event,
      containername: "product",
    };
    const uploadDocumentResponse = await uploadDocumentApi(files);
    const { Error } = uploadDocumentResponse;
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setSuccessMessage(
      intl.formatMessage({
        id: "TITLE.FILE.ATTACH.SUCCESSFULLY",
      })
    );
    showSuccessModal();
    setUploadDocument(uploadDocumentResponse);
    const [uploadedDoc] = uploadDocumentResponse;
    const { filename, mimetype } = uploadedDoc || {};
    let editCustomerObj = {
      id: id,
      purchaseorder_document: {
        filename: filename,
        file_type: mimetype,
      },
    };
    const editCustomerResponse = await editDCApi(editCustomerObj);
    const { Error: editCustomerEror } = editCustomerResponse || {};
    if (editCustomerEror) {
      unauthorization(editCustomerEror);
      setErrorMessage(getErrorMessageBasedOnLanguage(editCustomerEror));
      handleErrorModalShow();
      return;
    }
  };

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  // to handle the user-selected file
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    attachDocument(fileUploaded);
  };

  const onCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const handlePaginationOfPurchaseOrderListView = () => {
    const { pageNumber, pageSize } = queryParams || {};
    setQueryParams({ pageNumber: pageNumber + 1, pageSize });
  };

  const handlePaginationOfEditHistory = () => {
    const { pageNumber, pageSize } = queryParamsEditHistory || {};
    setQueryParamsEditHistory({ pageNumber: pageNumber + 1, pageSize });
    setUpdateEditHistoryList(Math.random());
  };

  const handleClickOfPOList = () => {
    setQueryParamsEditHistory({ pageNumber: 1, pageSize: 20 });
  };

  const getSecondaryColorClass = (purchaseOrder) => {
    const { id: DCID } = purchaseOrder;
    return DCID === id ? "text-secondary" : "";
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  const {
    purchaseorder_number,
    contact,
    date,
    expected_delivery,
    notes,
    purchaseorderstatus,
  } = DCDetails || {};
  const { name: statusName } = purchaseorderstatus || {};

  return (
    <>
      <Card className="p-0 mb-0">
        <div className="details-view d-block d-md-flex">
          {/* Listing */}
          <div className="column list-column border-right d-none d-lg-block">
            <div className="column-header d-flex align-items-center justify-content-between">
              <h2 className="modal-title">
                {" "}
                <FormattedMessage id="TITLE.DELIVERY.CHALLAN" />
              </h2>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={CreateNewDC}
              >
                <Button
                  variant="primary btn-inventory btn-icon"
                  size="sm"
                  onClick={() => {
                    const path = "/outwards/add-outward";
                    onClickChangeRoute(path);
                  }}
                >
                  <Plus size={14} className="text-secondary"></Plus>{" "}
                  <FormattedMessage id="TITLE.NEW" />
                </Button>
              </OverlayTrigger>
            </div>
            {(isLoadingPurchaseOrderListView || isLoadingEditHistory) && (
              <SectionLoader />
            )}
            <div id="scrollableDiv" className="column-body custom-scrollbar">
              {!isLoadingPurchaseOrderListView && (
                <InfiniteScroll
                  scrollableTarget="scrollableDiv"
                  dataLength={DCList.length}
                  next={() => {
                    handlePaginationOfPurchaseOrderListView();
                  }}
                  hasMore={isLoadMorePurchaseOrderListView}
                  loader={
                    <h4 style={{ textAlign: "center" }} className="p-1">
                      <FormattedMessage id="TITLE.LOADING" />
                      ...
                    </h4>
                  }
                >
                  {DCList.map((purchaseOrder, index) => (
                    <div
                      className="column-box cursor-pointer"
                      key={index}
                      onClick={() => {
                        handleClickOfPOList();
                        const path = `/outwards/view-dc-details/${purchaseOrder.id}`;
                        onClickChangeRoute(path);
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column">
                          <h5
                            className={`font-weight-bold mb-1 cursor-pointer ${getSecondaryColorClass(
                              purchaseOrder
                            )}`}
                          >
                            {renderContactName(purchaseOrder)}
                          </h5>
                          <div>
                            <span className="text-primary small-text border-right pr-2">
                              {renderDCNumber(purchaseOrder)}
                            </span>
                            <span className="small-text pl-2 text-dark">
                              {renderDCDate(purchaseOrder)}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-end">
                          <h5 className="text-dark fs-14 text-dark">
                            {amountFormatter(purchaseOrder.totalamount)}
                          </h5>
                          <div className="d-flex justify-content-end">
                            {renderDCStatus(purchaseOrder)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </InfiniteScroll>
              )}
            </div>
          </div>
          {/* Details */}
          {!isReceiveviewOpen && (
            <div className="column details-column">
              <div className="column-header d-flex align-items-center justify-content-between">
                <h2 className="modal-title"> {purchaseorder_number}</h2>
                <div className="d-flex">
                  {userRole === "Inventory Manager" &&
                    statusName === "Waiting For Approval" && (
                      <ButtonGroup className="mr-2" aria-label="First group">
                        <Badge
                          onClick={() => {
                            handleConfirmationAgreeStatusModalShow();
                          }}
                          className="d-flex align-items-center justify-content-center bg-success mr-1 cursor-pointer"
                          style={{ width: "28px", height: "28px" }}
                          bg="success"
                        >
                          <Check size={14} className="text-white" />
                        </Badge>

                        <Badge
                          onClick={() => {
                            handleConfirmationDisagreeStatusModalShow();
                          }}
                          className="d-flex align-items-center justify-content-center bg-danger ml-1 cursor-pointer"
                          style={{ width: "28px", height: "28px" }}
                          bg="danger"
                        >
                          <X size={14} className="text-white" />
                        </Badge>
                      </ButtonGroup>
                    )}
                  <ButtonGroup className="mr-2" aria-label="First group">
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={editTooltip}
                    >
                      <Button
                        variant="outline-tertiary btn-sm btn-icon"
                        onClick={() => {
                          const path = `/outwards/edit-outward/${id}`;
                          onClickChangeRoute(path);
                        }}
                      >
                        <Edit2 size={16} className="p-0 m-0"></Edit2>
                      </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={downloadTooltip}
                    >
                      <Button
                        variant="outline-tertiary btn-sm btn-icon"
                        onClick={() => downloadPdf()}
                      >
                        <FileText size={16} className="p-0 m-0"></FileText>
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={print}
                    >
                      <Button
                        variant="outline-tertiary btn-sm btn-icon"
                        onClick={() => printPdf()}
                      >
                        <Printer size={16} className="p-0 m-0"></Printer>
                      </Button>
                    </OverlayTrigger>
                    {/* <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={attachefileTooltip}
                    >
                      <Button variant="outline-tertiary btn-sm btn-icon">
                        
                        <Upload size={16} className="p-0 m-0"></Upload>
                      </Button>
                    </OverlayTrigger> */}
                    {/* <div>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={attachefileTooltip}
                      >
                        <Button
                          onClick={handleClick}
                          variant="outline-tertiary btn-sm btn-icon"
                        >
                          <Upload size={16} className="p-0 m-0"></Upload>
                        </Button>
                      </OverlayTrigger>
                      <input
                        type="file"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        style={{ display: "none" }}
                      />
                    </div> */}
                  </ButtonGroup>
                  <Dropdown>
                    <Dropdown.Toggle variant="dropdown-sm" id="dropdown-basic">
                      <FormattedMessage id="TITLE.MORE" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          if (
                            statusName === "Waiting For Approval" ||
                            (userRole === "Inventory Manager" &&
                              statusName === "Approved")
                          ) {
                            handleDeleteModalShow();
                          } else {
                            setErrorMessage(
                              intl.formatMessage({
                                id: "TITLE.YOU.CAN.NOT.DELETE.DC",
                              })
                            );
                            handleErrorModalShow();
                          }
                        }}
                      >
                        <FormattedMessage id="TITLE.DELETE.DC" />
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => {
                          // if (statusName === "Waiting For Approval") {
                          //   handleExpectedDeliveryDateModalShow();
                          //   setNoteForExpectedDeliveryDate(notes);
                          //   setExpectedDeliveryDate(expected_delivery);
                          // } else {
                          //   setErrorMessage(
                          //     intl.formatMessage({
                          //       id: "TITLE.YOU.CAN.NOT.SET.EXPECTED.DELIVERY.DATE",
                          //     })
                          //   );
                          //   handleErrorModalShow();
                          // }
                          handleExpectedDeliveryDateModalShow();
                          setNoteForExpectedDeliveryDate(notes);
                          setExpectedDeliveryDate(expected_delivery);
                        }}
                      >
                        <FormattedMessage id="TITLE.EXPECTED.DELIVERY.DATE" />
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Button
                    variant="close btn-icon btn-sm"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      const path = "/outwards/outward-listing";
                      onClickChangeRoute(path);
                    }}
                  >
                    <X size={20} className="p-0 m-0 text-dark"></X>
                  </Button>
                </div>
              </div>

              <div className="column-body custom-scrollbar">
                {isLoading && <SectionLoader />}
                <Tabs>
                  <TabList className="nav nav-tabs tab-coupon mb-0">
                    <Tab className="nav-link">
                      <FormattedMessage id="TITLE.DETAILS" />
                    </Tab>
                    <Tab className="nav-link">
                      <FormattedMessage id="TITLE.DELIVERED" />
                    </Tab>
                    <Tab className="nav-link">
                      <FormattedMessage id="TITLE.HISTORY" />
                    </Tab>
                  </TabList>

                  <TabPanel>
                    {(statusName === "Approved" ||
                      statusName === "Partially Fulfilled") && (
                      <div className="highlighted-row">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="left"></div>
                          <div className="right">
                            <Button
                              variant="tertiary text-dark"
                              onClick={() => {
                                setReceiveviewOpen(true);
                              }}
                            >
                              <FormattedMessage id="TITLE.DELIVER.SMALL" />
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </TabPanel>
                  <TabPanel>
                    <div className="highlighted-row">
                      <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between">
                        {(statusName === "Waiting For Approval" ||
                          statusName === "Approved") && (
                          <div className="left d-flex align-items-center">
                            <span>
                              <FormattedMessage id="TITLE.NO.ITEMS.HAVE.BEEN.DELIVERED.YET" />
                            </span>
                            <AlertCircle
                              size={14}
                              className="ml-1"
                            ></AlertCircle>
                            <Button
                              variant="link text-primary pl-2"
                              onClick={() => {
                                setReceiveviewOpen(true);
                              }}
                            >
                              <FormattedMessage id="TITLE.NEW.DELIVERY" />
                            </Button>
                          </div>
                        )}

                        {(statusName === "Delivered" ||
                          statusName === "Partially Fulfilled") && (
                          <div className="col-md-8 pl-0 left d-flex align-items-center">
                            <Table className="mb-0">
                              <thead>
                                <tr>
                                  <th>
                                    <FormattedMessage id="TITLE.PRODUCT.NAME" />
                                  </th>
                                  <th>
                                    <FormattedMessage id="TITLE.EXPIRY.DATE" />
                                  </th>
                                  <th>
                                    <FormattedMessage id="TITLE.BARCODE.NO" />
                                  </th>
                                  <th>
                                    <FormattedMessage id="TITLE.QTY" />
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {purchaseOrderReceiveList &&
                                  purchaseOrderReceiveList.map(
                                    (purchaseOrderReceive) => {
                                      const {
                                        product,
                                        expiry_date,
                                        barcode_no,
                                        quantity,
                                      } = purchaseOrderReceive || {};
                                      const { name } = product || {};
                                      return (
                                        <tr>
                                          <td>{name}</td>
                                          <td>
                                            <Moment
                                              date={expiry_date}
                                              format={DD_MM_YYYY}
                                            />
                                          </td>
                                          <td>{barcode_no}</td>
                                          <td>{quantity}</td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </Table>
                          </div>
                        )}
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    {isLoadingEditHistory && <SectionLoader />}
                    <div
                      className="view-body border-bottom column-body custom-scrollbar"
                      id="viewpodetailsscrolldiv"
                      style={{ maxHeight: "400px", height : "auto" }}
                    >
                      <ul className="history-list">
                        <InfiniteScroll
                          scrollableTarget="viewpodetailsscrolldiv"
                          dataLength={
                            editHistoryList ? editHistoryList.length : 0
                          }
                          next={() => {
                            handlePaginationOfEditHistory();
                          }}
                          hasMore={isLoadMoreEditHistory}
                          loader={
                            <h4 style={{ textAlign: "center" }} className="p-1">
                              <FormattedMessage id="TITLE.LOADING" />
                              ...
                            </h4>
                          }
                        >
                          {editHistoryList &&
                            editHistoryList.map((editHistory, index) => {
                              return (
                                <li className="history__row" key={index}>
                                  <div className="date__column">
                                    <div className="history__details">
                                      {getUTCTime(editHistory.created)}
                                    </div>
                                  </div>
                                  <div className="comment__column">
                                    <p className="comment__media">
                                      <div className="comment-media__body">
                                        <div className="history__details">
                                          <FormattedMessage
                                            id={getHistoryActionsDC(
                                              editHistory
                                            )}
                                          />
                                          <span className="ml-1 mr-1">
                                            <FormattedMessage id="TITLE.BY.SMALL" />
                                            <strong className="ml-1 mr-1">
                                              {getHistoryActionsUsername(
                                                editHistory
                                              )}
                                            </strong>
                                          </span>
                                        </div>
                                      </div>
                                    </p>
                                  </div>
                                </li>
                              );
                            })}
                        </InfiniteScroll>
                      </ul>
                    </div>
                  </TabPanel>
                </Tabs>
                <PDFDCView
                  id={id}
                  DCDetails={DCDetails}
                  singleDCPurchaseItem={singleDCPurchaseItem}
                  purchaseOrderProductList={purchaseOrderProductList}
                  DCScanNotes={DCScanNotes}
                  DCList={DCList}
                />
              </div>
            </div>
          )}
          {/* Receive Column */}
          {isReceiveviewOpen && (
            <DeliverOutwardForm
              id={id}
              DCDetails={DCDetails}
              singleDCPurchaseItem={singleDCPurchaseItem}
              onClickofcancel={handleOnClickOfcancle}
            />
          )}
          {/*  */}
        </div>
      </Card>

      {isDeleteModalOpen && (
        <DeleteModal
          id={purchaseorder_number}
          title={intl.formatMessage({
            id: "TITLE.DELETE.OUTWARD",
          })}
          description={intl.formatMessage({
            id: "TITLE.OUTWARD.WILL.BE.DELETED.FOREVER.AND.CANNOT.BE.RETRIEVED.LATER",
          })}
          isOpen={isDeleteModalOpen}
          onCloseModal={handleDeleteModalHide}
          onClickOfDeleteButton={onClickOfDeleteButton}
          isLoading={isLoadingDelete}
        />
      )}

      {openSuccessModal && (
        <SuccessModal
          open={openSuccessModal}
          onCloseModal={onCloseSuccessModal}
          message="File Attached Successfully"
        />
      )}

      {isExpectedDeliveryDateModalOpen && (
        <ExpectedDeliveryDateModal
          isLoadingExpectedDeliveryDate={isLoadingExpectedDeliveryDate}
          isOpen={isExpectedDeliveryDateModalOpen}
          noteForExpectedDeliveryDate={noteForExpectedDeliveryDate}
          expectedDeliveryDate={expectedDeliveryDate}
          date={date}
          onCloseModal={handleExpectedDeliveryDateModalHide}
          handleSaveOfExpectedDeliveryDate={handleSaveOfExpectedDeliveryDate}
        />
      )}
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}

      {openSuccessModal && (
        <SuccessModal
          open={openSuccessModal}
          onCloseModal={hideSuccessModal}
          message={successMessage}
        />
      )}

      {isConfirmationAgreeStatusModalOpen && (
        <ConfirmationModal
          isLoading={isLoadingConfirmationAgreeStatus}
          title={intl.formatMessage({ id: "TITLE.APPROVE.DC" })}
          isSuccess={"text-success"}
          variant={"success"}
          icon={
            <Check
              size={60}
              className="text-success mb-2"
              style={{ strokeWidth: "1.5px" }}
            />
          }
          description={intl.formatMessage({
            id: "TITLE.ARE.YOU.SURE.YOU.WANT.TO.APPROVE.DELIVERY.CHALLAN",
          })}
          cancelText={intl.formatMessage({ id: "TITLE.CANCEL" })}
          logoutText={intl.formatMessage({ id: "TITLE.YES" })}
          isOpen={isConfirmationAgreeStatusModalOpen}
          onCloseModal={handleConfirmationAgreeStatusModalHide}
          id={purchaseorder_number}
          onClickOfConfirmationButton={onClickOfConfirmationAgreeStatusButton}
        />
      )}

      {isConfirmationDisagreeStatusModalOpen && (
        <ConfirmationModal
          isLoading={isLoadingConfirmationDisagreeStatus}
          title={intl.formatMessage({ id: "TITLE.REJECT.DC" })}
          icon={
            <X
              size={60}
              className="text-danger mb-2"
              style={{ strokeWidth: "1.5px" }}
            />
          }
          variant={"danger"}
          isSuccess={"text-danger"}
          description={intl.formatMessage({
            id: "TITLE.ARE.YOU.SURE.YOU.WANT.TO.REJECT.DELIVERY.CHALLAN",
          })}
          cancelText={intl.formatMessage({ id: "TITLE.CANCEL" })}
          logoutText={intl.formatMessage({ id: "TITLE.YES" })}
          isOpen={isConfirmationDisagreeStatusModalOpen}
          onCloseModal={handleConfirmationDisagreeStatusModalHide}
          id={purchaseorder_number}
          onClickOfConfirmationButton={
            onClickOfConfirmationDisagreeStatusButton
          }
        />
      )}

      <iframe
        className="d-none"
        src={pdfUrl}
        name="myframe"
        id="myframe"
        title="myframe"
      ></iframe>
    </>
  );
};

export default ViewDCDetails;
