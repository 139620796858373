import React, { useMemo, useState } from "react";
import { Search, RefreshCw } from "react-feather";
import { Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { DD_MM_YYYY, dd_MM_yyyy } from "../../../../constants/const";
import { useDelayedPODCUIContext } from "../DelayedPODCUIContext";
import { isEqual } from "lodash";
import { ButtonLoader } from "../../../../helper/ButtonLoader";
import { Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";

const initialFilterValues = {
  delivery_date: "",
  expected_delivery: "",
  purchaseorder_number: "",
};

export function DelayedPODCFilter({
  isLoadingFilter,
  handleCLickOfFilterButtonFilter,
  handleCLickOfResetButtonFilter,
}) {
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState(null);
  const delayedPODCUIContext = useDelayedPODCUIContext();
  const delayedPODCUIProps = useMemo(() => {
    return {
      queryParams: delayedPODCUIContext.queryParams,
      setQueryParams: delayedPODCUIContext.setQueryParams,
    };
  }, [delayedPODCUIContext]);
  const intl = useIntl();

  const prepareFilter = (queryParams, values) => {
    const { delivery_date, purchaseorder_number, expected_delivery } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};
    filter.delivery_date = delivery_date !== "" ? delivery_date : undefined;
    filter.purchaseorder_number =
      purchaseorder_number !== "" ? purchaseorder_number : undefined;
    filter.expected_delivery =
      expected_delivery !== "" ? expected_delivery : undefined;

    newQueryParams.filter = { ...queryParams.filter, ...filter };
    return newQueryParams;
  };

  const applyFilter = (values) => {
    console.log(values
      )
    const newQueryParams = prepareFilter(
      delayedPODCUIProps.queryParams,
      values,
    );
    if (!isEqual(newQueryParams, delayedPODCUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      delayedPODCUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialFilterValues}
        onSubmit={(values) => {
          handleCLickOfFilterButtonFilter();
          applyFilter(values);
        }}
        onReset={(values) => {
          handleCLickOfResetButtonFilter();
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          resetForm,
          handleReset,
          setFieldValue,
        }) => (
          <Form
            className="form form-label-right filter-card"
            onSubmit={handleSubmit}
            onReset={() => {
              resetForm({ values: initialFilterValues });
              handleReset();
            }}
          >
            <Form.Group className="form-group mb-md-0 filter-form-group">
              <div className="filter-item">
                <div className="">
                  <small className="form-text text-muted mb-1">
                    <b>
                      <FormattedMessage id="TITLE.PO.DC.NUMBER" />
                    </b>
                  </small>
                  <Form.Control
                    type="text"
                    className="form-control"
                    name="purchaseorder_number"
                    placeholder={intl.formatMessage({
                      id: "TITLE.PO.DC.NUMBER",
                    })}
                    onChange={(e) => {
                      setFieldValue("purchaseorder_number", e.target.value);
                    }}
                    autoComplete="off"
                  />
                </div>
                {/* <div className="">
                  <small className="form-text text-muted mb-1">
                    <b>
                      <FormattedMessage id="TITLE.DELIVERY.DATE" />
                    </b>
                  </small>
                  <DatePicker
                    selected={deliveryDate}
                    dateFormat={dd_MM_yyyy}
                    onChange={(deliveryDate) => {
                      if (deliveryDate) {
                        setDeliveryDate(deliveryDate);
                        setFieldValue("delivery_date", deliveryDate);
                      } else {
                        setDeliveryDate("");
                        setFieldValue("po_date", "");
                      }
                    }}
                    placeholderText={intl.formatMessage({
                      id: "TITLE.DD/MM/YYYY",
                    })}
                    className="form-control w-100"
                    closeOnScroll={(e) => e.target === document}
                    style={{ zIndex: "10" }}
                  />
                </div> */}
                {/* <div className="">
                  <small className="form-text text-muted mb-1">
                    <b>
                      <FormattedMessage id="TITLE.EXPECTED.DELIVERY.DATE" />
                    </b>
                  </small>
                  <DatePicker
                    selected={expectedDeliveryDate}
                    dateFormat={dd_MM_yyyy}
                    onChange={(expectedDeliveryDate) => {
                      if (expectedDeliveryDate) {
                        setExpectedDeliveryDate(expectedDeliveryDate);
                        setFieldValue("expected_delivery", expectedDeliveryDate);
                      } else {
                        setExpectedDeliveryDate("");
                        setFieldValue("expected_delivery", "");
                      }
                    }}
                    placeholderText={intl.formatMessage({
                      id: "TITLE.DD/MM/YYYY",
                    })}
                    className="form-control w-100"
                    closeOnScroll={(e) => e.target === document}
                    style={{ zIndex: "10" }}
                  />
                </div> */}
              </div>
              <div className="filter-action d-flex align-items-center">
                <Button
                  type="submit"
                  variant="secondary d-flex align-items-center btn-inventory mr-2"
                >
                  <Search
                    className="m-0"
                    size={20}
                    style={{ color: "#fff", marginRight: "0" }}
                  />
                  {isLoadingFilter && <ButtonLoader />}
                </Button>
                <Button
                  type="reset"
                  variant="tertiary d-flex align-items-center btn-inventory"
                >
                  <RefreshCw
                    size={20}
                    style={{ color: "#CAA25C", marginRight: "0" }}
                    className="m-0"
                  />
                </Button>
              </div>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </>
  );
}
