import React, { useMemo, useState } from "react";
import { Search, RefreshCw } from "react-feather";
import { Form, Button } from "react-bootstrap";
import { useUserUIContext } from "../UserUIContext";
import { isEqual } from "lodash";
import { Formik } from "formik";
import { ButtonLoader } from "../../../helper/ButtonLoader";
import { FormattedMessage, useIntl } from "react-intl";

const initialFilterValues = {
  fullname: "",
  status: "",
  role_id: "",
  emp_id: "",
  cellnumber: "",
  email: "",
};

export function UserFilter({
  isLoadingFilter,
  handleCLickOfFilterButtonFilter,
}) {
  const [filterStatus, setFilterStatus] = useState("");
  const userUIContext = useUserUIContext();
  const userUIProps = useMemo(() => {
    return {
      queryParams: userUIContext.queryParams,
      setQueryParams: userUIContext.setQueryParams,
    };
  }, [userUIContext]);

  const prepareFilter = (queryParams, values) => {
    const { fullname, status, role_id, emp_id, cellnumber, email } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};
    filter.full_name = fullname !== "" ? fullname : undefined;
    filter.status = status !== "" ? status : undefined;
    filter.role_id = role_id !== "" ? role_id : undefined;
    filter.emp_id = emp_id !== "" ? emp_id : undefined;
    filter.cellnumber = cellnumber !== "" ? cellnumber : undefined;
    filter.email = email !== "" ? email : undefined;
    newQueryParams.filter = filter;
    return newQueryParams;
  };

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(userUIProps.queryParams, values);
    if (!isEqual(newQueryParams, userUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      userUIProps.setQueryParams(newQueryParams);
    }
  };
  const intl = useIntl();
  return (
    <>
      <Formik
        initialValues={initialFilterValues}
        onSubmit={(values) => {
          handleCLickOfFilterButtonFilter();
          applyFilter(values);
        }}
        onReset={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          resetForm,
          handleReset,
          setFieldValue,
        }) => (
          <Form
            autoComplete="off"
            className="form form-label-right filter-card"
            onSubmit={handleSubmit}
            onReset={() => {
              resetForm(initialFilterValues);
              setFilterStatus("");
              handleReset();
            }}
          >
            <Form.Group className="form-group mb-md-0 filter-form-group">
              <div className="filter-item">
                <div className="">
                  <small className="form-text text-muted mb-1">
                    <b>
                      <FormattedMessage id="TITLE.USER.NAME" />
                    </b>
                  </small>
                  <Form.Control
                    type="text"
                    className="form-control"
                    name="fullname"
                    placeholder={intl.formatMessage({ id: "TITLE.USER.NAME" })}
                    onChange={(e) => {
                      setFieldValue("fullname", e.target.value);
                    }}
                  />
                </div>
                <div className="">
                  <small className="form-text text-muted mb-1">
                    <b>
                      <FormattedMessage id="TITLE.EMPLOYEE.ID" />
                    </b>
                  </small>
                  <Form.Control
                    type="text"
                    className="form-control"
                    name="emp_id"
                    placeholder={intl.formatMessage({
                      id: "TITLE.EMPLOYEE.ID",
                    })}
                    onChange={(e) => {
                      setFieldValue("emp_id", e.target.value);
                    }}
                  />
                </div>
                <div className="">
                  <small className="form-text text-muted mb-1">
                    <b>
                      <FormattedMessage id="TITLE.EMAIL.ADDRESS" />
                    </b>
                  </small>
                  <Form.Control
                    type="text"
                    className="form-control"
                    name="email"
                    placeholder={intl.formatMessage({
                      id: "TITLE.EMAIL.ADDRESS",
                    })}
                    onChange={(e) => {
                      setFieldValue("email", e.target.value);
                    }}
                  />
                </div>
                <div className="">
                  <small className="form-text text-muted mb-1">
                    <b>
                      <FormattedMessage id="TITLE.PHONE.NUMBER" />
                    </b>
                  </small>
                  <Form.Control
                    type="number"
                    className="form-control"
                    name="cellnumber"
                    placeholder={intl.formatMessage({
                      id: "TITLE.PHONE.NUMBER",
                    })}
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    onChange={(e) => {
                      setFieldValue("cellnumber", e.target.value);
                    }}
                    onPaste={(evt) => {
                      const values = evt.clipboardData.getData("text");
                      const nonAlpha = values.replace(/[^\d.-]/g, "");
                      evt.preventDefault();
                      setFieldValue("cellnumber", nonAlpha);
                    }}
                    value={values.cellnumber}
                  />
                </div>
                <div className="">
                  <small className="form-text text-muted mb-1">
                    <b>
                      <FormattedMessage id="TITLE.ROLE" />
                    </b>
                  </small>
                  <Form.Control
                    type="text"
                    className="form-control"
                    name="role_id"
                    placeholder={intl.formatMessage({ id: "TITLE.ROLE" })}
                    onChange={(e) => {
                      setFieldValue("role_id", e.target.value);
                    }}
                  />
                </div>
                <div className="">
                  <small className="form-text text-muted mb-1">
                    <b>
                      <FormattedMessage id="TITLE.STATUS" />
                    </b>
                  </small>
                  <Form.Control
                    as="select"
                    className="select-control"
                    onChange={(event) => {
                      setFieldValue("status", event.target.value);
                      setFilterStatus(event.target.value);
                    }}
                    value={filterStatus}
                  >
                    <option value="">
                      {intl.formatMessage({ id: "TITLE.ALL" })}
                    </option>
                    <option value="1">
                      {intl.formatMessage({ id: "TITLE.ACTIVE" })}
                    </option>
                    <option value="0">
                      {intl.formatMessage({ id: "TITLE.INACTIVE" })}
                    </option>
                  </Form.Control>
                </div>
              </div>
              <div className="filter-action d-flex">
                <Button
                  variant="secondary d-flex align-items-center btn-inventory mr-2"
                  type="submit"
                >
                  <Search
                    size={20}
                    style={{ color: "#fff", marginRight: "0" }}
                  />
                  {isLoadingFilter && <ButtonLoader className={"mr-1 ml-1"} />}
                </Button>
                <Button
                  variant="tertiary d-flex align-items-center btn-inventory"
                  type="reset"
                >
                  <RefreshCw
                    size={20}
                    style={{ color: "#CAA25C", marginRight: "0" }}
                  />
                </Button>
              </div>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </>
  );
}
