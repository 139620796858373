import React, { useEffect, useMemo, useState } from "react";
import { Search, RefreshCw } from "react-feather";
import { Form, Button } from "react-bootstrap";
import { useActivePOUIContext } from "../ActivePOUIContext";
import { isEqual } from "lodash";
import { Formik } from "formik";
import { ButtonLoader } from "../../../../helper/ButtonLoader";
import { FormattedMessage, useIntl } from "react-intl";

const initialFilterValues = {
  contact_name: "",
  purchaseorder_number: "",
  expected_delivery: "",
  status: "",
  po_date: "",
};

export function ActivePOFilter({
  isLoadingFilter,
  POStatusList,
  handleCLickOfFilterButtonFilter,
  handleCLickOfResetButtonFilter,
}) {
  const intl = useIntl();
  const [filterStatus, setFilterStatus] = useState("");
  const [statusList, setStatusList] = useState([]);

  useEffect(() => {
    if (POStatusList && POStatusList.length > 0) {
      const statusList = POStatusList.filter((postatus) => {
        return (
          postatus?.name === "Partially Fulfilled" ||
          postatus?.name === "Approved" ||
          postatus?.name === "Waiting For Approval"
        );
      });
      setStatusList(statusList);
    }
  }, [POStatusList]);

  const activePOUIContext = useActivePOUIContext();
  const activePOUIProps = useMemo(() => {
    return {
      queryParams: activePOUIContext.queryParams,
      setQueryParams: activePOUIContext.setQueryParams,
    };
  }, [activePOUIContext]);
  const prepareFilter = (queryParams, values) => {
    const {
      contact_name,
      purchaseorder_number,
      expected_delivery,
      status,
      po_date,
    } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};
    filter.contact_name = contact_name !== "" ? contact_name : undefined;
    filter.purchaseorder_number =
      purchaseorder_number !== "" ? purchaseorder_number : undefined;
    filter.expected_delivery =
      expected_delivery !== "" ? expected_delivery : undefined;
    filter.status = status !== "" ? status : undefined;
    filter.po_date = po_date !== "" ? po_date : undefined;

    newQueryParams.filter = { ...queryParams.filter, ...filter };
    return newQueryParams;
  };

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(activePOUIProps.queryParams, values);
    if (!isEqual(newQueryParams, activePOUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      activePOUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialFilterValues}
        onSubmit={(values) => {
          handleCLickOfFilterButtonFilter();
          applyFilter(values);
        }}
        onReset={(values) => {
          handleCLickOfResetButtonFilter();
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          resetForm,
          handleReset,
          setFieldValue,
        }) => (
          <Form
            className="form form-label-right filter-card"
            onSubmit={handleSubmit}
            onReset={() => {
              resetForm({ values: initialFilterValues });
              handleReset();
            }}
          >
            <Form.Group className="form-group mb-md-0 filter-form-group">
              <div className="filter-item">
                <div className="">
                  <small className="form-text text-muted mb-1">
                    <b>
                      {" "}
                      <FormattedMessage id="TITLE.STATUS" />
                    </b>
                  </small>
                  <Form.Control
                    name="status"
                    as="select"
                    className="select-control"
                    onChange={(event) => {
                      setFieldValue("status", event.target.value);
                      setFilterStatus(event.target.value);
                    }}
                    value={filterStatus}
                  >
                    <option value="">
                      {intl.formatMessage({
                        id: "TITLE.ALL",
                      })}
                    </option>
                    {statusList &&
                      statusList.map((status, index) => (
                        <option key={index} value={`${status.id}`}>
                          {status.name}
                        </option>
                      ))}
                  </Form.Control>
                </div>
                {/* <div className="">
                  <small className="form-text text-muted mb-1">
                    <b>
                      <FormattedMessage id="TITLE.DATE" />
                    </b>
                  </small>
                  <DatePicker
                    name="po_date"
                    selected={date}
                    dateFormat={dd_MM_yyyy}
                    onChange={(date) => {
                      if (date) {
                        setDate(date);
                        setFieldValue("po_date", date);
                      } else {
                        setDate("");
                        setFieldValue("po_date", "");
                      }
                    }}
                    placeholderText={intl.formatMessage({
                      id: "TITLE.DD/MM/YYYY",
                    })}
                    className="form-control w-100"
                    closeOnScroll={(e) => e.target === document}
                    style={{ zIndex: "10" }}
                  />
                </div> */}
                {/* <div className="">
                  <small className="form-text text-muted mb-1">
                    <b>
                      {" "}
                      <FormattedMessage id="TITLE.EXPECTED.DELIVERY.DATE" />
                    </b>
                  </small>
                  <DatePicker
                    selected={expectedDeliveryDate}
                    dateFormat={dd_MM_yyyy}
                    onChange={(date) => {
                      if (date) {
                        setExpectedDeliveryDate(date);
                        setFieldValue("expected_delivery", date);
                      } else {
                        setExpectedDeliveryDate("");
                        setFieldValue("expected_delivery", "");
                      }
                    }}
                    placeholderText={intl.formatMessage({
                      id: "TITLE.DD/MM/YYYY",
                    })}
                    className="form-control w-100"
                    closeOnScroll={(e) => e.target === document}
                    style={{ zIndex: "10" }}
                  />
                </div> */}
                <div className="">
                  <small className="form-text text-muted mb-1">
                    <b>
                      {" "}
                      <FormattedMessage id="TITLE.PO.NUMBER" />
                    </b>
                  </small>
                  <Form.Control
                    type="text"
                    className="form-control"
                    name="purchaseorder_number"
                    placeholder={intl.formatMessage({
                      id: "TITLE.PO.NUMBER",
                    })}
                    onChange={(e) => {
                      setFieldValue("purchaseorder_number", e.target.value);
                    }}
                    autoComplete="off"
                  />
                </div>

                <div className="">
                  <small className="form-text text-muted mb-1">
                    <b>
                      {" "}
                      <FormattedMessage id="TITLE.VENDOR.NAME" />
                    </b>
                  </small>
                  <Form.Control
                    type="text"
                    className="form-control"
                    name="contact_name"
                    placeholder={intl.formatMessage({
                      id: "TITLE.VENDOR.NAME",
                    })}
                    onChange={(e) => {
                      setFieldValue("contact_name", e.target.value);
                    }}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="filter-action d-flex align-items-center">
                <Button
                  type="submit"
                  variant="secondary d-flex align-items-center btn-inventory mr-2"
                >
                  <Search
                    className="m-0"
                    size={20}
                    style={{ color: "#fff", marginRight: "0" }}
                  />
                  {isLoadingFilter && <ButtonLoader />}
                </Button>
                <Button
                  type="reset"
                  variant="tertiary d-flex align-items-center btn-inventory"
                >
                  <RefreshCw
                    size={20}
                    style={{ color: "#CAA25C", marginRight: "0" }}
                    className="m-0"
                  />
                </Button>
              </div>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </>
  );
}
