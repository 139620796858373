import React, { useState } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import { X, Check } from "react-feather";
import { stateList, statusName } from "../components/cities/CityUIHelpers";

export function EditCityModal({ onCloseModal, open, data }) {
  const { name, state, status } = data || {};
  const [selectedOption, setSelectedOption] = useState({
    value: status,
    label: 1 === status ? "Active" : "Inactive",
  });
  const [selectedState, setSelectedState] = useState({
    value: state,
    label: state,
  });
  const [isFormSuccess, setFormSuccess] = useState(false);

  return (
    <>
      <Modal
        size="md"
        show={open}
        onHide={onCloseModal}
        aria-labelledby="example-modal-sizes-title-sm"
        dialogClassName="inventory-small-modal"
        scrollable={true}
      >
        <Modal.Header
          className="d-flex justify-content-between align-items-center"
          // toggle={onCloseModal}
        >
          <h3 className="modal-title" id="exampleModalLabel2">
            Edit City
          </h3>
          <Button
            variant="link p-0 btn-icon"
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <X size={20} className="text-dark mr-0 pr-0" />
          </Button>
        </Modal.Header>
        <Modal.Body className="pb-0">
          {isFormSuccess && (
            <div className="notification icon inline success fs-16">
              <Check size={8} className="text-white"></Check> City edited
              successfully
            </div>
          )}
          <Form autoComplete="off">
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label for="categoryname">
                    City Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="categoryname"
                    placeholder="City Name"
                    value={name}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor="state">State</Form.Label>
                  <Select
                    defaultValue={selectedState}
                    onChange={setSelectedState}
                    isSearchable={true}
                    options={stateList}
                    placeholder="Select State"
                    id="state"
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label for="status">Status</Form.Label>
                  <Select
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    isSearchable={true}
                    options={statusName}
                    placeholder="Select Status"
                    id="status"
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            variant="success"
            onClick={() => {
              window.scrollTo(0, 0);
              setFormSuccess(!isFormSuccess);
              setTimeout(() => {
                onCloseModal("VaryingMdo");
              }, 1500);
            }}
          >
            Save
          </Button>
          <Button
            type="button"
            variant="tertiary text-dark"
            onClick={() => onCloseModal("VaryingMdo")}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
