import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./index.scss";
import App from "./components/app";

// Components
import Dashboard from "./components/dashboard";
import Profile from "./components/settings/profile";
import Login from "./components/auth/login";
import { InwardPage } from "./components/inward-module/InwardPage";
import { UserPage } from "./components/user-module/UserPage";
import { ProductUnitPage } from "./components/productUnit-module/ProductUnitPage";
import { CustomerPage } from "./components/customer-module/CustomerPage";
import { VendorPage } from "./components/vendor-module/VendorPage";
import AddNewInward from "./components/inward-module/add-new-inward/AddNewInward";
import ViewPODetails from "./components/view-po-details/ViewPODetails";
import ViewVendorDetails from "./components/view-vendor-details/ViewVendorDetails";
import ViewCustomerDetails from "./components/view-customer-details/ViewCustomerDetails";
import { OutwardPage } from "./components/outward-module/OutwardPage";
import AddNewOutward from "./components/outward-module/add-new-outward/AddNewOutward";
import ViewDCDetails from "./components/view-dc-details/ViewDCDetails";
import { ActivePOPage } from "./components/reports/active-po/ActivePOPage";
import { OutwardCustomerPage } from "./components/reports/outward-customer/OutwardCustomerPage";
import { OutwardProductPage } from "./components/reports/outward-product/OutwardProductPage";
import { InventoryAgingPage } from "./components/reports/inventory-aging/InventoryAgingPage";
import { InventoryValuationPage } from "./components/reports/inventory-valuation/InventoryValuationPage";
import { LowStockPage } from "./components/reports/low-stock/LowStockPage";
import { DelayedPODCPage } from "./components/reports/delayed-po-dc/DelayedPODCPage";
import { InwardValuationPage } from "./components/reports/inward-valuation/InwardValuationPage";
import { OutwardValuationPage } from "./components/reports/outward-valuation/OutwardValuationPage";
import { CategoryPage } from "./components/category-module/CategoryPage";
import { ProductPage } from "./components/product-module/ProductPage";
import ViewProductDetails from "./components/product-module/view-product-details/ViewProductDetails";
import { InventoryProductPage } from "./components/inventory-product-module/ProductPage";
import InventoryViewProductDetails from "./components/inventory-product-module/view-product-details/ViewProductDetails";
import { CountryPage } from "./components/countries/CountryPage";
import { StatePage } from "./components/states/StatePage";
import { CityPage } from "./components/cities/CityPage";
import ViewCategoryDetails from "./components/category-module/view-categiory-details/ViewCategoryDetails";
import WarningHeader from "./components/common/header_components/warningHeader";
import { isBrowser } from "react-device-detect";
import ViewLocationDetails from "./components/location-module/location-view-modal/ViewLocationDetails";
import EditInward from "./components/inward-module/edit-inward/EditInward";
import EditOutward from "./components/outward-module/edit-outward/EditOutward";
import { UIProvider } from "./helper/UIContext";
import { BlockLocationPage } from "./components/block-location/BlockLocationPage";
import { LocationPage } from "./components/location-module/LocationPage";
import {
  I18nProvider,
  MetronicI18nProvider,
  setLanguage,
  useLang,
} from "./i18n";
import { USER_DETAILS } from "./constants/const";
import AuthPage from "./components/auth/AuthPage";
import { addRemoveRTLLTRForDateRangePicker } from "./helper/helper";
import "react-datepicker/src/stylesheets/datepicker.scss";

const Root = () => {
  const selectedLang = useLang();
  if (selectedLang) {
    setLanguage(selectedLang);
    if (selectedLang === "ar") {
      document.body.classList.add("rtl");
    } else {
      document.body.classList.add("ltr");
    }
  } else {
    setLanguage("ar");
    document.body.classList.add("rtl");
  }
  addRemoveRTLLTRForDateRangePicker();

  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const { role } = userDetails || {};
  const { name: userRole } = role || {};

  return (
    <>
      {!isBrowser && <WarningHeader />}
      <BrowserRouter basename={"/"}>
        <Switch>
          {!userDetails && (
            <AuthPage>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/`}
                component={Login}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/auth/login`}
                component={Login}
              />
              <Redirect to="/" />
            </AuthPage>
          )}

          {userDetails && (
            <App>
              {userRole === "Inward Manager" && (
                <>
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/`}
                    component={Dashboard}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/dashboard`}
                    component={Dashboard}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/inwards/inward-listing`}
                    component={InwardPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/inwards/add-inward`}
                    component={AddNewInward}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/inwards/edit-inward/:id`}
                    component={EditInward}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/inwards/view-po-details/:id`}
                    component={ViewPODetails}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/inventoryproducts/products`}
                    component={InventoryProductPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/inventoryproducts/view-inventoryproduct-details/:id`}
                    component={InventoryViewProductDetails}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/vendors/vendor-listing`}
                    component={VendorPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/vendors/view-vendor-details/:id`}
                    component={ViewVendorDetails}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/settings/profile`}
                    component={Profile}
                  />
                </>
              )}

              {userRole === "Outward Manager" && (
                <>
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/`}
                    component={Dashboard}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/dashboard`}
                    component={Dashboard}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/outwards/outward-listing`}
                    component={OutwardPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/outwards/outward-listing/:id`}
                    component={OutwardPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/outwards/add-outward`}
                    component={AddNewOutward}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/outwards/edit-outward/:id`}
                    component={EditOutward}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/outwards/view-dc-details/:id`}
                    component={ViewDCDetails}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/inventoryproducts/products`}
                    component={InventoryProductPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/inventoryproducts/view-inventoryproduct-details/:id`}
                    component={InventoryViewProductDetails}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/customers/customer-listing`}
                    component={CustomerPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/customers/view-customer-details/:id`}
                    component={ViewCustomerDetails}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/settings/profile`}
                    component={Profile}
                  />
                </>
              )}

              {userRole === "Inventory Manager" && (
                <>
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/`}
                    component={Dashboard}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/dashboard`}
                    component={Dashboard}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/inwards/inward-listing`}
                    component={InwardPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/inwards/add-inward`}
                    component={AddNewInward}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/inwards/edit-inward/:id`}
                    component={EditInward}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/inwards/view-po-details/:id`}
                    component={ViewPODetails}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/outwards/outward-listing`}
                    component={OutwardPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/outwards/add-outward`}
                    component={AddNewOutward}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/outwards/edit-outward/:id`}
                    component={EditOutward}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/outwards/view-dc-details/:id`}
                    component={ViewDCDetails}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/inventoryproducts/products`}
                    component={InventoryProductPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/inventoryproducts/view-inventoryproduct-details/:id`}
                    component={InventoryViewProductDetails}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/customers/customer-listing`}
                    component={CustomerPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/customers/view-customer-details/:id`}
                    component={ViewCustomerDetails}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/reports/active-po`}
                    component={ActivePOPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/reports/outward-by-customer`}
                    component={OutwardCustomerPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/reports/outwards-by-product`}
                    component={OutwardProductPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/reports/inventory-aging-summary`}
                    component={InventoryAgingPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/reports/inventory-valuation-summary`}
                    component={InventoryValuationPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/reports/low-stock-items`}
                    component={LowStockPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/reports/delayed-po-dc`}
                    component={DelayedPODCPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/reports/inwards-valuation`}
                    component={InwardValuationPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/reports/outwards-valuation`}
                    component={OutwardValuationPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/vendors/vendor-listing`}
                    component={VendorPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/vendors/view-vendor-details/:id`}
                    component={ViewVendorDetails}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/settings/profile`}
                    component={Profile}
                  />

                  {/* // Admin Roles start */}
                  <Route
                    path={`${process.env.PUBLIC_URL}/master/categories/category-listing`}
                    component={CategoryPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/master/categories/view-category-details/:id`}
                    component={ViewCategoryDetails}
                  />

                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/master/products`}
                    component={ProductPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/master/products/view-product-details/:id`}
                    component={ViewProductDetails}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/master/locations/locations`}
                    component={LocationPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/master/locations/blocklocations`}
                    component={BlockLocationPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/master/locations/view-location-details`}
                    component={ViewLocationDetails}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/master/productunits/product-unit-listing`}
                    component={ProductUnitPage}
                  />

                  {/* // Admin Roles End */}
                </>
              )}

              {userRole === "Admin" && (
                <>
                  <Route
                    path={`${process.env.PUBLIC_URL}/master/categories/category-listing`}
                    component={CategoryPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/master/categories/view-category-details/:id`}
                    component={ViewCategoryDetails}
                  />

                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/master/products`}
                    component={ProductPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/master/products/view-product-details/:id`}
                    component={ViewProductDetails}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/customers/customer-listing`}
                    component={CustomerPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/customers/view-customer-details/:id`}
                    component={ViewCustomerDetails}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/users/user-listing`}
                    component={UserPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/master/locations/locations`}
                    component={LocationPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/master/locations/blocklocations`}
                    component={BlockLocationPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/master/locations/countries`}
                    component={CountryPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/master/locations/states`}
                    component={StatePage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/master/locations/cities`}
                    component={CityPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/master/locations/view-location-details`}
                    component={ViewLocationDetails}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/master/productunits/product-unit-listing`}
                    component={ProductUnitPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/vendors/vendor-listing`}
                    component={VendorPage}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/vendors/view-vendor-details/:id`}
                    component={ViewVendorDetails}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/settings/profile`}
                    component={Profile}
                  />

                  <Route exact path="/">
                    <Redirect to="/users/user-listing" />
                  </Route>

                  {/* <Redirect from="/" exact={true} to="/users/user-listing" /> */}
                </>
              )}
            </App>
          )}
        </Switch>
      </BrowserRouter>
    </>
  );
};

ReactDOM.render(
  <MetronicI18nProvider>
    <UIProvider>
      <I18nProvider>
        <Root />
      </I18nProvider>
    </UIProvider>
  </MetronicI18nProvider>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
