/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { amountFormatter } from "../constants/utils";
const PriceColumnFormatter = (cellContent, row, rowIndex) => {
  const { price = "0" } = row;
  return (
    <>
      <div>{amountFormatter(price)}</div>
      <> </>
    </>
  );
};

export default PriceColumnFormatter;
