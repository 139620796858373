import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import Select from "react-select";
import { Check, Plus, XCircle } from "react-feather";
import { useHistory } from "react-router-dom";
import Switch from "react-switch";
import DatePicker from "react-datepicker";
import {
  productListForScannedProductsApi,
  receiveInwardApi,
  receivePOLocationListApi,
} from "../../axios/services/services";
import moment from "moment";
import { DD_MM_YYYY, dd_MM_yyyy, YYYY_MM_DD } from "../../constants/const";
import { ButtonLoader } from "../../helper/ButtonLoader";
import { ErrorModal } from "../common/modals/ErrorModal";
import { FieldArray, Formik } from "formik";
import { ReceivePOSchema } from "../../helper/FormikSchema";
import { SectionLoader } from "../../helper/SectionLoader";
import {
  capitalizeFirstLetter,
  getErrorMessageBasedOnLanguage,
  setActiveMenu,
  unauthorization,
} from "../../constants/utils";
import { FormattedMessage, useIntl } from "react-intl";
import { MENUITEMS } from "../../constants/menu";
import { UIContext } from "../../helper/UIContext";
import { ViewPOProductForReceiveModal } from "../../global-modal/ViewPOProductForReceiveModal";
import ErrorMessage from "../../helper/ErrorMessage";

export function ReceiveInwardForm({
  onClickofcancel,
  renderPONumber,
  id,
  PODetails,
  purchaseOrderProductList,
}) {
  const intl = useIntl();
  const history = useHistory();
  const uiContext = useContext(UIContext);
  const { purchaseorder_number, date } = PODetails || {};
  const [receivedDate, setReceivedDate] = useState(new Date());
  const [locationList, setLocationList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingProductList, setLoadingProductList] = useState(false);
  const [productList, setProductList] = useState([]);
  const [isNewInwardCreateAdded, setNewInwardCreateAdded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isViewPOProductReceiveModalOpen, setViewPOProductReceiveModalOpen] =
    useState(false);
  const [isAddPrductTable, setAddPrductTable] = useState(true);
  const [firstProduct, setFirstProduct] = useState(null);
  const [nextProduct, setNextProduct] = useState(null);
  const [firstLocation, setFirstLocation] = useState(null);
  const [remainingReceivedQuantity, setRemainingReceivedQuantity] = useState(0);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [initialValues, setInitialValues] = useState({
    delivery_date: new Date(),
    notes: "",
    product_condition: [
      {
        barcode_no: "",
        productId: "",
        locationId: "",
        expiry_date: "",
        condition: true,
        quantity: 1,
        selectedProduct: {
          value: "",
          label: "",
        },
        seletedLocation: {
          value: "",
          label: "",
        },
      },
    ],
  });
  const handleAddProductConditionProductRef = useRef([]);
  const handleAddNewReceiveItemRef = useRef([]);

  useEffect(() => {
    getProductList();
  }, []);

  useEffect(() => {
    let totalProductQuantity = 0;
    let totalProductReceivedQuantity = 0;
    if (productList) {
      const products = productList.map((product) => {
        const { quantity, received_quantity } = product || {};
        totalProductQuantity = totalProductQuantity + quantity;
        totalProductReceivedQuantity =
          totalProductReceivedQuantity + received_quantity;
        return product;
      });
    }
    const remaining = totalProductQuantity - totalProductReceivedQuantity;
    setRemainingReceivedQuantity(remaining);
  }, [productList]);

  async function getProductList() {
    try {
      enableLoadingProductList();
      const ProductListResponse = await productListForScannedProductsApi({
        id,
      });
      const { Error, data: notScannedProductList } = ProductListResponse || {};
      if (Error) {
        disableLoadingProductList();
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      const products =
        notScannedProductList &&
        notScannedProductList.map((product, index) => {
          product.value = product?.productId;
          product.label = capitalizeFirstLetter(product?.product?.name);
          return product;
        });
      const locationListResponse = await receivePOLocationListApi();

      const { Error: ErrorLocation, data: locationData } =
        locationListResponse || [];
      if (ErrorLocation) {
        disableLoadingProductList();
        unauthorization(ErrorLocation);
        setErrorMessage(getErrorMessageBasedOnLanguage(ErrorLocation));
        handleErrorModalShow();
        return;
      }

      const locations = locationData.map((location) => {
        const { store_location, id, parent_location, locationId } =
          location || {};
        const { locationId: li } = locationId || {};
        let locName = store_location;
        var comma = ",";
        if (parent_location) {
          locName = store_location.concat(comma, locationId?.store_location);
          if (li) {
            locName = locName.concat(comma, li?.store_location);
          }
        }
        location.value = id;
        location.label = locName;
        return location;
      });

      const [firstLocation] = locations || {};
      const [firstProduct] = products || {};
      const expiryDate = firstProduct?.product?.shelf_life
        ? moment(new Date())
            .add(firstProduct?.product?.shelf_life, "days")
            .toDate()
        : "";
      let obj = {
        barcode_no: "",
        productId: firstProduct?.productId,
        locationId: firstLocation?.id,
        expiry_date: expiryDate,
        condition: true,
        quantity: 1,
        selectedProduct: firstProduct,
        selectedLocation: firstLocation,
      };
      initialValues.product_condition = [obj];
      setInitialValues(initialValues);
      setFirstProduct(firstProduct);
      setFirstLocation(firstLocation);
      setProductList(products ? products : []);
      setLocationList(locations ? locations : []);
      disableLoadingProductList();
    } catch (error) {
      disableLoadingProductList();
    }
  }

  const onChangeOfLocation = (setFieldValue, data, product, index) => {
    const { value } = data || {};
    setFieldValue(`product_condition.${index}.locationId`, value);
    setFieldValue(`product_condition.${index}.selectedLocation`, data);
  };

  const onChangeofExpiryDate = (setFieldValue, date, product, index) => {
    setFieldValue(`product_condition.${index}.expiry_date`, date);
  };

  const renderProductValue = (product) => {
    const obj = {
      label: product?.selectedProduct?.label,
      value: product?.selectedProduct?.value,
    };
    return obj;
  };

  const renderLocationValue = (product) => {
    const obj = {
      label: product?.selectedLocation?.label,
      value: product?.selectedLocation?.value,
    };
    return obj;
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingProductList = () => {
    setLoadingProductList(true);
  };

  const disableLoadingProductList = () => {
    setLoadingProductList(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleCloseOfViewPOProductReceiveModalShow = () => {
    setViewPOProductReceiveModalOpen(true);
  };

  const handleCloseOfViewPOProductReceiveModalHide = () => {
    setViewPOProductReceiveModalOpen(false);
  };

  const receiveInward = async (values) => {
    enableLoading();
    const { delivery_date, notes, product_condition } = values || {};
    const conditions = [];
    const productConditions = product_condition.map((product) => {
      const {
        productId,
        locationId,
        expiry_date,
        condition,
        quantity,
        barcode_no,
      } = product || {};
      conditions.push({
        productId,
        locationId,
        condition,
        quantity,
        barcode_no,
        ...(expiry_date === ""
          ? { expiry_date: null }
          : {
              expiry_date: expiry_date
                ? moment(expiry_date).format(YYYY_MM_DD)
                : null,
            }),
      });
      return product;
    });

    let inwardReceiveObj = {
      id,
      delivery_date: delivery_date
        ? moment(delivery_date).format(YYYY_MM_DD)
        : null,
      notes,
      product_condition: conditions,
    };
    const receiveInwardResponse = await receiveInwardApi(inwardReceiveObj);
    const { Error } = receiveInwardResponse || {};
    if (Error) {
      disableLoading();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setNewInwardCreateAdded(true);
    setTimeout(() => {
      disableLoading();
      const path = "/inwards/inward-listing";
      onClickChangeRoute(path);
    }, 2000);
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  const getNextProduct = (productConditions) => {
    // console.log("productConditions", productConditions);
  };

  return (
    <>
      <div className="column details-column">
        {isLoadingProductList && <SectionLoader />}
        <Formik
          initialValues={initialValues}
          validationSchema={ReceivePOSchema}
          onSubmit={(values) => {
            receiveInward(values);
          }}
          onReset={(values) => {}}
        >
          {({
            values,
            handleSubmit,
            handleBlur,
            handleChange,
            resetForm,
            handleReset,
            setFieldValue,
            errors,
            touched,
          }) => (
            <Form
              className="form form-label-right filter-card"
              autoComplete="off"
              onSubmit={handleSubmit}
              onReset={() => {
                resetForm(initialValues);
                handleReset();
              }}
            >
              <div
                className="w-100 column-header d-flex align-items-center justify-content-between"
                style={{ minHeight: "71px" }}
              >
                <h2 className="modal-title">
                  <FormattedMessage id="TITLE.NEW.PURCHASE.RECEIVE" />
                </h2>
              </div>
              <div className="column-body with-footer custom-scrollbar">
                <div className="view-body">
                  {isNewInwardCreateAdded && (
                    <div className="notification icon inline success fs-16">
                      <Check size={8} className="text-white"></Check>
                      <FormattedMessage id="TITLE.PURCHASE.RECEIVED.CREATED.SUCCESSFULLY" />
                    </div>
                  )}
                  <Row className="mb-3">
                    <Col md={4} lg={3} xs={6}>
                      <span className="text-dark">
                        <FormattedMessage id="TITLE.PO.NUMBER" />
                      </span>
                    </Col>
                    <Col md={4} lg={3} xs={6}>
                      <span className="text-primary">
                        {purchaseorder_number}
                      </span>
                    </Col>
                  </Row>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column md="4" lg="3" xs="6">
                      <FormattedMessage id="TITLE.RECEIVED.DATE" />
                    </Form.Label>
                    <Col md={4} lg={3} xs={6}>
                      <DatePicker
                        selected={receivedDate}
                        name="delivery_date"
                        dateFormat={dd_MM_yyyy}
                        onChange={(date) => {
                          if (date) {
                            setReceivedDate(date);
                            setFieldValue("delivery_date", date);
                          } else {
                            setReceivedDate("");
                            setFieldValue("delivery_date", "");
                          }
                        }}
                        maxDate={new Date()}
                        minDate={new Date(date)}
                        placeholderText={DD_MM_YYYY}
                        className="form-control w-100"
                        closeOnScroll={(e) => e.target === document}
                      />
                      {touched.delivery_date && errors.delivery_date ? (
                        <div className="text-danger">
                          {errors.delivery_date}
                        </div>
                      ) : null}
                    </Col>
                  </Form.Group>
                  <Row className="mb-1 justify-content-end">
                    <Button
                      variant="link p-0"
                      onClick={() => {
                        handleCloseOfViewPOProductReceiveModalShow();
                      }}
                    >
                      <FormattedMessage id="TITLE.VIEW.PO.PRODUCTS" />
                    </Button>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Table
                        className="receive-item-table viewpo-table"
                        style={{ tableLayout: "fixed" }}
                      >
                        <thead>
                          <tr>
                            <th style={{ minWidth: "200px" }}>
                              <FormattedMessage id="TITLE.PRODUCT" />
                            </th>
                            <th>
                              <FormattedMessage id="TITLE.LOCATION" />
                            </th>
                            <th>
                              <FormattedMessage id="TITLE.EXPIRY.DATE" />
                            </th>
                            <th
                              className="text-center"
                              style={{ width: "15%" }}
                            >
                              <FormattedMessage id="TITLE.CONDITION" />
                            </th>
                            <th
                              className="text-center"
                              style={{ width: "15%" }}
                            >
                              <FormattedMessage id="TITLE.TOTAL.QUANTITY" />
                            </th>
                            <th>
                              <FormattedMessage id="TITLE.BARCODE.NO" />
                            </th>
                            <th style={{ width: "5px" }}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {isAddPrductTable && (
                            <FieldArray
                              name="product_condition"
                              render={(product_condition) =>
                                values?.product_condition?.map(
                                  (product, index) => (
                                    <tr key={index}>
                                      <td>
                                        <Select
                                          onChange={(data) => {
                                            const { value, product } =
                                              data || {};
                                            setFieldValue(
                                              `product_condition.${index}.productId`,
                                              value
                                            );
                                            setFieldValue(
                                              `product_condition.${index}.selectedProduct`,
                                              data
                                            );
                                            const expiryDate =
                                              product?.shelf_life
                                                ? moment(new Date())
                                                    .add(
                                                      product?.shelf_life,
                                                      "days"
                                                    )
                                                    .toDate()
                                                : "";
                                            setFieldValue(
                                              `product_condition.${index}.expiry_date`,
                                              expiryDate
                                            );
                                          }}
                                          isSearchable={true}
                                          options={productList}
                                          placeholder="Select"
                                          id="state"
                                          className="react-select-container receive-product-select"
                                          classNamePrefix="react-select"
                                          value={renderProductValue(product)}
                                        />
                                        {touched.product_condition &&
                                        errors.product_condition &&
                                        errors.product_condition.length > 0 &&
                                        errors.product_condition[index] ? (
                                          <div className="text-danger">
                                            <ErrorMessage
                                              id={
                                                errors.product_condition[index]
                                                  .productId
                                              }
                                            />
                                          </div>
                                        ) : null}
                                      </td>
                                      <td>
                                        <Select
                                          onChange={(data) =>
                                            onChangeOfLocation(
                                              setFieldValue,
                                              data,
                                              product,
                                              index
                                            )
                                          }
                                          isSearchable={true}
                                          options={locationList}
                                          placeholder="Select"
                                          id="state"
                                          className="react-select-container receive-product-select"
                                          classNamePrefix="react-select"
                                          value={renderLocationValue(product)}
                                        />
                                        {touched.product_condition &&
                                        errors.product_condition &&
                                        errors.product_condition.length > 0 &&
                                        errors.product_condition[index] ? (
                                          <div className="text-danger">
                                            <ErrorMessage
                                              id={
                                                errors.product_condition[index]
                                                  .locationId
                                              }
                                            />
                                          </div>
                                        ) : null}
                                      </td>
                                      <td>
                                        <Form.Group>
                                          <DatePicker
                                            selected={product.expiry_date}
                                            dateFormat={dd_MM_yyyy}
                                            onChange={(date) =>
                                              onChangeofExpiryDate(
                                                setFieldValue,
                                                date,
                                                product,
                                                index
                                              )
                                            }
                                            placeholderText={DD_MM_YYYY}
                                            className="form-control w-100"
                                            closeOnScroll={(e) =>
                                              e.target === document
                                            }
                                            minDate={new Date(date)}
                                          />
                                          {touched.product_condition &&
                                          errors.product_condition &&
                                          errors.product_condition.length > 0 &&
                                          errors.product_condition[index] ? (
                                            <div className="text-danger">
                                              <ErrorMessage
                                                id={
                                                  errors.product_condition[
                                                    index
                                                  ].expiry_date
                                                }
                                              />
                                            </div>
                                          ) : null}
                                        </Form.Group>
                                      </td>
                                      <td
                                        style={{
                                          verticalAlign: "middle",
                                          textAlign: "center",
                                        }}
                                      >
                                        <Switch
                                          onChange={(event) => {
                                            setFieldValue(
                                              `product_condition.${index}.condition`,
                                              event
                                            );
                                          }}
                                          checked={product.condition}
                                          onColor="#009970"
                                          offHandleColor="#fff"
                                          onHandleColor="#fff"
                                          className="react-switch"
                                          height={20}
                                          width={40}
                                        />
                                      </td>
                                      <td
                                        style={{
                                          verticalAlign: "middle",
                                          textAlign: "center",
                                        }}
                                      >
                                        1
                                      </td>
                                      <td>
                                        <Form.Control
                                          size="sm"
                                          id="barcodenumber"
                                          name="barcode_no"
                                          type="text"
                                          className="form-control small-text"
                                          placeholder="00-AAA-0000000"
                                          value={product?.barcode_no}
                                          onChange={(event) => {
                                            const value = event.target.value;
                                            setFieldValue(
                                              `product_condition.${index}.barcode_no`,
                                              value
                                            );
                                          }}
                                          maxLength={14}
                                          onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                              handleAddNewReceiveItemRef.current.click();
                                            }
                                          }}
                                          autoFocus
                                        />
                                        {touched.product_condition &&
                                        errors.product_condition &&
                                        errors.product_condition.length > 0 &&
                                        errors.product_condition[index] ? (
                                          <div className="text-danger">
                                            <ErrorMessage
                                              id={
                                                errors.product_condition[index]
                                                  .barcode_no
                                              }
                                            />
                                          </div>
                                        ) : null}
                                      </td>
                                      <td>
                                        <Button
                                          variant="link p-0 m-0"
                                          className="delete-table-row"
                                          onClick={() => {
                                            if (
                                              values.product_condition
                                                .length === 1
                                            ) {
                                              setAddPrductTable(false);
                                            }
                                            product_condition.remove(index);
                                          }}
                                        >
                                          <XCircle
                                            size={16}
                                            style={{
                                              color: "#990029",
                                              marginRight: "0",
                                            }}
                                          />
                                        </Button>
                                        <Button
                                          variant={`link p-0 ml-2 d-none`}
                                          ref={(el) =>
                                            (handleAddProductConditionProductRef.current[
                                              index
                                            ] = el)
                                          }
                                          onClick={() => {
                                            // console.log("errors",errors);
                                            // if (
                                            //   errors
                                            //   errors.product_condition &&
                                            //   errors.product_condition[index]
                                            // ) {
                                            //   console.log(
                                            //     "errors.product_condition",
                                            //     errors
                                            //   );
                                            //   return;
                                            // }

                                            if (
                                              values?.product_condition
                                                ?.length ===
                                              remainingReceivedQuantity
                                            ) {
                                              return;
                                            }

                                            const lastProductCondition =
                                              values?.product_condition[
                                                values?.product_condition
                                                  ?.length - 1
                                              ];
                                            const {
                                              productId,
                                              locationId,
                                              selectedProduct,
                                              selectedLocation,
                                              expiry_date,
                                            } = lastProductCondition || {};
                                            const nextProduct = getNextProduct(
                                              values?.product_condition
                                            );
                                            const obj = {
                                              barcode_no: "",
                                              productId,
                                              locationId,
                                              expiry_date,
                                              condition: true,
                                              quantity: 1,
                                              selectedProduct,
                                              selectedLocation,
                                            };
                                            product_condition.insert(
                                              values?.product_condition?.length,
                                              obj
                                            );
                                          }}
                                        ></Button>
                                      </td>
                                    </tr>
                                  )
                                )
                              }
                            />
                          )}

                          {!isAddPrductTable && (
                            <tr>
                              <td colSpan={6}>
                                <div className="d-grid gap-2">
                                  <Button
                                    variant="gray btn-icon btn-block"
                                    onClick={() => {
                                      if (
                                        values.product_condition.length === 0
                                      ) {
                                        const expiryDate = firstProduct?.product
                                          ?.shelf_life
                                          ? moment(new Date())
                                              .add(
                                                firstProduct?.product
                                                  ?.shelf_life,
                                                "days"
                                              )
                                              .toDate()
                                          : "";
                                        const obj = {
                                          barcode_no: "",
                                          productId: firstProduct?.productId,
                                          locationId: firstLocation?.id,
                                          expiry_date: expiryDate,
                                          condition: true,
                                          quantity: 1,
                                          selectedProduct: firstProduct,
                                          selectedLocation: firstLocation,
                                        };
                                        values.product_condition.push(obj);
                                      }
                                      setAddPrductTable(true);
                                    }}
                                  >
                                    <Plus
                                      size={16}
                                      style={{ color: "#333333" }}
                                    />
                                    <FormattedMessage id="TITLE.ADD.PRODUCT" />
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>

                      {isAddPrductTable && (
                        <Button
                          ref={handleAddNewReceiveItemRef}
                          variant="link btn-icon text-primary px-0 fs-14"
                          onClick={() => {
                            // handleAddProductConditionProductRef.current.click();
                            if (
                              handleAddProductConditionProductRef.current &&
                              handleAddProductConditionProductRef.current
                                .length > 0
                            ) {
                              handleAddProductConditionProductRef.current[0].click();
                            }
                          }}
                        >
                          <Plus size={14} className="text-primary" />{" "}
                          <FormattedMessage id="TITLE.ADD.NEW.RECEIVE.ITEM" />
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <div className="mt-3">
                    <Form.Group>
                      <Form.Label htmlFor="notes">
                        <FormattedMessage id="TITLE.NOTES.FOR.INTERNAL.USE" />
                      </Form.Label>
                      <Form.Control
                        id="notes"
                        name="notes"
                        as="textarea"
                        className="form-control"
                        resize="none"
                        onChange={(event) => {
                          const value = event.target.value;
                          setFieldValue("notes", value);
                        }}
                        placeholder={intl.formatMessage({
                          id: "TITLE.ENTER.NOTES",
                        })}
                        value={values?.notes}
                        maxLength={255}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className="column-footer">
                <Button
                  variant="success mr-3 btn-inventory"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    handleSubmit();
                  }}
                  disabled={isLoading}
                >
                  <FormattedMessage id="TITLE.SAVE" />
                  {isLoading && <ButtonLoader className="mr-1 ml-1" />}
                </Button>
                <Button
                  variant="tertiary text-dark btn-inventory"
                  onClick={() => {
                    onClickofcancel();
                  }}
                >
                  <FormattedMessage id="TITLE.CANCEL" />
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
      {isViewPOProductReceiveModalOpen && (
        <ViewPOProductForReceiveModal
          isViewPOProductReceiveModalOpen={isViewPOProductReceiveModalOpen}
          handleCloseOfViewPOProductReceiveModalHide={
            handleCloseOfViewPOProductReceiveModalHide
          }
          purchaseOrderProductList={purchaseOrderProductList}
        />
      )}
    </>
  );
}

export default ReceiveInwardForm;
