import React, { useState } from "react";
import { Formik } from "formik";
import { Search, RefreshCw } from "react-feather";
import { Form, Button } from "react-bootstrap";
export function CityFilter({ filterValues }) {
  const [filterStatus, setFilterStatus] = useState(" ");
  const applyFilter = (values) => {
    filterValues(values);
  };

  return (
    <>
      <Form className="form form-label-right filter-card">
        <Form.Group className="form-group mb-md-0 filter-form-group">
          <div className="filter-item">
            <div className="">
              <Form.Control
                type="text"
                className="form-control"
                name="city_name"
                placeholder="Search City Name"
              />
            </div>
            <div className="">
              <Form.Control
                as="select"
                className="select-control"
                onChange={(event) => {
                  setFilterStatus(event.target.value);
                }}
                value={filterStatus}
                name="status"
              >
                <option value="">Select Status</option>
                <option value="active">Active</option>
                <option value="Inactive">Inactive</option>
              </Form.Control>
            </div>
          </div>
          <div className="filter-action d-flex align-items-center mt-3">
            <Button
              variant="secondary d-flex align-items-center btn-inventory mr-2"
              onClick={() => {
                // TODO Pass filter value to city page for filter and search
                applyFilter();
              }}
            >
              <Search size={20} style={{ color: "#fff", marginRight: "0" }} />
            </Button>
            <Button
              variant="tertiary d-flex align-items-center btn-inventory"
              onClick={() => {
                // TODO Reset Filter Value and Pass filter value to city page for filter and search
              }}
            >
              <RefreshCw
                size={20}
                style={{ color: "#CAA25C", marginRight: "0" }}
              />
            </Button>
          </div>
        </Form.Group>
      </Form>
    </>
  );
}
