//

import React, { useContext, useState } from "react";
import { Modal, Col, Row, Table } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { MENUITEMS } from "../constants/menu";
import {
  amountFormatter,
  capitalizeFirstLetter,
  getProductMediaUrl,
  getProductStatusTextClass,
  setActiveMenu,
} from "../constants/utils";
import { ImageWrapper } from "../helper/ImageWrapper";
import { UIContext } from "../helper/UIContext";

export function ViewPOProductForReceiveModal({
  isViewPOProductReceiveModalOpen,
  handleCloseOfViewPOProductReceiveModalHide,
  purchaseOrderProductList,
}) {
  const history = useHistory();
  const uiContext = useContext(UIContext);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);

  const renderProductImage = (productItem) => {
    const { product } = productItem || {};
    const { product_media } = product || {};
    const { name } = product_media || {};
    return getProductMediaUrl(name);
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  return (
    <>
      <Modal
        size="md"
        show={isViewPOProductReceiveModalOpen}
        onHide={handleCloseOfViewPOProductReceiveModalHide}
        aria-labelledby="example-modal-sizes-title-lg"
        dialogClassName="inventory-small-modal"
        scrollable={true}
      >
        <Modal.Header
          closeButton
          className="d-flex justify-content-between align-items-center pr-2 border-bottom-0 order"
        >
          <h3 className="modal-title" id="exampleModalLabel2">
            <FormattedMessage id="TITLE.VIEW.PO.PRODUCTS" />
          </h3>
        </Modal.Header>
        <Modal.Body className="p-4">
          <Row>
            <Col md={12}>
              <Table responsive className="viewpo-table">
                <thead>
                  <tr>
                    <th style={{ Width: "28%" }}>
                      <FormattedMessage id="TITLE.PRODUCT" />
                    </th>
                    <th style={{ Width: "15%" }}>
                      <FormattedMessage id="TITLE.ORDERED" />
                    </th>
                    <th style={{ Width: "20%" }}>
                      <FormattedMessage id="TITLE.STATUS" />
                    </th>
                    <th style={{ Width: "17%" }}>
                      <FormattedMessage id="TITLE.RATE" />
                    </th>
                    <th style={{ Width: "20%" }}>
                      <FormattedMessage id="TITLE.AMOUNT" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {purchaseOrderProductList &&
                    purchaseOrderProductList.map((product, index) => (
                      <tr key={index}>
                        <td>
                          <div className="table-product-view">
                            <div className="product-thumb">
                              <div>
                                <ImageWrapper
                                  className={
                                    "img-radius align-top blur-up lazyloaded"
                                  }
                                  src={renderProductImage(product)}
                                />
                              </div>
                            </div>
                            <div className="product-name pl-3">
                              <Link
                                to={`/inventoryproducts/view-inventoryproduct-details/${product?.product?.id}`}
                                className="text-primary fs-14 mb-2 text-capitalize"
                                onClick={() => {
                                  const path = `/inventoryproducts/view-inventoryproduct-details/${product?.product?.id}`;
                                  onClickChangeRoute(path);
                                }}
                              >
                                {capitalizeFirstLetter(product?.product?.name)}
                              </Link>
                              <div className="small-description small-text text-dark">
                                {product.description}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-nowrap">
                          {product?.quantity}{" "}
                          {product?.product?.productunit?.name} <br />
                          <div className="small-description small-text text-dark">
                            <FormattedMessage id="TITLE.RECEIVED.QUANTITY" /> :{" "}
                            {product.received_quantity}
                          </div>
                        </td>
                        <td
                          className={`${getProductStatusTextClass(
                            product?.product_status
                          )} text-nowrap`}
                        >
                          {product?.product_status}
                        </td>
                        <td className="text-nowrap">
                          {amountFormatter(product?.price)}
                        </td>
                        <td className="text-nowrap">
                          {amountFormatter(product?.totalamount)}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
