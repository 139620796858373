import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import {
  amountFormatter,
  capitalizeFirstLetter,
  getProductMediaUrl,
} from "../../../constants/utils";
import { FormattedMessage, useIntl } from "react-intl";
import { useLang } from "../../../i18n";
import { USER_DETAILS } from "../../../constants/const";
import { ImageWrapper } from "../../../helper/ImageWrapper";
export function DefaultProductView({ productDetails, id, POSummary }) {
  const selectedLang = useLang();
  const {
    name,
    category,
    price,
    stock_keeping_unit,
    status,
    opening_stock,
    reorder_level,
    adjust_stock,
    shelf_life,
    description,
    available_quantity,
    total_quantity_adjusted,
    productunit,
    product_media,
  } = productDetails || {};
  const intl = useIntl();
  const { new_quantity_on_hand, quantity_adjusted } = adjust_stock || {};
  const { name: productUnitName } = productunit || {};
  const { name: categoryName } = category || {};
  const [productMedia] = product_media || [];
  const { name: productMediaName } = productMedia || {};
  const [data] = POSummary || [];
  let chartLables = [];
  let chartPO = [];
  let chartDC = [];
  const { po_details, dc_details, label } = data || {};
  const chartPOData = po_details;
  const chartDCData = dc_details;
  const labels = label;

  if (labels) {
    if (selectedLang === "ar") {
      chartLables = labels.reverse();
      chartPO = chartPOData.reverse();
      chartDC = chartDCData.reverse();
    } else {
      chartLables = labels;
      chartPO = chartPOData;
      chartDC = chartDCData;
    }
  }

  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const { role } = userDetails || {};
  const { name: userRole } = role || {};

  // PO Summary Graph
  const summaryChart = {
    responsive: true,
    labels: chartLables,
    datasets: [
      {
        label: intl.formatMessage({
          id: "TITLE.PO.AMOUNT",
        }),
        lagend: "none",
        data: chartPOData,
        backgroundColor: "transparent",
        borderColor: "rgba(0, 153, 112, 1)",
      },
      {
        label: intl.formatMessage({
          id: "TITLE.DC.AMOUNT",
        }),
        lagend: "none",
        data: chartDCData,
        backgroundColor: "transparent",
        borderColor: "rgba(153, 0, 41, 1)",
      },
    ],
  };

  return (
    <>
      <div className="view-body">
        <Row className="mt-4 mb-5">
          <Col md={6} style={{ paddingRight: "30px", paddingBottom: "80px" }}>
            <div
              style={{
                border: "1px solid #eee",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <ImageWrapper
                className={"img-radius align-top blur-up lazyloaded img-fluid"}
                src={getProductMediaUrl(productMediaName)}
              />
            </div>
          </Col>
          <Col md={6} lg={6}>
            <div>
              <h3 className="modal-title mb-2">
                <FormattedMessage id="TITLE.PRIMARY.DETAILS" />
              </h3>
            </div>
            <div className="address">
              <Row>
                <Col md={4} xs={6}>
                  <h6 className="text-dark">
                    <FormattedMessage id="TITLE.CATEGORY" /> :
                  </h6>
                </Col>
                <Col md={8} xs={6} className="text-blck">
                  {capitalizeFirstLetter(categoryName)}
                </Col>
              </Row>
              <Row>
                <Col md={4} xs={6}>
                  <h6 className="text-dark">
                    <FormattedMessage id="TITLE.NAME" /> :
                  </h6>
                </Col>
                <Col md={8} xs={6} className="text-blck">
                  {capitalizeFirstLetter(name)}
                </Col>
              </Row>
              <Row>
                <Col md={4} xs={6}>
                  <h6 className="text-dark">
                    <FormattedMessage id="TITLE.STATUS" /> :
                  </h6>
                </Col>
                <Col md={8} xs={6} className="text-blck">
                  {status === 0
                    ? intl.formatMessage({ id: "TITLE.INACTIVE" })
                    : intl.formatMessage({ id: "TITLE.ACTIVE" })}
                </Col>
              </Row>
              <Row>
                <Col md={4} xs={6}>
                  <h6 className="text-dark">
                    <FormattedMessage id="TITLE.PRICE" /> :
                  </h6>
                </Col>
                <Col md={8} xs={6} className="text-blck">
                  {amountFormatter(price)}
                </Col>
              </Row>
              <Row>
                <Col md={4} xs={6}>
                  <h6 className="text-dark">
                    <FormattedMessage id="TITLE.SKU" /> :
                  </h6>
                </Col>
                <Col md={8} xs={6} className="text-blck">
                  {stock_keeping_unit ? stock_keeping_unit : "-"}
                </Col>
              </Row>
              <Row>
                <Col md={4} xs={6}>
                  <h6 className="text-dark">
                    <FormattedMessage id="TITLE.PRODUCT.UNIT" /> :
                  </h6>
                </Col>
                <Col md={8} xs={6} className="text-blck">
                  {productUnitName}
                </Col>
              </Row>
              <Row>
                <Col md={4} xs={6}>
                  <h6 className="text-dark">
                    <FormattedMessage id="TITLE.DESCRIPTION" />
                  </h6>
                </Col>
                <Col md={8} xs={6} className="text-blck">
                  {description ? description : "-"}
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={6}>
            <div>
              <h3 className="modal-title mb-2">
                <FormattedMessage id="TITLE.STOCK.DETAILS" />
              </h3>
            </div>
            <div className="address">
              <Row>
                <Col md={6} xs={6}>
                  <h6 className="text-dark">
                    <FormattedMessage id="TITLE.OPENING.STOCK" />
                  </h6>
                </Col>
                <Col md={6} xs={6} className="text-blck">
                  {opening_stock}
                </Col>
              </Row>
              <Row>
                <Col md={6} xs={6}>
                  <h6 className="text-dark">
                    <FormattedMessage id="TITLE.REORDER.LEVEL" />
                  </h6>
                </Col>
                <Col md={6} xs={6} className="text-dark">
                  {reorder_level ? reorder_level : "-"}
                </Col>
              </Row>
              {/* <Row>
                <Col md={6} xs={6}>
                  <h6 className="text-dark">
                    <FormattedMessage id="TITLE.PERISHABLE.DATE" />
                  </h6>
                </Col>
                <Col md={6} xs={6} className="text-dark">
                  <Moment date={parishable_date} format={DD_MM_YYYY} />
                </Col>
              </Row> */}
              {/* <Row>
                <Col md={6} xs={6}>
                  <h6 className="text-dark">
                    <FormattedMessage id="TITLE.ADJUST.STOCK" />
                  </h6>
                </Col>
                <Col md={6} xs={6} className="text-dark">
                  {total_quantity_adjusted ? total_quantity_adjusted : "-"}
                </Col>
              </Row> */}
              <Row>
                <Col md={6} xs={6}>
                  <h6 className="text-dark">
                    <FormattedMessage id="TITLE.STOCK.ON.HAND" />
                  </h6>
                </Col>
                <Col md={6} xs={6} className="text-dark">
                  {available_quantity}
                </Col>
              </Row>
              <Row>
                <Col md={6} xs={6}>
                  <h6 className="text-dark">
                    <FormattedMessage id="TITLE.SHELF.LIFE.DAYS" />
                  </h6>
                </Col>
                <Col md={6} xs={6} className="text-dark">
                  {shelf_life ? shelf_life : "-"}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        {userRole !== "Admin" && (
          <Table responsive className="viewpo-table">
            <thead>
              <tr>
                <th>
                  {" "}
                  <FormattedMessage id="TITLE.PURCHASE.ORDERS.DELIVERY.CHALLAN.SUMMARY" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <>
                    <div className="po-dc-chart">
                      <Line
                        data={summaryChart}
                        width={700}
                        height={350}
                        options={{
                          scales: {
                            xAxes: [
                              {
                                // position: "right", // right-align axis.
                              },
                            ],
                            yAxes: [
                              {
                                position:
                                  selectedLang === "ar" ? "right" : "left", // right-align axis.
                              },
                            ],
                          },
                        }}
                      />
                    </div>
                  </>
                </td>
              </tr>
            </tbody>
          </Table>
        )}
      </div>
    </>
  );
}

export default DefaultProductView;
