import React, { useEffect, useState } from "react";
import { Button, Modal, Col, Form } from "react-bootstrap";
import { Trash2 } from "react-feather";
import { ButtonLoader } from "../../../helper/ButtonLoader";
import { FormattedMessage, useIntl } from "react-intl";
import {
  ChevronDownMaster,
  DropdownMaster,
  DropdownIndicatorMaster,
} from "../../../helper/helper";
import CreatableSelect from "react-select/creatable";
import ButtonAtl from "@atlaskit/button";
import { capitalizeFirstLetter } from "../../../constants/utils";
import { AddNewCategoryModal } from "../../category-module/add-new-category/AddNewCategoryModal";

export function DeleteCategoryModal({
  title,
  description,
  reassignDescription,
  id,
  onCloseModal,
  isOpen,
  // onClickOfDeleteButton,
  categoryList,
  formikDeleteCategory,
  categoryDetails,
  isLoading,
  addNewCategoryModalFromDelete,
}) {
  const intl = useIntl();
  const [isOpenCategory, setOpenCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isOpenAddNewCategoryModal, setOpenAddNewCategoryModal] =
    useState(false);
  const [searchedCategoryName, setSearchedCategoryName] = useState("");
  const [categoryListFiltered, setCategoryListFiltered] = useState([]);

  useEffect(() => {
    const { id: categoryId } = categoryDetails || {};
    const clist =
      categoryList &&
      categoryList
        .map((category) => {
          const { name, id } = category || {};
          category.value = id;
          category.label = name;
          return category;
        })
        .filter((category) => category.id !== categoryId);
    setCategoryListFiltered(clist);
  }, [categoryList]);

  const toggleOpenCategory = () => {
    setOpenCategory(!isOpenCategory);
  };

  const addNewCategoryModalShow = () => {
    setOpenAddNewCategoryModal(true);
  };

  const addNewCategoryModalHide = () => {
    setOpenAddNewCategoryModal(false);
  };

  const onSelectChange = (e) => {
    toggleOpenCategory();
    if (e) {
      setSelectedCategory(e);
      formikDeleteCategory.setFieldValue("categoryId", e.value);
    } else {
      setSelectedCategory(null);
      formikDeleteCategory.setFieldValue("categoryId", "");
    }
  };

  const formatCreateLabel = (inputValue) => {
    setSearchedCategoryName(capitalizeFirstLetter(inputValue));
    return (
      "+" +
      intl.formatMessage({
        id: "TITLE.ADD.NEW.CATEGORY",
      })
    );
  };

  const handleInputChange = (event) => {};

  const handleCloseOfAddNewCategoryModal = () => {
    addNewCategoryModalHide();
    addNewCategoryModalFromDelete();
  };

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onCloseModal}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <form
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            window.scrollTo(0, 0);
            formikDeleteCategory.handleSubmit();
          }}
          onReset={() => {
            formikDeleteCategory.handleReset();
          }}
        >
          <Modal.Body>
            <div className="text-center p-3">
              <h3 className="modal-title text-center mb-3 text-danger">
                {`${id}`}
              </h3>
              <Trash2
                size={60}
                className="text-danger mb-2"
                style={{ strokeWidth: "1.5px" }}
              ></Trash2>
              <h3 className="modal-title text-center mb-3 text-danger">
                {`${title}`}
              </h3>
              <p className="text-center text-dark mb-0">{description}</p>
              <p className="text-center text-dark mt-2 mb-0">
                {reassignDescription}
              </p>
            </div>

            <div className="text-center p-3">
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor="category">
                    <FormattedMessage id="TITLE.SELECT.CATEGORY" />
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <DropdownMaster
                    isOpen={isOpenCategory}
                    onClose={toggleOpenCategory}
                    target={
                      <ButtonAtl
                        iconAfter={<ChevronDownMaster />}
                        onClick={() => {
                          toggleOpenCategory();
                        }}
                        isSelected={isOpenCategory}
                        className="form-control w-100 bg-white custom-select-btn mb-0"
                      >
                        {selectedCategory ? (
                          `${selectedCategory.label}`
                        ) : (
                          <FormattedMessage id="TITLE.SELECT.CATEGORY" />
                        )}
                      </ButtonAtl>
                    }
                  >
                    <CreatableSelect
                      onChange={(e) => onSelectChange(e)}
                      onInputChange={handleInputChange}
                      options={categoryListFiltered}
                      createOptionPosition="top"
                      formatCreateLabel={formatCreateLabel}
                      isSearchable
                      onCreateOption={() => {
                        addNewCategoryModalShow();
                        setOpenCategory(false);
                      }}
                      autoFocus
                      backspaceRemovesValue={true}
                      components={{
                        DropdownIndicatorMaster,
                        IndicatorSeparator: null,
                      }}
                      controlShouldRenderValue={true}
                      hideSelectedOptions={false}
                      isClearable={true}
                      menuIsOpen
                      placeholder={intl.formatMessage({
                        id: "TITLE.SELECT.CATEGORY",
                      })}
                      tabSelectsValue={true}
                      value={selectedCategory}
                      className="react-custom-select-container"
                      classNamePrefix="react-select"
                    />
                  </DropdownMaster>
                  {formikDeleteCategory.touched.categoryId &&
                  formikDeleteCategory.errors.categoryId ? (
                    <div className="text-danger">
                      {formikDeleteCategory.errors.categoryId}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
            </div>

            <div className="d-flex align-items-center justify-content-center mb-3">
              <Button
                type="submit"
                variant="danger btn-inventory mr-3 btn-inventory"
                disabled={isLoading}
              >
                <FormattedMessage id="TITLE.YES.DELETE" />
                {isLoading && <ButtonLoader className="mr-1 ml-1" />}
              </Button>
              <Button
                variant="tertiary btn-inventory text-dark btn-inventory"
                onClick={() => onCloseModal()}
              >
                <FormattedMessage id="TITLE.CANCEL" />
              </Button>
            </div>
          </Modal.Body>
        </form>
      </Modal>
      {isOpenAddNewCategoryModal && (
        <AddNewCategoryModal
          searchedCategoryName={searchedCategoryName}
          open={isOpenAddNewCategoryModal}
          onCloseModal={addNewCategoryModalHide}
          handleCloseOfAddNewCategoryModal={handleCloseOfAddNewCategoryModal}
        />
      )}
    </>
  );
}
