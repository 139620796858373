import React, { useEffect, useMemo, useState } from "react";
import { Card, Button, Spinner, ButtonGroup } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { Upload, Filter, Plus } from "react-feather";
import { CityFilter } from "./city-filter/CityFilter";
import { CityTable } from "./city-table/CityTable";
import { useCityUIContext } from "./CityUIContext";

export function CityCard({
  isLoading,
  cityList,
  handleFilterValues,
  filterValues,
  loadingExportButton,
  exportCSVButton,
  cityListExport,
  handleExportButton,
  cityListTotalCount,
  currentPage,
  setCurrentPage,
  onClickOfCreateNew,
}) {
  const cityUIContext = useCityUIContext();
  const cityUIProps = useMemo(() => {
    return {
      ids: cityUIContext.ids,
      queryParams: cityUIContext.queryParams,
      setQueryParams: cityUIContext.setQueryParams,
      openViewDialog: cityUIContext.openViewDialog,
      openEditDialog: cityUIContext.openEditDialog,
      openDeleteDialog: cityUIContext.openDeleteDialog,
    };
  }, [cityUIContext]);

  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isPageLoaderShow, setPageLoaderShow] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setPageLoaderShow(false);
    }, 500);
  }, []);

  return (
    <>
      <Card className="card-custom card-stretch make-payment-wrapper">
        {isPageLoaderShow && (
          <div className="section-loader">
            <Spinner animation="grow" size="sm" className="mr-1" />
            <Spinner animation="grow" size="sm" className="mr-1" />
            <Spinner animation="grow" size="sm" className="mr-1" />
            <Spinner animation="grow" size="sm" className="mr-1" />
            <Spinner animation="grow" size="sm" className="mr-1" />
          </div>
        )}
        <Card.Header className="align-items-center d-flex justify-content-between">
          <h3 className="card-title page-title text-black mb-0">Cities</h3>
          <div className="d-flex">
            <ButtonGroup className="mr-3">
              <Button
                variant="tertiary d-flex align-items-center btn-inventory"
                onClick={() => {
                  setFilterOpen(!isFilterOpen);
                }}
              >
                <Filter size={16} style={{ color: "#CAA25C" }} />{" "}
                <span className="d-none d-md-flex">
                  {isFilterOpen ? "Remove" : "Filter"}
                </span>
              </Button>
              <CSVLink
                ref={exportCSVButton}
                className="btn btn-tertiary d-flex align-items-center btn-inventory"
                headers={[
                  { label: "City ID", key: "id" },
                  { label: "City Name", key: "name" },
                  { label: "State", key: "state" },
                  { label: "Status", key: "status" },
                ]}
                data={cityListExport}
                filename={`cities-export-${new Date().getTime()}.csv`}
              >
                <Upload size={16} style={{ color: "#CAA25C" }} />
                <span className="d-none d-md-flex">Export</span>
              </CSVLink>
            </ButtonGroup>
            <Button
              variant="primary d-flex align-items-center btn-inventory"
              onClick={() => {
                onClickOfCreateNew();
              }}
            >
              <Plus size={16} style={{ color: "#CAA25C" }} />
              <span className="d-none d-md-flex">Create New</span>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          {isFilterOpen && <CityFilter filterValues={handleFilterValues} />}
          <CityTable
            isLoading={isLoading}
            cityList={cityList}
            filterValues={filterValues}
            cityListTotalCount={cityListTotalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Card.Body>
      </Card>
    </>
  );
}
