import React, { createContext, useContext, useCallback } from "react";
import { isEqual, isFunction } from "lodash";

const ProductUnitUIContext = createContext();

export function useProductUnitUIContext() {
  return useContext(ProductUnitUIContext);
}

export const ProductUnitUIConsumer = ProductUnitUIContext.Consumer;

export function ProductUnitUIProvider({
  productUnitUIEvents,
  children,
  queryParams,
  setQueryParamsBase,
  ids,
  setIds,
}) {
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    openViewDialog: productUnitUIEvents.openViewDialog,
    openEditDialog: productUnitUIEvents.openEditDialog,
    openDeleteDialog: productUnitUIEvents.openDeleteDialog,
  };

  return (
    <ProductUnitUIContext.Provider value={value}>
      {children}
    </ProductUnitUIContext.Provider>
  );
}
