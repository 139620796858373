import React, { useEffect, useMemo, useState } from "react";
import { Card, Button, Spinner, ButtonGroup } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { Upload, Filter, Plus } from "react-feather";
import { CountryFilter } from "./country-filter/CountryFilter";
import { CountryTable } from "./country-table/CountryTable";
import { useCountryUIContext } from "./CountryUIContext";

export function CountryCard({
  isLoading,
  countryList,
  handleFilterValues,
  filterValues,
  loadingExportButton,
  exportCSVButton,
  countryListExport,
  handleExportButton,
  countryListTotalCount,
  currentPage,
  setCurrentPage,
  onClickOfCreateNew,
}) {
  const countryUIContext = useCountryUIContext();
  const countryUIProps = useMemo(() => {
    return {
      ids: countryUIContext.ids,
      queryParams: countryUIContext.queryParams,
      setQueryParams: countryUIContext.setQueryParams,
      openViewDialog: countryUIContext.openViewDialog,
      openEditDialog: countryUIContext.openEditDialog,
      openDeleteDialog: countryUIContext.openDeleteDialog,
    };
  }, [countryUIContext]);

  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isPageLoaderShow, setPageLoaderShow] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setPageLoaderShow(false);
    }, 500);
  }, []);

  return (
    <>
      <Card className="card-custom card-stretch make-payment-wrapper">
        {isPageLoaderShow && (
          <div className="section-loader">
            <Spinner animation="grow" size="sm" className="mr-1" />
            <Spinner animation="grow" size="sm" className="mr-1" />
            <Spinner animation="grow" size="sm" className="mr-1" />
            <Spinner animation="grow" size="sm" className="mr-1" />
            <Spinner animation="grow" size="sm" className="mr-1" />
          </div>
        )}
        <Card.Header className="align-items-center d-flex justify-content-between">
          <h3 className="card-title page-title text-black mb-0">Countries</h3>
          <div className="d-flex">
            <ButtonGroup className="mr-2">
              <Button
                variant="tertiary d-flex align-items-center btn-inventory"
                onClick={() => {
                  setFilterOpen(!isFilterOpen);
                }}
              >
                <Filter size={16} style={{ color: "#CAA25C" }} />{" "}
                <span className="d-none d-md-flex">
                  {isFilterOpen ? "Remove" : "Filter"}
                </span>
              </Button>
              <CSVLink
                ref={exportCSVButton}
                className="btn btn-tertiary d-flex align-items-center btn-inventory"
                headers={[
                  { label: "Country ID", key: "id" },
                  { label: "Country Name", key: "name" },
                  { label: "Status", key: "status" },
                ]}
                data={countryListExport}
                filename={`countries-export-${new Date().getTime()}.csv`}
              >
                <Upload size={16} style={{ color: "#CAA25C" }} />
                <span className="d-none d-md-flex">Export</span>
              </CSVLink>
            </ButtonGroup>
            <Button
              variant="primary d-flex align-items-center btn-inventory"
              onClick={() => {
                onClickOfCreateNew();
              }}
            >
              <Plus size={16} style={{ color: "#CAA25C" }} /> 
              <span className="d-none d-md-flex">Create New</span>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          {isFilterOpen && <CountryFilter filterValues={handleFilterValues} />}
          <CountryTable
            isLoading={isLoading}
            countryList={countryList}
            filterValues={filterValues}
            countryListTotalCount={countryListTotalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Card.Body>
      </Card>
    </>
  );
}
