import React, { useState, useEffect } from "react";
import { Button, Modal, Row, Col, Form, Spinner } from "react-bootstrap";
import Select from "react-select";
import { X, Check } from "react-feather";
import { ErrorModal } from "../components/common/modals/ErrorModal";
import { useFormik } from "formik";
import {
  getSingleProductUnitDetailsApi,
  editProductUnitApi,
} from "../axios/services/services";
import { EditProductUnitSchema } from "../helper/FormikSchema";
import { FormattedMessage, useIntl } from "react-intl";
import { getErrorMessageBasedOnLanguage } from "../constants/utils";
import { unauthorization } from "../constants/utils";

const statusName = [
  { value: "1", label: "Active" },
  { value: "0", label: "Inactive" },
];

let EditProductUnitInitialValues = {
  name: "",
};

export function EditUnitModal({
  onCloseModal,
  open,
  id,
  handleCloseOfEditNewProductUnitModal,
}) {
  const [selectedOption, setSelectedOption] = useState({});
  const [isLoadingSingleProductUnit, setLoadingSingleProductUnit] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [productUnitDetails, setProductUnitDetails] = useState({});
  const [isSaveLoading, setSaveLoading] = useState(false);
  const [categoryEdited, setProductUnitEdited] = useState(false);
  const intl = useIntl();
  const formikEditProductUnit = useFormik({
    initialValues: EditProductUnitInitialValues,
    validationSchema: EditProductUnitSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableSaveLoading();
      const { name } = values || {};
      const { value: status } = selectedOption;
      let obj = {
        id,
        name,
        status,
      };

      const editProductUnitResponse = await editProductUnitApi(obj);

      const { data, Error } = editProductUnitResponse || {};
      if (Error) {
        disableSaveLoading();
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setProductUnitEdited(true);
      window.scrollTo(0, 0);
      setTimeout(() => {
        disableSaveLoading();
        handleCloseOfEditNewProductUnitModal(data);
      }, 2500);
    },
  });

  async function getSingleProductUnitDetails() {
    enableLoadingSingleProductUnit();
    const singleProductUnitDetails = await getSingleProductUnitDetailsApi({
      id,
    });
    disableLoadingSingleProductUnit(false);
    const { Error, data } = singleProductUnitDetails || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setProductUnitDetails(data ? data : []);
    const { name, status } = data || {};
    const statusName = parseInt(status);
    if (statusName === 1) {
      setSelectedOption({ value: "1", label: "Active" });
    } else {
      setSelectedOption({ value: "0", label: "Inactive" });
    }
    // console.log(selectedOption);

    formikEditProductUnit.setFieldValue("name", name);
  }

  useEffect(() => {
    getSingleProductUnitDetails();
  }, []);

  const enableSaveLoading = () => {
    setSaveLoading(true);
  };

  const disableSaveLoading = () => {
    setSaveLoading(false);
  };

  const enableLoadingSingleProductUnit = () => {
    setLoadingSingleProductUnit(true);
  };

  const disableLoadingSingleProductUnit = () => {
    setLoadingSingleProductUnit(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  return (
    <>
      <Modal
        size="md"
        show={open}
        onHide={onCloseModal}
        dialogClassName="inventory-small-modal"
      >
        <Modal.Header
          className="d-flex justify-content-between align-items-center"
          toggle={onCloseModal}
        >
          <h3 className="modal-title" id="exampleModalLabel2">
            <FormattedMessage id="TITLE.EDIT.PRODUCT.UNIT" />
            {isLoadingSingleProductUnit && (
              <Spinner animation="border" size="sm" className="ml-1" />
            )}
          </h3>
          <Button
            variant="link p-0 btn-icon"
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <X size={20} className="text-dark mr-0 pr-0" />
          </Button>
        </Modal.Header>
        <Modal.Body className="pb-0">
          {categoryEdited && (
            <div className="notification icon inline success fs-16">
              <Check size={8} className="text-white"></Check>{" "}
              <FormattedMessage id="TITLE.PRODUCT.UNIT.HAS.BEEN.EDITED.SUCCESSFULLY" />
            </div>
          )}
          <Form autoComplete="off">
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor="name">
                    <FormattedMessage id="TITLE.PRODUCT.UNIT.NAME" />{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="name"
                    required
                    placeholder={intl.formatMessage({
                      id: "TITLE.PRODUCT.UNIT.NAME",
                    })}
                    {...formikEditProductUnit.getFieldProps("name")}
                  />
                  {formikEditProductUnit.touched.name &&
                  formikEditProductUnit.errors.name ? (
                    <div className="text-danger">
                      {formikEditProductUnit.errors.name}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor="status">
                    <FormattedMessage id="TITLE.STATUS" />
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    value={selectedOption}
                    onChange={(value) => {
                      handleOptionChange(value);
                    }}
                    isSearchable={true}
                    options={statusName}
                    placeholder={intl.formatMessage({
                      id: "TITLE.SELECT.STATUS",
                    })}
                    id="status"
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success mr-3 btn-inventory"
            onClick={() => {
              formikEditProductUnit.handleSubmit();
            }}
            disabled={isSaveLoading}
          >
            <FormattedMessage id="TITLE.SAVE" />
            {isSaveLoading && (
              <Spinner animation="border" size="sm" className="ml-1" />
            )}
          </Button>
          <Button
            type="button"
            variant="tertiary text-dark"
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <FormattedMessage id="TITLE.CANCEL" />
          </Button>
        </Modal.Footer>
      </Modal>
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
