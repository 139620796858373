import React, { useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { Check } from "react-feather";
import { useFormik } from "formik";
import { editProductApi } from "../../../axios/services/services";
import { ErrorModal } from "../../common/modals/ErrorModal";
import { EditProductAdjustStockSchema } from "../../../helper/FormikSchema";
import { getErrorMessageBasedOnLanguage } from "../../../constants/utils";
import { unauthorization } from "../../../constants/utils";
import { FormattedMessage, useIntl } from "react-intl";

let EditProductInitialValues = {
  reference_number: "",
  new_quantity_on_hand: "",
  quantity_adjusted: "",
  reason: "",
  comments: "",
};

export function AdjustStockForm({ adjustStockHide, productDetails, id }) {
  const intl = useIntl();
  const [ShelfLifeDate, setShelfLifeDate] = useState(new Date());
  const [productEdited, setProductEdited] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isSaveLoading, setSaveLoading] = useState(false);

  const { available_quantity } = productDetails || {};

  const formikEditProduct = useFormik({
    initialValues: EditProductInitialValues,
    validationSchema: EditProductAdjustStockSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableSaveLoading();
      const {
        reference_number,
        new_quantity_on_hand,
        quantity_adjusted,
        reason,
        comments,
      } = values || {};

      const adjust_stock = {
        id,
        date: ShelfLifeDate,
        reference_number,
        new_quantity_on_hand: parseInt(new_quantity_on_hand),
        quantity_adjusted: parseInt(quantity_adjusted),
        reason,
        comments,
      };

      const editProductResponse = await editProductApi({
        id,
        adjust_stock,
      });

      const { Error } = editProductResponse || {};
      if (Error) {
        disableSaveLoading();
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setProductEdited(true);
      window.scrollTo(0, 0);
      setTimeout(() => {
        disableSaveLoading();
        adjustStockHide()
      }, 2500);
    },
  });

  const enableSaveLoading = () => {
    setSaveLoading(true);
  };

  const disableSaveLoading = () => {
    setSaveLoading(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  return (
    <>
      <div className="column details-column">
        <div className="column-body with-footer custom-scrollbar">
          <div className="view-body">
            <Form autoComplete="off">
              {productEdited && (
                <div className="notification icon inline success fs-16">
                  <Check size={8} className="text-white"></Check>{" "}
                  <FormattedMessage id="TITLE.PRODUCT.SUCCESSFULLY.ADJUSTED" />
                </div>
              )}
              <Row className="mb-3">
                <Col md={4} lg={3}>
                  <span className="text-dark">
                    <FormattedMessage id="TITLE.DATE" />
                  </span>
                </Col>
                <Col md={4} lg={3}>
                  <ReactDatePicker
                    selected={ShelfLifeDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => setShelfLifeDate(date)}
                    placeholderText="DD/MM/YYYY"
                    className="form-control w-100"
                    closeOnScroll={(e) => e.target === document}
                  />
                </Col>
                {/* {formikEditProduct.touched.date &&
                formikEditProduct.errors.date ? (
                  <div className="text-danger">
                    {formikEditProduct.errors.date}
                  </div>
                ) : null} */}
              </Row>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column md="4" lg="3">
                  <FormattedMessage id="TITLE.REFERENCE.NUMBER" />{" "}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Col md={4} lg={3}>
                  <Form.Control
                    required=""
                    name="reference_number"
                    type="text"
                    className="form-control"
                    // placeholder="0123456789"
                    id="exampleInputEmail1"
                    placeholder={intl.formatMessage({
                      id: "TITLE.REFERENCE.NUMBER",
                    })}
                    {...formikEditProduct.getFieldProps("reference_number")}
                  />
                  {formikEditProduct.touched.reference_number &&
                  formikEditProduct.errors.reference_number ? (
                    <div className="text-danger">
                      {formikEditProduct.errors.reference_number}
                    </div>
                  ) : null}
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column md="4" lg="3">
                  <FormattedMessage id="TITLE.STOCK.ON.HAND" />
                </Form.Label>
                <Col md={4} lg={3}>
                  <Form.Control
                    required=""
                    name="stock_on_hand"
                    type="text"
                    className="form-control"
                    value={available_quantity}
                    disabled
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column md="4" lg="3">
                  <FormattedMessage id="TITLE.NEW.QUANTITY.ON.HAND" />
                  <span className="text-danger">*</span>
                </Form.Label>
                <Col md={4} lg={3}>
                  <Form.Control
                    name="new_quantity_on_hand"
                    type="number"
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder={intl.formatMessage({
                      id: "TITLE.NEW.QUANTITY.ON.HAND",
                    })}
                    value={formikEditProduct.values.new_quantity_on_hand}
                    onChange={(event) => {
                      const value = event.target.value;
                      const newQty = parseInt(value);
                      formikEditProduct.setFieldValue(
                        "new_quantity_on_hand",
                        newQty
                      );
                      const latestQty = newQty - available_quantity;
                      if (!isNaN(latestQty)) {
                        formikEditProduct.setFieldValue(
                          "quantity_adjusted",
                          latestQty
                        );
                      } else {
                        formikEditProduct.setFieldValue("quantity_adjusted", 0);
                      }
                    }}
                    onPaste={(evt) => {
                      const values = evt.clipboardData.getData("text");
                      const nonAlpha = values.replace(/[^\d.-]/g, "");
                      evt.preventDefault();
                      formikEditProduct.setFieldValue(
                        "quantity_adjusted",
                        nonAlpha
                      );
                    }}
                  />
                  {formikEditProduct.touched.new_quantity_on_hand &&
                  formikEditProduct.errors.new_quantity_on_hand ? (
                    <div className="text-danger">
                      {formikEditProduct.errors.new_quantity_on_hand}
                    </div>
                  ) : null}
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column md="4" lg="3">
                  <FormattedMessage id="TITLE.QUANTITY.ADJUSTED" />{" "}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Col md={4} lg={3}>
                  <Form.Control
                    required=""
                    name="quantity_adjusted"
                    type="number"
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder={intl.formatMessage({
                      id: "TITLE.EG.+10,-10",
                    })}
                    value={formikEditProduct.values.quantity_adjusted}
                    onChange={(event) => {
                      const value = event.target.value;
                      formikEditProduct.setFieldValue(
                        "quantity_adjusted",
                        value
                      );
                      const qtyAdjust = parseInt(value);
                      const latestQty = available_quantity + qtyAdjust;
                      if (!isNaN(latestQty)) {
                        formikEditProduct.setFieldValue(
                          "new_quantity_on_hand",
                          latestQty
                        );
                      } else {
                        formikEditProduct.setFieldValue(
                          "new_quantity_on_hand",
                          0
                        );
                      }
                    }}
                    onPaste={(evt) => {
                      const values = evt.clipboardData.getData("text");
                      const nonAlpha = values.replace(/[^\d.-]/g, "");
                      evt.preventDefault();
                      formikEditProduct.setFieldValue(
                        "new_quantity_on_handi",
                        nonAlpha
                      );
                    }}
                  />
                  {formikEditProduct.touched.quantity_adjusted &&
                  formikEditProduct.errors.quantity_adjusted ? (
                    <div className="text-danger">
                      {formikEditProduct.errors.quantity_adjusted}
                    </div>
                  ) : null}
                </Col>
              </Form.Group>

              <div className="mt-3">
                <Form.Group>
                  <Form.Label for="notes">
                    <FormattedMessage id="TITLE.REASON" />
                  </Form.Label>
                  <Form.Control
                    id="notes"
                    name="reason"
                    as="textarea"
                    className="form-control"
                    resize="none"
                    placeholder={intl.formatMessage({
                      id: "TITLE.ENTER.YOUR.REASON",
                    })}
                    {...formikEditProduct.getFieldProps("reason")}
                  />
                  {formikEditProduct.touched.reason &&
                  formikEditProduct.errors.reason ? (
                    <div className="text-danger">
                      {formikEditProduct.errors.reason}
                    </div>
                  ) : null}
                </Form.Group>
              </div>
              <div className="mt-3">
                <Form.Group>
                  <Form.Label for="notes">
                    <FormattedMessage id="TITLE.COMMENTS" />
                  </Form.Label>
                  <Form.Control
                    id="notes"
                    name="comments"
                    as="textarea"
                    className="form-control"
                    resize="none"
                    placeholder={intl.formatMessage({
                      id: "TITLE.ENTER.YOUR.COMMENTS",
                    })}
                    {...formikEditProduct.getFieldProps("comments")}
                  />
                  {formikEditProduct.touched.comments &&
                  formikEditProduct.errors.comments ? (
                    <div className="text-danger">
                      {formikEditProduct.errors.comments}
                    </div>
                  ) : null}
                </Form.Group>
              </div>
            </Form>
          </div>
        </div>
        <div className="column-footer">
          <Button
            variant="success mr-3 btn-inventory"
            onClick={() => {
              formikEditProduct.handleSubmit();
            }}
            disabled={isSaveLoading}
          >
            <FormattedMessage id="TITLE.SAVE" />
            {isSaveLoading && (
              <Spinner animation="border" size="sm" className="ml-1" />
            )}
          </Button>
          <Button
            variant="tertiary text-dark btn-inventory"
            onClick={() => {
              adjustStockHide();
            }}
          >
            <FormattedMessage id="TITLE.CANCEL" />
          </Button>
        </div>
      </div>
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}

export default AdjustStockForm;
