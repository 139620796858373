import React, { useEffect, useState } from "react";
import { InwardValuationCard } from "./InwardValuationCard";
import { InwardValuationUIProvider } from "./InwardValuationUIContext";
import { initialFilter } from "./InwardValuationUIHelpers";
import { ErrorModal } from "../../common/modals/ErrorModal";
import {
  getErrorMessageBasedOnLanguage,
  stringToDateWithFormat,
  unauthorization,
} from "../../../constants/utils";
import moment from "moment";
import {
  STR_CSV,
  STR_EXCEL,
  STR_PDF,
  YYYY_MM_DD,
} from "../../../constants/const";
import {
  reportInwardValuationApi,
  reportInwardValuationExportApi,
  reportInwardValuationPrintApi,
} from "../../../axios/services/services";

export function InwardValuationPage({ history }) {
  const [reportInwardValuation, setReportInwardValuation] = useState([]);
  const [reportInwardValuationTotalCount, setReportInwardValuationTotalCount] =
    useState(0);
    const [reportInwardValuationTotalAmount, setReportInwardValuationTotalAmount] =
    useState(0);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isLoadingFilter, setLoadingFilter] = useState(false);
  const [isFilter, setFilter] = useState(false);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const [pdfUrl, setPdfUrl] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(new Date());
  const [selectedDateOption, setselectedDateOption] = useState("This Month");

  async function getReportInwardValuation() {
    enableLoading();
    if (isFilter) {
      enableLoadingFilter();
    }
    const reportInwardValuationResponse = await reportInwardValuationApi({
      queryParams,
    });
    disableLoading();
    if (isFilter) {
      disableLoadingFilter();
    }
    setFilter(false);
    const { Error, data, startdate, enddate, count, Total } =
      reportInwardValuationResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setReportInwardValuation(data ? data : []);
    setStartDate(startdate);
    setEndDate(enddate);
    setReportInwardValuationTotalCount(count);
    setReportInwardValuationTotalAmount(Total);
  }

  useEffect(() => {
    getReportInwardValuation();
  }, [queryParams]);

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingFilter = () => {
    setLoadingFilter(true);
  };

  const disableLoadingFilter = () => {
    setLoadingFilter(false);
  };

  const inwardvaluationUIEvents = {
    openViewDialog: (row) => { },
    openEditDialog: (row) => { },
    openDeleteDialog: (row) => { },
  };

  // Excel, PDF, CSV
  const handleExport = (docType) => {
    getReportInwardValuationExport(docType);
  };

  const handlePrint = () => {
    getReportInwardValuationPrint();
  };

  async function getReportInwardValuationExport(docType) {
    if (isFilter) {
      enableLoadingFilter();
    }
    let extention = "";
    if (docType === "Excel") {
      extention = STR_EXCEL;
    }
    if (docType === "PDF") {
      extention = STR_PDF;
    }
    if (docType === "CSV") {
      extention = STR_CSV;
    }
    const reportInwardValuationResponse = await reportInwardValuationExportApi({
      queryParams,
      docType,
      filename: "inward-valuation" + new Date(),
      extention: extention,
    });
    if (isFilter) {
      disableLoadingFilter();
    }
    const { Error } = reportInwardValuationResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
  }

  async function getReportInwardValuationPrint() {
    const reportInwardValuationResponse = await reportInwardValuationPrintApi({
      queryParams,
    });

    const { Error } = reportInwardValuationResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    var fils = new Blob([reportInwardValuationResponse.res.data], {
      type: "application/pdf",
    });
    var fileURLs = URL.createObjectURL(fils);
    setPdfUrl(fileURLs);
    setTimeout(() => {
      const iframe = document.getElementById("myframe");
      const iframeWindow = iframe.contentWindow || iframe;
      iframe.focus();
      iframeWindow.print();
    }, 50);
  }

  const handleCLickOfFilterButtonFilter = async () => {
    setFilter(true);
  };

  const handleCLickOfResetButtonFilter = () => {
    setselectedDateOption("This Month");
  };

  const handleSelectedDateOption = (selectedDateOption) => {
    setselectedDateOption(selectedDateOption);
  };

  const handleClickOfDateFilterButton = (chosenLabel, fromDate, endDate) => {
    let label = chosenLabel;
    label = chosenLabel.toLowerCase();
    if (label === "last 7 days") {
      label = "lastweek";
    }
    if (label === "last 30 days") {
      label = "last30days";
    }
    if (label === "this month") {
      label = "thismonth";
    }
    if (label === "last month") {
      label = "lastmonth";
    }
    if (label === "last year") {
      label = "lastyear";
    }
    if (label === "custom range") {
      label = "customrange";
    }
    const dateFrom = stringToDateWithFormat(fromDate);
    const dateEnd = stringToDateWithFormat(endDate);
    const dateFromMoment = moment(dateFrom).format(YYYY_MM_DD);
    const dateEndMoment = moment(dateEnd).format(YYYY_MM_DD);

    const newQueryParams = { ...queryParams };
    const { filter } = newQueryParams || {};
    filter.date = label;
    filter.fromdate = dateFromMoment;
    filter.todate = dateEndMoment;
    newQueryParams.pageNumber = 1;
    setQueryParamsBase(newQueryParams);
  };

  return (
    <div>
      <>
        <InwardValuationUIProvider
          inwardvaluationUIEvents={inwardvaluationUIEvents}
          queryParams={queryParams}
          setQueryParamsBase={setQueryParamsBase}
          ids={ids}
          setIds={setIds}
        >
          <InwardValuationCard
            isLoading={isLoading}
            isLoadingFilter={isLoadingFilter}
            startDate={startDate}
            endDate={endDate}
            selectedDateOption={selectedDateOption}
            reportInwardValuation={reportInwardValuation}
            reportInwardValuationTotalCount={reportInwardValuationTotalCount}
            reportInwardValuationTotalAmount={reportInwardValuationTotalAmount}
            handleCLickOfFilterButtonFilter={handleCLickOfFilterButtonFilter}
            handleCLickOfResetButtonFilter={handleCLickOfResetButtonFilter}
            handleClickOfDateFilterButton={handleClickOfDateFilterButton}
            handleExport={handleExport}
            handlePrint={handlePrint}
            handleSelectedDateOption={handleSelectedDateOption}
          />
        </InwardValuationUIProvider>

        {isErrorModalOpen && (
          <ErrorModal
            message={errorMessage}
            isErrorModalOpen={isErrorModalOpen}
            hideErrorModal={handleErrorModalHide}
          />
        )}

        <iframe
          className="d-none"
          src={pdfUrl}
          name="myframe"
          id="myframe"
          title="myframe"
        ></iframe>
      </>
    </div>
  );
}
