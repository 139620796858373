/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import Moment from "react-moment";
import { DD_MM_YYYY } from "../constants/const";
const DeliveryDateTimeColumnFormatter = (cellContent, row, rowIndex) => {
  const { delivery_date } = row || {};
  const renderDate = () => {
    if (delivery_date)
      return <Moment date={delivery_date} format={DD_MM_YYYY} />;
    return "-";
  };
  return <>{renderDate()}</>;
};

export default DeliveryDateTimeColumnFormatter;
