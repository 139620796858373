/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { getStatusForActiveDeactiveClass } from "../constants/utils";
const StatusActiveDeactiveTypeFormatter = (cellContent, row, rowIndex) => {
  const { status } = row;
  const renderStatus = () => {
    return (
      <span
        className={`${getStatusForActiveDeactiveClass(
          status
        )} status-color text-capitalize`}
      >
        {status === 1 ? "Active" : "Inactive"}
      </span>
    );
  };

  return renderStatus();
};

export default StatusActiveDeactiveTypeFormatter;
