import React from "react";
import ViewSingleDetailModal from "../../../global-modal/ViewSingleDetailModal";

export function CustomerViewModal({ data, handleConfirmHide, isConfirmModal }) {
  const {
    id,
    contact_name,
    company_name,
    billing_address,
    shipping_address,
    notes,
    status,
    email,
    phone_number,
    currency,
    remarks,
  } = data;
  const dataArray = [];

  if (id) {
    dataArray.push({ key: "User Id", value: id });
  }

  if (contact_name) {
    dataArray.push({ key: "Name", value: contact_name });
  }

  if (email) {
    dataArray.push({ key: "Email", value: email });
  }

  if (phone_number) {
    dataArray.push({ key: "Phone Number", value: phone_number });
  }

  if (company_name) {
    dataArray.push({ key: "Company Name", value: company_name });
  }

  if (billing_address) {
    dataArray.push({ key: "Billing Address", value: billing_address });
  }

  if (shipping_address) {
    dataArray.push({ key: "Shipping Address", value: shipping_address });
  }

  const renderCustomerStatus = () => {
    let statusClass = "";
    statusClass =
      status === 1
        ? "badge badge-pill badge-success"
        : "badge badge-pill badge-warning";
    return (
      <span className={`${statusClass} status-color text-capitalize`}>
        {/* {status === 1 ? "Active" : "Deactive"} */}
        {status === "Active" ? "Active" : "Inactive"}
      </span>
    );
  };

  dataArray.push({
    key: "Customer status",
    value: renderCustomerStatus(),
  });

  if (currency) {
    dataArray.push({ key: "Currency", value: currency });
  }

  if (remarks) {
    dataArray.push({ key: "Remarks", value: remarks });
  }

  if (notes) {
    dataArray.push({ key: "Notes", value: notes });
  }

  // if (account_number) {
  //   dataArray.push({ key: "Account Number", value: account_number });
  // }
  // if (firstname && lastname) {
  //   dataArray.push({ key: "Name", value: renderUserName() });
  // }
  // if (account_number) {
  //   dataArray.push({ key: "Email", value: email });
  // }
  // if (cellnumber) {
  //   dataArray.push({ key: "Phone Number", value: cellnumber });
  // }
  // if (username) {
  //   dataArray.push({ key: "Username", value: username });
  // }
  // if (business_name) {
  //   dataArray.push({ key: "Business Name", value: business_name });
  // }
  // if (last_transaction_date) {
  //   dataArray.push({
  //     key: "Last Transaction date",
  //     value: renderLastTransactionDate(last_transaction_date),
  //   });
  // }

  // if (address_1 || address_2 || city || state || country || zip_code) {
  //   dataArray.push({
  //     key: "Address",
  //     value: renderUserAddress(),
  //   });
  // }

  // if (createdAt) {
  //   dataArray.push({
  //     key: "Joining Date",
  //     value: renderJoiningDate(),
  //   });
  // }

  // // TODO Replace social signin with content like google and facebook
  // dataArray.push({
  //   key: "Login Via",
  //   value: social_signin ? social_signin : "-",
  // });

  // dataArray.push({
  //   key: "is User Deleted?",
  //   value: renderUserStatus(),
  // });

  // TODO Add User's Bank Details

  return (
    <>
      <ViewSingleDetailModal
        handleConfirmHide={handleConfirmHide}
        isConfirmModal={isConfirmModal}
        title={`View User Details - ${contact_name}`}
        dataArray={dataArray}
      />
    </>
  );
}
