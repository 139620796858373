import React, { useMemo, useState } from "react";
import { Card, Button, ButtonGroup } from "react-bootstrap";
import { Upload, Filter, Plus } from "react-feather";
import { LocationTable } from "./location-table/LocationTable";
import { useLocationUIContext } from "./LocationUIContext";
import { CSVLink } from "react-csv";
import { LocationFilter } from "../location-module/location-filter/LocationFilter";
import { FormattedMessage } from "react-intl";
import { locationsListExportApi } from "../../axios/services/services";

export function LocationCard({
  isLoadingFilter,
  isLoading,
  isFilterOpen,
  locationList,
  exportCSVButton,
  transactionListExport,
  locationListTotalCount,
  currentPage,
  setCurrentPage,
  onClickOfCreateNew,
  handleClickOfRemoveFilterButton,
  handleCLickOfFilterButtonFilter,
}) {
  const locationUIContext = useLocationUIContext();
  const locationUIProps = useMemo(() => {
    return {
      ids: locationUIContext.ids,
      queryParams: locationUIContext.queryParams,
      setQueryParams: locationUIContext.setQueryParams,
      openViewDialog: locationUIContext.openViewDialog,
      openEditDialog: locationUIContext.openEditDialog,
      openDeleteDialog: locationUIContext.openDeleteDialog,
    };
  }, [locationUIContext]);

  const [locationsExportList, setLocationsExportList] = useState();

  async function handleExport() {
    const locationsListExportResponse = await locationsListExportApi({
      filename: "Location",
    });
    const { data } = locationsListExportResponse || {};
    setLocationsExportList(data);
  }

  return (
    <>
      <Card className="card-custom card-stretch make-payment-wrapper">
        <Card.Header className="align-items-center d-flex justify-content-between">
          <h3 className="card-title page-title text-black mb-0">
            <FormattedMessage id="TITLE.LOCATIONS" />
          </h3>
          <div className="d-flex">
            <ButtonGroup className="mr-3">
              <Button
                variant="tertiary d-flex align-items-center btn-inventory"
                onClick={() => {
                  handleClickOfRemoveFilterButton();
                }}
              >
                <Filter size={16} style={{ color: "#CAA25C" }} />{" "}
                <span className="d-none d-md-flex">
                  {isFilterOpen ? (
                    <FormattedMessage id="TITLE.REMOVE" />
                  ) : (
                    <FormattedMessage id="TITLE.FILTER" />
                  )}
                </span>
              </Button>

              <Button
                variant="tertiary d-flex align-items-center btn-inventory"
                onClick={() => {
                  handleExport();
                }}
              >
                <Upload size={16} style={{ color: "#CAA25C" }} />
                <span className="d-none d-md-flex">
                  <FormattedMessage id="TITLE.EXPORT" />
                </span>
              </Button>

              {/* <CSVLink
                ref={exportCSVButton}
                className="btn btn-tertiary d-flex align-items-center btn-inventory"
                headers={[
                  { label: "Store Location", key: "store_location" },
                  { label: "No. of Products", key: "no_of_products" },
                  { label: "Parent Location", key: "locationId.store_location" },
                  { label: "Status", key: "status_name" }
                ]}
                data={transactionListExport}
                filename={`locations-${new Date().getTime()}.csv`}
              >
                <Upload size={16} style={{ color: "#CAA25C" }} />
                <span className="d-none d-md-flex"><FormattedMessage id="TITLE.EXPORT" /></span>
              </CSVLink> */}
            </ButtonGroup>
            <Button
              variant="primary d-flex align-items-center btn-inventory"
              onClick={() => {
                onClickOfCreateNew();
              }}
            >
              <Plus size={16} style={{ color: "#CAA25C" }} />
              <span className="d-none d-md-flex">
                <FormattedMessage id="TITLE.CREATE.NEW" />
              </span>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          {isFilterOpen && (
            <LocationFilter
              isLoadingFilter={isLoadingFilter}
              handleCLickOfFilterButtonFilter={handleCLickOfFilterButtonFilter}
            />
          )}
          <LocationTable
            isLoading={isLoading}
            locationList={locationList}
            locationListTotalCount={locationListTotalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Card.Body>
      </Card>
    </>
  );
}
