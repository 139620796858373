import React, { useEffect, useState } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import { X, Check } from "react-feather";
import {
  cityListAllApi,
  editShippingAddressCustomerApi,
} from "../../axios/services/services";
import {
  capitalizeFirstLetter,
  getErrorMessageBasedOnLanguage,
  unauthorization,
} from "../../constants/utils";
import { useFormik } from "formik";
import { ButtonLoader } from "../../helper/ButtonLoader";
import { ErrorModal } from "../common/modals/ErrorModal";
import { EditCustomerShippingAddressSchema } from "../../helper/FormikSchema";
import { FormattedMessage } from "react-intl";

let EditCustomerShippingAddressInitialValues = {
  shippingAddress: "",
  shippingZipcode: "",
  shippingCity: "",
};

export function AddShippingAddressCustomerModal({
  onCloseModal,
  open,
  data,
  id,
  handleCloseOfEditShippingAddressCustomerModal,
}) {
  const [selectedShippingCity, setSelectedShippingCity] = useState("");
  const [cityList, setCityList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isSaveLoading, setSaveLoading] = useState(false);
  const [addressEdited, setAddressEdited] = useState(false);

  async function getCityList() {
    const cityListResponse = await cityListAllApi({});
    const { Error, data } = cityListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    const cities = data.map((city) => {
      const { id, name } = city || {};
      city.value = id;
      city.label = capitalizeFirstLetter(name);
      return city;
    });
    setCityList(cities ? cities : []);
  }

  useEffect(() => {
    getCityList();
    const { shippingaddress } = data || {};
    const { building_no, zipcode, city } = shippingaddress || {};
    const { id, name } = city || {};
    formikEditCustomer.setFieldValue(
      "shippingAddress",
      building_no ? building_no : ""
    );
    formikEditCustomer.setFieldValue("shippingZipcode", zipcode ? zipcode : "");
    formikEditCustomer.setFieldValue("shippingCity", id ? id : "");
    setSelectedShippingCity({ value: id, label: capitalizeFirstLetter(name) });
  }, []);

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const enableSaveLoading = () => {
    setSaveLoading(true);
  };

  const disableSaveLoading = () => {
    setSaveLoading(false);
  };

  const formikEditCustomer = useFormik({
    initialValues: EditCustomerShippingAddressInitialValues,
    validationSchema: EditCustomerShippingAddressSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const {
        shippingAddress: shippingaddressId,
        shippingZipcode: shipping_zipcode,
        shippingCity: shipping_cityId,
      } = values || {};

      let obj = {
        id,
        address: {
          shippingaddressId,
          shipping_zipcode,
          shipping_cityId: shipping_cityId,
        },
      };

      enableSaveLoading();
      const editShippingAddressCustomerResponse =
        await editShippingAddressCustomerApi(obj);

      disableSaveLoading();
      const { message, data, Error } =
        editShippingAddressCustomerResponse || {};

      if (Error) {
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setAddressEdited(true);
      window.scrollTo(0, 0);
      setTimeout(() => {
        handleCloseOfEditShippingAddressCustomerModal();
      }, 2500);
    },
  });

  return (
    <>
      <Modal
        size="md"
        show={open}
        onHide={onCloseModal}
        aria-labelledby="example-modal-sizes-title-lg"
        // dialogClassName="addvendor-modal modal"
        scrollable={true}
      >
        <Modal.Header
          className="d-flex justify-content-between align-items-center"
          // toggle={onCloseModal}
        >
          <h3 className="modal-title" id="exampleModalLabel2">
            <FormattedMessage id="TITLE.EDIT.SHIPPING.ADDRESS" />
          </h3>
          <Button
            variant="link p-0 btn-icon"
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <X size={20} className="text-dark mr-0 pr-0" />
          </Button>
        </Modal.Header>
        <Modal.Body className="pb-0">
          {addressEdited && (
            <div className="notification icon inline success fs-16">
              <Check size={8} className="text-white"></Check>{" "}
              <FormattedMessage id="TITLE.SHIPPING.ADDRESS.EDITED.SUCCESSFULLY" />
            </div>
          )}
          <Form autoComplete="off">
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor="Status">
                    <FormattedMessage id="TITLE.ADDRESS" />
                  </Form.Label>{" "}
                  <span className="text-danger">*</span>
                  <Form.Control
                    id="shippingAddress"
                    name="shippingAddress"
                    placeholder="Shipping Address"
                    className="form-control"
                    {...formikEditCustomer.getFieldProps("shippingAddress")}
                  />
                  {formikEditCustomer.touched.shippingAddress &&
                  formikEditCustomer.errors.shippingAddress ? (
                    <div className="text-danger">
                      {formikEditCustomer.errors.shippingAddress}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor="city">
                    <FormattedMessage id="TITLE.CITY" />
                  </Form.Label>
                  <Select
                    value={selectedShippingCity}
                    onChange={(data) => {
                      setSelectedShippingCity(data);
                      formikEditCustomer.setFieldValue(
                        "shippingCity",
                        data.value
                      );
                    }}
                    isSearchable={true}
                    placeholder="Select City"
                    options={cityList}
                    id="city"
                    name="shippingCity"
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                  {formikEditCustomer.touched.shippingCity &&
                  formikEditCustomer.errors.shippingCity ? (
                    <div className="text-danger">
                      {formikEditCustomer.errors.shippingCity}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor="Status">
                    <FormattedMessage id="TITLE.ZIP.POSTAL.CODE" />
                  </Form.Label>{" "}
                  <span className="text-danger">*</span>
                  <Form.Control
                    id="shippingZipcode"
                    name="shippingZipcode"
                    type="number"
                    placeholder="Zip/Postal Code"
                    className="form-control"
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    onPaste={(evt) => {
                      const values = evt.clipboardData.getData("text");
                      const nonAlpha = values.replace(/[^\d.-]/g, "");
                      evt.preventDefault();
                      formikEditCustomer.setFieldValue(
                        "shippingZipcode",
                        nonAlpha
                      );
                    }}
                    {...formikEditCustomer.getFieldProps("shippingZipcode")}
                  />
                  {formikEditCustomer.touched.shippingZipcode &&
                  formikEditCustomer.errors.shippingZipcode ? (
                    <div className="text-danger">
                      {formikEditCustomer.errors.shippingZipcode}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success mr-3 btn-inventory"
            onClick={() => {
              formikEditCustomer.handleSubmit();
            }}
            disabled={isSaveLoading}
          >
            <FormattedMessage id="TITLE.SAVE" />
            {isSaveLoading && (
              <ButtonLoader className={"mr-1 ml-1"}></ButtonLoader>
            )}
          </Button>
          <Button
            type="button"
            variant="tertiary text-dark"
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <FormattedMessage id="TITLE.CANCEL" />
          </Button>
        </Modal.Footer>
      </Modal>
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
