import React, { useContext, useState } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import { X, Check } from "react-feather";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { AddProductUnitSchema } from "../helper/FormikSchema";
import { addProductUnitApi } from "../axios/services/services";
import { ErrorModal } from "../components/common/modals/ErrorModal";
import { ButtonLoader } from "../helper/ButtonLoader";
import { FormattedMessage, useIntl } from "react-intl";
import {
  getErrorMessageBasedOnLanguage,
  setActiveMenu,
} from "../constants/utils";
import { unauthorization } from "../constants/utils";
import { MENUITEMS } from "../constants/menu";
import { UIContext } from "../helper/UIContext";
import { SET_TIMEOUT } from "../constants/const";

export function AddNewUnitModal({
  searchedProductUnitName,
  onCloseModal,
  open,
  handleCloseOfAddNewProductUnitModal,
}) {
  let AddProductUnitInitialValues = {
    name: searchedProductUnitName,
  };

  const history = useHistory();
  const uiContext = useContext(UIContext);
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isNewProductUnitAdded, setNewProductUnitAdded] = useState(false);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const intl = useIntl();

  const formikAddProductUnit = useFormik({
    // enableReinitialize: true,
    initialValues: AddProductUnitInitialValues,
    validationSchema: AddProductUnitSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      const { name } = values || {};
      let addProductUnitObj = {
        name,
      };
      const addProductUnitResponse = await addProductUnitApi(addProductUnitObj);
      const { data, Error } = addProductUnitResponse || {};

      if (Error) {
        disableLoading();
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setNewProductUnitAdded(true);
      setTimeout(() => {
        disableLoading();
        window.scrollTo(0, 0);
        handleCloseOfAddNewProductUnitModal(data);
      }, SET_TIMEOUT);
    },
    onReset: (values, { resetForm }) => {},
  });

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  return (
    <>
      <Modal
        size="md"
        show={open}
        onHide={onCloseModal}
        dialogClassName="inventory-small-modal"
        animation={false}
      >
        <Form
          autoComplete="off"
          onSubmit={(event) => {
            event.preventDefault();
            window.scrollTo(0, 0);
            formikAddProductUnit.handleSubmit();
          }}
        >
          <Modal.Header className="d-flex justify-content-between align-items-center">
            <h3 className="modal-title" id="exampleModalLabel2">
              <FormattedMessage id="TITLE.ADD.NEW.PRODUCT.UNIT" />
            </h3>
            <Button
              variant="link p-0 btn-icon"
              onClick={() => onCloseModal("VaryingMdo")}
            >
              <X size={20} className="text-dark mr-0 pr-0" />
            </Button>
          </Modal.Header>
          <Modal.Body>
            {isNewProductUnitAdded && (
              <div className="notification icon inline success fs-16">
                <Check size={8} className="text-white"></Check>
                <FormattedMessage id="TITLE.NEW.PRODUCT.UNIT.CREATED.SUCCESSFULLY" />
              </div>
            )}

            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor="productUnitname">
                    <FormattedMessage id="TITLE.PRODUCT.UNIT.NAME" />{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="productUnitname"
                    required
                    autoFocus
                    autoComplete="off"
                    placeholder={intl.formatMessage({ id: "TITLE.UNIT.NAME" })}
                    {...formikAddProductUnit.getFieldProps("name")}
                  />
                  {formikAddProductUnit.touched.name &&
                  formikAddProductUnit.errors.name ? (
                    <div className="text-danger">
                      {formikAddProductUnit.errors.name}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="success" disabled={isLoading}>
              <FormattedMessage id="TITLE.SAVE" />
              {isLoading && <ButtonLoader className="mr-1 ml-1" />}
            </Button>
            <Button
              type="button"
              variant="tertiary text-dark"
              onClick={() => onCloseModal("VaryingMdo")}
            >
              <FormattedMessage id="TITLE.CANCEL" />
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
