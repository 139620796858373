import React from "react";
import { Table, Image } from "react-bootstrap";
import {
  capitalizeFirstLetter,
  amountFormatter,
} from "../../../constants/utils";
import { SectionLoader } from "../../../helper/SectionLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import nodatafoundImage from "../../../assets/images/dashboard/nodata-found.svg";
import { FormattedMessage, useIntl } from "react-intl";

export function ProductView({
  categoryDetails,
  handlePaginationOfProductListView,
  isLoadingProductListView,
  isLoadMoreProductListView,
}) {
  const intl = useIntl();
  const { name, total_products, description, products } = categoryDetails || {};

  return (
    <>
      {isLoadingProductListView && <SectionLoader />}
      <div id="scrollableDiv" className="column-body custom-scrollbar">
        {!isLoadingProductListView && (
          <InfiniteScroll
            scrollableTarget="scrollableDiv"
            dataLength={products ? products.length : 0}
            next={() => {
              handlePaginationOfProductListView();
            }}
            hasMore={isLoadMoreProductListView}
            loader={
              <h4 style={{ textAlign: "center" }} className="p-1">
                <FormattedMessage id="TITLE.LOADING" />
                ...
              </h4>
            }
          >
            <div className="view-body">
              <div className="mb-3">
                <h3 className="page-title">
                  {name && capitalizeFirstLetter(name)}
                </h3>
                <span className="text-dark">
                  {total_products &&
                    `${total_products} ${intl.formatMessage({
                      id: "TITLE.PRODUCT.S",
                    })}`}
                </span>
              </div>
              <div className="mb-5">
                <span className="text-muted">
                  {description && <FormattedMessage id="TITLE.DESCRIPTION" />}
                </span>
                <p className="text-black">{description}</p>
              </div>
              <Table responsive className="viewpo-table table">
                <thead>
                  <tr>
                    <th scope="col">
                      <FormattedMessage id="TITLE.PRODUCT.NAME" />
                    </th>
                    <th scope="col">
                      <FormattedMessage id="TITLE.SKU" />
                    </th>
                    <th scope="col">
                      <FormattedMessage id="TITLE.PRICE" />
                    </th>
                    <th scope="col" className="text-right">
                      <FormattedMessage id="TITLE.STOCK.ON.HAND" />
                    </th>
                    <th scope="col" className="text-right">
                      <FormattedMessage id="TITLE.REORDER.LEVEL" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {products &&
                    products.map((product, index) => (
                      <tr>
                        <td className="text-dark">{product?.name}</td>
                        <td className="digits">
                          {product?.stock_keeping_unit}
                        </td>
                        <td>{amountFormatter(product?.price)}</td>
                        <td className="text-right">
                          {product?.available_quantity}
                        </td>
                        <td className="text-right">{product?.reorder_level}</td>
                      </tr>
                    ))}
                  {products &&
                    products.length === 0 &&
                    !isLoadingProductListView && (
                      <>
                        <tr>
                          <td className="no-record text-center" colSpan={5}>
                            <Image
                              src={nodatafoundImage}
                              alt="No Data Found"
                              class="img-fluid"
                              style={{ maxHeight: "250px" }}
                            />
                            <span className="d-block">
                              <FormattedMessage id="TITLE.NO.RECORDS.FOUND" />
                            </span>
                          </td>
                        </tr>
                      </>
                    )}
                </tbody>
              </Table>
            </div>
          </InfiniteScroll>
        )}
      </div>{" "}
    </>
  );
}

export default ProductView;
